import "./index.scss";
import React from "react";
import { Device, Slot } from "../../../../../models";
import { Link, useHistory } from "react-router-dom";

import Routes from "../../../../../../utils/routes";
import { media_url } from "../../../../../../service";
import { useEffect } from "react";

type Props = {
  device: Device;
  user_allowed: boolean;
  is_initial_fetching: boolean;
  slots: Slot[];
  // total_stock: number;
  // total_price: number;
  // setShowConfirmUpdateAllDialog: (show: boolean) => void;
  setSelectedSlot: (slot: Slot) => void;
};

const SlotsView = (props: Props) => {
  const history = useHistory();

  // // make total_price number separated by commas (e.g. 1,000,000)
  // const total_price_string = props.total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <div className="slots-lay">
      {props.is_initial_fetching && (
        <div className="device-detail-item-loader loading-shimmer" />
      )}
      {/* <div className='slots-header'>
        <span className='slots-header-text'>{`${props.slots.length} slots`}</span>
        <span className='slots-header-text'>{`${props.total_stock} in stock`}</span>
        <span className='slots-header-text'>{`${total_price_string} ${props.device.currency} total price`}</span>
        {props.user_allowed &&
          <span className='slots-header-text header-btn' onClick={(e) => {
            e.preventDefault();
            props.setShowConfirmUpdateAllDialog(true);
          }}>Full Load Machine</span>
        }
      </div> */}
      <div className="slots-contents">
        {props.slots.map((slot, index) => {
          return (
            <SlotListItem
              user_allowed={props.user_allowed}
              slot={slot}
              currency={props.device.currency}
              device_id={props.device.id}
              device_username={props.device.username}
              selected={history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/slots/${slot.slot_number}`
              )}
              key={index + "-slot-" + slot.slot_number}
              onClick={(slot: Slot) => props.setSelectedSlot(slot)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SlotsView;

type SlotListItemProps = {
  user_allowed: boolean;
  slot: Slot;
  currency: string;
  device_id: string;
  device_username: string;
  selected: boolean;
  onClick: (slot: Slot) => void;
};

const SlotListItem = (props: SlotListItemProps) => {
  useEffect(() => {
    if (props.selected) {
      props.onClick(props.slot);
    }
  }, [props.selected]);

  const image_url =
    props.slot.brand_image_title !== null && props.slot.brand_image_title !== ""
      ? `${media_url}${props.device_id}/images/${props.slot.brand_image_title}`
      : require("../../../../../../assets/images/bottle-stub.png");
  const total_price_string = props.slot.price
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return (
    <div className="slot-item">
      <Link
        to={`${Routes.Devices}/${props.device_username}/slots/${props.slot.slot_number}`}
        className="slot-item-main blue-grad"
        onClick={(e) => {
          // e.preventDefault();
          props.onClick(props.slot);
        }}
      >
        <span className="brand-name-text">{props.slot.brand_name}</span>
        <div className="left-lay">
          <span className="left-text">No. {props.slot.slot_number}</span>
        </div>
        <div className="row-lay">
          <span className="row-text">{props.slot.quantity} In Stock</span>
        </div>
        <div className="row-lay">
          <span className="price-text" title={"Price"}>
            {total_price_string + " " + props.currency}
          </span>
        </div>
        <div className="img-lay">
          <div className="img-top" />
          <img className="slot-item-img" src={image_url} alt="bottle" />
        </div>
      </Link>
    </div>
  );
};
