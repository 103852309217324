import { OnResult } from './listeners';
import { UserRole } from '../components/models';
import axios from 'axios';

export const media_url = "https://vm.imc.co.tz/uploads/";
const host = "https://vm.imc.co.tz/api/v2/";
export const api_key = "axiopu45yru54piegh048yruht3wp";

export default class DatabaseManager {

    private static instance: DatabaseManager;

    public static getInstance(): DatabaseManager {
        if (!DatabaseManager.instance) {
            DatabaseManager.instance = new DatabaseManager();
        }
        return DatabaseManager.instance;
    }

    private cancelToken: any = axios.CancelToken;
    private source: any = this.cancelToken.source();

    public loginUser(email: string, password: string, callback: OnResult<any>): void {
        // window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('action', "user_login");

        try {
            const chunk = (new Date()).valueOf();

            axios({
                url: host + 'auth?t=' + chunk,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                .then((result: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    console.error("> axios error: ", err.message);
                    callback.onError(err.message);
                })
                .catch((thrown) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                        console.error("> axios thrown: ", thrown);
                    }
                });

        } catch (e: any) {
            // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
            callback.onError(e);
        }
    }

    public signupUser(name: string, email: string, password: string, callback: OnResult<any>): void {
        // window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('action', "user_signup");

        try {
            const chunk = (new Date()).valueOf();

            axios({
                url: host + 'auth?t=' + chunk,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                .then((result: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    console.error("> axios error: ", err.message);
                    callback.onError(err.message);
                })
                .catch((thrown) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                        console.error("> axios thrown: ", thrown);
                    }
                });

        } catch (e: any) {
            // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
            callback.onError(e);
        }
    }

    public sendPasswordResetEmail(email: string, callback: OnResult<any>): void {

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('email', email);
        formData.append('action', "password_recovery");

        try {
            const chunk = (new Date()).valueOf();

            axios({
                url: host + 'auth?t=' + chunk,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                .then((result: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    console.error("> axios error: ", err.message);
                    callback.onError(err.message);
                })
                .catch((thrown) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                        console.error("> axios thrown: ", thrown);
                    }
                });

        } catch (e: any) {
            // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
            callback.onError(e);
        }
    }

    public recoverPassword(email: string, password: string, sessionId: string, callback: OnResult<any>): void {

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('session_id', sessionId);
        formData.append('action', "password_recovery_change");

        try {
            const chunk = (new Date()).valueOf();

            axios({
                url: host + 'auth?t=' + chunk,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                .then((result: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    console.error("> axios error: ", err.message);
                    callback.onError(err.message);
                })
                .catch((thrown) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                        console.error("> axios thrown: ", thrown);
                    }
                });

        } catch (e: any) {
            // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
            callback.onError(e);
        }
    }

    public changePassword(
        user_id: string,
        old_password: string,
        password: string,
        callback: OnResult<any>): void {

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('old_password', old_password);
        formData.append('password', password);
        formData.append('action', "change_password");

        try {
            const chunk = (new Date()).valueOf();

            axios({
                url: host + 'auth?t=' + chunk,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                .then((result: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    console.error("> axios error: ", err.message);
                    callback.onError(err.message);
                })
                .catch((thrown) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                        console.error("> axios thrown: ", thrown);
                    }
                });

        } catch (e: any) {
            // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
            callback.onError(e);
        }
    }

    public changeName(
        user_id: string,
        name: string,
        callback: OnResult<any>): void {

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('name', name);
        formData.append('action', "change_name");

        try {
            const chunk = (new Date()).valueOf();

            axios({
                url: host + 'auth?t=' + chunk,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                .then((result: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    console.error("> axios error: ", err.message);
                    callback.onError(err.message);
                })
                .catch((thrown) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                        console.error("> axios thrown: ", thrown);
                    }
                });

        } catch (e: any) {
            // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
            callback.onError(e);
        }
    }

    public getUserDevices(user_id: string, callback: OnResult<any>): void {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "user_devices");

        try {
            const chunk = (new Date()).valueOf();

            axios({
                url: host + 'activity?t=' + chunk,
                method: 'post',
                responseType: 'json',
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                cancelToken: this.source.token
            })
                .then((result: any) => {
                    window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    // console.log("daily_device_data: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    console.error("> axios error: ", err.message);
                    callback.onError(err.message);
                })
                .catch((thrown) => {
                    window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });

        } catch (e: any) {
            window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
            callback.onError(e);
        }
    }

    public cancelRequests() {
        this.source.cancel('Operation canceled by the user.');
        this.source = this.cancelToken.source();
    }

    public getUserDevice(user_id: string, device_id: string): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('device_id', device_id);
        formData.append('action', "user_device");

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getMutualDevices(user_id: string, device_ids: string[]): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "mutual_devices");
        if (device_ids.length > 0) {
            formData.append('user_devices', device_ids.join(",")); // "1,2,3,4,5"
        } else {
            formData.append('user_devices', "");
        }

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public updateDevice(
        user_id: string,
        device_id: string,
        name: string,
        location_name: string,
        currency: string,
        phone_number: string,
        video_file_1: File | null, previous_video_title_1: string,
        video_file_2: File | null, previous_video_title_2: string,
        video_file_3: File | null, previous_video_title_3: string,
        video_file_4: File | null, previous_video_title_4: string,
        video_file_5: File | null, previous_video_title_5: string,
        business_name: string,
        country: string,
        region: string,
        address: string,
        tin_number: string,
        vrn_number: string
    ): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('device_id', device_id);
        formData.append('device_name', name.trim());
        formData.append('device_location_name', location_name.trim());
        formData.append('device_currency', currency.trim());
        formData.append('device_phone_number', phone_number.trim());
        formData.append('device_business_name', business_name.trim());
        formData.append('device_country', country.trim());
        formData.append('device_region', region.trim());
        formData.append('device_address', address.trim());
        formData.append('device_tin_number', tin_number);
        formData.append('device_vrn_number', vrn_number);
        //
        if (video_file_1) {
            formData.append('video_file_1', video_file_1);
        }
        if (video_file_2) {
            formData.append('video_file_2', video_file_2);
        }
        if (video_file_3) {
            formData.append('video_file_3', video_file_3);
        }
        if (video_file_4) {
            formData.append('video_file_4', video_file_4);
        }
        if (video_file_5) {
            formData.append('video_file_5', video_file_5);
        }
        if (previous_video_title_1) {
            formData.append('pre_video_title_1', previous_video_title_1);
        }
        if (previous_video_title_2) {
            formData.append('pre_video_title_2', previous_video_title_2);
        }
        if (previous_video_title_3) {
            formData.append('pre_video_title_3', previous_video_title_3);
        }
        if (previous_video_title_4) {
            formData.append('pre_video_title_4', previous_video_title_4);
        }
        if (previous_video_title_5) {
            formData.append('pre_video_title_5', previous_video_title_5);
        }
        //
        formData.append('action', "update_device");

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    // cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public updateDeviceSlot(
        user_id: string,
        device_id: string,
        slot_number: number,
        brand_name: string,
        price: number,
        quantity: number,
        slot_image_file: File | null, previous_slot_image_title: string,
        slot_video_file: File | null, previous_slot_video_title: string
    ): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('device_id', device_id);
        formData.append('slot_number', slot_number.toString());
        formData.append('brand_name', brand_name.replaceAll(',', '').trim());
        formData.append('price', price.toString());
        formData.append('quantity', quantity.toString());
        //
        if (slot_image_file) {
            formData.append('image_file', slot_image_file);
        }
        if (slot_video_file) {
            formData.append('video_file', slot_video_file);
        }
        if (previous_slot_image_title !== '') {
            formData.append('pre_image_title', previous_slot_image_title);
        }
        if (previous_slot_video_title !== '') {
            formData.append('pre_video_title', previous_slot_video_title);
        }
        //
        formData.append('action', "device_update_slot");

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    // cancelToken: this.source.token
                })
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public updateDeviceAllSlots(
        user_id: string,
        device_id: string
    ): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('device_id', device_id);
        formData.append('action', "device_update_all_slots");

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    // cancelToken: this.source.token
                })
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public updateMemberAccess(
        user_id: string,
        member_id: string,
        device_id: string,
        role: UserRole,
        allowed: boolean
    ): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('member_id', member_id);
        formData.append('device_id', device_id);
        formData.append('member_allowed', allowed ? '1' : '0');
        formData.append('member_role', role);
        formData.append('action', "update_member_access");

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    // cancelToken: this.source.token
                })
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public addMemberToDevice(
        user_id: string,
        member_id: string,
        device_id: string,
        role: UserRole,
        allowed: boolean
    ): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('member_id', member_id);
        formData.append('device_id', device_id);
        formData.append('member_allowed', allowed ? '1' : '0');
        formData.append('member_role', role);
        formData.append('action', "add_member_to_device");

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    // cancelToken: this.source.token
                })
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public removeMemberFromDevice(
        user_id: string,
        member_id: string,
        device_id: string
    ): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));

        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('member_id', member_id);
        formData.append('device_id', device_id);
        formData.append('action', "remove_member_from_device");

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    // cancelToken: this.source.token
                })
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getDashboardData(user_id: string, device_ids: string[], start_time: number, end_time: number): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "dashboard_data");
        formData.append('start_time', start_time.toString());
        formData.append('end_time', end_time.toString());
        if (device_ids.length > 0) {
            formData.append('user_devices', device_ids.join(",")); // "1,2,3,4,5"
        } else {
            formData.append('user_devices', "");
        }

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getDeviceOrdersData(user_id: string, device_id: string, start_time: number, end_time: number): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "device_orders_data");
        formData.append('start_time', start_time.toString());
        formData.append('end_time', end_time.toString());
        formData.append('device_id', device_id);
        formData.append('sort_order', end_time > 0 ? 'ASC' : 'DESC');

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getDeviceOrderData(user_id: string, device_id: string, order_id: string): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "device_order_data");
        formData.append('order_id', order_id);
        formData.append('device_id', device_id);

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });
            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getDeviceLogsData(user_id: string, device_id: string, start_time: number, end_time: number): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "device_logs_data");
        formData.append('start_time', start_time.toString());
        formData.append('end_time', end_time.toString());
        formData.append('device_id', device_id);
        formData.append('sort_order', end_time > 0 ? 'ASC' : 'DESC');

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getDeviceLogData(user_id: string, device_id: string, log_id: string): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "device_log_data");
        formData.append('log_id', log_id);
        formData.append('device_id', device_id);

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });
            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getDevicectivitiesData(user_id: string, device_id: string, start_time: number, end_time: number): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "device_activities_data");
        formData.append('start_time', start_time.toString());
        formData.append('end_time', end_time.toString());
        formData.append('device_id', device_id);
        formData.append('sort_order', end_time > 0 ? 'ASC' : 'DESC');

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getDeviceLastActivityData(user_id: string, device_id: string, log_id: string): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "device_last_activity_data");
        formData.append('device_id', device_id);

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });
            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getUsersList(user_id: string, search_term: string): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('user_id', user_id);
        formData.append('action', "users_list");
        formData.append('search_term', search_term);

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });
            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public logoutUser(callback: OnResult<any>): void {
        try {
            localStorage.removeItem('vending-un');
            localStorage.removeItem('vending-uid');
            localStorage.removeItem('vending-ur');
        } catch (e: any) {
            callback.onError(e);
        }

        setTimeout(() => {
            callback.onResult(true);
        }, 1000);
    }

    public getShopDevicesList(
        search_term: string,
        search_country: string,
        search_region: string,
        search_address: string,
    ): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('action', "shop_devices_list");
        formData.append('search_term', search_term);
        formData.append('search_country', search_country);
        formData.append('search_region', search_region);
        formData.append('search_address', search_address);

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });
            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getShopDeviceData(device_username: string): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const formData = new FormData();
        formData.append('key', api_key);
        formData.append('action', "shop_device_data");
        formData.append('device_username', device_username);

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'activity?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });
            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }
}