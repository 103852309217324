import "../activities/index.scss";

import { Device, ListObject, Log, UserData } from "../../../../../models";
import { Link, useHistory } from "react-router-dom";
import {
  RefObject,
  SyntheticEvent,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import React from "react";
import DatabaseManager from "../../../../../../service";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { DateRangePicker } from "rsuite";
import { FaTimes } from "react-icons/fa";
import Period from "../../../../../../utils/period";
import Routes from "../../../../../../utils/routes";
import { TableVirtuoso } from "react-virtuoso";
import addDays from "date-fns/addDays";
import moment from "moment";
import subDays from "date-fns/subDays";

const { combine, before, afterToday } = DateRangePicker;

type LogsProps = {
  scroller: HTMLElement;
  device: Device;
  user_data: UserData;
  refreshId: number;
  show: boolean;
  onListChanged: (list_size: number) => void;
};

const LogsTab = (props: LogsProps) => {
  const history = useHistory();
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const databaseManager: DatabaseManager = new DatabaseManager();
  const root: RefObject<HTMLDivElement> = useRef(null);
  const modal_ref: RefObject<HTMLDivElement> = useRef(null);

  const wasViewInitialised = useRef(false);
  const [showInputModal, setShowInputModal] = useState(false);
  const [selected_date, setSelectedDate] = useState<DateRange>([
    Period.getTodayStartTime(),
    Period.getTodayEndTime(),
  ]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(true);
  const lastItemDateCreated = useRef(0);
  const [error_loading, setErrorLoading] = useState<string | null>(null);
  const [display_start_time, setDisplayStartTime] = useState<string>("");
  const [display_end_time, setDisplayEndTime] = useState<string>("");

  const [viewBy, setViewBy] = useState<"date" | "default">("default");
  const viewByRef = useRef<"date" | "default">("default");

  const [list, setList] = useState<ListObject<Log>[]>([]);
  const [selected_log, setSelectedLog] = useState<Log | null>(null);

  useEffect(() => {
    didMount.current = true;
    if (!wasViewInitialised.current) {
      if (props.show) {
        init();
      }
    }
    return () => {
      didMount.current = false;
    };
  }, []);

  useEffect(() => {
    if (!didMount.current) return;
    if (props.show) {
      if (!wasViewInitialised.current) {
        init();
      } else {
        if (props.refreshId > 0) {
          onRefreshClick();
        }
      }
    }
  }, [props.refreshId]);

  useEffect(() => {
    if (!didMount.current) return;
    if (props.show) {
      if (!wasViewInitialised.current) {
        init();
      }
    }
  }, [props.show]);

  const init = () => {
    wasViewInitialised.current = true;
    listenForInputFocus();
    if (viewByRef.current === "default") {
      getDataByDefault(true);
    } else {
      getDataByDate(selected_date, true);
    }
  };

  const listenForInputFocus = () => {
    setTimeout(() => {
      if (!didMount.current) {
        return;
      }
      if (root.current) {
        // find first input element
        const input_element = root.current.querySelector(
          "input"
        ) as HTMLInputElement;
        if (input_element) {
          // detect on focus
          input_element.addEventListener("focus", () => {
            if (!didMount.current) {
              return;
            }
            // console.log('input_element.focus()');
            setShowInputModal(true);
            forceUpdate();

            setTimeout(() => {
              if (!didMount.current) {
                return;
              }
              // find element by class name
              const buttons_container = document.querySelector(
                ".rs-picker-toolbar"
              ) as HTMLDivElement;
              if (buttons_container) {
                // get all buttons and add event listener for click
                const buttons = buttons_container.querySelectorAll("button");
                if (buttons && buttons.length > 0) {
                  for (let i = 0; i < buttons.length; i++) {
                    if (!didMount.current) {
                      return;
                    }
                    const button = buttons[i] as HTMLButtonElement;
                    if (button) {
                      button.addEventListener("click", () => {
                        if (!didMount.current) {
                          return;
                        }
                        // console.log('button.click()');
                        setShowInputModal(false);
                        forceUpdate();
                      });
                    }
                  }
                }
              }
            }, 1000);
          });
        }

        // detect ecscape key for this div
        document.addEventListener("keydown", (e) => {
          if (!didMount.current) {
            return;
          }
          if (e.keyCode === 27) {
            // console.log('dashboard_ref.current.keydown()');
            if (root.current) {
              if (!didMount.current) {
                return;
              }
              if (modal_ref.current) {
                // perform click
                modal_ref.current.click();
              } else {
                // console.log('modal not found');
                setShowInputModal(false);
                forceUpdate();
              }
            }
          }
        });
      }
    }, 100);
  };

  const onRefreshClick = () => {
    if (!didMount.current || isLoading) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/logs`
      )
    )
      return;
    lastItemDateCreated.current = 0;
    forceUpdate();
    if (viewByRef.current === "default") {
      getDataByDefault(true);
    } else {
      getDataByDate(selected_date, true);
    }
  };

  const loadMore = () => {
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/logs`
      )
    )
      return;
    if (!didMount.current) return;
    if (!isMoreDataAvailable) return;
    if (isLoading) return;
    if (viewByRef.current !== "default") return;
    // if (lastItemDateCreated.current <= 0) return;
    // getData(lastItemDateCreated.current - 1, 0, false);
    getDataByDefault(false);
  };

  const resetData = (dateRange: DateRange, fromDatePicker: boolean) => {
    if (!didMount.current) return;
    // const weeklyDateRange: DateRange = [Period.getPreviousSevenDaysItsFirstDay(), Period.getTodayEndTime()];
    const todayDateRange: DateRange = [
      Period.getDayStartTime(),
      Period.getTodayEndTime(),
    ];

    setIsLoading(false);
    const _selected_date = fromDatePicker ? dateRange : todayDateRange;
    setSelectedDate(_selected_date);
    forceUpdate();

    setTimeout(() => {
      if (!didMount.current) return;
      if (
        !history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/logs`
        )
      )
        return;
      getDataByDate(_selected_date, true);
    }, 500);
  };

  const getDataByDate = (dateRange: DateRange, is_refresh: boolean) => {
    if (!didMount.current) return;
    if (isLoading) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/logs`
      )
    )
      return;
    const _selected_date: DateRange = dateRange;
    const start_date: Date = _selected_date[0]
      ? _selected_date[0]
      : Period.getTodayStartTime();
    const end_date: Date = _selected_date[1]
      ? _selected_date[1]
      : Period.getTodayEndTime();

    const the_start_date: number = start_date.valueOf();
    const the_end_date: number = end_date.valueOf();
    getData(the_start_date, the_end_date, is_refresh);
  };

  const getDataByDefault = (is_refresh: boolean) => {
    if (!didMount.current) return;
    if (isLoading) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/logs`
      )
    )
      return;

    const end_of_today: Date = Period.getTodayEndTime();
    const the_end_of_today_date: number = end_of_today.valueOf() + 1;

    if (is_refresh) {
      getData(the_end_of_today_date, 0, true);
    } else {
      const next_date =
        lastItemDateCreated.current - 1 > 0
          ? lastItemDateCreated.current - 1
          : the_end_of_today_date;
      getData(next_date, 0, false);
    }
  };

  const getData = (
    start_time: number,
    end_time: number,
    is_refresh: boolean
  ) => {
    if (!didMount.current) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/logs`
      )
    )
      return;
    if (start_time > 0) {
      if (is_refresh) {
        setList([]);
        setIsInitialLoading(true);
      }
      setIsLoading(true);
      setErrorLoading(null);
      forceUpdate();
      // databaseManager.cancelRequests();
      databaseManager
        .getDeviceLogsData(
          props.user_data.id,
          props.device.id,
          start_time,
          end_time
        )
        .then((result: any) => {
          if (!didMount.current) return;
          // console.log("getDeviceOrdersData: result: ", result);
          // setIsLoading(false);
          // setIsInitialLoading(false);
          if (result.success && result.success.data) {
            setErrorLoading(null);
            const _list: ListObject<Log>[] = is_refresh ? [] : list;
            const logs: Log[] = result.success.data;
            if (logs && logs.length > 0) {
              setIsMoreDataAvailable(true);
              for (let i = 0; i < logs.length; i++) {
                const log: Log = logs[i];
                const obj: ListObject<Log> = {
                  id: log.id,
                  type: "item",
                  data: log,
                };
                const existing_pos = isItemExist(log.id, _list);
                if (existing_pos != -1) {
                  _list[existing_pos] = obj;
                } else {
                  _list.push(obj);
                }
              }
              // sort list by date_created in descending order
              _list.sort((a, b) => {
                const a_date = (a.data as Log).date_created;
                const b_date = (b.data as Log).date_created;
                return b_date - a_date;
              });
              setTimeout(() => {
                const _first_item_date_created =
                  _list.length > 0 ? (_list[0].data as Log).date_created : 0;
                const _last_item_date_created =
                  _list.length > 0
                    ? (_list[_list.length - 1].data as Log).date_created
                    : 0;
                if (_list.length > 0) {
                  lastItemDateCreated.current = _last_item_date_created;
                }
                let _title = "";
                if (
                  _first_item_date_created > 0 &&
                  _last_item_date_created > 0
                ) {
                  // get first date using moment in format of "DD-MM-YYYY at HH:mm"
                  const _first_date = moment(_first_item_date_created).format(
                    "DD-MM-YYYY HH:mm"
                  );
                  // get last date using moment in format of "DD-MM-YYYY at HH:mm"
                  const _last_date = moment(_last_item_date_created).format(
                    "DD-MM-YYYY HH:mm"
                  );
                  _title = ` Between ${_last_date} to ${_first_date}`;
                  setDisplayStartTime(_first_date);
                  setDisplayEndTime(_last_date);
                }
                setList(_list);
                setIsLoading(false);
                setIsInitialLoading(false);
                forceUpdate();
              }, 100);
            } else {
              setIsLoading(false);
              setIsInitialLoading(false);
              setIsMoreDataAvailable(false);
            }
          } else {
            setIsLoading(false);
            setIsInitialLoading(false);
            setErrorLoading("Couldn't load data, please try again");
          }
          forceUpdate();
        });
    }
  };

  const isItemExist = (id: string, list: ListObject<Log>[]) => {
    const filtered_list = list.filter((obj: ListObject<Log>) => obj.id === id);
    if (filtered_list && filtered_list.length > 0) {
      return list.indexOf(filtered_list[0]);
    }
    return -1;
  };

  const datePicker = () => {
    const lower_date = new Date();
    lower_date.setFullYear(2022, 5, 1);
    lower_date.setHours(0, 0, 0, 0);

    return (
      <DateRangePicker
        format={"dd-MM-yyyy HH:mm:ss"}
        appearance="subtle"
        placeholder="Select date range"
        character="  to  "
        // size="sm"
        defaultValue={selected_date}
        cleanable={false}
        oneTap={false}
        open={showInputModal}
        disabledDate={
          combine &&
          combine(before && before(lower_date), afterToday && afterToday())
        }
        style={{
          backgroundColor: "var(--color-secondary)",
        }}
        ranges={[
          {
            label: "Yesterday",
            value: [
              addDays(Period.getDayStartTime(), -1),
              addDays(Period.getDayEndTime(), -1),
            ],
          },
          {
            label: "Today",
            value: [Period.getTodayStartTime(), Period.getTodayEndTime()],
          },
          // {
          //   label: 'Tomorrow',
          //   value: [dateFns.addDays(new Date(), 1), dateFns.addDays(new Date(), 1)]
          // },
          {
            label: "Last 7 days",
            value: [
              subDays(Period.getDayStartTime(), 6),
              Period.getDayEndTime(),
            ],
          },
          {
            label: "Last 30 days",
            value: [
              subDays(Period.getDayStartTime(), 29),
              Period.getDayEndTime(),
            ],
          },
        ]}
        onOpen={() => {
          // console.log('date-range-picker: opened');
          setShowInputModal(true);
        }}
        onChange={(date: DateRange | null) => {
          if (date === null) {
            return;
          }
          // console.log('date-range-picker: changed: ', date);
          resetData(date, true);
        }}
        onOk={(date: DateRange, event: SyntheticEvent<Element, Event>) => {
          if (date === null) {
            return;
          }
          event.preventDefault();
          // console.log('date-range-picker: ok: ', date);
          resetData(date, true);
        }}
        onClose={() => {
          setShowInputModal(false);
        }}
      />
    );
  };

  const getDateView = (time: number) => {
    // get current time using moment
    // const current_time = moment();
    // get date of today using moment
    const today = moment().startOf("day").format("DD-MM-YYYY");
    // get date of yesterday using moment
    const yesterday = moment()
      .subtract(1, "days")
      .startOf("day")
      .format("DD-MM-YYYY");
    // get input date using moment
    const input_date = moment(time).format("DD-MM-YYYY");
    // get input time using moment
    const input_time = moment(time).format("HH:mm:ss");
    // check if input date is today
    if (input_date === today) {
      return ["Today", input_time];
    }
    // check if input date is yesterday
    if (input_date === yesterday) {
      return ["Yesterday", input_time];
    }
    return [input_date, input_time];
  };

  useEffect(() => {
    if (!didMount.current) return;
    props.onListChanged(list.length);
  }, [list.length]);

  const tableHeader = useRef<HTMLDivElement>(null);
  const tableFooter = useRef<HTMLDivElement>(null);

  return (
    <>
      {history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/logs/`
      ) &&
        history.location.pathname.length >
          `${Routes.Devices}/${props.device.username}/logs/`.length + 1 && (
          <LogInfo
            user_data={props.user_data}
            device={props.device}
            log={selected_log}
            onClose={() => {
              setSelectedLog(null);
              forceUpdate();
            }}
          />
        )}
      <div
        className="logs-main-lay"
        ref={root}
        style={{ display: props.show ? "flex" : "none" }}
      >
        {showInputModal && (
          <div
            className="logs-input-modal"
            ref={modal_ref}
            onClick={() => {
              if (!didMount.current) return;
              setShowInputModal(false);
            }}
          />
        )}
        <div
          className={`activities-tab-date-lay${
            viewBy === "default" && list.length > 0 && isMoreDataAvailable
              ? " with-load-btn"
              : ""
          }`}
        >
          <div className="left-lay">
            <div className="set-lay">
              <span className="set-lay-text">View By</span>
              <select
                className="set-lay-select"
                value={viewBy === "date" ? "date" : "default"}
                onChange={(e) => {
                  e.preventDefault();
                  let val = e.target.value.toString();
                  setViewBy(val === "date" ? "date" : "default");
                  viewByRef.current = val === "date" ? "date" : "default";
                  if (val === "date") {
                    listenForInputFocus();
                  }
                  forceUpdate();
                  setTimeout(() => {
                    // console.log('viewBy changed, calling onRefreshClick');
                    onRefreshClick();
                  }, 500);
                }}
              >
                <option value={"default"}>Default</option>
                <option value={"date"}>Date Range</option>
              </select>
            </div>
          </div>
          {viewBy === "date" && (
            <div className="range-display-lay">
              <div className="range-display-cont">{datePicker()}</div>
            </div>
          )}
        </div>
        {isInitialLoading && (
          <div className="loading-lay">
            <div className="def-loading-lay">
              <div className="def-loading-spinner" />
              <span className="def-loading-text">Loading...</span>
            </div>
          </div>
        )}
        {((!isLoading && list.length === 0) || error_loading) && (
          <div className="no-devices-lay">
            {!error_loading && (
              <span className="no-devices-text">
                No data available, try select different date
              </span>
            )}
            {error_loading && (
              <span
                className="no-devices-text error-text"
                onClick={onRefreshClick}
              >
                {error_loading}
              </span>
            )}
          </div>
        )}
        <div
          className="activities-tab-table-header"
          ref={tableHeader}
          style={{ display: list.length > 0 ? "flex" : "none" }}
        >
          <div
            className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-1"
            style={{ position: "sticky", left: 0, zIndex: 1 }}
          >
            <span className="activities-tab-table-header-seg">Log ID</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-2">
            <span className="activities-tab-table-header-seg">Date</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-3">
            <span className="activities-tab-table-header-seg">Type</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-4">
            <span className="activities-tab-table-header-seg">Message</span>
          </div>
        </div>
        <TableVirtuoso
          className="activities-tab-table-contents"
          style={{ display: "flex" }}
          customScrollParent={props.scroller}
          onScroll={(event) => {
            if (tableHeader.current) {
              tableHeader.current.scrollLeft = (
                event.currentTarget as any
              ).scrollLeft;
            }
            if (tableFooter.current) {
              tableFooter.current.scrollLeft = (
                event.currentTarget as any
              ).scrollLeft;
            }
          }}
          data={list}
          defaultItemHeight={80}
          fixedItemHeight={80}
          itemContent={(index: number, obj: ListObject<Log>) => {
            const log = obj.data as Log;
            return (
              <>
                {obj.data && (
                  <>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-1 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/logs/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedLog(log);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">{log.id}</span>
                      </Link>
                    </td>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-2 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/logs/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedLog(log);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {getDateView(log.date_created)[0]}
                        </span>
                        <span className="tab-item-main-seg-text">
                          {getDateView(log.date_created)[1]}
                        </span>
                      </Link>
                    </td>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-3 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/logs/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedLog(log);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {log.type.toUpperCase()}
                        </span>
                      </Link>
                    </td>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-4 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/logs/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedLog(log);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {log.info &&
                          log.info.message &&
                          log.info.message.length > 0
                            ? log.info.message
                            : ""}
                        </span>
                      </Link>
                    </td>
                  </>
                )}
              </>
            );
          }}
          endReached={loadMore}
        />
        <div
          className="activities-tab-table-footer"
          ref={tableFooter}
          style={{ display: viewBy === "default" ? "flex" : "none" }}
        >
          {isMoreDataAvailable && !isLoading && (
            <div
              className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-1"
              style={{ position: "sticky", left: 0, zIndex: 1 }}
            >
              <div
                className="activities-tab-table-footer-btn action-btn"
                onClick={loadMore}
              >
                <span className="activities-tab-table-footer-btn-text action-text">
                  Load More
                </span>
              </div>
            </div>
          )}
          {isLoading && (
            <div
              className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-1"
              style={{ position: "sticky", left: 0, zIndex: 1 }}
            >
              <div className="def-loading-lay">
                <div className="def-loading-spinner" />
                <span className="def-loading-text">Loading...</span>
              </div>
            </div>
          )}
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-2"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-3"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-4"></div>
        </div>
      </div>
    </>
  );
};

export default LogsTab;

type LogInfoProps = {
  user_data: UserData;
  device: Device;
  log: Log | null;
  onClose: () => void;
};

const LogInfo = (props: LogInfoProps) => {
  const history = useHistory();
  const databaseManager: DatabaseManager = new DatabaseManager();
  const didMount = useRef(false);

  const [is_updating, setIsUpdating] = useState(false);
  const [is_loading, setIsLoading] = useState(false);

  const [id, setId] = useState(props.log?.id || "");
  const [log, setLog] = useState(props.log || null);

  const handleKeyPress = (e: any) => {
    // go back if user press escape
    if (e.keyCode === 27 || e.key === "Escape") {
      if (!didMount.current) return;
      if (is_updating) return;
      if (
        history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/logs/${id}`
        )
      ) {
        props.onClose();
        if (history.length > 1) {
          history.goBack();
        } else {
          history.push(`${Routes.Devices}/${props.device.username}/logs`);
        }
      }
    }
  };

  useEffect(() => {
    didMount.current = true;
    document.addEventListener("keydown", handleKeyPress, false);
    let id = "";
    // get it from the url eg: /activities/username/orders/123
    if (
      history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/logs/`
      )
    ) {
      id = history.location.pathname.split(
        `${Routes.Devices}/${props.device.username}/logs/`
      )[1];
      if (id.indexOf("/") > 0) {
        id = id.split("/")[0];
      }
    }
    if (id) {
      getData(id);
    }
    return () => {
      didMount.current = false;
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);

  useEffect(() => {
    if (props.log) {
      setId(props.log.id);
      getData(props.log.id);
    }
  }, [props.log]);

  const getData = (id: string) => {
    if (!didMount.current) return;
    setIsLoading(true);

    databaseManager
      .getDeviceLogData(props.user_data.id, props.device.id, id)
      .then((result: any) => {
        if (!didMount.current) return;
        setIsLoading(false);
        if (result && result.success && result.success.data) {
          const data: Log | null =
            result.success.data.length > 0 ? result.success.data[0] : null;
          if (data) {
            setId(data.id);
            setLog(data);
          }
        }
      });
  };

  // function formatPrice(price: number): string {
  //   return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  return (
    <div
      className="log-info-main"
      id="log-info-main"
      onClick={(e) => {
        if (e.target === document.getElementById("log-info-main")) {
          if (is_updating) return;
          props.onClose();
          if (history.length > 1) {
            history.goBack();
          } else {
            history.push(`${Routes.Devices}/${props.device.username}/logs`);
          }
        }
      }}
    >
      <div className="info-contents">
        <div className="info-title-lay">
          <span className="info-text">{`Log Info`}</span>
          <FaTimes
            className="info-close-btn"
            title="close"
            onClick={(e) => {
              e.preventDefault();
              if (history.length > 1) {
                history.goBack();
              } else {
                history.push(`${Routes.Devices}/${props.device.username}/logs`);
              }
            }}
          />
        </div>
        {log && (
          <div className="form-contents">
            <div className="form-lay-main">
              <div className="form-lay">
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">ID</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">{id}</span>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Date Created</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">
                      {moment(log.date_created).format("DD-MM-YYYY")}
                    </span>
                    <span className="form-info-text">
                      {moment(log.date_created).format("HH:mm:ss")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-lay">
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Log Type</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">
                      {log.type.toUpperCase()}
                    </span>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Message</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">
                      {log.info &&
                      log.info.message &&
                      log.info.message.length > 0
                        ? log.info.message
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-lay-main">
              <div className="form-lay single-item">
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Details</span>
                  </div>
                  <div className="form-info-key-val-lay">
                    {/* loop through log.info in each key value pair */}
                    {log.info &&
                      Object.keys(log.info).map((key, index) => {
                        if (key !== "message") {
                          const value = (log.info as any)[key];
                          return (
                            <div key={index} className="form-info-key-val">
                              <div className="form-info-key-lay">
                                <span className="form-info-key">
                                  {key.replaceAll("_", " ")}
                                </span>
                              </div>
                              <div className="form-info-val-lay">
                                {/* if value is array, then loop through it */}
                                {Array.isArray(value) ? (
                                  <div className="form-info-val-array">
                                    {value.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="form-info-array-item"
                                        >
                                          <span className="form-info-array-text">
                                            {item}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <>
                                    {/* else if key contain date and not contain str, and value is number, then convert it to date */}
                                    {key.indexOf("date") > -1 &&
                                    key.indexOf("str") === -1 &&
                                    typeof value === "number" &&
                                    value > 0 ? (
                                      <div className="form-info-val-text-lay">
                                        <span className="form-info-val-text">
                                          {moment(value).format("DD-MM-YYYY")}
                                        </span>
                                        <span className="form-info-val-text">
                                          {moment(value).format("HH:mm:ss")}
                                        </span>
                                      </div>
                                    ) : // else if value is boolean then show it as yes or no
                                    typeof value === "boolean" ? (
                                      <div className="form-info-val-text-lay">
                                        <span className="form-info-val-text">
                                          {value ? "Yes" : "No"}
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="form-info-val-text-lay">
                                        <span className="form-info-val-text">
                                          {value}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
              </div>
            </div>
            {/* {payment &&
                <div className='form-lay-main'>
                  <div className='form-lay single-item'>
                    <div className='form-item'>
                      <div className='form-title-lay'>
                        <span className='form-title-text'>Payment</span>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-title-lay'>
                        <span className='form-title-text'>Date Created</span>
                      </div>
                      <div className='form-info-lay'>
                        <span className='form-info-text'>{moment(payment.date_created).format('DD-MM-YYYY')}</span>
                        <span className='form-info-text'>{moment(payment.date_created).format('HH:mm:ss')}</span>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-title-lay'>
                        <span className='form-title-text'>Date Completed</span>
                      </div>
                      <div className='form-info-lay'>
                        <span className='form-info-text'>{payment.date_completed > 0 ? moment(payment.date_completed).format('DD-MM-YYYY') : ''}</span>
                        <span className='form-info-text'>{payment.date_completed > 0 ? moment(payment.date_completed).format('HH:mm:ss') : ''}</span>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-title-lay'>
                        <span className='form-title-text'>Status</span>
                      </div>
                      <div className='form-info-lay'>
                        <span className='form-info-text'>{payment.status}</span>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-title-lay'>
                        <span className='form-title-text'>Type</span>
                      </div>
                      <div className='form-info-lay'>
                        <span className='form-info-text'>{payment.type}</span>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-title-lay'>
                        <span className='form-title-text'>Amount</span>
                      </div>
                      <div className='form-info-lay'>
                        <span className='form-info-text'>{payment.amount + ' ' + payment.currency}</span>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-title-lay'>
                        <span className='form-title-text'>Curtomer Mobile</span>
                      </div>
                      <div className='form-info-lay'>
                        <span className='form-info-text'>{payment.mobile}</span>
                      </div>
                    </div>
                    {payment.reference_number.length > 0 &&
                      <div className='form-item'>
                        <div className='form-title-lay'>
                          <span className='form-title-text'>Reference Number</span>
                        </div>
                        <div className='form-info-lay'>
                          <span className='form-info-text'>{payment.reference_number}</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              } */}
          </div>
        )}
      </div>
    </div>
  );
};
