const Routes = {
    Landing: '/',
    Dashboard: '/dashboard',
    Devices: '/devices',
    Receipts: '/receipts',
    Login: '/login',
    Signup: '/signup',
    PasswordForget: '/password-forget',
    Account: '/account',
    Help: '/help',
    Terms: '/terms',
    Privacy: '/privacy',
    Shop: '/shop',
    Settings: '/settings',
}

export default Routes;