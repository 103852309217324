import "./slot-edit.scss";

import DatabaseManager, { media_url } from "../../../../../../service";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { useEffect, useReducer, useRef, useState } from "react";
import React from "react";
import { IoWarning } from "react-icons/io5";
import Routes from "../../../../../../utils/routes";
import { Slot } from "../../../../../models";
import { useHistory } from "react-router-dom";

type SlotEditProps = {
  user_id: string;
  device_id: string;
  device_username: string;
  curreny: string;
  user_allowed: boolean;
  slot: Slot;
  slot_refresh_id: number;
  proceed_update: boolean;
  onSlotUpdated: (slot: Slot) => void;
  onRequestDeviceSlotCommand: () => void;
};

const SlotEdit = (props: SlotEditProps) => {
  const history = useHistory();
  const databaseManager: DatabaseManager = new DatabaseManager();
  // const default_image_src = require('../../../../../../assets/images/bottle-stub.png');
  const update_refresh_id = useRef(0);
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [input_slot_brand_name, setInputSlotBrandName] = useState(
    props.slot.brand_name
  );
  const [input_slot_price, setInputSlotPrice] = useState(props.slot.price);
  const [input_slot_quantity, setInputSlotQuantity] = useState(
    props.slot.quantity
  );
  //
  const [slot_image_title, setSlotImageTitle] = useState(
    props.slot.brand_image_title ? props.slot.brand_image_title : ""
  );
  const [previous_slot_image_title, setPreviousSlotImageTitle] = useState("");
  const [slot_image_src, setSlotImageSrc] = useState<string | null>(
    props.slot.brand_image_title !== null && props.slot.brand_image_title !== ""
      ? `${media_url}/${props.device_id}/images/${props.slot.brand_image_title}`
      : ""
  );
  const [slot_image_file, setSlotImageFile] = useState<File | null>(null);
  //
  const [slot_video_title, setSlotVideoTitle] = useState(
    props.slot.brand_video_title ? props.slot.brand_video_title : ""
  );
  const [previous_slot_video_title, setPreviousSlotVideoTitle] = useState("");
  const [slot_video_src, setSlotVideoSrc] = useState<string | null>(
    props.slot.brand_video_title !== null && props.slot.brand_video_title !== ""
      ? `${media_url}/${props.device_id}/videos/${props.slot.brand_video_title}`
      : ""
  );
  const [slot_video_file, setSlotVideoFile] = useState<File | null>(null);
  //
  const [is_updating, setIsUpdating] = useState(false);
  const [updating_message, setUpdatingMessage] = useState<string | null>(null);
  const [is_updating_error, setIsUpdatingError] = useState(false);

  const handleKeyPress = (e: any) => {
    // go back if user press escape
    if (e.keyCode === 27 || e.key === "Escape") {
      if (!didMount.current) return;
      if (is_updating) return;
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push(`${Routes.Devices}/${props.device_username}`);
      }
    }
  };

  useEffect(() => {
    didMount.current = true;
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      didMount.current = false;
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);

  useEffect(() => {
    if (!didMount.current) return;
    updateSlot();
  }, [
    props.slot,
    props.slot.brand_name,
    props.slot.price,
    props.slot.quantity,
    props.slot.brand_image_title,
    props.slot.brand_video_title,
  ]);

  useEffect(() => {
    if (!didMount.current) return;
    if (props.slot_refresh_id !== update_refresh_id.current) {
      update_refresh_id.current = props.slot_refresh_id;
      if (props.proceed_update) {
        proceedUpdateSlot();
      } else {
        // the device is busy at the moment
        setIsUpdating(false);
        setIsUpdatingError(true);
        setUpdatingMessage(
          "Device is busy at the moment. Please try again later."
        );
      }
    }
  }, [props.slot, props.slot_refresh_id, props.proceed_update]);

  const updateSlot = () => {
    if (!didMount.current) {
      return;
    }
    if (!props.user_allowed) {
      return;
    }
    setInputSlotBrandName(props.slot.brand_name);
    setInputSlotPrice(props.slot.price);
    setInputSlotQuantity(props.slot.quantity);
    //
    setSlotImageTitle(
      props.slot.brand_image_title ? props.slot.brand_image_title : ""
    );
    setPreviousSlotImageTitle("");
    setSlotImageSrc(
      props.slot.brand_image_title !== null &&
        props.slot.brand_image_title !== ""
        ? `${media_url}/${props.device_id}/images/${props.slot.brand_image_title}`
        : ""
    );
    // setSlotImageFile(null);
    //
    setSlotVideoTitle(
      props.slot.brand_video_title ? props.slot.brand_video_title : ""
    );
    setPreviousSlotVideoTitle("");
    setSlotVideoSrc(
      props.slot.brand_video_title !== null &&
        props.slot.brand_video_title !== ""
        ? `${media_url}/${props.device_id}/videos/${props.slot.brand_video_title}`
        : ""
    );
    // setSlotVideoFile(null);
  };

  const onValueChange = (type: "brand_name" | "price" | "quantity", e: any) => {
    e.preventDefault();
    if (!didMount.current) return;
    if (type === "brand_name") {
      setInputSlotBrandName(e.target.value);
    } else if (type === "price") {
      setInputSlotPrice(
        isNaN(e.target.value) || e.target.value === ""
          ? NaN
          : parseFloat(e.target.value)
      );
    } else if (type === "quantity") {
      setInputSlotQuantity(
        isNaN(e.target.value) || e.target.value === ""
          ? NaN
          : parseFloat(e.target.value)
      );
    }
    forceUpdate();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!didMount.current) {
      return;
    }
    if (!props.user_allowed) {
      return;
    }
    const brand_name: string = input_slot_brand_name;
    const price: number = input_slot_price;
    const quantity: number = input_slot_quantity;
    // //
    // const image_file: File | null = slot_image_file; // optional
    // const previous_image_title: string = previous_slot_image_title;
    // //
    // const video_file: File | null = slot_video_file; // optional
    // const previous_video_title: string = previous_slot_video_title;
    //
    if (brand_name === "") {
      setUpdatingMessage("Brand name is required.");
      setIsUpdatingError(true);
      return;
    }
    if (isNaN(price)) {
      setUpdatingMessage("Price is required.");
      setIsUpdatingError(true);
      return;
    }
    if (isNaN(quantity)) {
      setUpdatingMessage("Quantity is required.");
      setIsUpdatingError(true);
      return;
    }
    setIsUpdating(true);
    setIsUpdatingError(false);
    props.onRequestDeviceSlotCommand();
  };

  const proceedUpdateSlot = () => {
    if (!didMount.current) {
      return;
    }
    if (!props.user_allowed) {
      return;
    }
    const brand_name: string = input_slot_brand_name;
    const price: number = input_slot_price;
    const quantity: number = input_slot_quantity;
    //
    const image_file: File | null = slot_image_file; // optional
    const previous_image_title: string = previous_slot_image_title;
    //
    const video_file: File | null = slot_video_file; // optional
    const previous_video_title: string = previous_slot_video_title;
    //
    if (brand_name === "") {
      setUpdatingMessage("Brand name is required.");
      setIsUpdatingError(true);
      return;
    }
    if (isNaN(price)) {
      setUpdatingMessage("Price is required.");
      setIsUpdatingError(true);
      return;
    }
    if (isNaN(quantity)) {
      setUpdatingMessage("Quantity is required.");
      setIsUpdatingError(true);
      return;
    }
    setIsUpdating(true);
    setIsUpdatingError(false);
    databaseManager
      .updateDeviceSlot(
        props.user_id,
        props.device_id,
        props.slot.slot_number,
        brand_name,
        price,
        quantity,
        image_file,
        previous_image_title,
        video_file,
        previous_video_title
      )
      .then((result) => {
        if (!didMount.current) {
          return;
        }
        setIsUpdating(false);
        if (result && result.success) {
          setIsUpdating(false);
          setUpdatingMessage("Slot updated.");
          setIsUpdatingError(false);
          setTimeout(() => {
            if (!didMount.current) {
              return;
            }
            setIsUpdating(false);
            setUpdatingMessage(null);
            setIsUpdatingError(false);
            //
            const updated_slot = props.slot;
            updated_slot.brand_name = brand_name;
            updated_slot.price = price;
            updated_slot.quantity = quantity;
            if (result.success.data && result.success.data.length > 0) {
              const data = result.success.data[0];
              updated_slot.brand_image_title = data.image_title
                ? data.image_title
                : slot_image_title;
              updated_slot.brand_video_title = data.video_title
                ? data.video_title
                : slot_video_title;
            }
            //
            props.onSlotUpdated(updated_slot); // update the slot in the parent component
          }, 1000);
        } else if (result && result.error && result.error.message) {
          setIsUpdating(false);
          setUpdatingMessage(result.error.message);
          setIsUpdatingError(true);
        } else {
          setIsUpdating(false);
          setUpdatingMessage(
            "An error occured while updating slot. Please try again."
          );
          setIsUpdatingError(true);
        }
      })
      .catch((_error) => {
        if (!didMount.current) {
          return;
        }
        setIsUpdating(false);
        setUpdatingMessage(
          "An error occured while updating slot. Please try again."
        );
        setIsUpdatingError(true);
      });
  };

  return (
    <div
      className="slot-edit-main"
      id="slot-edit-main"
      onClick={(e) => {
        try {
          if ((e.target as any).id === "slot-edit-main") {
            if (is_updating) return;
            if (history.length > 1) {
              history.goBack();
            } else {
              history.push(`${Routes.Devices}/${props.device_username}`);
            }
          }
        } catch (_err) {}
      }}
    >
      <div className="slot-edit-contents">
        <div className="slot-edit-title-lay">
          {props.user_allowed && (
            <button className="update-btn action-btn" onClick={onSubmit}>
              <FaCheckCircle className="update-icon action-icon" />
              <span className="update-text action-text">Update</span>
            </button>
          )}
          <span className="slot-edit-text">{`Slot No. ${props.slot.slot_number} Settings`}</span>
          <FaTimes
            className="slot-edit-close-btn"
            title="close"
            onClick={(e) => {
              e.preventDefault();
              if (history.length > 1) {
                history.goBack();
              } else {
                history.push(`${Routes.Devices}/${props.device_username}`);
              }
            }}
          />
        </div>
        {(is_updating || updating_message) && (
          <div className="slot-edit-modal">
            {is_updating && (
              <div className="def-loading-lay">
                <div className="def-loading-spinner" />
                <span className="def-loading-text">Loading...</span>
              </div>
            )}
            {updating_message && (
              <div
                className="def-dialog-lay"
                onClick={(e) => {
                  e.preventDefault();
                  setUpdatingMessage(null);
                  setIsUpdatingError(false);
                  setIsUpdating(false);
                }}
              >
                {is_updating_error && (
                  <IoWarning className="def-dialog-icon error-icon" />
                )}
                {!is_updating_error && (
                  <FaCheckCircle className="def-dialog-icon" />
                )}
                <span
                  className={
                    is_updating_error
                      ? "def-dialog-text error-text"
                      : "def-dialog-text"
                  }
                >
                  {updating_message}
                </span>
              </div>
            )}
          </div>
        )}
        <div className="form-contents">
          <div className="form-lay first-lay">
            <div className="form-title-lay">
              <span className="form-title">Slot Details</span>
            </div>
            <div className="input-field-lay">
              <span className="input-field-text">Brand Name</span>
              <input
                className="brand-name-input input"
                value={input_slot_brand_name}
                onChange={(e) => {
                  if (!props.user_allowed) return;
                  onValueChange("brand_name", e);
                }}
                type="text"
                required={true}
                autoComplete={"off"}
                placeholder="Enter brand name"
              />
            </div>
            <div className="input-field-lay">
              <span className="input-field-text">
                {"Brand Price (" + props.curreny + ")"}
              </span>
              <input
                className="price-input input"
                value={
                  isNaN(input_slot_price) ? "" : input_slot_price.toString()
                }
                onChange={(e) => {
                  if (!props.user_allowed) return;
                  onValueChange("price", e);
                }}
                type="number"
                min={0}
                required={true}
                autoComplete={"off"}
                placeholder="Enter price"
              />
            </div>
            <div className="input-field-lay">
              <span className="input-field-text">
                Quantity (available in stock)
              </span>
              <select
                className="phone-input input"
                value={
                  isNaN(input_slot_quantity)
                    ? ""
                    : input_slot_quantity.toString()
                }
                onChange={(e) => {
                  try {
                    if (!props.user_allowed) return;
                    // check if the value is a number and not greater than 5
                    if (
                      !isNaN(parseInt(e.target.value)) &&
                      parseInt(e.target.value) > 5
                    ) {
                      return;
                    }
                    onValueChange("quantity", e);
                  } catch (_err) {}
                }}
                required={true}
                autoComplete={"off"}
                placeholder="Enter quantity"
              >
                {/* <option value=''>Select quantity</option> */}
                <option value="0">0 Available</option>
                <option value="1">1 Available</option>
                <option value="2">2 Available</option>
                <option value="3">3 Available</option>
                <option value="4">4 Available</option>
                <option value="5">5 Available</option>
              </select>
            </div>
          </div>
          <div className="form-lay first-lay">
            <div className="form-title-lay">
              <span className="form-title">Media</span>
            </div>
            <div className="input-field-lay">
              <span className="input-field-text">Brand image</span>
              {props.user_allowed && (
                <button
                  className="slot-image-btn slot-media-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!didMount.current) return;
                    const inputFileElement = document.getElementById(
                      "slot-edit-image-input-file"
                    );
                    if (inputFileElement) {
                      inputFileElement.click();
                    }
                  }}
                >
                  Select Brand Image (optional)
                </button>
              )}
              {props.user_allowed && (
                <input
                  className="slot-image-input file-input input"
                  hidden={true}
                  multiple={false}
                  type="file"
                  id="slot-edit-image-input-file"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => {
                    e.preventDefault();
                    if (!didMount.current) return;
                    const files: FileList | null = e.target.files;
                    if (files && files.length > 0) {
                      const file: File = files[0];
                      if (file) {
                        // make sure file is not null
                        const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
                        const actual_filesize = parseFloat(filesize);
                        if (actual_filesize <= 5) {
                          // make sure file size is not greater than 5 MB
                          const filePath = URL.createObjectURL(file);
                          setSlotImageSrc(filePath);
                          setSlotImageFile(
                            file
                          ); /* this will be updated on server storage*/
                          setPreviousSlotImageTitle(
                            slot_image_title
                          ); /* set previous_image_title to remove on server storage */
                        } else {
                          setUpdatingMessage(
                            "Image size should be less than 2 MB"
                          );
                        }
                      }
                    }
                  }}
                />
              )}
              {slot_image_src && (
                <div className="slot-image-cont slot-media-cont">
                  {props.user_allowed && (
                    <FaTimes
                      className="slot-media-close-icon"
                      title={"remove image"}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!didMount.current) return;
                        setSlotImageSrc(null);
                        setSlotImageFile(
                          null
                        ); /* this will be updated on server storage*/
                        setPreviousSlotImageTitle(
                          slot_image_title
                        ); /* set previous_image_title to remove on server storage */
                      }}
                    />
                  )}
                  {/* <div className="slot-media-top" /> */}
                  <img
                    className="slot-media"
                    src={slot_image_src}
                    alt={"brand"}
                  />
                </div>
              )}
            </div>
            <div className="input-field-lay">
              <span className="input-field-text">Brand video</span>
              {props.user_allowed && (
                <button
                  className="slot-video-btn slot-media-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!didMount.current) return;
                    const inputFileElement = document.getElementById(
                      "slot-edit-video-input-file"
                    );
                    if (inputFileElement) {
                      inputFileElement.click();
                    }
                  }}
                >
                  Select Brand Video (optional)
                </button>
              )}
              {props.user_allowed && (
                <input
                  className="slot-video-input file-input input"
                  hidden={true}
                  multiple={false}
                  type="file"
                  id="slot-edit-video-input-file"
                  accept="video/mp4, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g, video/mp4, video/mpeg, video/ogg, video/webm, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/x-flv, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g"
                  onChange={(e) => {
                    e.preventDefault();
                    if (!didMount.current) return;
                    const files: FileList | null = e.target.files;
                    if (files && files.length > 0) {
                      const file: File = files[0];
                      if (file) {
                        // make sure file is not null
                        const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
                        const actual_filesize = parseFloat(filesize);
                        if (actual_filesize <= 100) {
                          // make sure file size is less than 100 MB
                          const filePath = URL.createObjectURL(file);
                          setSlotVideoSrc(filePath);
                          setSlotVideoFile(
                            file
                          ); /* this will be updated on server storage*/
                          setPreviousSlotVideoTitle(
                            slot_video_title
                          ); /* set previous_video_title to remove on server storage */
                        } else {
                          setUpdatingMessage(
                            "Video size should be less than 100 MB"
                          );
                        }
                      }
                    }
                  }}
                />
              )}
              {slot_video_src && (
                <div className="slot-video-cont slot-media-cont">
                  {props.user_allowed && (
                    <FaTimes
                      className="slot-media-close-icon"
                      title={"remove video"}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!didMount.current) return;
                        setSlotVideoSrc(null);
                        setSlotVideoFile(
                          null
                        ); /* this will be updated on server storage*/
                        setPreviousSlotVideoTitle(
                          slot_video_title
                        ); /* set previous_video_title to remove on server storage */
                      }}
                    />
                  )}
                  {/* <div className="slot-media-top" /> */}
                  <video
                    className="slot-media"
                    src={slot_video_src}
                    controls={true}
                    autoPlay={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotEdit;
