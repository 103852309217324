import React from "react";

export type Auth = {
  isAuthenticated: boolean;
  isAdmin: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setIsAdmin: (isAuthenticated: boolean) => void;
};

// create context for the service
const AuthContext = React.createContext<Auth>({
  isAuthenticated: false,
  isAdmin: false,
  setIsAuthenticated: () => {},
  setIsAdmin: () => {},
});

export default AuthContext;
