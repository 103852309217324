import {
  Device,
  DeviceCommand,
  SocketUserDevice,
  UserData,
  WsDevice,
  WsDeviceActivity,
  WsDeviceCallback,
  WsDeviceReply,
  WsNewUserCommand,
  WsShopDevice,
  WsShopDeviceProcess,
  WsShopDeviceRequestReply,
  WsShopper,
  WsShopperRequest,
  WsShopperRequestCart,
  WsSlot,
  WsUser,
  WsUserCallback,
  WsUserCommand,
} from "../models";
import io, { Socket } from "socket.io-client";
import { v4 as uuidV4 } from "uuid";

import { api_key } from "../../service";
import { Slot } from "./../models/index.d";

type Callback = {
  onConnect: () => void;
  onDisconnect: () => void;
  onUserCallback: (userCallback: WsUserCallback) => void;
  onDeviceJoin: (device: WsDevice) => void;
  onDeviceActivity: (activity: WsDeviceActivity) => void;
  onDeviceReply: (reply: WsDeviceReply) => void;
  onDeviceLeave: (device: WsDevice) => void;
};

export default class VendingWebSocket {
  private socket: Socket;
  private callback: Callback;

  private user: WsUser | null = null;

  private client_id = uuidV4();

  private socket_user_devices: SocketUserDevice[] = [];

  constructor(socket_url: string, callback: Callback) {
    // this.socket_url = socket_url;
    this.callback = callback;

    this.socket = io(socket_url);

    this.socket.on("connect", () => {
      // console.log(`socket connected`);
      // this.session_id = this.socket.id;
      this.callback.onConnect();
    });

    this.socket.on("disconnect", (reason) => {
      // console.log(`socket disconnected:`, reason);
      // this.socket.removeAllListeners();
      // this.socket.off('user-join-callback', () => {});
      // this.socket.off('device-join', () => {});
      // this.socket.off('device-activity', () => {});
      // this.socket.off('device-reply', () => {});
      // this.socket.off('device-leave', () => {});
      // this.socket.off('client-leave', () => {});
      this.callback.onDisconnect();
    });
  }

  public getSocketDevices(): SocketUserDevice[] {
    // let count = 0;
    // for (const socket_user_device of this.socket_user_devices) {
    //     if (socket_user_device.online) {
    //         count++;
    //     }
    // }
    return this.socket_user_devices;
  }

  public initUserSocket(user: UserData, devices: Device[]) {
    const user_devices: string[] = [];
    this.socket_user_devices = [];

    for (let i = 0; i < devices.length; i++) {
      const device = devices[i];
      user_devices.push(device.id);
      this.socket_user_devices.push({
        device_id: device.id,
        session_id: null,
        online: false,
      });
    }

    this.user = {
      session_id: this.socket.id,
      key: api_key,
      id: user.id,
      name: user.name,
      email: user.email,
      phone_number: user.phone_number,
      device_ids: user_devices,
    };

    // this.userData = user;

    if (!this.socket.connected) {
      this.callback.onDisconnect();
      return;
    }

    this.socket.emit("user-join", JSON.stringify(this.user));

    // listen for user-join-callback channel
    this.socket
      .off("user-join-callback")
      .on("user-join-callback", (data: string) => {
        if (!data) {
          return;
        }
        try {
          // convert string to object
          const obj: WsUserCallback = JSON.parse(data);
          // console.log(`user-join-callback:`, data);
          this.callback.onUserCallback(obj);
        } catch (err) {
          // console.error(`user-join-callback:`, err);
        }
      });

    // listen for device-join channel
    this.socket.off("device-join").on("device-join", (data: string) => {
      if (!data) {
        return;
      }
      try {
        // convert string to object
        const obj: WsDevice = JSON.parse(data);
        if (obj && obj.id) {
          // check if user has this device
          if (this.user && this.socket_user_devices.length > 0) {
            let found_pos = -1;
            for (let i = 0; i < this.socket_user_devices.length; i++) {
              const socket_user_device = this.socket_user_devices[i];
              if (
                socket_user_device.device_id === obj.id ||
                (socket_user_device.session_id &&
                  socket_user_device.session_id === obj.session_id)
              ) {
                found_pos = i;
                break;
              }
            }
            if (found_pos >= 0) {
              this.socket_user_devices[found_pos].online = true;
              this.socket_user_devices[found_pos].session_id = obj.session_id;
              // console.log(`device-join:`, obj);
              this.callback.onDeviceJoin(obj);
            }
          }
        }
      } catch (err) {
        console.error(`device-join:`, err);
      }
    });

    // listen for device-reply channel
    this.socket.off("device-reply").on("device-reply", (data: string) => {
      if (!data) {
        return;
      }
      try {
        // convert string to object
        const obj: WsDeviceReply = JSON.parse(data);
        if (obj && obj.device_id) {
          // check if user has this device
          if (this.user && this.user.device_ids.length > 0) {
            if (this.user.device_ids.indexOf(obj.device_id) > -1) {
              // console.log(`device-reply:`, data);
              this.callback.onDeviceReply(obj);
            }
          }
        }
      } catch (err) {
        console.error(`device-reply:`, err);
      }
    });

    // listen for device-activity channel
    this.socket.off("device-activity").on("device-activity", (data: string) => {
      if (!data) {
        return;
      }
      try {
        // convert string to object
        const obj: WsDeviceActivity = JSON.parse(data);
        if (obj && obj.device_id) {
          // check if user has this device
          if (this.user && this.user.device_ids.length > 0) {
            if (this.user.device_ids.indexOf(obj.device_id) > -1) {
              // console.log(`device-activity:`, data);
              this.callback.onDeviceActivity(obj);
            }
          }
        }
      } catch (err) {
        console.error(`device-activity:`, err);
      }
    });

    // listen for device-leave channel
    this.socket.off("device-leave").on("device-leave", (data: string) => {
      if (!data) {
        return;
      }
      try {
        // convert string to object
        const obj: WsDevice = JSON.parse(data);
        if (obj && obj.id) {
          // check if user has this device
          if (this.user && this.socket_user_devices.length > 0) {
            let found_pos = -1;
            for (let i = 0; i < this.socket_user_devices.length; i++) {
              const socket_user_device = this.socket_user_devices[i];
              if (
                socket_user_device.device_id === obj.id ||
                (socket_user_device.session_id &&
                  socket_user_device.session_id === obj.session_id)
              ) {
                found_pos = i;
                break;
              }
            }
            if (found_pos >= 0) {
              this.socket_user_devices[found_pos].online = false;
              this.socket_user_devices[found_pos].session_id = obj.session_id;
              // console.log(`device-leave:`, obj);
              this.callback.onDeviceLeave(obj);
            }
          }
        }
      } catch (err) {
        console.error(`device-leave:`, err);
      }
    });

    // listen for client-leave channel
    this.socket.off("client-leave").on("client-leave", (data: string) => {
      if (!data) {
        return;
      }
      try {
        // convert string to object
        const obj = JSON.parse(data);
        if (obj && obj.id) {
          // check if user has this device
          if (this.user && this.socket_user_devices.length > 0) {
            let found_pos = -1;
            for (let i = 0; i < this.socket_user_devices.length; i++) {
              const socket_user_device = this.socket_user_devices[i];
              if (
                socket_user_device.device_id === obj.id ||
                (socket_user_device.session_id &&
                  socket_user_device.session_id === obj.id)
              ) {
                found_pos = i;
                break;
              }
            }
            if (found_pos >= 0) {
              this.socket_user_devices[found_pos].online = false;
              this.socket_user_devices[found_pos].session_id = obj.id;
              // console.log(`client-device-leave:`, obj);
              this.callback.onDeviceLeave(obj);
            }
          }
        }
      } catch (err) {
        console.error(`client-leave:`, err);
      }
    });

    // new API
    this.socket.emit("dash-user-join", JSON.stringify(this.user));

    this.socket
      .off("dash-user-join-callback")
      .on("dash-user-join-callback", (data: string) => {
        if (!data) {
          return;
        }
        try {
          // convert string to object
          const obj: WsUserCallback = JSON.parse(data);
          this.callback.onUserCallback(obj);
        } catch (err) {
          console.error("dash-user-join-callback", err);
        }
      });

    for (let i = 0; i < devices.length; i++) {
      this.socket
        .off("dash-dev-join-" + devices[i].id)
        .on("dash-dev-join-" + devices[i].id, (data: string) => {
          if (!data) {
            return;
          }
          try {
            const obj: WsDevice = JSON.parse(data);
            if (obj && obj.id) {
              if (this.user && this.socket_user_devices.length > 0) {
                let found_pos = -1;
                for (let i = 0; i < this.socket_user_devices.length; i++) {
                  const socket_user_device = this.socket_user_devices[i];
                  if (
                    socket_user_device.device_id === obj.id ||
                    (socket_user_device.session_id &&
                      socket_user_device.session_id === obj.session_id)
                  ) {
                    found_pos = i;
                    break;
                  }
                }
                if (found_pos >= 0) {
                  this.socket_user_devices[found_pos].online = true;
                  this.socket_user_devices[found_pos].session_id =
                    obj.session_id;
                  // console.log('dash-dev-join-' + devices[i].id, data);
                  this.callback.onDeviceJoin(obj);
                }
              }
            }
          } catch (err) {
            console.error("dash-dev-join-" + devices[i].id, err);
          }
        });

      this.socket
        .off("dash-dev-activity-" + devices[i].id)
        .on("dash-dev-activity-" + devices[i].id, (data: string) => {
          if (!data) {
            return;
          }
          try {
            const obj: WsDeviceActivity = JSON.parse(data);
            if (obj && obj.device_id) {
              if (this.user && this.user.device_ids.length > 0) {
                if (this.user.device_ids.indexOf(obj.device_id) > -1) {
                  // console.log('dash-dev-activity-' + devices[i].id, data);
                  this.callback.onDeviceActivity(obj);
                }
              }
            }
          } catch (err) {
            console.error("dash-dev-activity-" + devices[i].id, err);
          }
        });

      this.socket
        .off("dash-dev-reply-" + devices[i].id + "-" + this.client_id)
        .on(
          "dash-dev-reply-" + devices[i].id + "-" + this.client_id,
          (data: string) => {
            if (!data) {
              return;
            }
            try {
              const obj: WsDeviceReply = JSON.parse(data);
              if (obj && obj.device_id) {
                if (this.user && this.user.device_ids.length > 0) {
                  if (this.user.device_ids.indexOf(obj.device_id) > -1) {
                    // console.log('dash-dev-reply-' + devices[i].id + '-' + this.client_id, data);
                    this.callback.onDeviceReply(obj);
                  }
                }
              }
            } catch (err) {
              console.error(
                "dash-dev-reply-" + devices[i].id + "-" + this.client_id,
                err
              );
            }
          }
        );

      this.socket
        .off("dash-dev-leave-" + devices[i].id)
        .on("dash-dev-leave-" + devices[i].id, (data: string) => {
          if (!data) {
            return;
          }
          try {
            const obj: WsDevice = JSON.parse(data);
            if (obj && obj.device_id) {
              if (this.user && this.socket_user_devices.length > 0) {
                let found_pos = -1;
                for (let i = 0; i < this.socket_user_devices.length; i++) {
                  const socket_user_device = this.socket_user_devices[i];
                  if (
                    socket_user_device.device_id === obj.device_id ||
                    (socket_user_device.session_id &&
                      socket_user_device.session_id === obj.session_id)
                  ) {
                    found_pos = i;
                    break;
                  }
                }
                if (found_pos >= 0) {
                  this.socket_user_devices[found_pos].online = false;
                  this.socket_user_devices[found_pos].session_id =
                    obj.session_id;
                  // console.log('dash-dev-leave-' + devices[i].id, obj);
                  this.callback.onDeviceLeave(obj);
                }
              }
            }
          } catch (err) {
            console.error("dash-dev-leave-" + devices[i].id, err);
          }
        });
    }
  }

  public sendUserCommand(
    device_id: string,
    command: DeviceCommand,
    value: number,
    request_code: number
  ) {
    if (!this.socket.connected) {
      this.callback.onDisconnect();
      return;
    }
    if (this.user) {
      const user_command: WsUserCommand = {
        session_id: this.socket.id,
        user_id: this.user.id,
        device_id: device_id,
        command: command,
        value: value,
        request_code: request_code,
      };
      this.socket.emit("user-command", JSON.stringify(user_command));

      // new API
      const new_user_command: WsNewUserCommand = {
        client_id: this.client_id,
        device_id: device_id,
        session_id: this.socket.id,
        user_id: this.user.id,
        command: command,
        value: value,
        request_code: request_code,
      };
      this.socket.emit("dash-user-command", JSON.stringify(new_user_command));
    }
  }

  public sendUserLeave() {
    if (!this.socket.connected) {
      this.callback.onDisconnect();
      return;
    }
    if (this.user) {
      this.socket.emit("user-leave", JSON.stringify(this.user));
      this.socket.emit("dash-user-leave", JSON.stringify(this.user));
    }
  }

  public stopSocket() {
    if (!this.socket.connected) {
      this.callback.onDisconnect();
      return;
    }
    this.sendUserLeave();
  }

  public endSocket() {
    if (!this.socket.connected) {
      this.callback.onDisconnect();
      return;
    }
    this.socket.disconnect();
  }
}

type ShopSocketCallback = {
  onConnect: () => void;
  onDisconnect: () => void;
  onDeviceData: (device: WsShopDevice) => void;
  onDeviceRequestReply: (reply: WsShopDeviceRequestReply) => void;
  onDeviceProcess: (process: WsShopDeviceProcess) => void;
  onDeviceLeave: (device_id: string) => void;
};

export class ShopVendingSocket {
  private socket: Socket;
  // private session_id: string | null = null;
  private callback: ShopSocketCallback;

  private client_id = uuidV4();

  private device_ids: string[] = [];

  constructor(socket_url: string, callback: ShopSocketCallback) {
    // this.socket_url = socket_url;
    this.callback = callback;

    this.socket = io(socket_url);

    this.socket.on("connect", () => {
      // console.log(`socket connected`);
      // this.session_id = this.socket.id;
      this.callback.onConnect();
    });

    this.socket.on("disconnect", (reason) => {
      console.error(`socket disconnected:`, reason);
      this.callback.onDisconnect();
    });
  }

  public initShopDevice(device: Device) {
    // console.log('socket: initShopDevice', device.id);

    if (!this.socket.connected) {
      console.error("socket: initShopDevice, socket not connected yet");
      this.callback.onDisconnect();
      return;
    }

    if (!this.device_ids.includes(device.id)) {
      this.device_ids.push(device.id);
    }

    const shop_device: WsShopper = {
      device_id: device.id,
      client_id: this.client_id,
    };

    this.socket.emit("shop-shopper-join", JSON.stringify(shop_device));

    this.socket
      .off("shop-dev-data-" + device.id)
      .on("shop-dev-data-" + device.id, (data: string) => {
        if (!data) {
          return;
        }
        try {
          // convert string to object
          const obj: WsShopDevice = JSON.parse(data);
          // console.log('shop-dev-data-' + device.id, data);
          if (obj) {
            this.callback.onDeviceData(obj);
          }
        } catch (err) {
          console.error("shop-dev-data-" + device.id, err);
        }
      });

    this.socket
      .off("shop-dev-reply-" + device.id + "-" + this.client_id)
      .on(
        "shop-dev-reply-" + device.id + "-" + this.client_id,
        (data: string) => {
          if (!data) {
            return;
          }
          try {
            // convert string to object
            const obj: WsShopDeviceRequestReply = JSON.parse(data);
            if (obj && obj.device_id) {
              if (this.device_ids.length > 0) {
                let found_pos = this.device_ids.findIndex((id) => {
                  return id === obj.device_id;
                });
                if (found_pos != -1) {
                  // console.log('shop-dev-reply-' + device.id + '-' + this.client_id, obj);
                  this.callback.onDeviceRequestReply(obj);
                }
              }
            }
          } catch (err) {
            console.error(
              "shop-dev-reply-" + device.id + "-" + this.client_id,
              err
            );
          }
        }
      );

    this.socket
      .off("shop-dev-process-" + device.id + "-" + this.client_id)
      .on(
        "shop-dev-process-" + device.id + "-" + this.client_id,
        (data: string) => {
          if (!data) {
            return;
          }
          try {
            // convert string to object
            const obj: WsShopDeviceProcess = JSON.parse(data);
            if (obj && obj.device_id) {
              if (this.device_ids.length > 0) {
                let found_pos = this.device_ids.findIndex((id) => {
                  return id === obj.device_id;
                });
                if (found_pos != -1) {
                  // console.log('shop-dev-process-' + device.id + '-' + this.client_id, obj);
                  this.callback.onDeviceProcess(obj);
                }
              }
            }
          } catch (err) {
            console.error(
              "shop-dev-reply-" + device.id + "-" + this.client_id,
              err
            );
          }
        }
      );

    this.socket
      .off("shop-dev-leave-" + device.id)
      .on("shop-dev-leave-" + device.id, (data: string) => {
        if (!data) {
          return;
        }
        try {
          // convert string to object
          const obj: WsShopDevice = JSON.parse(data);
          if (obj && obj.device_id) {
            if (this.device_ids.length > 0) {
              let found_pos = this.device_ids.findIndex((id) => {
                return id === obj.device_id;
              });
              if (found_pos != -1) {
                // console.log('shop-dev-leave-' + device.id, obj);
                this.callback.onDeviceLeave(this.device_ids[found_pos]);
              }
            }
          }
        } catch (err) {
          console.error("shop-dev-leave-" + device.id, err);
        }
      });

    this.socket.off("client-leave").on("client-leave", (data: string) => {
      if (!data) {
        return;
      }
      try {
        // convert string to object
        const obj = JSON.parse(data);
        if (obj && obj.id) {
          if (this.device_ids.length > 0) {
            let found_pos = this.device_ids.findIndex((id) => {
              return id === obj.id;
            });
            if (found_pos != -1) {
              // console.log(`client-leave:`, obj);
              this.callback.onDeviceLeave(this.device_ids[found_pos]);
            }
          }
        }
      } catch (err) {
        console.error(`client-leave:`, err);
      }
    });
  }

  public requestShopDevice(device: Device, request_code: number) {
    if (!this.socket.connected) {
      this.callback.onDisconnect();
      return;
    }

    if (!this.device_ids.includes(device.id)) {
      this.device_ids.push(device.id);
    }

    const obj: WsShopperRequest = {
      session_id: this.socket.id,
      client_id: this.client_id,
      device_id: device.id,
      request_code: request_code,
    };

    this.socket.emit("shop-shopper-req-dev", JSON.stringify(obj));
  }

  public sendCart(
    device: Device,
    slots: Slot[],
    purchase_type: WsShopperRequestCart["purchase_type"],
    phone_number: WsShopperRequestCart["phone_number"]
  ) {
    if (!this.socket.connected) {
      this.callback.onDisconnect();
      return;
    }

    if (!this.device_ids.includes(device.id)) {
      this.device_ids.push(device.id);
    }

    const cart: WsSlot[] = [];
    slots.forEach((s) => {
      if (s.selected_count !== undefined && s.selected_count > 0) {
        cart.push({
          s: s.slot_number,
          q: s.selected_count,
        });
      }
    });

    const obj: WsShopperRequestCart = {
      session_id: this.socket.id,
      client_id: this.client_id,
      device_id: device.id,
      cart: cart,
      purchase_type: purchase_type,
      phone_number: phone_number,
    };

    this.socket.emit("shop-shopper-req-cart", JSON.stringify(obj));
  }

  public stopSocket() {
    if (!this.socket.connected) {
      this.callback.onDisconnect();
      return;
    }
  }

  public endSocket() {
    if (!this.socket.connected) {
      this.callback.onDisconnect();
      return;
    }
    this.socket.disconnect();
  }
}
