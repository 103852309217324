import { useEffect, useReducer, useRef, useState } from "react";
import { FaHome, FaTachometerAlt } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import DatabaseManager from "../../service";
import React from "react";
import {
  Device,
  Slot,
  WsShopDevice,
  WsShopDeviceProcess,
  WsShopDeviceRequestReply,
  WsShopperRequestCart,
} from "../models";
import Routes from "../../utils/routes";
import Themer from "../../utils/themer";
import { VirtuosoGrid } from "react-virtuoso";

import "./index.scss";
import { MdLocationOn } from "react-icons/md";
import ShopDevice from "./shop-device";
import { HiShoppingCart } from "react-icons/hi";
import { ShopVendingSocket } from "../main/web-socket";
const countryList = require("country-list");

type ShopDev = {
  username: string;
  active: boolean;
  dev?: Device;
  //
  socket_refresh_id: number;
  socket_dev?: WsShopDevice;
  socket_dev_reply?: WsShopDeviceRequestReply;
  socket_dev_process?: WsShopDeviceProcess;
};

const ShopPage = () => {
  const history = useHistory();
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [search_term, setSearchTerm] = useState("");
  const [search_country, setSearchCountry] = useState("");
  const [search_region, setSearchRegion] = useState("");
  const [search_address, setSearchAddress] = useState("");

  const [list, setList] = useState<Device[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const [error, setError] = useState("");

  const shopsRef = useRef<ShopDev[]>([]);
  const [shops, setShops] = useState<ShopDev[]>([]);

  const isFirstTime = useRef(true);

  const socket = useRef<ShopVendingSocket | null>(null);
  const socket_url = useRef<string | null>(null);
  const [socket_connected, setSocketConnected] = useState<boolean>(false);
  const socketConnectedRef = useRef(false);

  useEffect(() => {
    didMount.current = true;
    Themer.subscribe();
    document.title = "Shop  -  Moran Vending Machine";
    if (isFirstTime.current) {
      isFirstTime.current = false;
      search();
      selectPage();
    }
    return () => {
      didMount.current = false;
      Themer.unsubscribe();
    };
  }, []);

  const search = () => {
    if (!didMount.current) return;
    if (isLoadingRef.current) return;
    setIsLoading(true);
    isLoadingRef.current = true;
    // console.log('Shop: search');

    DatabaseManager.getInstance()
      .getShopDevicesList(
        search_term,
        search_country,
        search_region,
        search_address
      )
      .then((result) => {
        if (!didMount.current) return;
        setIsLoading(false);
        isLoadingRef.current = false;
        if (result && result.success && result.success.data) {
          setError("");
          if (
            result.success.data.length > 0 &&
            result.success.data[0] &&
            result.success.data[0].socket_url
          ) {
            const _socket_url = result.success.data[0].socket_url;
            if (socket_url.current !== _socket_url) {
              socket_url.current = result.success.data[0].socket_url;
              initSocket();
            }
          }
          setList(
            result.success.data &&
              result.success.data[0] &&
              result.success.data[0].devices
              ? result.success.data[0].devices
              : []
          );
          // setList([...result.success.data, ...result.success.data, ...result.success.data, ...result.success.data, ...result.success.data, ...result.success.data, ...result.success.data, ...result.success.data]);
        } else if (result && result.error) {
          setError(
            result.error.message
              ? result.error.message
              : "Sorry, something went wrong. Please try again"
          );
          setList([]);
        } else {
          setError("Sorry, something went wrong. Please try again");
          setList([]);
        }
      })
      .catch(() => {
        if (!didMount.current) return;
        setIsLoading(false);
        isLoadingRef.current = false;
        setError("Something went wrong while loading data. Please try again.");
      });
  };

  useEffect(() => {
    if (!didMount.current) return;
    selectPage();
  }, [history.location.pathname]);

  const selectPage = () => {
    if (!history.location.pathname.startsWith(Routes.Shop)) return;

    let path = history.location.pathname;
    if (path.endsWith("/")) {
      path = path.substring(0, path.length - 1);
    }
    if (
      path.startsWith(Routes.Shop + "/") &&
      path.length > (Routes.Shop + "/").length + 1
    ) {
      // /shop/D1234/slash/slash
      path = path.substring(1);
      const deviceUsername = path.split("/")[1];
      if (shopsRef.current.length > 0) {
        const index = shopsRef.current.findIndex((d) => {
          return d.username === deviceUsername;
        });
        if (index === -1) {
          shopsRef.current.push({
            username: deviceUsername,
            active: false,
            socket_refresh_id: 0,
          });
          setShops(shopsRef.current);
          forceUpdate();
        }
      } else {
        shopsRef.current.push({
          username: deviceUsername,
          active: false,
          socket_refresh_id: 0,
        });
        setShops(shopsRef.current);
        forceUpdate();
      }
    }
  };

  const initSocket = () => {
    if (!didMount.current) return;
    if (!socket_url.current) return;
    if (socket.current) {
      socket.current.stopSocket();
      socket.current.endSocket();
      setSocketConnected(false);
      socketConnectedRef.current = false;
    }

    socket.current = new ShopVendingSocket(socket_url.current, {
      onConnect: () => {
        if (!didMount.current) return;
        // console.log('socket: onConnect');
        setSocketConnected(true);
        socketConnectedRef.current = true;
        if (socket.current && shopsRef.current.length > 0) {
          for (let i = 0; i < shopsRef.current.length; i++) {
            const s = shopsRef.current[i];
            if (s.dev) {
              socket.current.initShopDevice(s.dev);
            }
          }
        }
      },
      onDisconnect: () => {
        if (!didMount.current) return;
        // console.log('socket: onDisconnect');
        setSocketConnected(false);
        socketConnectedRef.current = false;
      },
      onDeviceData: (dev: WsShopDevice) => {
        // console.log('socket: onDeviceData', dev);
        if (!didMount.current) return;
        if (shopsRef.current.length === 0) return;
        const i = shopsRef.current.findIndex((s) => {
          return s.dev && s.dev.id === dev.device_id;
        });
        if (i > -1) {
          const ss = shopsRef.current[i];
          ss.active = true;
          ss.socket_dev = dev;
          ss.socket_refresh_id = ss.socket_refresh_id + 1;
          shopsRef.current[i] = ss;
          setShops(shopsRef.current);
          forceUpdate();
        }
      },
      onDeviceRequestReply: (reply: WsShopDeviceRequestReply) => {
        if (!didMount.current) return;
        const i = shopsRef.current.findIndex((s) => {
          return s.dev && s.dev.id === reply.device_id;
        });
        if (i > -1) {
          const ss = shopsRef.current[i];
          ss.active = true;
          ss.socket_dev_reply = reply;
          ss.socket_refresh_id = ss.socket_refresh_id + 1;
          shopsRef.current[i] = ss;
          setShops(shopsRef.current);
          forceUpdate();
        }
      },
      onDeviceProcess: (process: WsShopDeviceProcess) => {
        if (!didMount.current) return;
        const i = shopsRef.current.findIndex((s) => {
          return s.dev && s.dev.id === process.device_id;
        });
        if (i > -1) {
          const ss = shopsRef.current[i];
          ss.active = true;
          ss.socket_dev_process = process;
          ss.socket_refresh_id = ss.socket_refresh_id + 1;
          shopsRef.current[i] = ss;
          setShops(shopsRef.current);
          forceUpdate();
        }
      },
      onDeviceLeave: (device_id: string) => {
        if (!didMount.current) return;
        const i = shopsRef.current.findIndex((s) => {
          return s.dev && s.dev.id === device_id;
        });
        if (i > -1) {
          shopsRef.current[i].active = false;
          setShops(shopsRef.current);
          forceUpdate();
        }
      },
    });
  };

  const onDeviceLoaded = (device: Device) => {
    if (!didMount.current) return;
    const i = shopsRef.current.findIndex((s) => {
      return s.username === device.username;
    });
    if (i > -1) {
      shopsRef.current[i].dev = device;
      if (socketConnectedRef.current && socket.current) {
        socket.current.initShopDevice(device);
      }
    }
    setShops(shopsRef.current);
    forceUpdate();
  };

  const onRequestShopDevice = (device: Device, request_code: number) => {
    if (!didMount.current) return;
    if (socketConnectedRef.current && socket.current) {
      socket.current.requestShopDevice(device, request_code);
    }
  };

  const onSendShopCart = (
    device: Device,
    slots: Slot[],
    purchase_type: WsShopperRequestCart["purchase_type"],
    phone_number: WsShopperRequestCart["phone_number"]
  ) => {
    if (!didMount.current) return;
    if (socketConnectedRef.current && socket.current) {
      socket.current.sendCart(device, slots, purchase_type, phone_number);
    }
  };

  return (
    <div className="shop-main-lay">
      <div className="shop-main-header">
        <div className="left-btns-lay">
          <Link to={Routes.Landing} className="btn-item">
            <FaHome className="btn-item-icon" />
            <span className="btn-item-text">Home</span>
          </Link>
          <Link to={Routes.Dashboard} className="btn-item">
            <FaTachometerAlt className="btn-item-icon" />
            <span className="btn-item-text">Dashboard</span>
          </Link>
          <Link to={Routes.Shop} className="btn-item">
            <HiShoppingCart className="btn-item-icon" />
            <span className="btn-item-text">Shop</span>
          </Link>
        </div>

        <span className="header-title">Shop - Moran Vending Machine</span>

        {/* <Link to={Routes.Account} className="user-lay" title={this.props.user_name ? this.props.user_name : "User"}>
          <p className="user-name">{this.props.user_name ? this.props.user_name : "User"}</p>
          <i className="fas fa-user user-icon"></i>
        </Link> */}

        <div className="pallete-container">
          <label className="switch" title="Change theme">
            <input type="checkbox" className="checkbox" id="theme-btn" />
            <span className="toggle-thumb">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 20 20"
                style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}
              >
                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 20 20"
                style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}
              >
                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
              </svg>
            </span>
          </label>
        </div>
      </div>
      <div
        className={`shop-body-lay${
          history.location.pathname.startsWith(Routes.Shop) + "/" &&
          history.location.pathname.length > (Routes.Shop + "/").length + 1
            ? " with-item"
            : ""
        }`}
      >
        <div className={`left-lay`}>
          <div className="header-ly">
            <span className="header-title-txt">
              Find Vending Machine Near You
            </span>
            <div className="searches-lay">
              <div className="searches-left-lay">
                <div className="pairs-lay">
                  <select
                    className="search-item select"
                    value={search_country}
                    onChange={(e) => {
                      setSearchCountry(e.currentTarget.value);
                    }}
                  >
                    <option value="" className="search-option">
                      Select Country
                    </option>
                    {countryList.getNames().map((n: string, i: number) => {
                      return (
                        <option key={i} value={n} className="search-option">
                          {n}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    type="text"
                    className="search-item input"
                    placeholder="Enter Region (optional)"
                    value={search_region}
                    onInput={(e) => {
                      setSearchRegion(e.currentTarget.value);
                    }}
                  />
                </div>
                <input
                  type="text"
                  className="search-input input"
                  placeholder='Search for Machine Eg: "D1234" (optional)'
                  value={search_term}
                  onInput={(e) => {
                    setSearchTerm(e.currentTarget.value);
                  }}
                />
              </div>
              <div className="searches-right-lay">
                <button className="search-btn action-btn" onClick={search}>
                  {/* <MdSearch className='add-members-search-icon action-icon' /> */}
                  <span className="search-text action-text">Search</span>
                </button>
              </div>
            </div>
          </div>
          <div className="body-lay">
            {isLoading && (
              <div className="loading-lay">
                <div className="def-loading-lay">
                  <div className="def-loading-spinner" />
                  <span className="def-loading-text">Loading...</span>
                </div>
              </div>
            )}
            {!isLoading && (list.length === 0 || error.length > 0) && (
              <div className="empty-lay">
                {error.length > 0 && (
                  <span className="error-text">{error}</span>
                )}
                {error.length === 0 && (
                  <span className="empty-text">
                    No Vending Machine Found. Please Search Different Term.
                  </span>
                )}
              </div>
            )}
            {list.length > 0 && (
              <VirtuosoGrid
                className="list-lay"
                listClassName="list-container"
                totalCount={list.length}
                // overscan={200}
                // components={{
                //   Item: () => {
                //     return <div className='item-container' />
                //   },
                //   List: () => {
                //     return <div className='list-container' />
                //   },
                //   ScrollSeekPlaceholder: ({ height, width, index }) => (
                //     <div className='item-container'>
                //       <div className='item-wrapper'>{'--'}</div>
                //     </div>
                //   ),
                // }}
                itemContent={(index) => <DeviceItem d={list[index]} />}
                // scrollSeekConfiguration={{
                //   enter: velocity => Math.abs(velocity) > 200,
                //   exit: velocity => Math.abs(velocity) < 30,
                //   change: (_, range) => console.log({ range }),
                // }}
              />
            )}
          </div>
        </div>
        <div className={`right-lay`}>
          {shops.map((u, i) => {
            return (
              <ShopDevice
                key={i}
                u={u.username}
                active={u.active && socket_connected}
                onDeviceLoaded={onDeviceLoaded}
                //
                socket_refresh_id={u.socket_refresh_id}
                socket_dev={u.socket_dev}
                socket_dev_reply={u.socket_dev_reply}
                socket_dev_process={u.socket_dev_process}
                //
                onRequestShopDevice={onRequestShopDevice}
                onSendShopCart={onSendShopCart}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShopPage;

type DeviceItemProps = {
  d: Device;
};

const DeviceItem = (props: DeviceItemProps) => {
  const history = useHistory();

  return (
    <Link
      to={Routes.Shop + "/" + props.d.username}
      className={`shop-device-item-lay${
        history.location.pathname.startsWith(
          Routes.Shop + "/" + props.d.username
        )
          ? " also-active"
          : ""
      }`}
    >
      <span className="id-text">{props.d.username}</span>
      <div
        className="image-lay"
        style={{
          backgroundImage: `url(${require("../../assets/images/moran-vending-machine.png")})`,
        }}
      >
        <div className="image-lay-top" />
        {/* <img className='image'
          src={require('../../assets/images/moran-vending-machine.png')}
          alt='vending photo' /> */}
      </div>
      <div className="info-lay">
        <span className="title-text">{props.d.name}</span>
        <span className="business-text">{props.d.business_name}</span>
        <div className="location-lay">
          <span className="location-title">{props.d.location_name}</span>
          <div className="location-info">
            <MdLocationOn className="location-icon" />
            <span className="location-text">
              {props.d.region + ", " + props.d.country}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};
