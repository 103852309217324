import "./index.scss";
import React from "react";
import { Link, useHistory } from "react-router-dom";

import AuthContext from "../../../service/context";
import DatabaseManager from "../../../service";
import Routes from "../../../utils/routes";
import Themer from "../../../utils/themer";
import { useContext, useEffect, useRef, useState } from "react";

const PasswordForget = () => {
  const history = useHistory();
  const didMount = useRef(false);
  const databaseManager: DatabaseManager = new DatabaseManager();
  const contextType = useContext(AuthContext);

  const [email, setEmil] = useState("");
  const [error, setError] = useState<any | null>(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    didMount.current = true;
    const user_id = localStorage.getItem("vending-uid");
    if (user_id) {
      contextType.setIsAuthenticated(true);
      let { from }: any = history.location.state || {
        from: { pathname: Routes.Dashboard },
      };
      history.replace(from);
    }
    Themer.subscribe();
    document.title = "Forget Password - Moran Vending Machine";

    return () => {
      didMount.current = false;
      Themer.unsubscribe();
    };
  }, []);

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (!didMount.current) return;

    // check if email is valid
    if (!validateEmail(email)) {
      setError("Invalid email address");
      return;
    }

    setIsLoading(true);
    setError(null);

    databaseManager.sendPasswordResetEmail(email, {
      onResult: (result: any) => {
        if (!didMount.current) return;
        setIsLoading(false);
        if (result) {
          // console.log('result: ', result);
          if (result.success) {
            // const name = result.success.data[0].name;
            // const user_id = result.success.data[0].id;
            // const user_role = result.success.data[0].role;
            // localStorage.setItem('vending-un', name);
            // localStorage.setItem('vending-uid', user_id);
            // localStorage.setItem('vending-ur', user_role);

            // contextType.setIsAuthenticated(true);
            // let { from }: any = history.location.state || {
            //   from: { pathname: Routes.Dashboard },
            // };
            // history.replace(from);
            if (result.success.message && result.success.message.length > 0) {
              setError({ message: result.success.message });
            }
          } else {
            setError({ message: result.error.message });
          }
        } else {
          setError({
            message: "Something went wrong. please try again later.",
          });
        }
      },
      onError: (_err: any) => {
        if (!didMount.current) return;
        // console.log('fetch: error: ', err);
        setIsLoading(false);
        setError({ message: "Something went wrong. please try again later." });
      },
    });
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onEmailChange = (event: any) => {
    setEmil(event.target.value);
    setIsInvalid(event.target.value.length < 5);
  };

  return (
    <div className="pf-main">
      <div className="pf-header">
        <span className="app-title">Moran Vending Machine</span>
        <div className="pallete-container">
          <label className="switch" title="Change theme">
            <input type="checkbox" className="checkbox" id="theme-btn" />
            <span className="toggle-thumb">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 20 20"
                style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}
              >
                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 20 20"
                style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}
              >
                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
              </svg>
            </span>
          </label>
        </div>
      </div>
      <div className="pf-form-container">
        <form className="form-container" onSubmit={onSubmit}>
          <span className="pf-title">Forget Password</span>
          <span className="pf-title">
            Provide your registered email address to get intructions.
          </span>
          <input
            required={true}
            className="email-input input"
            name="email"
            value={email}
            onChange={onEmailChange}
            type="text"
            autoComplete="email"
            placeholder="Enter Your Email Address"
          />
          <button className="pf-btn" disabled={isInvalid} type="submit">
            Continue
          </button>

          {isLoading && (
            <i className="fa-li fa fa-spinner fa-spin loading-icon"></i>
          )}
          {error && <p className="message-box">{error.message}</p>}
          <Link to={Routes.Login} className="signup-btn">
            Login
          </Link>
          <Link to={Routes.Signup} className="signup-btn">
            Create Account
          </Link>
          <div className="footer-lay">
            <div className="footer-seg">
              <Link className="footer-link" to={Routes.Landing}>
                Home
              </Link>
              <Link className="footer-link" to={Routes.Shop}>
                Shop
              </Link>
              <Link className="footer-link" to={Routes.Privacy}>
                Privacy
              </Link>
              <Link
                className="footer-link"
                to={Routes.Terms} /* target='_blank' rel='noreferrer'*/
              >
                Terms
              </Link>
            </div>
            <div className="footer-seg">
              <span className="footer-text">Copyright &copy;</span>
              <a
                className="footer-link"
                href="https://imperialinnovations.co.tz"
                target="_blank"
                rel="noreferrer"
              >
                Imperial Innovations
              </a>
              <span className="footer-text">2023</span>
            </div>
            <div className="footer-seg">
              <span className="footer-text">All Rights Reserved</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordForget;
