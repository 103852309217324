import "./index.scss";

import {
  ChartSeries,
  Device,
  DeviceActivity,
  ListObject,
  UserData,
} from "../../../../../models";
import { Link, useHistory } from "react-router-dom";
import {
  RefObject,
  SyntheticEvent,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import React from "react";
import { AiOutlineBarChart } from "react-icons/ai";
import DatabaseManager from "../../../../../../service";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { DateRangePicker } from "rsuite";
import ExcelJS from "exceljs";
import { FaListUl } from "react-icons/fa";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Period from "../../../../../../utils/period";
import Routes from "../../../../../../utils/routes";
import { TableVirtuoso } from "react-virtuoso";
import addDays from "date-fns/addDays";
import moment from "moment";
import subDays from "date-fns/subDays";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

const { combine, before, afterToday } = DateRangePicker;

type ActivitiesProps = {
  scroller: HTMLElement;
  device: Device;
  user_data: UserData;
  refreshId: number;
  show: boolean;
  onListChanged: (list_size: number) => void;
  export_id: number;
};

const ActivitiesTab = (props: ActivitiesProps) => {
  const history = useHistory();
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const databaseManager: DatabaseManager = new DatabaseManager();
  const root: RefObject<HTMLDivElement> = useRef(null);
  const modal_ref: RefObject<HTMLDivElement> = useRef(null);

  const wasViewInitialised = useRef(false);
  const [showInputModal, setShowInputModal] = useState(false);
  const [selected_date, setSelectedDate] = useState<DateRange>([
    Period.getTodayStartTime(),
    Period.getTodayEndTime(),
  ]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(true);
  const lastItemDateCreated = useRef(0);
  const [error_loading, setErrorLoading] = useState<string | null>(null);
  const [display_start_time, setDisplayStartTime] = useState<string>("");
  const [display_end_time, setDisplayEndTime] = useState<string>("");

  const [viewBy, setViewBy] = useState<"date" | "default">("default");
  const viewByRef = useRef<"date" | "default">("default");
  const [tabType, setTabType] = useState<"chart" | "list">("chart");

  const [list, setList] = useState<ListObject<DeviceActivity>[]>([]);

  const [selected_act, setSelectedAct] = useState<DeviceActivity | null>(null);

  const chart_options_1: Highcharts.Options = new ActivityChartOps().getOptions;
  const chart_options_2: Highcharts.Options = new ActivityChartOps().getOptions;
  const chart_options_3: Highcharts.Options = new ActivityChartOps().getOptions;

  const _chart_1_options = chart_options_1;
  (_chart_1_options.chart as any).type = "line";
  if (_chart_1_options.title)
    _chart_1_options.title.text = "Fridge & Glass Heat States";
  (_chart_1_options.xAxis as any).type = "datetime";
  (_chart_1_options.yAxis as any).title.text = "States";
  _chart_1_options.colors = ["#e900c2", "#3f3e82", "#1bd111", "#ff0000"];
  if (_chart_1_options.chart && _chart_1_options.chart.events) {
    _chart_1_options.chart.events.load = () => onChartLoad();
    _chart_1_options.chart.events.render = () => onChartRendered();
    _chart_1_options.chart.events.redraw = () => onChartRedraw();
  }
  const [chart_1_options, setChart1Options] =
    useState<Highcharts.Options>(_chart_1_options);

  const _chart_2_options = chart_options_2;
  (_chart_2_options.chart as any).type = "line";
  if (_chart_2_options.title)
    _chart_2_options.title.text = "Door & Light State";
  (_chart_2_options.xAxis as any).type = "datetime";
  (_chart_2_options.yAxis as any).title.text = "States";
  _chart_2_options.colors = ["#1bd111", "#3f3e82", "#ff0000"];
  if (_chart_2_options.chart && _chart_2_options.chart.events) {
    _chart_2_options.chart.events.load = () => onChartLoad();
    _chart_2_options.chart.events.render = () => onChartRendered();
    _chart_2_options.chart.events.redraw = () => onChartRedraw();
  }
  const [chart_2_options, setChart2Options] =
    useState<Highcharts.Options>(_chart_2_options);

  const _chart_3_options = chart_options_3;
  (_chart_3_options.chart as any).type = "line";
  if (_chart_3_options.title)
    _chart_3_options.title.text = "Device Temperature °C";
  (_chart_3_options.xAxis as any).type = "datetime";
  (_chart_3_options.yAxis as any).title.text = "Temperature °C";
  _chart_3_options.colors = ["#3f3e82", "#1bd111", "#ff0000"];
  if (_chart_3_options.chart && _chart_3_options.chart.events) {
    _chart_3_options.chart.events.load = () => onChartLoad();
    _chart_3_options.chart.events.render = () => onChartRendered();
    _chart_3_options.chart.events.redraw = () => onChartRedraw();
  }
  const [chart_3_options, setChart3Options] =
    useState<Highcharts.Options>(_chart_3_options);

  useEffect(() => {
    didMount.current = true;
    if (!wasViewInitialised.current) {
      if (props.show) {
        init();
      }
    }
    return () => {
      didMount.current = false;
    };
  }, []);

  useEffect(() => {
    if (!didMount.current) return;
    if (props.show) {
      if (!wasViewInitialised.current) {
        init();
      } else {
        if (props.refreshId > 0) {
          // console.log('refreshing orders');
          onRefreshClick();
        }
      }
    }
  }, [props.refreshId]);

  useEffect(() => {
    if (!didMount.current) return;
    if (props.show) {
      if (!wasViewInitialised.current) {
        init();
      }
    }
  }, [props.show]);

  const init = () => {
    wasViewInitialised.current = true;
    listenForInputFocus();
    if (viewByRef.current === "default") {
      // console.log('initial-calling: getDataByDefault');
      getDataByDefault(true);
    } else {
      // console.log('initial-calling: getDataByDate');
      getDataByDate(selected_date, true);
    }
  };

  const listenForInputFocus = () => {
    setTimeout(() => {
      if (!didMount.current) {
        return;
      }
      if (root.current) {
        // find first input element
        const input_element = root.current.querySelector(
          "input"
        ) as HTMLInputElement;
        if (input_element) {
          // detect on focus
          input_element.addEventListener("focus", () => {
            if (!didMount.current) {
              return;
            }
            // console.log('input_element.focus()');
            setShowInputModal(true);
            forceUpdate();

            setTimeout(() => {
              if (!didMount.current) {
                return;
              }
              // find element by class name
              const buttons_container = document.querySelector(
                ".rs-picker-toolbar"
              ) as HTMLDivElement;
              if (buttons_container) {
                // get all buttons and add event listener for click
                const buttons = buttons_container.querySelectorAll("button");
                if (buttons && buttons.length > 0) {
                  for (let i = 0; i < buttons.length; i++) {
                    if (!didMount.current) {
                      return;
                    }
                    const button = buttons[i] as HTMLButtonElement;
                    if (button) {
                      button.addEventListener("click", () => {
                        if (!didMount.current) {
                          return;
                        }
                        // console.log('button.click()');
                        setShowInputModal(false);
                        forceUpdate();
                      });
                    }
                  }
                }
              }
            }, 1000);
          });
        }

        // detect ecscape key for this div
        document.addEventListener("keydown", (e) => {
          if (!didMount.current) {
            return;
          }
          if (e.keyCode === 27) {
            // console.log('dashboard_ref.current.keydown()');
            if (root.current) {
              if (!didMount.current) {
                return;
              }
              if (modal_ref.current) {
                // perform click
                modal_ref.current.click();
              } else {
                // console.log('modal not found');
                setShowInputModal(false);
                forceUpdate();
              }
            }
          }
        });
      }
    }, 100);
  };

  const onRefreshClick = () => {
    if (!didMount.current || isLoading) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/activities`
      )
    )
      return;
    lastItemDateCreated.current = 0;
    forceUpdate();
    if (viewByRef.current === "default") {
      getDataByDefault(true);
    } else {
      getDataByDate(selected_date, true);
    }
  };

  const loadMore = () => {
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/activities`
      )
    )
      return;
    if (!didMount.current) return;
    if (!isMoreDataAvailable) return;
    if (isLoading) return;
    if (viewByRef.current !== "default") return;
    getDataByDefault(false);
  };

  const resetData = (dateRange: DateRange, fromDatePicker: boolean) => {
    if (!didMount.current) return;
    const todayDateRange: DateRange = [
      Period.getDayStartTime(),
      Period.getTodayEndTime(),
    ];

    setIsLoading(false);
    const _selected_date = fromDatePicker ? dateRange : todayDateRange;
    setSelectedDate(_selected_date);
    forceUpdate();

    setTimeout(() => {
      if (!didMount.current) return;
      if (
        !history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/activities`
        )
      )
        return;
      getDataByDate(_selected_date, true);
    }, 500);
  };

  const getDataByDate = (dateRange: DateRange, is_refresh: boolean) => {
    if (!didMount.current) return;
    if (isLoading) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/activities`
      )
    )
      return;
    const _selected_date: DateRange = dateRange;
    const start_date: Date = _selected_date[0]
      ? _selected_date[0]
      : Period.getTodayStartTime();
    const end_date: Date = _selected_date[1]
      ? _selected_date[1]
      : Period.getTodayEndTime();

    const the_start_date: number = start_date.valueOf();
    const the_end_date: number = end_date.valueOf();
    getData(the_start_date, the_end_date, is_refresh);
  };

  const getDataByDefault = (is_refresh: boolean) => {
    if (!didMount.current) return;
    if (isLoading) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/activities`
      )
    )
      return;

    const end_of_today: Date = Period.getTodayEndTime();
    const the_end_of_today_date: number = end_of_today.valueOf() + 1;

    if (is_refresh) {
      getData(the_end_of_today_date, 0, true);
    } else {
      const next_date =
        lastItemDateCreated.current - 1 > 0
          ? lastItemDateCreated.current - 1
          : the_end_of_today_date;
      getData(next_date, 0, false);
    }
  };

  const getData = (
    start_time: number,
    end_time: number,
    is_refresh: boolean
  ) => {
    if (!didMount.current) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/activities`
      )
    )
      return;
    if (start_time > 0) {
      if (is_refresh) {
        setList([]);
        setChart1Options(_chart_1_options); // reset chart
        setChart2Options(_chart_2_options); // reset chart
        setChart3Options(_chart_3_options); // reset chart
        setIsInitialLoading(true);
      }
      setIsLoading(true);
      setErrorLoading(null);
      forceUpdate();
      // databaseManager.cancelRequests();
      databaseManager
        .getDevicectivitiesData(
          props.user_data.id,
          props.device.id,
          start_time,
          end_time
        )
        .then((result: any) => {
          if (!didMount.current) return;
          // console.log("getDeviceOrdersData: result: ", result);
          // setIsLoading(false);
          // setIsInitialLoading(false);
          if (result.success && result.success.data) {
            setErrorLoading(null);
            const _list: ListObject<DeviceActivity>[] = is_refresh ? [] : list;
            const das: DeviceActivity[] = result.success.data;
            if (das && das.length > 0) {
              setIsMoreDataAvailable(true);
              setChart1Options(_chart_1_options); // reset chart
              setChart2Options(_chart_2_options); // reset chart
              setChart3Options(_chart_3_options); // reset chart
              for (let i = 0; i < das.length; i++) {
                const device_activity: DeviceActivity = das[i];
                const obj: ListObject<DeviceActivity> = {
                  id: device_activity.date_created.toString(),
                  type: "item",
                  data: device_activity,
                };
                const existing_pos = isItemExist(
                  device_activity.date_created.toString(),
                  _list
                );
                if (existing_pos != -1) {
                  _list[existing_pos] = obj;
                } else {
                  _list.push(obj);
                }
              }
              // sort list by date_created in descending order
              _list.sort((a, b) => {
                const a_date = (a.data as DeviceActivity).date_created;
                const b_date = (b.data as DeviceActivity).date_created;
                return b_date - a_date;
              });
              setTimeout(() => {
                const _first_item_date_created =
                  _list.length > 0
                    ? (_list[0].data as DeviceActivity).date_created
                    : 0;
                const _last_item_date_created =
                  _list.length > 0
                    ? (_list[_list.length - 1].data as DeviceActivity)
                        .date_created
                    : 0;
                if (_list.length > 0) {
                  // console.log('first-item-date-created: ', _first_item_date_created);
                  // console.log('last-item-date-created: ', _last_item_date_created);
                  lastItemDateCreated.current = _last_item_date_created;
                }
                // let _title = "";
                if (
                  _first_item_date_created > 0 &&
                  _last_item_date_created > 0
                ) {
                  // get first date using moment in format of "DD-MM-YYYY at HH:mm"
                  const _first_date = moment(_first_item_date_created).format(
                    "DD-MM-YYYY HH:mm"
                  );
                  // get last date using moment in format of "DD-MM-YYYY at HH:mm"
                  const _last_date = moment(_last_item_date_created).format(
                    "DD-MM-YYYY HH:mm"
                  );
                  // _title = ` Between ${_last_date} to ${_first_date}`;
                  setDisplayStartTime(_first_date);
                  setDisplayEndTime(_last_date);
                }

                const series_1: ChartSeries[] = [
                  {
                    name: "Fridge",
                    type: "area",
                    turboThreshold: 10000,
                    data: [],
                  },
                  {
                    name: "Glass Heat",
                    type: "area",
                    turboThreshold: 10000,
                    data: [],
                  },
                ]; // y-axis series

                const series_2: ChartSeries[] = [
                  {
                    name: "Door",
                    type: "area",
                    turboThreshold: 10000,
                    data: [],
                  },
                  {
                    name: "Light",
                    type: "area",
                    turboThreshold: 10000,
                    data: [],
                  },
                ]; // y-axis series

                const series_3: ChartSeries[] = [
                  {
                    name: "Temperature",
                    type: "area",
                    turboThreshold: 10000,
                    data: [],
                  },
                  {
                    name: "Target Temp",
                    type: "line",
                    turboThreshold: 10000,
                    data: [],
                  },
                ]; // y-axis series

                das.forEach((da) => {
                  series_1[0].data.push({
                    x: da.date_created,
                    y: da.fridge,
                  });
                  series_1[1].data.push({
                    x: da.date_created,
                    y: da.glass_heat,
                  });
                  series_2[0].data.push({
                    x: da.date_created,
                    y: da.door,
                  });
                  series_2[1].data.push({
                    x: da.date_created,
                    y: da.light,
                  });
                  series_3[0].data.push({
                    x: da.date_created,
                    y: da.temp,
                  });
                  series_3[1].data.push({
                    x: da.date_created,
                    y: da.target_temp,
                  });
                });
                const new__chart_1_options = _chart_1_options;
                (new__chart_1_options as any).series = series_1;
                const new__chart_2_options = _chart_2_options;
                (new__chart_2_options as any).series = series_2;
                const new__chart_3_options = _chart_3_options;
                (new__chart_3_options as any).series = series_3;

                setChart1Options(new__chart_1_options);
                setChart2Options(new__chart_2_options);
                setChart3Options(new__chart_3_options);
                setList(_list);
                setIsLoading(false);
                setIsInitialLoading(false);
                forceUpdate();
              }, 100);
            } else {
              setIsLoading(false);
              setIsInitialLoading(false);
              setIsMoreDataAvailable(false);
            }
          } else {
            setIsLoading(false);
            setIsInitialLoading(false);
            setErrorLoading("Couldn't load data, please try again");
          }
          forceUpdate();
        });
    }
  };

  const isItemExist = (
    id: string,
    list: ListObject<DeviceActivity>[]
  ): number => {
    const filtered_list = list.filter(
      (obj: ListObject<DeviceActivity>) => obj.id === id
    );
    if (filtered_list && filtered_list.length > 0) {
      return list.indexOf(filtered_list[0]);
    }
    return -1;
  };

  const datePicker = () => {
    const lower_date = new Date();
    lower_date.setFullYear(2022, 5, 1);
    lower_date.setHours(0, 0, 0, 0);

    return (
      <DateRangePicker
        format={"dd-MM-yyyy HH:mm:ss"}
        appearance="subtle"
        placeholder="Select date range"
        character="  to  "
        // size="sm"
        defaultValue={selected_date}
        cleanable={false}
        oneTap={false}
        open={showInputModal}
        disabledDate={
          combine &&
          combine(before && before(lower_date), afterToday && afterToday())
        }
        style={{
          backgroundColor: "var(--color-secondary)",
        }}
        ranges={[
          {
            label: "Yesterday",
            value: [
              addDays(Period.getDayStartTime(), -1),
              addDays(Period.getDayEndTime(), -1),
            ],
          },
          {
            label: "Today",
            value: [Period.getTodayStartTime(), Period.getTodayEndTime()],
          },
          // {
          //   label: 'Tomorrow',
          //   value: [dateFns.addDays(new Date(), 1), dateFns.addDays(new Date(), 1)]
          // },
          {
            label: "Last 7 days",
            value: [
              subDays(Period.getDayStartTime(), 6),
              Period.getDayEndTime(),
            ],
          },
          {
            label: "Last 30 days",
            value: [
              subDays(Period.getDayStartTime(), 29),
              Period.getDayEndTime(),
            ],
          },
        ]}
        onOpen={() => {
          // console.log('date-range-picker: opened');
          setShowInputModal(true);
        }}
        onChange={(date: DateRange | null) => {
          if (date === null) {
            return;
          }
          // console.log('date-range-picker: changed: ', date);
          resetData(date, true);
        }}
        onOk={(date: DateRange, event: SyntheticEvent<Element, Event>) => {
          if (date === null) {
            return;
          }
          event.preventDefault();
          // console.log('date-range-picker: ok: ', date);
          resetData(date, true);
        }}
        onClose={() => {
          setShowInputModal(false);
        }}
      />
    );
  };

  const getDateView = (time: number): string[] => {
    // get current time using moment
    // const current_time = moment();
    // get date of today using moment
    const today = moment().startOf("day").format("DD-MM-YYYY");
    // get date of yesterday using moment
    const yesterday = moment()
      .subtract(1, "days")
      .startOf("day")
      .format("DD-MM-YYYY");
    // get input date using moment
    const input_date = moment(time).format("DD-MM-YYYY");
    // get input time using moment
    const input_time = moment(time).format("HH:mm:ss");
    // check if input date is today
    if (input_date === today) {
      return ["Today", input_time];
    }
    // check if input date is yesterday
    if (input_date === yesterday) {
      return ["Yesterday", input_time];
    }
    return [input_date, input_time];
  };

  const onChartRendered = () => {
    // console.log('onChartRendered: called:');
  };

  const onChartLoad = () => {
    // console.log('onChartLoad:     called:');
  };

  const onChartRedraw = () => {
    // console.log('onChartRedraw:   called:');
  };

  const onExportClick = () => {
    if (list.length === 0) return;
    // create a new workbook from orders data
    const workbook = new ExcelJS.Workbook();
    // get current date
    const current_date = new Date();

    workbook.creator = "Moran Vending Machine - Dashboard";
    workbook.created = current_date;
    workbook.modified = current_date;
    workbook.lastModifiedBy = "Moran Vending Machine - Dashboard";
    workbook.lastPrinted = current_date;

    // get current date in format of dd-mm-yyyy-hh-mm-ss
    const _date = moment(current_date).format("DD-MM-YYYY-HH-mm-ss");
    const file_name = `${props.device.id}-ACTIVITIES-${_date}.xlsx`;
    // create a new worksheet
    let _title = props.device.id + " Activities";
    if (display_start_time.length > 0 && display_end_time.length > 0) {
      _title += ` Between ${display_end_time} to ${display_start_time}`;
    }
    const worksheet = workbook.addWorksheet(props.device.id + " Activities");
    // add headers
    worksheet.columns = [
      // { header: 'SN', key: 'sn', width: 10 },
      { header: "Date Created".toUpperCase(), key: "date_created", width: 25 },
      { header: "Fridge".toUpperCase(), key: "fridge", width: 20 },
      { header: "Glass Heat".toUpperCase(), key: "glass_heat", width: 28 },
      { header: "Door".toUpperCase(), key: "door", width: 20 },
      { header: "Temperature".toUpperCase(), key: "temp", width: 30 },
      {
        header: "Target Temperature".toUpperCase(),
        key: "target_temp",
        width: 35,
      },
    ];
    // set header alignment
    worksheet.getRow(1).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    // set header font
    worksheet.getRow(1).font = { name: "Arial", size: 10, bold: true };
    // set header border
    worksheet.getRow(1).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    // set header fill
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "FFD3D3D3",
      },
      bgColor: {
        argb: "FFD3D3D3",
      },
    };
    // set header height
    worksheet.getRow(1).height = 25;
    // set header wrap text
    worksheet.getRow(1).alignment = { wrapText: true };
    // add data
    // worksheet.addRows(list);
    // ireate over orders data instead of using addRows
    list.forEach((obj: ListObject<DeviceActivity>, index: number) => {
      if (obj.data) {
        const da: DeviceActivity = obj.data as DeviceActivity;
        // get date created in format: DD-MM-YYYY HH:mm:ss using moment
        const date_created = moment(da.date_created).format(
          "DD-MM-YYYY HH:mm:ss"
        );
        // add row
        worksheet.addRow({
          date_created: date_created,
          fridge: da.fridge === 0 ? "OFF" : "ON",
          glass_heat: da.glass_heat === 0 ? "OFF" : "ON",
          door: da.door === 0 ? "CLOSED" : "OPEN",
          temp: da.temp + " °C",
          target_temp: da.target_temp + " °C",
        });
        // set row height
        worksheet.getRow(index + 2).height = 50;
        // set row alignment also wrap text
        worksheet.getRow(index + 2).alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        // set row content wrap text
        // worksheet.getRow(index + 2).alignment = { wrapText: true };
        // set row border
        worksheet.getRow(index + 2).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        // set row font
        worksheet.getRow(index + 2).font = { name: "Arial", size: 10 };
        // alternate row color
        if (index % 2 === 0) {
          worksheet.getRow(index + 2).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "FFF5F5F5",
            },
            bgColor: {
              argb: "FFF5F5F5",
            },
          };
        }
      }
    });

    // save workbook
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // FileSaver.saveAs(blob, 'orders.xlsx');
      // create a new link to download the file and click it
      const link = document.createElement("a");
      // hide the link
      link.style.display = "none";
      link.href = window.URL.createObjectURL(blob);
      link.download = file_name;
      document.body.appendChild(link);
      // click the link
      link.click();
      setTimeout(() => {
        // remove the link
        document.body.removeChild(link);
      }, 1000);
    });
  };

  // // format price with separated thousands
  // const formatPrice = (price: number): string => {
  //   return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  // // format number with suffix either K or M or B or T or P
  // const formatNumber = (num: number): string => {
  //   if (num < 10000) return "" + num;

  //   // what tier? (determines SI symbol)
  //   var tier = Math.log10(Math.abs(num)) / 3 | 0;

  //   // if zero, we don't need a suffix
  //   if (tier === 0) return num.toString();

  //   const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
  //   // get suffix and determine scale
  //   var suffix = SI_SYMBOL[tier];
  //   var scale = Math.pow(10, tier * 3);

  //   // scale the number
  //   var scaled = num / scale;

  //   // format number and add suffix
  //   return scaled.toFixed(1) + suffix;
  // }

  // const onActivityUpdated = (new_order: DeviceActivity) => {
  //   if (!didMount.current) return;
  //   if (list.length === 0) return;
  //   const index = list.findIndex(order => order.id === new_order.date_created.toString());
  //   if (index === -1) return;
  //   const new_list = [...list];
  //   new_list[index].data = new_order;
  //   setList(new_list);
  // }

  useEffect(() => {
    if (!didMount.current) return;
    if (props.export_id <= 0) return;
    onExportClick();
  }, [props.export_id]);

  useEffect(() => {
    if (!didMount.current) return;
    props.onListChanged(list.length);
  }, [list.length]);

  const tableHeader = useRef<HTMLDivElement>(null);
  const tableFooter = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* {history.location.pathname === `${Routes.Devices}/${props.device.username}/activities/` &&
        history.location.pathname.length > `${Routes.Devices}/${props.device.username}/activities/`.length + 1 &&
        <ActivityInfo
          user_data={props.user_data}
          device={props.device}
          activity={selected_act}
          onUpdated={onActivityUpdated}
          onClose={() => {
            setSelectedAct(null);
            forceUpdate();
          }} />
      } */}
      <div
        className="activities-main-main-lay"
        ref={root}
        style={{ display: props.show ? "flex" : "none" }}
      >
        {showInputModal && (
          <div
            className="orders-input-modal"
            ref={modal_ref}
            onClick={() => {
              if (!didMount.current) return;
              setShowInputModal(false);
            }}
          />
        )}
        <div
          className={`activities-tab-date-lay${
            viewBy === "default" &&
            tabType === "chart" &&
            list.length > 0 &&
            isMoreDataAvailable
              ? " with-load-btn"
              : ""
          }`}
        >
          <div className="left-lay">
            <div className="set-lay">
              <span className="set-lay-text">View By</span>
              <select
                className="set-lay-select"
                value={viewBy === "date" ? "date" : "default"}
                onChange={(e) => {
                  e.preventDefault();
                  let val = e.target.value.toString();
                  setViewBy(val === "date" ? "date" : "default");
                  viewByRef.current = val === "date" ? "date" : "default";
                  if (val === "date") {
                    listenForInputFocus();
                  }
                  forceUpdate();
                  setTimeout(() => {
                    // console.log('viewBy changed, calling onRefreshClick');
                    onRefreshClick();
                  }, 500);
                }}
              >
                <option value={"default"}>Default</option>
                <option value={"date"}>Date Range</option>
              </select>
            </div>
            <div className="btns-lay">
              <button
                className={`btns-lay-btn${
                  tabType === "chart" ? " btn-active" : ""
                }`}
                onClick={() => {
                  setTabType("chart");
                }}
              >
                <AiOutlineBarChart className="btns-lay-btn-icon" />
                <span className="btns-lay-btn-text">Chart</span>
              </button>
              <button
                className={`btns-lay-btn${
                  tabType === "list" ? " btn-active" : ""
                }`}
                onClick={() => {
                  setTabType("list");
                }}
              >
                <FaListUl className="btns-lay-btn-icon" />
                <span className="btns-lay-btn-text">List</span>
              </button>
              {viewBy === "default" &&
                tabType === "chart" &&
                list.length > 0 &&
                isMoreDataAvailable && (
                  <button
                    className={`btns-lay-btn btn-active only-text`}
                    onClick={loadMore}
                  >
                    <span className="btns-lay-btn-text">Load More</span>
                  </button>
                )}
            </div>
          </div>
          {viewBy === "date" && (
            <div className="range-display-lay">
              <div className="range-display-cont">{datePicker()}</div>
            </div>
          )}
        </div>
        {isInitialLoading && (
          <div className="loading-lay">
            <div className="def-loading-lay">
              <div className="def-loading-spinner" />
              <span className="def-loading-text">Loading...</span>
            </div>
          </div>
        )}
        {((!isLoading && list.length === 0) || error_loading) && (
          <div className="no-devices-lay">
            {!error_loading && (
              <span className="no-devices-text">
                No data available, try select different date
              </span>
            )}
            {error_loading && (
              <span
                className="no-devices-text error-text"
                onClick={onRefreshClick}
              >
                {error_loading}
              </span>
            )}
          </div>
        )}
        <div
          className="activities-tab-charts-lay"
          style={{ display: tabType === "chart" ? "flex" : "none" }}
        >
          <div className="activities-tab-charts-chart-lay">
            {!isLoading && (
              <HighchartsReact
                highcharts={Highcharts}
                isPureConfig={true}
                oneToOne={true}
                constructorType={"chart"}
                allowChartUpdate={false}
                immutable={true}
                updateArgs={[false, false, false]}
                containerProps={{
                  className: "chart-lay",
                  id: "activities-tab-charts-chart-3",
                }}
                options={chart_3_options}
              />
            )}
          </div>
          <div className="activities-tab-charts-chart-lay">
            {!isLoading && (
              <HighchartsReact
                highcharts={Highcharts}
                isPureConfig={true}
                oneToOne={true}
                constructorType={"chart"}
                allowChartUpdate={false}
                immutable={true}
                updateArgs={[false, false, false]}
                containerProps={{
                  className: "chart-lay",
                  id: "activities-tab-charts-chart-1",
                }}
                options={chart_1_options}
              />
            )}
          </div>
          <div className="activities-tab-charts-chart-lay">
            {!isLoading && (
              <HighchartsReact
                highcharts={Highcharts}
                isPureConfig={true}
                oneToOne={true}
                constructorType={"chart"}
                allowChartUpdate={false}
                immutable={true}
                updateArgs={[false, false, false]}
                containerProps={{
                  className: "chart-lay",
                  id: "activities-tab-charts-chart-2",
                }}
                options={chart_2_options}
              />
            )}
          </div>
        </div>
        <div
          className="activities-tab-table-header"
          ref={tableHeader}
          style={{
            display: list.length > 0 && tabType === "list" ? "flex" : "none",
          }}
        >
          <div
            className="activities-tab-table-header-seg-lay activities-tab-header-seg-1"
            style={{ position: "sticky", left: 0, zIndex: 1 }}
          >
            <span className="activities-tab-table-header-seg">Date</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-2">
            <span className="activities-tab-table-header-seg">Fridge</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-3">
            <span className="activities-tab-table-header-seg">Glass Heat</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-4">
            <span className="activities-tab-table-header-seg">Door</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-5">
            <span className="activities-tab-table-header-seg">Light</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-6">
            <span className="activities-tab-table-header-seg">Temperature</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-7">
            <span className="activities-tab-table-header-seg">
              Target Temperature
            </span>
          </div>
        </div>
        <TableVirtuoso
          className="activities-tab-table-contents"
          style={{ display: tabType === "list" ? "flex" : "none" }}
          customScrollParent={props.scroller}
          onScroll={(event) => {
            if (tableHeader.current) {
              tableHeader.current.scrollLeft = (
                event.currentTarget as any
              ).scrollLeft;
            }
            if (tableFooter.current) {
              tableFooter.current.scrollLeft = (
                event.currentTarget as any
              ).scrollLeft;
            }
          }}
          data={list}
          defaultItemHeight={80}
          fixedItemHeight={80}
          itemContent={(index: number, obj: ListObject<DeviceActivity>) => {
            return (
              <>
                {obj.data && (
                  <>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-1 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                      style={{ position: "sticky", left: 0, zIndex: 1 }}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/activities/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedAct(obj.data as DeviceActivity);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {
                            getDateView(
                              (obj.data as DeviceActivity).date_created
                            )[0]
                          }
                        </span>
                        <span className="tab-item-main-seg-text">
                          {
                            getDateView(
                              (obj.data as DeviceActivity).date_created
                            )[1]
                          }
                        </span>
                      </Link>
                    </td>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-2 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/activities/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedAct(obj.data as DeviceActivity);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {(obj.data as DeviceActivity).fridge === 0
                            ? "OFF"
                            : "ON"}
                        </span>
                      </Link>
                    </td>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-3 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/activities/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedAct(obj.data as DeviceActivity);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {(obj.data as DeviceActivity).glass_heat === 0
                            ? "OFF"
                            : "ON"}
                        </span>
                      </Link>
                    </td>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-4 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/activities/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedAct(obj.data as DeviceActivity);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {(obj.data as DeviceActivity).door === 0
                            ? "CLOSED"
                            : "OPEN"}
                        </span>
                      </Link>
                    </td>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-5 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/activities/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedAct(obj.data as DeviceActivity);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {(obj.data as DeviceActivity).light === 0
                            ? "OFF"
                            : "ON"}
                        </span>
                      </Link>
                    </td>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-6 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/activities/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedAct(obj.data as DeviceActivity);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {(obj.data as DeviceActivity).temp + " °C"}
                        </span>
                      </Link>
                    </td>
                    <td
                      className={`tab-item-main-seg tab-item-main-seg-7 ${
                        index % 2 === 0 ? "item-even" : "item-odd"
                      }`}
                    >
                      <Link
                        to={
                          Routes.Devices +
                          "/" +
                          props.device.username +
                          "/activities/" +
                          obj.id
                        }
                        className="link-lay"
                        onClick={() => {
                          setSelectedAct(obj.data as DeviceActivity);
                          forceUpdate();
                        }}
                      >
                        <span className="tab-item-main-seg-text">
                          {(obj.data as DeviceActivity).target_temp + " °C"}
                        </span>
                      </Link>
                    </td>
                  </>
                )}
              </>
            );
          }}
        />
        <div
          className="activities-tab-table-footer"
          ref={tableFooter}
          style={{
            display:
              tabType === "list" && viewBy === "default" ? "flex" : "none",
          }}
        >
          {isMoreDataAvailable && !isLoading && (
            <div
              className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-1"
              style={{ position: "sticky", left: 0, zIndex: 1 }}
            >
              <div
                className="activities-tab-table-footer-btn action-btn"
                onClick={loadMore}
              >
                <span className="activities-tab-table-footer-btn-text action-text">
                  Load More
                </span>
              </div>
            </div>
          )}
          {isLoading && (
            <div
              className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-1"
              style={{ position: "sticky", left: 0, zIndex: 1 }}
            >
              <div className="def-loading-lay">
                <div className="def-loading-spinner" />
                <span className="def-loading-text">Loading...</span>
              </div>
            </div>
          )}
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-2"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-3"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-4"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-5"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-6"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-7"></div>
        </div>
      </div>
    </>
  );
};

export default ActivitiesTab;

export class ActivityChartOps {
  private options: Highcharts.Options = {
    global: {
      // useUTC: false
    },
    chart: {
      // id: id,
      // height: 400,
      // width: '100%',
      type: "column",
      // stacked: true,
      // foreColor: "#ccc",
      // backgroundColor: 'inherit',
      zoomType: "x",
      events: {},
    },
    // colors: ['#3fb0e4', '#811796'],
    title: {
      text: "",
      align: "left",
      style: {
        fontSize: "16px",
        color: "var(--color-edit-txt)",
        fontWeight: "normal",
        fontFamily: "inherit",
        // fontFamily: 'Trebuchet MS, Verdana, sans-serif',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        //align: 'right',
        //verticalAlign: 'top',
        y: 0,
      },
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
          symbol: "circle",
        },
        lineWidth: 2,
      },
    },
    credits: { enabled: false },
    // tooltip: {
    //   // formatter: () => {
    //   //   return 'x: ' + Highcharts.dateFormat('%e %b %y %H:%M:%S', x) +
    //   //     ' y: ' + y.toFixed(2);
    //   // }
    // },
    time: {
      useUTC: false,
    },
    xAxis: {
      // type: 'category', // 'datetime',
      allowDecimals: true,
      startOnTick: true,
      // minRange: 1000 * 60 * 60,
      // dateTimeLabelFormats:
      // gridLineWidth: 1,
      // gridLineColor: 'red',
      // lineColor: 'var(--color-edit-txt)',
      // tickColor: 'var(--color-edit-txt)',
      labels: {
        style: {
          fontSize: "12px",
          color: "var(--color-edit-txt)",
          fontWeight: "normal",
          fontFamily: "Trebuchet MS, Verdana, sans-serif",
        },
      },
      title: {
        style: {
          fontSize: "12px",
          color: "var(--color-edit-txt)",
          fontWeight: "normal",
          fontFamily: "Trebuchet MS, Verdana, sans-serif",
        },
      },
      // categories: [],
      // crosshair: true,
      dateTimeLabelFormats: {
        millisecond: "%H:%M:%S.%L",
        second: "%H:%M:%S",
        minute: "%H:%M",
        hour: "%H:%M",
        day: "%e. %b",
        week: "%e. %b",
        month: "%b '%y",
        year: "%Y",
      },
    },
    yAxis: {
      minorTickInterval: "auto",
      // lineColor: 'var(--color-edit-txt)',
      lineWidth: 1,
      tickWidth: 1,
      // tickColor: 'var(--color-edit-txt)',
      // gridLineWidth: 1,
      // gridLineColor: 'red',
      labels: {
        style: {
          fontSize: "12px",
          color: "var(--color-edit-txt)",
          fontWeight: "normal",
          fontFamily: "Trebuchet MS, Verdana, sans-serif",
        },
      },
      title: {
        style: {
          fontSize: "12px",
          color: "var(--color-edit-txt)",
          fontWeight: "normal",
          fontFamily: "Trebuchet MS, Verdana, sans-serif",
        },
        // text: 'Total Orders Per Machine',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    series: [],
  };
  public get getOptions(): Highcharts.Options {
    return this.options;
  }
}

export class ActivityPieChartOps {
  private options: Highcharts.Options = {
    chart: {
      type: "pie",
      events: {},
    },
    title: {
      text: "",
      align: "left",
      style: {
        fontSize: "16px",
        color: "var(--color-edit-txt)",
        fontWeight: "normal",
        fontFamily: "inherit",
        // fontFamily: 'Trebuchet MS, Verdana, sans-serif',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        //align: 'right',
        //verticalAlign: 'top',
        y: 0,
      },
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
          symbol: "circle",
        },
        lineWidth: 2,
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.y:.1f}%",
        },
      },
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          style: {
            color: "var(--color-edit-txt)",
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Trebuchet MS, Verdana, sans-serif",
          },
        },
      },
    },
    credits: { enabled: false },
    // subtitle: {
    //   text: 'Click the slices to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
    // },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: "%",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      // headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      // '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      // footerFormat: '</table>',
      // shared: true,
      // useHTML: true
    },
    series: [],
  };

  public get getOptions(): Highcharts.Options {
    return this.options;
  }
}
