import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
// import { startOfMonth, endOfMonth, format } from "date-fns";
import { filterAndSortReports, filterAndSortReceipts } from "./function";
const backend = "https://vm.imc.co.tz/";
const fileGeneratorBackend = "https://vendingmaker.imc.co.tz";

function sortByField(arr: any, field: any) {
  return arr.sort((a: any, b: any) => {
    if (a[field] > b[field]) {
      return -1;
    }
    if (a[field] < b[field]) {
      return 1;
    }
    return 0;
  });
}

interface ChildProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Receipt: React.FC<ChildProps> = ({ setLoading }) => {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading1] = useState(false);
  const [fetchDataState, setFetchDataState] = useState(false);
  const [start_date, set_start_date] = useState("2024-06-22");
  const [end_date, set_end_date] = useState("2024-06-10");
  const handleStartDateChange = (event: any) => {
    const date = event.target.value;
    set_start_date(date);
  };
  const handleEndDateChange = (event: any) => {
    const date = event.target.value;
    set_end_date(date);
  };
  useEffect(() => {
    setLoading(true);
    setLoading1(true);
    axios.get(`${backend}/api/v2/report.php`).then(({ data }) => {
      setLoading(false);
      setLoading1(false);
      console.log(data.receipts);

      const output1 = filterAndSortReceipts(
        data.receipts,
        start_date,
        end_date
      );
      console.log(output1);

      setReceipts(output1);
    });
  }, [fetchDataState]);

  useEffect(() => {
    console.log(receipts);
  }, [receipts]);

  return (
    <>
      <div>
        <h4 style={{ marginTop: "10px", textAlign: "center" }}>Export</h4>
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
            gap: "10px",
          }}
        >
          <a
            href={
              fileGeneratorBackend +
              "/" +
              "generate_receipt_pdf?start_date=" +
              start_date +
              "&end_date=" +
              end_date
            }
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              PDF
            </button>
          </a>
          <a
            href={
              fileGeneratorBackend +
              "/" +
              "generate_receipt_excel?start_date=" +
              start_date +
              "&end_date=" +
              end_date
            }
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              Excel
            </button>
          </a>
        </nav>

        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            gap: "20px",
          }}
        >
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              margin: "5px",
            }}
          >
            <span style={{ fontWeight: "bolder" }}>From</span>
            <input
              type="date"
              style={{ padding: "5px" }}
              name=""
              id=""
              value={start_date}
              onChange={handleStartDateChange}
            />
          </nav>
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              margin: "5px",
            }}
          >
            <span style={{ fontWeight: "bolder" }}>To</span>
            <input
              type="date"
              style={{ padding: "5px" }}
              name=""
              id=""
              value={end_date}
              onChange={handleEndDateChange}
            />
          </nav>
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
            }}
          >
            <span>j</span>
            <button
              style={{ background: "red", padding: "6px 30px" }}
              onClick={() => {
                setFetchDataState((f) => !f);
              }}
            >
              Search
            </button>
          </nav>
        </nav>
      </div>
      <div
        style={{
          width: "100%",
          overflow: "auto",
        }}
      >
        <div className="App">
          <table
            style={{
              margin: "20px auto",
              borderCollapse: "collapse",
            }}
          >
            <caption
              style={{ captionSide: "top", fontSize: "1.5em", margin: "10px" }}
            >
              Receipts Information
            </caption>
            <div style={{ height: "70vh", position: "relative" }}>
              <thead>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    RCT Number
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    RCT Date
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Receipt Time
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Subtotal
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Discount
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    VAT
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <div
                  style={{
                    height: "10px",
                  }}
                ></div>
                {receipts.map((receipt: any, index) =>
                  receipt.receipt_date == "0000-00-00" ? (
                    ""
                  ) : (
                    <tr key={index}>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.R_num}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.receipt_date}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.receipt_time}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.subtotal}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.discount}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {(receipt.total - receipt.subtotal).toFixed(2)}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.total}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <nav>
                    <h3>Please wait </h3>
                    <h4 style={{ textAlign: "center" }}>data featching...</h4>
                  </nav>
                  <ColorRing
                    visible={true}
                    height="100"
                    width="100"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              )}
            </div>
          </table>
        </div>
      </div>
    </>
  );
};

const ZReport: React.FC<ChildProps> = ({ setLoading }) => {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading1] = useState(false);
  const [start_date, set_start_date] = useState("2024-06-22");
  const [end_date, set_end_date] = useState("2024-06-10");
  const [fetchDataState, setFetchDataState] = useState(false);
  const handleStartDateChange = (event: any) => {
    const date = event.target.value;
    set_start_date(date);
  };
  const handleEndDateChange = (event: any) => {
    const date = event.target.value;
    console.log(date);
    set_end_date(date);
  };

  useEffect(() => {
    setLoading(true);
    setLoading1(true);
    axios
      .get(`${backend}/api/v2/report.php?zreport=zreport`)
      .then(({ data }) => {
        setLoading(false);
        setLoading1(false);
        console.log(data.zreport[0]);
        const output1 = filterAndSortReports(
          data.zreport,
          start_date,
          end_date
        );
        // setReceipts(sortByField(data.receipts, "receipt_date").slice(0, 100));
        setReceipts(output1);
      });
  }, [fetchDataState]);

  useEffect(() => {
    console.log(receipts);
  }, [receipts]);

  return (
    <>
      <div>
        <h4 style={{ marginTop: "10px", textAlign: "center" }}>Export</h4>
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
            gap: "10px",
          }}
        >
          <a
            href={
              fileGeneratorBackend +
              "/" +
              "generate_zreport_pdf?start_date=" +
              start_date +
              "&end_date=" +
              end_date
            }
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              PDF
            </button>
          </a>
          <a
            href={
              fileGeneratorBackend +
              "/" +
              "generate_zreport_excel?start_date=" +
              start_date +
              "&end_date=" +
              end_date
            }
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              Excel
            </button>
          </a>
        </nav>

        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            gap: "20px",
          }}
        >
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              margin: "0 50px",
            }}
          >
            <span style={{ fontWeight: "bolder" }}>Select Month</span>
            <input
              type="month"
              style={{ padding: "5px" }}
              name=""
              id=""
              value={end_date}
              onChange={handleEndDateChange}
            />
          </nav>

          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
            }}
          >
            <span>j</span>
            <button
              style={{ background: "red", padding: "6px 30px" }}
              onClick={() => {
                setFetchDataState((f) => !f);
              }}
            >
              Search
            </button>
          </nav>
        </nav>
      </div>
      <div>
        <h4 style={{ marginTop: "10px", textAlign: "center" }}>Export</h4>
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
            gap: "10px",
          }}
        >
          <a
            href={fileGeneratorBackend + "/" + "generate_ZReport_pdf"}
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              PDF
            </button>
          </a>
          <a
            href={fileGeneratorBackend + "/" + "generate_ZReport_excel"}
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              Excel
            </button>
          </a>
        </nav>
      </div>
      <div
        style={{
          width: "100%",
          overflow: "auto",
        }}
      >
        <div className="App">
          <table
            style={{
              margin: "20px auto",
              borderCollapse: "collapse",
            }}
          >
            <caption
              style={{ captionSide: "top", fontSize: "1.5em", margin: "10px" }}
            >
              Z-Report sent to TRA
            </caption>
            <div style={{ height: "70vh", position: "relative" }}>
              <thead>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Z-number
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    ZREPORTDATE
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    ZREPORT_TIME
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    SubTotal
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Discount
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    VAT
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Total
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Gross
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  height: "20vh",
                  overflow: "auto",
                }}
              >
                {receipts.map((receipt: any, index) => (
                  <tr key={index}>
                    <td style={{ padding: "1px 8px", textAlign: "center" }}>
                      {receipt.report_number}
                    </td>
                    <td style={{ padding: "1px 8px", textAlign: "center" }}>
                      {receipt.report_date}
                    </td>
                    <td style={{ padding: "1px 8px", textAlign: "center" }}>
                      {receipt.report_time}
                    </td>
                    <td style={{ padding: "1px 8px", textAlign: "center" }}>
                      {receipt.subtotal}
                    </td>
                    <td style={{ padding: "1px 8px", textAlign: "center" }}>
                      {receipt.discount}
                    </td>
                    <td style={{ padding: "1px 8px", textAlign: "center" }}>
                      {(receipt.total - receipt.subtotal).toFixed(2)}
                    </td>
                    <td style={{ padding: "1px 8px", textAlign: "center" }}>
                      {receipt.total}
                    </td>
                    <td style={{ padding: "1px 8px", textAlign: "center" }}>
                      {receipt.total_gross}
                    </td>
                  </tr>
                ))}
              </tbody>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <nav>
                    <h3>Please wait </h3>
                    <h4 style={{ textAlign: "center" }}>data featching...</h4>
                  </nav>
                  <ColorRing
                    visible={true}
                    height="100"
                    width="100"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              )}
            </div>
          </table>
        </div>
      </div>
    </>
  );
};

const FMReport: React.FC<ChildProps> = ({ setLoading }) => {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading1] = useState(false);
  const [start_date, set_start_date] = useState("2024-06-22");
  const [end_date, set_end_date] = useState("2024-06-10");
  const [fetchDataState, setFetchDataState] = useState(false);
  const handleStartDateChange = (event: any) => {
    const date = event.target.value;
    set_start_date(date);
  };
  const handleEndDateChange = (event: any) => {
    const date = event.target.value;
    set_end_date(date);
  };

  useEffect(() => {
    setLoading(true);
    setLoading1(true);
    axios
      .get(`${backend}/api/v2/report.php?zreport=zreport`)
      .then(({ data }) => {
        setLoading(false);
        setLoading1(false);
        console.log(data.zreport[0]);
        const output1 = filterAndSortReports(
          data.zreport,
          start_date,
          end_date
        );
        // setReceipts(sortByField(data.receipts, "receipt_date").slice(0, 100));
        setReceipts(output1);
      });
  }, [fetchDataState]);

  useEffect(() => {
    console.log(receipts);
  }, [receipts]);

  return (
    <>
      <div>
        <h4 style={{ marginTop: "10px", textAlign: "center" }}>Export</h4>
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
            gap: "10px",
          }}
        >
          <a
            href={
              fileGeneratorBackend +
              "/" +
              "generate_zreport_pdf?start_date=" +
              start_date +
              "&end_date=" +
              end_date
            }
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              PDF
            </button>
          </a>
          <a
            href={
              fileGeneratorBackend +
              "/" +
              "generate_zreport_excel?start_date=" +
              start_date +
              "&end_date=" +
              end_date
            }
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              Excel
            </button>
          </a>
        </nav>

        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            gap: "20px",
          }}
        >
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              margin: "5px",
            }}
          >
            <span style={{ fontWeight: "bolder" }}>From</span>
            <input
              type="date"
              style={{ padding: "5px" }}
              name=""
              id=""
              value={start_date}
              onChange={handleStartDateChange}
            />
          </nav>
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              margin: "5px",
            }}
          >
            <span style={{ fontWeight: "bolder" }}>To</span>
            <input
              type="date"
              style={{ padding: "5px" }}
              name=""
              id=""
              value={end_date}
              onChange={handleEndDateChange}
            />
          </nav>

          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
            }}
          >
            <span>j</span>
            <button
              style={{ background: "red", padding: "6px 30px" }}
              onClick={() => {
                setFetchDataState((f) => !f);
              }}
            >
              Search
            </button>
          </nav>
        </nav>
      </div>
      <div
        style={{
          width: "100%",
          overflow: "auto",
        }}
      >
        <div className="App">
          <table
            style={{
              margin: "20px auto",
              borderCollapse: "collapse",
            }}
          >
            <caption
              style={{ captionSide: "top", fontSize: "1.5em", margin: "10px" }}
            >
              Z-Report sent to TRA
            </caption>
            <div style={{ height: "70vh", position: "relative" }}>
              <thead>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Z-number
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    ZREPORTDATE
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    ZREPORT_TIME
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    SubTotal
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Discount
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    VAT
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Total
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Gross
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  height: "20vh",
                  overflow: "auto",
                }}
              >
                {!loading &&
                  receipts.map((receipt: any, index) => (
                    <tr key={index}>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.report_number}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.report_date}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.report_time}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.subtotal}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.discount}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {(receipt.total - receipt.subtotal).toFixed(2)}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.total}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.total_gross}
                      </td>
                    </tr>
                  ))}
              </tbody>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <nav>
                    <h3>Please wait </h3>
                    <h4 style={{ textAlign: "center" }}>data featching...</h4>
                  </nav>
                  <ColorRing
                    visible={true}
                    height="100"
                    width="100"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              )}
            </div>
          </table>
        </div>
      </div>
    </>
  );
};

function Report() {
  const [report, setReport] = useState("receipt");
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <nav
          style={{
            color: "#fff",
            display: "flex",
            gap: 6,
            justifyContent: "center",
          }}
        >
          {report == "receipt" ? (
            <button
              style={{
                background: "#80272e",
                padding: "5px 10em",
              }}
            >
              Receipts
            </button>
          ) : (
            <button
              style={{
                background: "black",
                padding: "5px 10em",
              }}
              onClick={() => {
                setReport("receipt");
              }}
            >
              Receipts
            </button>
          )}
          {/* {report == "zreport" ? (
            <button
              style={{
                background: "#80272e",
                padding: "5px 10em",
              }}
            >
              Z-Report
            </button>
          ) : (
            <button
              style={{
                background: "black",
                padding: "5px 10em",
              }}
              onClick={() => {
                setReport("zreport");
              }}
            >
              Z-Report
            </button>
          )} */}

          {report == "fmreport" ? (
            <button
              style={{
                background: "#80272e",
                padding: "5px 10em",
              }}
            >
              ZReport
            </button>
          ) : (
            <button
              style={{
                background: "black",
                padding: "5px 10em",
              }}
              onClick={() => {
                setReport("fmreport");
              }}
            >
              ZReport
            </button>
          )}
        </nav>
      </div>

      <div>
        {report == "zreport" ? (
          <ZReport setLoading={setLoading} />
        ) : report == "receipt" ? (
          <Receipt setLoading={setLoading} />
        ) : (
          <FMReport setLoading={setLoading} />
        )}
      </div>
    </>
  );
}

// function Sales() {
//   return (
//     <>
//       <div></div>
//     </>
//   );
// }

const Sales: React.FC<ChildProps> = ({ setLoading }) => {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading1] = useState(false);
  const [fetchDataState, setFetchDataState] = useState(false);
  const [start_date, set_start_date] = useState("2024-06-22");
  const [end_date, set_end_date] = useState("2024-06-10");
  const handleStartDateChange = (event: any) => {
    const date = event.target.value;
    set_start_date(date);
  };
  const handleEndDateChange = (event: any) => {
    const date = event.target.value;
    set_end_date(date);
  };
  useEffect(() => {
    setLoading(true);
    setLoading1(true);
    axios.get(`${backend}/api/v2/report.php`).then(({ data }) => {
      setLoading(false);
      setLoading1(false);
      console.log(data.receipts);

      const output1 = filterAndSortReceipts(
        data.receipts,
        start_date,
        end_date
      );
      console.log(output1);

      setReceipts(output1);
    });
  }, [fetchDataState]);

  useEffect(() => {
    console.log(receipts);
  }, [receipts]);

  return (
    <>
      <div>
        <h4 style={{ marginTop: "10px", textAlign: "center" }}>Export</h4>
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
            gap: "10px",
          }}
        >
          <a
            href={
              fileGeneratorBackend +
              "/" +
              "generate_receipt_pdf?start_date=" +
              start_date +
              "&end_date=" +
              end_date
            }
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              PDF
            </button>
          </a>
          <a
            href={
              fileGeneratorBackend +
              "/" +
              "generate_receipt_excel?start_date=" +
              start_date +
              "&end_date=" +
              end_date
            }
            target="_blank"
          >
            <button
              style={{
                padding: "2px 5em",
                background: "gray",
                color: "white",
              }}
            >
              Excel
            </button>
          </a>
        </nav>

        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            gap: "20px",
          }}
        >
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              margin: "5px",
            }}
          >
            <span style={{ fontWeight: "bolder" }}>From</span>
            <input
              type="date"
              style={{ padding: "5px" }}
              name=""
              id=""
              value={start_date}
              onChange={handleStartDateChange}
            />
          </nav>
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              margin: "5px",
            }}
          >
            <span style={{ fontWeight: "bolder" }}>To</span>
            <input
              type="date"
              style={{ padding: "5px" }}
              name=""
              id=""
              value={end_date}
              onChange={handleEndDateChange}
            />
          </nav>
          <nav
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
            }}
          >
            <span>j</span>
            <button
              style={{ background: "red", padding: "6px 30px" }}
              onClick={() => {
                setFetchDataState((f) => !f);
              }}
            >
              Search
            </button>
          </nav>
        </nav>
      </div>
      <div
        style={{
          width: "100%",
          overflow: "auto",
        }}
      >
        <div className="App">
          <table
            style={{
              margin: "20px auto",
              borderCollapse: "collapse",
            }}
          >
            <caption
              style={{ captionSide: "top", fontSize: "1.5em", margin: "10px" }}
            >
              Receipts Information
            </caption>
            <div style={{ height: "70vh", position: "relative" }}>
              <thead>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    RC
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    RCT Date
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Receipt Time
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Subtotal
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Discount
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    VAT
                  </th>
                  <th style={{ padding: "1px 8px", textAlign: "center" }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <div
                  style={{
                    height: "10px",
                  }}
                ></div>
                {receipts.map((receipt: any, index) =>
                  receipt.receipt_date == "0000-00-00" ? (
                    ""
                  ) : (
                    <tr key={index}>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.R_num}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.receipt_date}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.receipt_time}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.subtotal}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.discount}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {(receipt.total - receipt.subtotal).toFixed(2)}
                      </td>
                      <td style={{ padding: "1px 8px", textAlign: "center" }}>
                        {receipt.total}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <nav>
                    <h3>Please wait </h3>
                    <h4 style={{ textAlign: "center" }}>data featching...</h4>
                  </nav>
                  <ColorRing
                    visible={true}
                    height="100"
                    width="100"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              )}
            </div>
          </table>
        </div>
      </div>
    </>
  );
};
export default function Accounting() {
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(true);
  return (
    <>
      {state == "report" ? (
        <Report />
      ) : state == "sales" ? (
        <Sales setLoading={setLoading} />
      ) : (
        <div>
          <nav className="">
            <button
              style={{
                background: "#80272e",
                padding: "5px 10em",
                margin: "5px",
              }}
              onClick={() => setState("report")}
            >
              Report
            </button>
            <button
              style={{
                background: "#80272e",
                padding: "5px 10em",
                margin: "5px",
              }}
              onClick={() => setState("sales")}
            >
              Sales
            </button>
          </nav>
        </div>
      )}
    </>
  );
}
