import "../activities/index.scss";
import React from "react";
import { ActivityChartOps, ActivityPieChartOps } from "../activities";
import {
  BrandPercentage,
  ChartSeries,
  Device,
  ListObject,
  Order,
  UserData,
} from "../../../../../models";
import { FaListUl, FaTimes } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import {
  RefObject,
  SyntheticEvent,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { TableVirtuoso, Virtuoso } from "react-virtuoso";

import { AiOutlineBarChart } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import DatabaseManager from "../../../../../../service";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { DateRangePicker } from "rsuite";
import ExcelJS from "exceljs";
import FormatterUtil from "../../../../../../utils/formatter-util";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Period from "../../../../../../utils/period";
import Routes from "../../../../../../utils/routes";
import addDays from "date-fns/addDays";
import moment from "moment";
import subDays from "date-fns/subDays";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

const { combine, before, afterToday } = DateRangePicker;

type OrdersProps = {
  scroller: HTMLElement;
  device: Device;
  user_data: UserData;
  refreshId: number;
  show: boolean;
  onListChanged: (list_size: number) => void;
  onQuantityChanged: (quantity: number) => void;
  onPriceChanged: (price: number) => void;
  export_id: number;
};

const OrdersTab = (props: OrdersProps) => {
  const history = useHistory();
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const databaseManager: DatabaseManager = new DatabaseManager();
  const root: RefObject<HTMLDivElement> = useRef(null);
  const modal_ref: RefObject<HTMLDivElement> = useRef(null);

  const wasViewInitialised = useRef(false);
  const [showInputModal, setShowInputModal] = useState(false);
  const [selected_date, setSelectedDate] = useState<DateRange>([
    Period.getTodayStartTime(),
    Period.getTodayEndTime(),
  ]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(true);
  const lastItemDateCreated = useRef(0);
  // const lastSelectedDate = useRef<DateRange>([Period.getTodayStartTime(), Period.getTodayEndTime()]);
  const [error_loading, setErrorLoading] = useState<string | null>(null);
  const [display_start_time, setDisplayStartTime] = useState<string>("");
  const [display_end_time, setDisplayEndTime] = useState<string>("");

  const [viewBy, setViewBy] = useState<"date" | "default">("default");
  const viewByRef = useRef<"date" | "default">("default");
  const [tabType, setTabType] = useState<"chart" | "list">("chart");

  const [list, setList] = useState<ListObject<Order>[]>([]);
  const [brandPercentages, setBrandpercentages] = useState<
    ListObject<BrandPercentage>[]
  >([]);
  const [brandPercentageTitle, setBrandPercentageTitle] = useState<string>("");

  const [total_quantities, setTotalQuantities] = useState<number>(0);
  const [total_prices, setTotalPrices] = useState<number>(0);

  const [selected_order, setSelectedOrder] = useState<Order | null>(null);

  const chart_options_1: Highcharts.Options = new ActivityChartOps().getOptions;
  const chart_options_2: Highcharts.Options = new ActivityChartOps().getOptions;
  const chart_options_3: Highcharts.Options = new ActivityPieChartOps()
    .getOptions;

  const _chart_1_options = chart_options_1;
  (_chart_1_options.chart as any).type = "line";
  if (_chart_1_options.title) _chart_1_options.title.text = "Orders Per Time";
  (_chart_1_options.xAxis as any).type = "datetime";
  // (_chart_1_options.yAxis as any).title.text = 'Quantities & Prices';
  (_chart_1_options.yAxis as any).title.text = "Quantities";
  _chart_1_options.colors = ["#e900c2", "#3f3e82", "#1bd111", "#ff0000"];
  if (_chart_1_options.chart && _chart_1_options.chart.events) {
    _chart_1_options.chart.events.load = () => onChartLoad();
    _chart_1_options.chart.events.render = () => onChartRendered();
    _chart_1_options.chart.events.redraw = () => onChartRedraw();
  }
  _chart_1_options.series = [
    {
      name: "Quantities",
      type: "area",
      turboThreshold: 10000,
      data: [],
    },
    // {
    //   name: "Prices",
    //   type: "area",
    //   turboThreshold: 10000,
    //   data: []
    // }
  ];
  const [chart_1_options, setChart1Options] =
    useState<Highcharts.Options>(_chart_1_options);

  const _chart_2_options = chart_options_2;
  (_chart_2_options.chart as any).type = "line";
  if (_chart_2_options.title) _chart_2_options.title.text = "Orders Per Time";
  (_chart_2_options.xAxis as any).type = "datetime";
  // (_chart_2_options.yAxis as any).title.text = 'Quantities & Prices';
  (_chart_2_options.yAxis as any).title.text = "Prices";
  _chart_2_options.colors = ["#1bd111", "#3f3e82", "#ff0000"];
  if (_chart_2_options.chart && _chart_2_options.chart.events) {
    _chart_2_options.chart.events.load = () => onChartLoad();
    _chart_2_options.chart.events.render = () => onChartRendered();
    _chart_2_options.chart.events.redraw = () => onChartRedraw();
  }
  _chart_2_options.series = [
    {
      name: "Prices",
      type: "area",
      turboThreshold: 10000,
      data: [],
    },
    // {
    //   name: "Quantities",
    //   type: "line",
    //   turboThreshold: 10000,
    //   data: []
    // }
  ];
  const [chart_2_options, setChart2Options] =
    useState<Highcharts.Options>(_chart_2_options);

  const _chart_3_options = chart_options_3;
  (_chart_3_options.chart as any).type = "pie";
  if (_chart_3_options.title)
    _chart_3_options.title.text = "Brands Sold Per Time";
  // (_chart_3_options.yAxis as any).title.text = 'Total Orders Per Machine';
  // _chart_3_options.colors = ['#3f3e82', '#1bd111', '#ff0000'];
  if (_chart_3_options.chart && _chart_3_options.chart.events) {
    _chart_3_options.chart.events.load = () => onChartLoad();
    _chart_3_options.chart.events.render = () => onChartRendered();
    _chart_3_options.chart.events.redraw = () => onChartRedraw();
  }
  const [pie_chart_options, setPieChartOptions] =
    useState<Highcharts.Options>(_chart_3_options);

  useEffect(() => {
    didMount.current = true;
    if (!wasViewInitialised.current) {
      if (props.show) {
        init();
      }
    }
    return () => {
      didMount.current = false;
    };
  }, []);

  useEffect(() => {
    if (!didMount.current) return;
    if (props.show) {
      if (!wasViewInitialised.current) {
        init();
      } else {
        if (props.refreshId > 0) {
          // console.log('refreshing orders');
          onRefreshClick();
        }
      }
    }
  }, [props.refreshId]);

  useEffect(() => {
    if (!didMount.current) return;
    if (props.show) {
      if (!wasViewInitialised.current) {
        init();
      }
    }
  }, [props.show]);

  const init = () => {
    wasViewInitialised.current = true;
    listenForInputFocus();
    if (viewByRef.current === "default") {
      // console.log('initial-calling: getDataByDefault');
      getDataByDefault(true);
    } else {
      // console.log('initial-calling: getDataByDate');
      getDataByDate(selected_date, true);
    }
  };

  const listenForInputFocus = () => {
    setTimeout(() => {
      if (!didMount.current) {
        return;
      }
      if (root.current) {
        // find first input element
        const input_element = root.current.querySelector(
          "input"
        ) as HTMLInputElement;
        if (input_element) {
          // detect on focus
          input_element.addEventListener("focus", () => {
            if (!didMount.current) {
              return;
            }
            // console.log('input_element.focus()');
            setShowInputModal(true);
            forceUpdate();

            setTimeout(() => {
              if (!didMount.current) {
                return;
              }
              // find element by class name
              const buttons_container = document.querySelector(
                ".rs-picker-toolbar"
              ) as HTMLDivElement;
              if (buttons_container) {
                // get all buttons and add event listener for click
                const buttons = buttons_container.querySelectorAll("button");
                if (buttons && buttons.length > 0) {
                  for (let i = 0; i < buttons.length; i++) {
                    if (!didMount.current) {
                      return;
                    }
                    const button = buttons[i] as HTMLButtonElement;
                    if (button) {
                      button.addEventListener("click", () => {
                        if (!didMount.current) {
                          return;
                        }
                        // console.log('button.click()');
                        setShowInputModal(false);
                        forceUpdate();
                      });
                    }
                  }
                }
              }
            }, 1000);
          });
        }

        // detect ecscape key for this div
        document.addEventListener("keydown", (e) => {
          if (!didMount.current) {
            return;
          }
          if (e.keyCode === 27) {
            // console.log('dashboard_ref.current.keydown()');
            if (root.current) {
              if (!didMount.current) {
                return;
              }
              if (modal_ref.current) {
                // perform click
                modal_ref.current.click();
              } else {
                // console.log('modal not found');
                setShowInputModal(false);
                forceUpdate();
              }
            }
          }
        });
      }
    }, 100);
  };

  const onRefreshClick = () => {
    if (!didMount.current || isLoading) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/orders`
      )
    )
      return;
    lastItemDateCreated.current = 0;
    forceUpdate();
    if (viewByRef.current === "default") {
      getDataByDefault(true);
    } else {
      getDataByDate(selected_date, true);
    }
  };

  const loadMore = () => {
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/orders`
      )
    )
      return;
    if (!didMount.current) return;
    if (!isMoreDataAvailable) return;
    if (isLoading) return;
    if (viewByRef.current !== "default") return;
    // if (lastItemDateCreated.current <= 0) return;
    // getData(lastItemDateCreated.current - 1, 0, false);
    getDataByDefault(false);
  };

  const resetData = (dateRange: DateRange, fromDatePicker: boolean) => {
    if (!didMount.current) return;
    // const weeklyDateRange: DateRange = [Period.getPreviousSevenDaysItsFirstDay(), Period.getTodayEndTime()];
    const todayDateRange: DateRange = [
      Period.getDayStartTime(),
      Period.getTodayEndTime(),
    ];

    setIsLoading(false);
    const _selected_date = fromDatePicker ? dateRange : todayDateRange;
    setSelectedDate(_selected_date);
    forceUpdate();

    setTimeout(() => {
      if (!didMount.current) return;
      if (
        !history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/orders`
        )
      )
        return;
      getDataByDate(_selected_date, true);
    }, 500);
  };

  const getDataByDate = (dateRange: DateRange, is_refresh: boolean) => {
    if (!didMount.current) return;
    if (isLoading) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/orders`
      )
    )
      return;
    const _selected_date: DateRange = dateRange;
    const start_date: Date = _selected_date[0]
      ? _selected_date[0]
      : Period.getTodayStartTime();
    const end_date: Date = _selected_date[1]
      ? _selected_date[1]
      : Period.getTodayEndTime();

    const the_start_date: number = start_date.valueOf();
    const the_end_date: number = end_date.valueOf();
    getData(the_start_date, the_end_date, is_refresh);
  };

  const getDataByDefault = (is_refresh: boolean) => {
    if (!didMount.current) return;
    if (isLoading) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/orders`
      )
    )
      return;

    const end_of_today: Date = Period.getTodayEndTime();
    const the_end_of_today_date: number = end_of_today.valueOf() + 1;

    if (is_refresh) {
      getData(the_end_of_today_date, 0, true);
    } else {
      const next_date =
        lastItemDateCreated.current - 1 > 0
          ? lastItemDateCreated.current - 1
          : the_end_of_today_date;
      getData(next_date, 0, false);
    }
  };

  const getData = (
    start_time: number,
    end_time: number,
    is_refresh: boolean
  ) => {
    if (!didMount.current) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/orders`
      )
    )
      return;
    if (start_time > 0) {
      // console.log('start_time: ' + start_time + ', end_time: ' + end_time);
      if (is_refresh) {
        setList([]);
        setTotalQuantities(0);
        setTotalPrices(0);
        // props.onListSizeChange(0);
        setChart1Options(_chart_1_options); // reset chart
        setChart2Options(_chart_2_options); // reset chart
        setIsInitialLoading(true);
      }
      setIsLoading(true);
      setErrorLoading(null);
      forceUpdate();
      // databaseManager.cancelRequests();
      databaseManager
        .getDeviceOrdersData(
          props.user_data.id,
          props.device.id,
          start_time,
          end_time
        )
        .then((result: any) => {
          if (!didMount.current) return;
          // console.log("getDeviceOrdersData: result: ", result);
          // setIsLoading(false);
          // setIsInitialLoading(false);
          if (result.success && result.success.data) {
            setErrorLoading(null);
            const _list: ListObject<Order>[] = is_refresh ? [] : list;
            const orders: Order[] = result.success.data;
            if (orders && orders.length > 0) {
              setIsMoreDataAvailable(true);
              setChart1Options(_chart_1_options); // reset chart
              setChart2Options(_chart_2_options); // reset chart
              for (let i = 0; i < orders.length; i++) {
                const order: Order = orders[i];
                const obj: ListObject<Order> = {
                  id: order.id,
                  type: "item",
                  data: order,
                };
                const existing_pos = isItemExist(order.id, _list);
                if (existing_pos != -1) {
                  _list[existing_pos] = obj;
                } else {
                  // const tmp_list = list;
                  // if (tmp_list.length > 0 && obj) {
                  //   // check whether to add object at beginning or at end of list
                  //   const d = order.date_created;
                  //   if ((tmp_list[0].data as Order).date_created > d) { // add at beginning of list
                  //     _list.unshift(obj);
                  //   } else if ((tmp_list[tmp_list.length - 1].data as Order).date_created < d) { // add at end of list
                  //     _list.push(obj);
                  //   }
                  // } else {
                  //   _list.push(obj);
                  // }
                  _list.push(obj);
                }
              }
              // sort list by date_created in descending order
              _list.sort((a, b) => {
                const a_date = (a.data as Order).date_created;
                const b_date = (b.data as Order).date_created;
                return b_date - a_date;
              });
              setTimeout(() => {
                const _first_item_date_created =
                  _list.length > 0 ? (_list[0].data as Order).date_created : 0;
                const _last_item_date_created =
                  _list.length > 0
                    ? (_list[_list.length - 1].data as Order).date_created
                    : 0;
                if (_list.length > 0) {
                  // console.log('first-item-date-created: ', _first_item_date_created);
                  // console.log('last-item-date-created: ', _last_item_date_created);
                  lastItemDateCreated.current = _last_item_date_created;
                }
                let _title = "";
                if (
                  _first_item_date_created > 0 &&
                  _last_item_date_created > 0
                ) {
                  // get first date using moment in format of "DD-MM-YYYY at HH:mm"
                  const _first_date = moment(_first_item_date_created).format(
                    "DD-MM-YYYY HH:mm"
                  );
                  // get last date using moment in format of "DD-MM-YYYY at HH:mm"
                  const _last_date = moment(_last_item_date_created).format(
                    "DD-MM-YYYY HH:mm"
                  );
                  _title = ` Between ${_last_date} to ${_first_date}`;
                  setDisplayStartTime(_first_date);
                  setDisplayEndTime(_last_date);
                }
                const _brand_precentages = calculateBrandsPercentages(_list);
                const total = calculateTotalPriceAndQunatity(_list);

                const series_1: ChartSeries[] = [
                  {
                    name: "Quantities",
                    type: "area",
                    turboThreshold: 10000,
                    data: [],
                  },
                  // {
                  //   name: "Prices",
                  //   type: "area",
                  //   turboThreshold: 10000,
                  //   data: []
                  // }
                ];
                const series_2: ChartSeries[] = [
                  // {
                  //   name: "Quantities",
                  //   type: "line",
                  //   turboThreshold: 10000,
                  //   data: []
                  // },
                  {
                    name: "Prices",
                    type: "area",
                    turboThreshold: 10000,
                    data: [],
                  },
                ];
                for (let i = 0; i < _list.length; i++) {
                  const order: Order = _list[i].data as Order;
                  series_1[0].data.push({
                    x: order.date_created,
                    y: order.total_quantity,
                  });
                  // series_1[1].data.push({
                  //   x: order.date_created,
                  //   y: order.total_price
                  // });
                  series_2[0].data.push({
                    x: order.date_created,
                    y: order.total_price,
                  });
                }
                const new__chart_1_options = _chart_1_options;
                if (new__chart_1_options.title)
                  new__chart_1_options.title.text = "Orders Per Time" + _title;
                (new__chart_1_options as any).series = series_1;
                // console.log('new__chart_1_options: ', new__chart_1_options);
                const new__chart_2_options = _chart_2_options;
                if (new__chart_2_options.title)
                  new__chart_2_options.title.text = "Orders Per Time" + _title;
                (new__chart_2_options as any).series = series_2;
                // console.log('new__chart_2_options: ', new__chart_2_options);

                const pie_series: {
                  name: string;
                  colorByPoint: boolean;
                  data: { name: string; y: number }[];
                }[] = []; // y-axis series

                pie_series.push({
                  name: "Brands",
                  colorByPoint: true,
                  data: [],
                });
                const pie_series_data: { name: string; y: number }[] = [];
                for (let i = 0; i < _brand_precentages.length; i++) {
                  const brand_percentage = _brand_precentages[i]
                    .data as BrandPercentage;
                  pie_series_data.push({
                    name: brand_percentage.brand_name,
                    y: brand_percentage.percentage,
                  });
                }
                pie_series[0].data = pie_series_data;

                const new__chart_3_options = _chart_3_options;
                if (new__chart_3_options.title)
                  new__chart_3_options.title.text = "Most Brands Sold" + _title;
                (new__chart_3_options as any).series = pie_series;

                setChart1Options(new__chart_1_options);
                setChart2Options(new__chart_2_options);
                setPieChartOptions(new__chart_3_options);
                // console.log('orders: new__chart_1_options: ', new__chart_1_options);
                // console.log('brands: ', _brand_precentages);
                setBrandPercentageTitle(`Most Brands Sold${_title}`);
                setBrandpercentages(_brand_precentages);
                setTotalQuantities(total[0]);
                setTotalPrices(total[1]);
                setList(_list);
                setIsLoading(false);
                setIsInitialLoading(false);
                forceUpdate();
              }, 100);
            } else {
              setIsLoading(false);
              setIsInitialLoading(false);
              setIsMoreDataAvailable(false);
            }
          } else {
            setIsLoading(false);
            setIsInitialLoading(false);
            setErrorLoading("Couldn't load data, please try again");
          }
          forceUpdate();
        });
    }
  };

  const isItemExist = (id: string, list: ListObject<Order>[]) => {
    // if (list && list.length > 0) {
    //   for (let i = 0; i < list.length; i++) {
    //     const obj: ListObject = list[i];
    //     if (obj.id === id) {
    //       return i;
    //     }
    //   }
    // }
    // return -1;
    // use filter instead of for loop
    const filtered_list = list.filter(
      (obj: ListObject<Order>) => obj.id === id
    );
    if (filtered_list && filtered_list.length > 0) {
      return list.indexOf(filtered_list[0]);
    }
    return -1;
  };

  const calculateTotalPriceAndQunatity = (list: ListObject<Order>[]) => {
    let total = [0, 0];
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        const obj: ListObject<Order> = list[i];
        if (obj.data) {
          total[0] = total[0] + obj.data.total_quantity;
          total[1] = total[1] + obj.data.total_price;
        }
      }
    }
    return total;
  };

  const calculateBrandsPercentages = (list: ListObject<Order>[]) => {
    const brands: ListObject<BrandPercentage>[] = [];

    for (let i = 0; i < list.length; i++) {
      const order: Order = list[i].data as Order;
      // check if slot_numbers, brand_names, quantities, prices lists are of same length
      if (
        order.slot_numbers.length === order.brand_names.length &&
        order.brand_names.length === order.quantities.length &&
        order.quantities.length === order.prices.length
      ) {
        // loop through order slot numbers
        for (let s = 0; s < order.slot_numbers.length; s++) {
          const brand_name = order.brand_names[s];
          const quantity = order.quantities[s];
          const price = order.prices[s];
          if (brand_name.length > 0) {
            const _brand_name_unique = brand_name.trim().toLowerCase();
            // check if brand already exist in brands list, if not add it
            const index = isBrandExist(_brand_name_unique, brands);
            if (index === -1) {
              const brand: ListObject<BrandPercentage> = {
                id: _brand_name_unique,
                type: "item",
                data: {
                  brand_name: brand_name,
                  percentage: 0,
                  total_quantity: quantity,
                  total_price: price,
                  total_orders: 1,
                },
              };
              brands.push(brand);
            } else {
              // if brand exist in brands list, update its percentage, total_quantity, total_price, total_orders
              const brand: ListObject<BrandPercentage> = brands[index];
              (brand.data as BrandPercentage).total_quantity =
                (brand.data as BrandPercentage).total_quantity + quantity;
              (brand.data as BrandPercentage).total_price =
                (brand.data as BrandPercentage).total_price + price;
              (brand.data as BrandPercentage).total_orders =
                (brand.data as BrandPercentage).total_orders + 1;
              brands[index] = brand;
            }
          }
        }
      }
    }

    const brands_output: ListObject<BrandPercentage>[] = [];

    if (brands && brands.length > 0) {
      const order_size = list.length;
      // calculate percentage of each brand with respect to total orders
      for (let i = 0; i < brands.length; i++) {
        const brand: ListObject<BrandPercentage> = brands[i];
        const total_orders_per_brand = (brand.data as BrandPercentage)
          .total_orders;
        const percentage = (total_orders_per_brand / order_size) * 100;
        (brand.data as BrandPercentage).percentage = percentage;
        brands_output.push(brand);
      }
    }

    // sort brands_output by percentage in descending order
    brands_output.sort(
      (a: ListObject<BrandPercentage>, b: ListObject<BrandPercentage>) => {
        const a_percentage = (a.data as BrandPercentage).percentage;
        const b_percentage = (b.data as BrandPercentage).percentage;
        return b_percentage - a_percentage;
      }
    );

    return brands_output;
  };

  const isBrandExist = (
    brand_name: string,
    list: ListObject<BrandPercentage>[]
  ) => {
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        const obj: ListObject<BrandPercentage> = list[i];
        if (obj.id === brand_name) {
          return i;
        }
      }
    }
    return -1;
  };

  const datePicker = () => {
    const lower_date = new Date();
    lower_date.setFullYear(2022, 5, 1);
    lower_date.setHours(0, 0, 0, 0);

    return (
      <DateRangePicker
        format={"dd-MM-yyyy HH:mm:ss"}
        appearance="subtle"
        placeholder="Select date range"
        character="  to  "
        // size="sm"
        defaultValue={selected_date}
        cleanable={false}
        oneTap={false}
        open={showInputModal}
        disabledDate={
          combine &&
          combine(before && before(lower_date), afterToday && afterToday())
        }
        style={{
          backgroundColor: "var(--color-secondary)",
        }}
        ranges={[
          {
            label: "Yesterday",
            value: [
              addDays(Period.getDayStartTime(), -1),
              addDays(Period.getDayEndTime(), -1),
            ],
          },
          {
            label: "Today",
            value: [Period.getTodayStartTime(), Period.getTodayEndTime()],
          },
          // {
          //   label: 'Tomorrow',
          //   value: [dateFns.addDays(new Date(), 1), dateFns.addDays(new Date(), 1)]
          // },
          {
            label: "Last 7 days",
            value: [
              subDays(Period.getDayStartTime(), 6),
              Period.getDayEndTime(),
            ],
          },
          {
            label: "Last 30 days",
            value: [
              subDays(Period.getDayStartTime(), 29),
              Period.getDayEndTime(),
            ],
          },
        ]}
        onOpen={() => {
          // console.log('date-range-picker: opened');
          setShowInputModal(true);
        }}
        onChange={(date: DateRange | null) => {
          if (date === null) {
            return;
          }
          // console.log('date-range-picker: changed: ', date);
          resetData(date, true);
        }}
        onOk={(date: DateRange, event: SyntheticEvent<Element, Event>) => {
          if (date === null) {
            return;
          }
          event.preventDefault();
          // console.log('date-range-picker: ok: ', date);
          resetData(date, true);
        }}
        onClose={() => {
          setShowInputModal(false);
        }}
      />
    );
  };

  const getDateView = (time: number) => {
    // get current time using moment
    // const current_time = moment();
    // get date of today using moment
    const today = moment().startOf("day").format("DD-MM-YYYY");
    // get date of yesterday using moment
    const yesterday = moment()
      .subtract(1, "days")
      .startOf("day")
      .format("DD-MM-YYYY");
    // get input date using moment
    const input_date = moment(time).format("DD-MM-YYYY");
    // get input time using moment
    const input_time = moment(time).format("HH:mm:ss");
    // check if input date is today
    if (input_date === today) {
      return ["Today", input_time];
    }
    // check if input date is yesterday
    if (input_date === yesterday) {
      return ["Yesterday", input_time];
    }
    return [input_date, input_time];
  };

  const onChartRendered = () => {
    // console.log('onChartRendered: called:');
  };

  const onChartLoad = () => {
    // console.log('onChartLoad:     called:');
  };

  const onChartRedraw = () => {
    // console.log('onChartRedraw:   called:');
  };

  const onExportClick = () => {
    if (list.length === 0) return;
    // console.log('exporting orders');
    // example of orders data
    // [
    //   {
    //     "id": "VMO16644657797Y4XG",
    //     "slot_numbers": [
    //         21,
    //         26,
    //         27
    //     ],
    //     "brand_names": [
    //         "KILIMANJARO WATER 50",
    //         "PEPSI MAX 400ML",
    //         "MIRINDA 400ML"
    //     ],
    //     "quantities": [
    //         1,
    //         1,
    //         1
    //     ],
    //     "prices": [
    //         1000,
    //         1000,
    //         1000
    //     ],
    //     "total_quantity": 3,
    //     "total_price": 3000,
    //     "currency": "TZS",
    //     "purchase_type": "mobile",
    //     "date_created": 1664465779000,
    //     "completed": false,
    //     "date_updated": 0,
    //     "transaction_id": "VMT1664465624DZGUS"
    // },
    // ]
    // create a new workbook from orders data
    const workbook = new ExcelJS.Workbook();
    // get current date
    const current_date = new Date();

    workbook.creator = "Moran Vending Machine - Dashboard";
    workbook.created = current_date;
    workbook.modified = current_date;
    workbook.lastModifiedBy = "Moran Vending Machine - Dashboard";
    workbook.lastPrinted = current_date;

    // get current date in format of dd-mm-yyyy-hh-mm-ss
    const _date = moment(current_date).format("DD-MM-YYYY-HH-mm-ss");
    const file_name = `${props.device.id}-ORDERS-${_date}.xlsx`;
    // create a new worksheet
    let _title = props.device.id + " Orders";
    if (display_start_time.length > 0 && display_end_time.length > 0) {
      _title += ` Between ${display_end_time} to ${display_start_time}`;
    }
    const worksheet = workbook.addWorksheet(props.device.id + " Orders");
    // add headers
    worksheet.columns = [
      // { header: 'SN', key: 'sn', width: 10 },
      { header: "ID".toUpperCase(), key: "id", width: 28 },
      { header: "Slot Numbers".toUpperCase(), key: "slot_numbers", width: 20 },
      { header: "Brand Names".toUpperCase(), key: "brand_names", width: 30 },
      { header: "Quantities".toUpperCase(), key: "quantities", width: 20 },
      { header: "Prices".toUpperCase(), key: "prices", width: 30 },
      {
        header: "Total Quantity".toUpperCase(),
        key: "total_quantity",
        width: 20,
      },
      { header: "Total Price".toUpperCase(), key: "total_price", width: 20 },
      { header: "Currency".toUpperCase(), key: "currency", width: 15 },
      { header: "Payment Type".toUpperCase(), key: "purchase_type", width: 20 },
      { header: "Date Created".toUpperCase(), key: "date_created", width: 25 },
      { header: "Completed".toUpperCase(), key: "completed", width: 15 },
      { header: "Date Updated".toUpperCase(), key: "date_updated", width: 25 },
      {
        header: "Transaction ID".toUpperCase(),
        key: "transaction_id",
        width: 28,
      },
      { header: "Payment Date".toUpperCase(), key: "payment_date", width: 25 },
      {
        header: "Payment Status".toUpperCase(),
        key: "payment_status",
        width: 25,
      },
      {
        header: "Customer Mobile".toUpperCase(),
        key: "customer_mobile",
        width: 28,
      },
      {
        header: "Reference Number".toUpperCase(),
        key: "reference_number",
        width: 30,
      },
    ];
    // set header alignment
    worksheet.getRow(1).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    // set header font
    worksheet.getRow(1).font = { name: "Arial", size: 10, bold: true };
    // set header border
    worksheet.getRow(1).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    // set header fill
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "FFD3D3D3",
      },
      bgColor: {
        argb: "FFD3D3D3",
      },
    };
    // set header height
    worksheet.getRow(1).height = 25;
    // set header wrap text
    worksheet.getRow(1).alignment = { wrapText: true };
    // add data
    // worksheet.addRows(list);
    // ireate over orders data instead of using addRows
    list.forEach((obj: ListObject<Order>, index: number) => {
      if (obj.data) {
        const order: Order = obj.data as Order;
        // get date created in format: DD-MM-YYYY HH:mm:ss using moment
        const date_created = moment(order.date_created).format(
          "DD-MM-YYYY HH:mm:ss"
        );
        // get date updated in format: DD-MM-YYYY HH:mm:ss using moment if date_updated is not 0
        const date_updated =
          order.date_updated === 0
            ? ""
            : moment(order.date_updated).format("DD-MM-YYYY HH:mm:ss");
        // add row
        worksheet.addRow({
          // sn: index + 1,
          id: order.id,
          slot_numbers: order.slot_numbers.join(", "),
          brand_names: order.brand_names.join(", "),
          quantities:
            order.quantities.length === 1
              ? order.quantities.join(", ").toString()
              : order.quantities.join(", "),
          prices:
            order.prices.length === 1
              ? order.prices.join(", ").toString()
              : order.prices.join(", "),
          total_quantity: order.total_quantity,
          total_price: order.total_price,
          currency: order.currency,
          purchase_type: order.purchase_type,
          date_created: date_created,
          completed: order.completed ? "Yes" : "No",
          date_updated: date_updated,
          transaction_id: order.transaction_id,
          payment_date:
            order.payment && order.payment.date_created
              ? moment(order.payment.date_created).format("DD-MM-YYYY HH:mm:ss")
              : "",
          payment_status:
            order.payment && order.payment.status ? order.payment.status : "",
          customer_mobile:
            order.payment && order.payment.mobile ? order.payment.mobile : "",
          reference_number:
            order.payment && order.payment.reference_number
              ? order.payment.reference_number
              : "",
        });
        // set row height
        worksheet.getRow(index + 2).height = 50;
        // set row alignment also wrap text
        worksheet.getRow(index + 2).alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        // set row content wrap text
        // worksheet.getRow(index + 2).alignment = { wrapText: true };
        // set row border
        worksheet.getRow(index + 2).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        // set row font
        worksheet.getRow(index + 2).font = { name: "Arial", size: 10 };
        // if order is not completed set row fill to red
        if (!order.completed) {
          worksheet.getRow(index + 2).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "ffff369b",
            },
            bgColor: {
              argb: "ffff369b",
            },
          };
          // also change text color to white
          worksheet.getRow(index + 2).font = {
            name: "Arial",
            size: 10,
            color: {
              argb: "FFFFFFFF",
            },
          };
        }
        // else alternate row color
        else {
          if (index % 2 === 0) {
            worksheet.getRow(index + 2).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: "FFF5F5F5",
              },
              bgColor: {
                argb: "FFF5F5F5",
              },
            };
          }
        }
      }
    });

    // save workbook
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // FileSaver.saveAs(blob, 'orders.xlsx');
      // create a new link to download the file and click it
      const link = document.createElement("a");
      // hide the link
      link.style.display = "none";
      link.href = window.URL.createObjectURL(blob);
      link.download = file_name;
      document.body.appendChild(link);
      // click the link
      link.click();
      setTimeout(() => {
        // remove the link
        document.body.removeChild(link);
      }, 1000);
    });
  };

  const onExportBrandsClick = () => {
    // get brands list if it is not empty
    if (brandPercentages.length === 0) return;
    // get brands list
    const list = brandPercentages;

    const workbook = new ExcelJS.Workbook();
    // get current date
    const current_date = new Date();

    workbook.creator = "Moran Vending Machine - Dashboard";
    workbook.created = current_date;
    workbook.modified = current_date;
    workbook.lastModifiedBy = "Moran Vending Machine - Dashboard";
    workbook.lastPrinted = current_date;

    // get current date in format of dd-mm-yyyy-hh-mm-ss
    const _date = moment(current_date).format("DD-MM-YYYY-HH-mm-ss");
    const file_name = `${props.device.id}-BRANDS-${_date}.xlsx`;
    // create a new worksheet
    let _title = props.device.id + " Most Brands Sold";
    if (display_start_time.length > 0 && display_end_time.length > 0) {
      _title += ` Between ${display_end_time} to ${display_start_time}`;
    }
    const worksheet = workbook.addWorksheet(props.device.id + " Brands");
    // add headers
    worksheet.columns = [
      { header: "Brand Names".toUpperCase(), key: "brand_names", width: 30 },
      { header: "Orders".toUpperCase(), key: "orders", width: 15 },
      { header: "Quantities".toUpperCase(), key: "quantities", width: 20 },
      { header: "Total Price".toUpperCase(), key: "total_price", width: 20 },
      { header: "Percentage Sold".toUpperCase(), key: "percentage", width: 28 },
    ];
    // set header alignment
    worksheet.getRow(1).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    // set header font
    worksheet.getRow(1).font = { name: "Arial", size: 10, bold: true };
    // set header border
    worksheet.getRow(1).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    // set header fill
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "FFD3D3D3",
      },
      bgColor: {
        argb: "FFD3D3D3",
      },
    };
    // set header height
    worksheet.getRow(1).height = 25;
    // set header wrap text
    worksheet.getRow(1).alignment = { wrapText: true };

    // add data
    list.forEach((obj: ListObject<BrandPercentage>, index: number) => {
      // get object
      let brand = obj.data as BrandPercentage;
      // add row
      worksheet.addRow({
        brand_names: brand.brand_name,
        orders: brand.total_orders,
        quantities: brand.total_quantity,
        total_price: brand.total_price + " " + props.device.currency,
        percentage: brand.percentage + "%",
      });
      // set row height
      worksheet.getRow(index + 2).height = 50;
      // set row alignment also wrap text
      worksheet.getRow(index + 2).alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      // set row content wrap text
      // worksheet.getRow(index + 2).alignment = { wrapText: true };
      // set row border
      worksheet.getRow(index + 2).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      // set row font
      worksheet.getRow(index + 2).font = { name: "Arial", size: 10 };
      // alternate row color
      if (index % 2 === 0) {
        worksheet.getRow(index + 2).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "FFF5F5F5",
          },
          bgColor: {
            argb: "FFF5F5F5",
          },
        };
      }
    });

    // save workbook
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // FileSaver.saveAs(blob, 'orders.xlsx');
      // create a new link to download the file and click it
      const link = document.createElement("a");
      // hide the link
      link.style.display = "none";
      link.href = window.URL.createObjectURL(blob);
      link.download = file_name;
      document.body.appendChild(link);
      // click the link
      link.click();
      setTimeout(() => {
        // remove the link
        document.body.removeChild(link);
      }, 1000);
    });
  };

  const onOrderUpdated = (new_order: Order) => {
    if (!didMount.current) return;
    if (list.length === 0) return;
    const index = list.findIndex((order) => order.id === new_order.id);
    if (index === -1) return;
    const new_list = [...list];
    new_list[index].data = new_order;
    setList(new_list);
  };

  useEffect(() => {
    if (!didMount.current) return;
    if (props.export_id <= 0) return;
    onExportClick();
  }, [props.export_id]);

  useEffect(() => {
    if (!didMount.current) return;
    props.onListChanged(list.length);
  }, [list.length]);

  useEffect(() => {
    if (!didMount.current) return;
    props.onQuantityChanged(total_quantities);
  }, [total_quantities]);

  useEffect(() => {
    if (!didMount.current) return;
    props.onPriceChanged(total_prices);
  }, [total_prices]);

  const tableHeader = useRef<HTMLDivElement>(null);
  const tableFooter = useRef<HTMLDivElement>(null);

  return (
    <>
      {history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/orders/`
      ) &&
        history.location.pathname.length >
          `${Routes.Devices}/${props.device.username}/orders/`.length + 1 && (
          <OrderInfo
            user_data={props.user_data}
            device={props.device}
            order={selected_order}
            onUpdated={onOrderUpdated}
            onClose={() => {
              setSelectedOrder(null);
              forceUpdate();
            }}
          />
        )}
      <div
        className="orders-main-lay"
        ref={root}
        style={{ display: props.show ? "flex" : "none" }}
      >
        {showInputModal && (
          <div
            className="orders-input-modal"
            ref={modal_ref}
            onClick={() => {
              if (!didMount.current) return;
              setShowInputModal(false);
            }}
          />
        )}
        <div
          className={`activities-tab-date-lay${
            viewBy === "default" &&
            tabType === "chart" &&
            list.length > 0 &&
            isMoreDataAvailable
              ? " with-load-btn"
              : ""
          }`}
        >
          <div className="left-lay">
            <div className="set-lay">
              <span className="set-lay-text">View By</span>
              <select
                className="set-lay-select"
                value={viewBy === "date" ? "date" : "default"}
                onChange={(e) => {
                  e.preventDefault();
                  let val = e.target.value.toString();
                  setViewBy(val === "date" ? "date" : "default");
                  viewByRef.current = val === "date" ? "date" : "default";
                  if (val === "date") {
                    listenForInputFocus();
                  }
                  forceUpdate();
                  setTimeout(() => {
                    // console.log('viewBy changed, calling onRefreshClick');
                    onRefreshClick();
                  }, 500);
                }}
              >
                <option value={"default"}>Default</option>
                <option value={"date"}>Date Range</option>
              </select>
            </div>
            <div className="btns-lay">
              <button
                className={`btns-lay-btn${
                  tabType === "chart" ? " btn-active" : ""
                }`}
                onClick={() => {
                  setTabType("chart");
                }}
              >
                <AiOutlineBarChart className="btns-lay-btn-icon" />
                <span className="btns-lay-btn-text">Chart</span>
              </button>
              <button
                className={`btns-lay-btn${
                  tabType === "list" ? " btn-active" : ""
                }`}
                onClick={() => {
                  setTabType("list");
                }}
              >
                <FaListUl className="btns-lay-btn-icon" />
                <span className="btns-lay-btn-text">List</span>
              </button>
              {viewBy === "default" &&
                tabType === "chart" &&
                list.length > 0 &&
                isMoreDataAvailable && (
                  <button
                    className={`btns-lay-btn btn-active only-text`}
                    onClick={loadMore}
                  >
                    <span className="btns-lay-btn-text">Load More</span>
                  </button>
                )}
            </div>
          </div>
          {viewBy === "date" && (
            <div className="range-display-lay">
              <div className="range-display-cont">{datePicker()}</div>
            </div>
          )}
        </div>
        {isInitialLoading && (
          <div className="loading-lay">
            <div className="def-loading-lay">
              <div className="def-loading-spinner" />
              <span className="def-loading-text">Loading...</span>
            </div>
          </div>
        )}
        {((!isLoading && list.length === 0) || error_loading) && (
          <div className="no-devices-lay">
            {!error_loading && (
              <span className="no-devices-text">
                No data available, try select different date
              </span>
            )}
            {error_loading && (
              <span
                className="no-devices-text error-text"
                onClick={onRefreshClick}
              >
                {error_loading}
              </span>
            )}
          </div>
        )}
        <div
          className="activities-tab-charts-lay"
          style={{ display: tabType === "chart" ? "flex" : "none" }}
        >
          <div className="activities-tab-charts-chart-lay">
            {!isLoading && (
              <HighchartsReact
                highcharts={Highcharts}
                isPureConfig={true}
                oneToOne={true}
                constructorType={"chart"}
                allowChartUpdate={false}
                immutable={true}
                updateArgs={[false, false, false]}
                containerProps={{
                  className: "chart-lay",
                  id: "activities-tab-charts-chart-1",
                }}
                options={chart_1_options}
              />
            )}
          </div>
          <div className="activities-tab-charts-chart-lay">
            {!isLoading && (
              <HighchartsReact
                highcharts={Highcharts}
                isPureConfig={true}
                oneToOne={true}
                constructorType={"chart"}
                allowChartUpdate={false}
                immutable={true}
                updateArgs={[false, false, false]}
                containerProps={{
                  className: "chart-lay",
                  id: "activities-tab-charts-chart-2",
                }}
                options={chart_2_options}
              />
            )}
          </div>
          <div className="activities-tab-charts-chart-lay also-pie-chart">
            {!isLoading && (
              <HighchartsReact
                highcharts={Highcharts}
                isPureConfig={true}
                oneToOne={true}
                constructorType={"chart"}
                allowChartUpdate={false}
                immutable={true}
                updateArgs={[false, false, false]}
                containerProps={{
                  className: "chart-lay",
                  id: "activities-tab-charts-chart-3",
                }}
                options={pie_chart_options}
              />
            )}
          </div>
        </div>
        <div
          className="activities-tab-chart-table-header"
          style={{ display: tabType === "chart" ? "flex" : "none" }}
        >
          <div className="activities-tab-chart-table-header-lay">
            <div className="activities-tab-chart-table-header-left">
              <span className="activities-tab-chart-table-header-text">
                {brandPercentageTitle}
              </span>
            </div>
            <div className="activities-tab-chart-table-header-right">
              {brandPercentages.length > 0 && (
                <button
                  className="export-btn action-btn"
                  onClick={() => onExportBrandsClick()}
                >
                  <BiExport className="action-icon" />
                  <span className="action-text">Export</span>
                </button>
              )}
            </div>
          </div>
          <div className="activities-tab-chart-table-header-main">
            <div className="tab-chart-table-header-main-seg-lay orders-seg-1">
              <span className="tab-chart-table-header-main-seg">
                {"Brand Names"}
              </span>
            </div>
            <div className="tab-chart-table-header-main-seg-lay orders-seg-2">
              <span className="tab-chart-table-header-main-seg">
                {"Orders"}
              </span>
            </div>
            <div className="tab-chart-table-header-main-seg-lay orders-seg-3">
              <span className="tab-chart-table-header-main-seg">
                {"Quantities"}
              </span>
            </div>
            <div className="tab-chart-table-header-main-seg-lay orders-seg-4">
              <span className="tab-chart-table-header-main-seg">
                {"Total Price"}
              </span>
            </div>
            <div className="tab-chart-table-header-main-seg-lay orders-seg-5">
              <span className="tab-chart-table-header-main-seg">{"Sold"}</span>
            </div>
          </div>
        </div>
        <Virtuoso
          className="activities-tab-chart-table-contents"
          style={{ display: tabType === "chart" ? "flex" : "none" }}
          customScrollParent={props.scroller}
          data={brandPercentages}
          // defaultItemHeight={400}
          itemContent={(index: number, obj: ListObject<BrandPercentage>) => {
            return (
              <div
                className={`activities-tab-chart-table-item${
                  index === brandPercentages.length - 1 ? " last-item" : ""
                }`}
                key={index}
              >
                {obj.data && (
                  <div
                    className={`activities-tab-chart-table-item-main ${
                      index % 2 === 0 ? "item-even" : "item-odd"
                    }`}
                  >
                    <div className="tab-chart-table-item-main-seg-lay orders-seg-1">
                      <span className="tab-chart-table-item-main-seg">
                        {(obj.data as BrandPercentage).brand_name}
                      </span>
                    </div>
                    <div className="tab-chart-table-item-main-seg-lay orders-seg-2">
                      <span className="tab-chart-table-item-main-seg">
                        {FormatterUtil.formatNumber(
                          (obj.data as BrandPercentage).total_orders
                        )}
                      </span>
                    </div>
                    <div className="tab-chart-table-item-main-seg-lay orders-seg-3">
                      <span className="tab-chart-table-item-main-seg">
                        {(obj.data as BrandPercentage).total_quantity}
                      </span>
                    </div>
                    <div className="tab-chart-table-item-main-seg-lay orders-seg-4">
                      <span className="tab-chart-table-item-main-seg">
                        {FormatterUtil.formatPrice(
                          (obj.data as BrandPercentage).total_price
                        ) +
                          " " +
                          props.device.currency}
                      </span>
                    </div>
                    <div className="tab-chart-table-item-main-seg-lay orders-seg-5">
                      <span className="tab-chart-table-item-main-seg">
                        {(obj.data as BrandPercentage).percentage.toFixed(2) +
                          " %"}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        />

        <div
          className="activities-tab-table-header"
          ref={tableHeader}
          style={{ display: tabType === "list" ? "flex" : "none" }}
        >
          <div
            className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-1"
            style={{ position: "sticky", left: 0, zIndex: 1 }}
          >
            <span className="activities-tab-table-header-seg">Order ID</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-2">
            <span className="activities-tab-table-header-seg">Date</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-3">
            <span className="activities-tab-table-header-seg">Quantity</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-4">
            <span className="activities-tab-table-header-seg">Price</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-5">
            <span className="activities-tab-table-header-seg">
              Payment Type
            </span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-6">
            <span className="activities-tab-table-header-seg">Completed</span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-7">
            <span className="activities-tab-table-header-seg">
              Transaction ID
            </span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-8">
            <span className="activities-tab-table-header-seg">
              Payment Status
            </span>
          </div>
          <div className="activities-tab-table-header-seg-lay activities-tab-table-header-seg-9">
            <span className="activities-tab-table-header-seg">Mobile</span>
          </div>
        </div>
        <TableVirtuoso
          className="activities-tab-table-contents"
          style={{ display: tabType === "list" ? "flex" : "none" }}
          customScrollParent={props.scroller}
          onScroll={(event) => {
            if (tableHeader.current) {
              tableHeader.current.scrollLeft = (
                event.currentTarget as any
              ).scrollLeft;
            }
            if (tableFooter.current) {
              tableFooter.current.scrollLeft = (
                event.currentTarget as any
              ).scrollLeft;
            }
          }}
          data={list}
          defaultItemHeight={80}
          fixedItemHeight={80}
          itemContent={(index: number, obj: ListObject<Order>) => {
            if (!obj || !obj.data) return null;
            return (
              <>
                <td
                  className={`tab-item-main-seg tab-item-main-seg-1 ${
                    index % 2 === 0 ? "item-even" : "item-odd"
                  }${(obj.data as Order).completed ? "" : " item-negative"}`}
                  style={{ position: "sticky", left: 0, zIndex: 1 }}
                >
                  <Link
                    to={
                      Routes.Devices +
                      "/" +
                      props.device.username +
                      "/orders/" +
                      obj.id
                    }
                    className="link-lay"
                    onClick={() => {
                      setSelectedOrder(obj.data as Order);
                      forceUpdate();
                    }}
                  >
                    <span className="tab-item-main-seg-text">
                      {(obj.data as Order).id}
                    </span>
                  </Link>
                </td>
                <td
                  className={`tab-item-main-seg tab-item-main-seg-2 ${
                    index % 2 === 0 ? "item-even" : "item-odd"
                  }${(obj.data as Order).completed ? "" : " item-negative"}`}
                >
                  <Link
                    to={
                      Routes.Devices +
                      "/" +
                      props.device.username +
                      "/orders/" +
                      obj.id
                    }
                    className="link-lay"
                    onClick={() => {
                      setSelectedOrder(obj.data as Order);
                      forceUpdate();
                    }}
                  >
                    <span className="tab-item-main-seg-text">
                      {getDateView((obj.data as Order).date_created)[0]}
                    </span>
                    <span className="tab-item-main-seg-text">
                      {getDateView((obj.data as Order).date_created)[1]}
                    </span>
                  </Link>
                </td>
                <td
                  className={`tab-item-main-seg tab-item-main-seg-3 ${
                    index % 2 === 0 ? "item-even" : "item-odd"
                  }${(obj.data as Order).completed ? "" : " item-negative"}`}
                >
                  <Link
                    to={
                      Routes.Devices +
                      "/" +
                      props.device.username +
                      "/orders/" +
                      obj.id
                    }
                    className="link-lay"
                    onClick={() => {
                      setSelectedOrder(obj.data as Order);
                      forceUpdate();
                    }}
                  >
                    <span className="tab-item-main-seg-text">
                      {(obj.data as Order).total_quantity}
                    </span>
                  </Link>
                </td>
                <td
                  className={`tab-item-main-seg tab-item-main-seg-4 ${
                    index % 2 === 0 ? "item-even" : "item-odd"
                  }${(obj.data as Order).completed ? "" : " item-negative"}`}
                >
                  <Link
                    to={
                      Routes.Devices +
                      "/" +
                      props.device.username +
                      "/orders/" +
                      obj.id
                    }
                    className="link-lay"
                    onClick={() => {
                      setSelectedOrder(obj.data as Order);
                      forceUpdate();
                    }}
                  >
                    <span className="tab-item-main-seg-text">
                      {FormatterUtil.formatPrice(
                        (obj.data as Order).total_price
                      ) +
                        " " +
                        (obj.data as Order).currency}
                    </span>
                  </Link>
                </td>
                <td
                  className={`tab-item-main-seg tab-item-main-seg-5 ${
                    index % 2 === 0 ? "item-even" : "item-odd"
                  }${(obj.data as Order).completed ? "" : " item-negative"}`}
                >
                  <Link
                    to={
                      Routes.Devices +
                      "/" +
                      props.device.username +
                      "/orders/" +
                      obj.id
                    }
                    className="link-lay"
                    onClick={() => {
                      setSelectedOrder(obj.data as Order);
                      forceUpdate();
                    }}
                  >
                    <span className="tab-item-main-seg-text">
                      {(obj.data as Order).purchase_type}
                    </span>
                  </Link>
                </td>
                <td
                  className={`tab-item-main-seg tab-item-main-seg-6 ${
                    index % 2 === 0 ? "item-even" : "item-odd"
                  }${(obj.data as Order).completed ? "" : " item-negative"}`}
                >
                  <Link
                    to={
                      Routes.Devices +
                      "/" +
                      props.device.username +
                      "/orders/" +
                      obj.id
                    }
                    className="link-lay"
                    onClick={() => {
                      setSelectedOrder(obj.data as Order);
                      forceUpdate();
                    }}
                  >
                    <span className="tab-item-main-seg-text">
                      {(obj.data as Order).completed ? "Yes" : "No"}
                    </span>
                  </Link>
                </td>
                <td
                  className={`tab-item-main-seg tab-item-main-seg-7 ${
                    index % 2 === 0 ? "item-even" : "item-odd"
                  }${(obj.data as Order).completed ? "" : " item-negative"}`}
                >
                  <Link
                    to={
                      Routes.Devices +
                      "/" +
                      props.device.username +
                      "/orders/" +
                      obj.id
                    }
                    className="link-lay"
                    onClick={() => {
                      setSelectedOrder(obj.data as Order);
                      forceUpdate();
                    }}
                  >
                    <span className="tab-item-main-seg-text">
                      {(obj.data as Order).transaction_id}
                    </span>
                  </Link>
                </td>
                <td
                  className={`tab-item-main-seg tab-item-main-seg-8 ${
                    index % 2 === 0 ? "item-even" : "item-odd"
                  }${(obj.data as Order).completed ? "" : " item-negative"}`}
                >
                  <Link
                    to={
                      Routes.Devices +
                      "/" +
                      props.device.username +
                      "/orders/" +
                      obj.id
                    }
                    className="link-lay"
                    onClick={() => {
                      setSelectedOrder(obj.data as Order);
                      forceUpdate();
                    }}
                  >
                    {(obj.data as Order).payment &&
                      (obj.data as Order).payment?.status && (
                        <span className="tab-item-main-seg-text">
                          {(obj.data as Order).payment?.status}
                        </span>
                      )}
                  </Link>
                </td>
                <td
                  className={`tab-item-main-seg tab-item-main-seg-9 ${
                    index % 2 === 0 ? "item-even" : "item-odd"
                  }${(obj.data as Order).completed ? "" : " item-negative"}`}
                >
                  <Link
                    to={
                      Routes.Devices +
                      "/" +
                      props.device.username +
                      "/orders/" +
                      obj.id
                    }
                    className="link-lay"
                    onClick={() => {
                      setSelectedOrder(obj.data as Order);
                      forceUpdate();
                    }}
                  >
                    {(obj.data as Order).payment &&
                      (obj.data as Order).payment?.mobile && (
                        <span className="tab-item-main-seg-text">
                          {(obj.data as Order).payment?.mobile}
                        </span>
                      )}
                  </Link>
                </td>
              </>
            );
          }}
        />
        <div
          className="activities-tab-table-footer"
          ref={tableFooter}
          style={{
            display:
              tabType === "list" && viewBy === "default" ? "flex" : "none",
          }}
        >
          {isMoreDataAvailable && !isLoading && (
            <div
              className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-1"
              style={{ position: "sticky", left: 0, zIndex: 1 }}
            >
              <div
                className="activities-tab-table-footer-btn action-btn"
                onClick={loadMore}
              >
                <span className="activities-tab-table-footer-btn-text action-text">
                  Load More
                </span>
              </div>
            </div>
          )}
          {isLoading && (
            <div
              className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-1"
              style={{ position: "sticky", left: 0, zIndex: 1 }}
            >
              <div className="def-loading-lay">
                <div className="def-loading-spinner" />
                <span className="def-loading-text">Loading...</span>
              </div>
            </div>
          )}
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-2"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-3"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-4"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-5"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-6"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-7"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-8"></div>
          <div className="activities-tab-table-footer-seg-lay activities-tab-table-footer-seg-9"></div>
        </div>
      </div>
    </>
  );
};

export default OrdersTab;

type OrderInfoProps = {
  user_data: UserData;
  device: Device;
  order: Order | null;
  onUpdated: (new_order: Order) => void;
  onClose: () => void;
};

const OrderInfo = (props: OrderInfoProps) => {
  const history = useHistory();
  const databaseManager: DatabaseManager = new DatabaseManager();
  const didMount = useRef(false);

  const [is_updating, setIsUpdating] = useState(false);
  const [is_loading, setIsLoading] = useState(false);

  const [id, setId] = useState(props.order?.id || "");
  const [slot_numbers, setSlotNumbers] = useState(
    props.order?.slot_numbers || []
  );
  const [brand_names, setBrandNames] = useState(props.order?.brand_names || []);
  const [quantities, setQuantities] = useState(props.order?.quantities || []);
  const [prices, setPrices] = useState(props.order?.prices || []);
  //
  const [total_quantity, setTotalQuantity] = useState(
    props.order?.total_quantity || 0
  );
  const [total_price, setTotalPrice] = useState(props.order?.total_price || 0);
  const [currency, setCurrency] = useState(
    props.order?.currency || props.device.currency
  );
  const [purchase_type, setPurchaseType] = useState(
    props.order?.purchase_type || ""
  );
  const [date_created, setDateCreated] = useState(
    props.order?.date_created || 0
  );
  const [completed, setCompleted] = useState(props.order?.completed || false);
  const [date_updated, setDateUpdated] = useState(
    props.order?.date_updated || 0
  );
  const [transaction_id, setTransactionId] = useState(
    props.order?.transaction_id || ""
  );
  const [payment, setPayment] = useState(props.order?.payment || undefined);

  const handleKeyPress = (e: any) => {
    // go back if user press escape
    if (e.keyCode === 27 || e.key === "Escape") {
      if (!didMount.current) return;
      if (is_updating) return;
      if (
        history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/orders/${id}`
        )
      ) {
        props.onClose();
        if (history.length > 1) {
          history.goBack();
        } else {
          history.push(`${Routes.Devices}/${props.device.username}/orders`);
        }
      }
    }
  };

  useEffect(() => {
    didMount.current = true;
    document.addEventListener("keydown", handleKeyPress, false);
    let id = "";
    // get it from the url eg: /activities/username/orders/123
    if (
      history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/orders/`
      )
    ) {
      id = history.location.pathname.split(
        `${Routes.Devices}/${props.device.username}/orders/`
      )[1];
      if (id.indexOf("/") > 0) {
        id = id.split("/")[0];
      }
    }
    if (id) {
      getData(id);
    }
    return () => {
      didMount.current = false;
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);

  useEffect(() => {
    if (props.order) {
      setId(props.order.id);
      getData(props.order.id);
    }
  }, [props.order]);

  const getData = (id: string) => {
    if (!didMount.current) return;
    setIsLoading(true);

    databaseManager
      .getDeviceOrderData(props.user_data.id, props.device.id, id)
      .then((result: any) => {
        if (!didMount.current) return;
        setIsLoading(false);
        if (result && result.success && result.success.data) {
          const data: Order | null =
            result.success.data.length > 0 ? result.success.data[0] : null;
          if (data) {
            setId(data.id);
            setSlotNumbers(data.slot_numbers);
            setBrandNames(data.brand_names);
            setQuantities(data.quantities);
            setPrices(data.prices);
            //
            setTotalQuantity(data.total_quantity);
            setTotalPrice(data.total_price);
            setCurrency(data.currency);
            setPurchaseType(data.purchase_type);
            setDateCreated(data.date_created);
            setCompleted(data.completed);
            setDateUpdated(data.date_updated);
            setTransactionId(data.transaction_id);
            setPayment(data.payment);

            props.onUpdated(data);
          }
        }
      });
  };

  function formatPrice(price: number): string {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div
      className="order-info-main"
      id="order-info-main"
      onClick={(e) => {
        if (e.target === document.getElementById("order-info-main")) {
          if (is_updating) return;
          props.onClose();
          if (history.length > 1) {
            history.goBack();
          } else {
            history.push(`${Routes.Devices}/${props.device.username}/orders`);
          }
        }
      }}
    >
      <div className="info-contents">
        <div className="info-title-lay">
          {/* {props.user_allowed &&
            <button className='update-btn action-btn' onClick={onSubmit}>
              <FaCheckCircle className='update-icon action-icon' />
              <span className='update-text action-text'>Update</span>
            </button>
          } */}
          <span className="info-text">{`Order Info`}</span>
          <FaTimes
            className="info-close-btn"
            title="close"
            onClick={(e) => {
              e.preventDefault();
              if (history.length > 1) {
                history.goBack();
              } else {
                history.push(
                  `${Routes.Devices}/${props.device.username}/orders`
                );
              }
            }}
          />
        </div>
        <div className="form-contents">
          <div className="form-lay-main">
            <div className="form-lay">
              <div className="form-item">
                <div className="form-title-lay">
                  <span className="form-title-text">ID</span>
                </div>
                <div className="form-info-lay">
                  <span className="form-info-text">{id}</span>
                </div>
              </div>
              <div className="form-item">
                <div className="form-title-lay">
                  <span className="form-title-text">Date Created</span>
                </div>
                <div className="form-info-lay">
                  <span className="form-info-text">
                    {moment(date_created).format("DD-MM-YYYY")}
                  </span>
                  <span className="form-info-text">
                    {moment(date_created).format("HH:mm:ss")}
                  </span>
                </div>
              </div>
              <div className="form-item">
                <div className="form-title-lay">
                  <span className="form-title-text">Date Updated</span>
                </div>
                <div className="form-info-lay">
                  <span className="form-info-text">
                    {date_updated > 0
                      ? moment(date_updated).format("DD-MM-YYYY")
                      : ""}
                  </span>
                  <span className="form-info-text">
                    {date_updated > 0
                      ? moment(date_updated).format("HH:mm:ss")
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-lay">
              <div className="form-item">
                <div className="form-title-lay">
                  <span className="form-title-text">Payment Type</span>
                </div>
                <div className="form-info-lay">
                  <span className="form-info-text">{purchase_type}</span>
                </div>
              </div>
              <div className="form-item">
                <div className="form-title-lay">
                  <span className="form-title-text">Completed</span>
                </div>
                <div className="form-info-lay">
                  <span className="form-info-text">
                    {completed ? "Yes" : "No"}
                  </span>
                </div>
              </div>
              <div className="form-item">
                <div className="form-title-lay">
                  <span className="form-title-text">Transaction ID</span>
                </div>
                <div className="form-info-lay">
                  <span className="form-info-text">{transaction_id}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-lay-main">
            <div className="form-lay single-item">
              <div className="form-item">
                <div className="form-title-lay">
                  <span className="form-title-text">Details</span>
                </div>
                <div className="form-info-lay">
                  <table className="form-info-lay-table">
                    <thead>
                      <tr>
                        <th>Slot No.</th>
                        <th>Brand</th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {slot_numbers &&
                        slot_numbers.length > 0 &&
                        slot_numbers.length === brand_names.length &&
                        slot_numbers.length === quantities.length &&
                        slot_numbers.length === prices.length &&
                        slot_numbers.map((s: number, i: number) => {
                          return (
                            <tr key={i}>
                              <td>{s}</td>
                              <td>{brand_names[i]}</td>
                              <td>{quantities[i]}</td>
                              <td>{prices[i]}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td>{total_quantity}</td>
                        <td>{formatPrice(total_price) + " " + currency}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {payment && (
            <div className="form-lay-main">
              <div className="form-lay single-item">
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Payment</span>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Date Created</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">
                      {moment(payment.date_created).format("DD-MM-YYYY")}
                    </span>
                    <span className="form-info-text">
                      {moment(payment.date_created).format("HH:mm:ss")}
                    </span>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Date Completed</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">
                      {payment.date_completed > 0
                        ? moment(payment.date_completed).format("DD-MM-YYYY")
                        : ""}
                    </span>
                    <span className="form-info-text">
                      {payment.date_completed > 0
                        ? moment(payment.date_completed).format("HH:mm:ss")
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Status</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">{payment.status}</span>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Type</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">{payment.type}</span>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Amount</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">
                      {payment.amount + " " + payment.currency}
                    </span>
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-title-lay">
                    <span className="form-title-text">Curtomer Mobile</span>
                  </div>
                  <div className="form-info-lay">
                    <span className="form-info-text">{payment.mobile}</span>
                  </div>
                </div>
                {payment.reference_number.length > 0 && (
                  <div className="form-item">
                    <div className="form-title-lay">
                      <span className="form-title-text">Reference Number</span>
                    </div>
                    <div className="form-info-lay">
                      <span className="form-info-text">
                        {payment.reference_number}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
