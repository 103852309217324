import { useEffect, useReducer, useRef, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import {
  FaCartPlus,
  FaCheckCircle,
  FaInfo,
  FaMinus,
  FaPhoneAlt,
  FaPlus,
  FaTimes,
} from "react-icons/fa";
import {
  HiOutlineStatusOffline,
  HiShoppingCart,
  HiStatusOnline,
} from "react-icons/hi";
import { MdCancel, MdDelete, MdLocationOn, MdRefresh } from "react-icons/md";
import { RiLayoutBottom2Fill } from "react-icons/ri";
import { BsCartCheckFill, BsListCheck } from "react-icons/bs";
import { TbBottle } from "react-icons/tb";
import { Link, useHistory } from "react-router-dom";
import DatabaseManager, { media_url } from "../../../service";
import Routes from "../../../utils/routes";
import {
  Device,
  Slot,
  WsShopDevice,
  WsShopDeviceProcess,
  WsShopDeviceRequestReply,
  WsShopperRequestCart,
  WsSlot,
} from "../../models";
import queryString from "query-string";
import "./index.scss";
import { IoPricetag } from "react-icons/io5";
import React from "react";

type ShopDeviceProps = {
  u: string;
  active: boolean;
  //
  onDeviceLoaded: (device: Device) => void;
  //
  socket_refresh_id: number;
  socket_dev?: WsShopDevice;
  socket_dev_reply?: WsShopDeviceRequestReply;
  socket_dev_process?: WsShopDeviceProcess;
  //
  onRequestShopDevice: (device: Device, request_code: number) => void;
  onSendShopCart: (
    device: Device,
    slots: Slot[],
    purchase_type: WsShopperRequestCart["purchase_type"],
    phone_number: WsShopperRequestCart["phone_number"]
  ) => void;
};

const ShopDevice = (props: ShopDeviceProps) => {
  const history = useHistory();
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const wasLoadAtLeastOnce = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [device, setDevice] = useState<Device | null>(null);
  const [slots, setSlots] = useState<Slot[]>([]);
  const brandNames = useRef<string[]>([]);

  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);

  const show_confirm_empty_cart_dialog = useRef(false);

  const [phone_number_input, setPhoneNumberInput] = useState("");
  const [errorPaymentInput, setErrorPaymentInput] = useState("");
  const phone_number = useRef("");
  const [payment_dialog_loading, setPaymentDialogLoading] = useState(false);
  const paymentDialogLoadingRef = useRef(false);
  const [error_cart_fault, setErrorCartFault] = useState("");

  const [show_process_dialog, setShowProcessDialog] = useState(false);
  const [process_stage, setProcessStage] = useState<
    "request-code" | "processing"
  >("request-code");
  const [request_code_input, setRequestCodeInput] = useState("");
  const internal_request_code = useRef(0);
  const request_code_timeout = useRef<any>(null);
  const [request_code_sent, setRequestCodeSent] = useState(false);
  const [request_code_error, setRequestCodeError] = useState("");

  const [process_loading, setProcessLoading] = useState(false);
  const [process_message, setProcessMessage] = useState<string[]>([]);
  const [process_error, setProcessError] = useState<string[]>([]);
  const [process_completed, setProcessCompleted] = useState(false);

  const process_timeout = useRef<any>(null);

  const socketLoaded = useRef(false);

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 27 || e.key === "Escape") {
      hideDialog();
    }
  };

  const hideDialog = () => {
    if (!didMount.current) return;
    if (!history.location.pathname.startsWith(Routes.Shop + "/" + props.u))
      return;
    if (history.location.hash.includes(`payment`)) {
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push({
          pathname: history.location.pathname,
          hash: "",
        });
      }
    } else if (history.location.hash.includes(`confirm`)) {
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push({
          pathname: history.location.pathname,
          hash: "",
        });
      }
    } else if (
      show_confirm_empty_cart_dialog.current &&
      history.location.hash.includes(`dialog`)
    ) {
      show_confirm_empty_cart_dialog.current = false;
      history.replace({
        pathname: history.location.pathname,
        hash: "",
      });
    } else if (history.location.search.includes(`slot=`)) {
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push({
          pathname: history.location.pathname,
          search: "",
        });
      }
    }
  };

  useEffect(() => {
    didMount.current = true;
    if (history.location.pathname.startsWith(Routes.Shop + "/" + props.u)) {
      onRefreshClick();
    }
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      didMount.current = false;
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);

  useEffect(() => {
    if (!didMount.current) return;
    if (!history.location.pathname.startsWith(Routes.Shop + "/" + props.u))
      return;
    if (!wasLoadAtLeastOnce.current) {
      onRefreshClick();
    }
    getSlotById();
  }, [history.location.pathname, history.location.search]);

  const onRefreshClick = () => {
    if (!didMount.current) return;
    setErrorCartFault("");
    getData();
  };

  const getData = () => {
    if (!didMount.current) return;
    if (!history.location.pathname.startsWith(Routes.Shop + "/" + props.u))
      return;
    setIsLoading(true);
    setError("");
    wasLoadAtLeastOnce.current = true;

    DatabaseManager.getInstance()
      .getShopDeviceData(props.u)
      .then((r) => {
        if (!didMount.current) return;
        setIsLoading(false);
        if (r.success && r.success.data && r.success.data.length > 0) {
          const device: Device | null = r.success.data[0].device
            ? r.success.data[0].device
            : null;
          if (device && device.id && device.username && !socketLoaded.current) {
            socketLoaded.current = true;
            props.onDeviceLoaded(device);
          }
          setDevice(device);
          setSlots(r.success.data[0].slots ? r.success.data[0].slots : []);
          getSlotById();
        } else if (r.error) {
          setError(
            r.error.message
              ? r.error.message
              : "Sorry, we couldn't find the machine you are looking for. Please try again later."
          );
        } else {
          setError(
            "Sorry, we couldn't find the machine you are looking for. Please try again later."
          );
        }
      })
      .catch(() => {
        if (!didMount.current) return;
        setIsLoading(false);
        setError(
          "Sorry, something went wrong while loading machine. Please try again later."
        );
      });
  };

  const getTotalAvailable = () => {
    if (slots.length === 0) return 0;
    let a = 0;
    slots.forEach((s) => {
      a = a + s.quantity;
    });
    return a;
  };

  const getTotalBrands = () => {
    if (slots.length === 0) return 0;
    let b = 0;
    brandNames.current = [];
    slots.forEach((s) => {
      const brand = s.brand_name.trim().toLowerCase();
      let index = brandNames.current.findIndex((brnd) => {
        return brnd === brand;
      });
      if (index === -1) {
        brandNames.current.push(brand);
      }
    });
    b = brandNames.current.length;
    return b;
  };

  const getSlotById = () => {
    if (!didMount.current) return;
    if (!device || slots.length === 0) {
      // selectedSlotRef.current = null;
      setSelectedSlot(null);
      forceUpdate();
      return;
    }
    // let path = history.location.pathname;
    // if (path === `${Routes.Shop}/${device.username}/slots` || path === `${Routes.Shop}/${device.username}/slots/`) {
    //   return null;
    // }
    // path = path.replace(`${Routes.Shop}/${device.username}/slots/`, '');
    const parsed = queryString.parse(history.location.search);
    // console.log('getSlotById, parsed', parsed);
    if (!parsed.slot) {
      // selectedSlotRef.current = null;
      setSelectedSlot(null);
      forceUpdate();
      return;
    }
    const id = parsed.slot + "";
    // const id = path.split('/')[0];
    if (isNaN(Number(id))) {
      // selectedSlotRef.current = null;
      setSelectedSlot(null);
      forceUpdate();
      return;
    }
    const index = slots.findIndex((ss) => {
      return ss.slot_number === Number(id);
    });
    if (index > -1) {
      // const s = JSON.parse(JSON.stringify(slots[index]));
      setSelectedSlot(Object.create(slots[index]));
      forceUpdate();
    }
    // for (var i = 0; i < slots.length; i++) {
    //   if (slots[i].slot_number === Number(id)) {
    //     // const s = slots[i];
    //     // s.selected_count = 0;
    //     // selectedSlotRef.current = slots[i];
    //     setSelectedSlot(slots[i]);
    //     forceUpdate();
    //     break;
    //   }
    // }
    // console.log('getSlotById, slots.length', slots.length);
  };

  // useEffect(() => {
  //   setSelectedSlot(selectedSlotRef.current);
  //   console.log('selectedSlot', selectedSlotRef.current);
  //   forceUpdate();
  // }, [selectedSlotRef.current]);

  useEffect(() => {
    getSlotById();
    // console.log('slots', slots);
  }, [slots]);

  // const onPlusBtnClick = () => {
  //   if (!didMount.current) return;
  //   if (!selectedSlot) return;
  //   let index = -1;
  //   if (cart.length > 0) {
  //     index = cart.findIndex(s => {
  //       return s.slot_number === selectedSlot.slot_number;
  //     });
  //   }
  //   const cartt = cart;
  //   if (index > -1) {
  //     const sc = cartt[index];
  //     if (sc.selected_count === undefined) {
  //       sc.selected_count = 1;
  //     }
  //     if (sc.selected_count + 1 <= sc.quantity) {
  //       sc.selected_count = sc.selected_count + 1;
  //     }
  //     setSelectedSlot(sc);
  //     cartt[index] = sc;
  //   } else {
  //     if (selectedSlot.selected_count === undefined) {
  //       selectedSlot.selected_count = 1;
  //     }
  //     if (selectedSlot.selected_count + 1 <= selectedSlot.quantity) {
  //       selectedSlot.selected_count = selectedSlot.selected_count + 1;
  //     }
  //     cartt.push(selectedSlot);
  //   }
  //   setCart(cartt);
  //   forceUpdate();
  // }

  // const onMinusBtnClick = () => {
  //   if (!didMount.current) return;
  //   if (!selectedSlot) return;
  //   let index = -1;
  //   if (cart.length > 0) {
  //     index = cart.findIndex(s => {
  //       return s.slot_number === selectedSlot.slot_number;
  //     });
  //   }
  //   const cartt = cart;
  //   if (index > -1) {
  //     const sc = cartt[index];
  //     if (sc.selected_count === undefined) {
  //       sc.selected_count = 1;
  //     }
  //     if (sc.selected_count - 1 > 0) {
  //       sc.selected_count = sc.selected_count - 1;
  //       cartt[index] = sc;
  //     } else {
  //       sc.selected_count = 0;
  //       cartt.splice(index, 1); // remove slot if selected count is zero
  //     }
  //     setSelectedSlot(sc);
  //   } else {
  //     if (selectedSlot.selected_count === undefined) {
  //       selectedSlot.selected_count = 1;
  //     }
  //     if (selectedSlot.selected_count + 1 <= selectedSlot.quantity) {
  //       selectedSlot.selected_count = selectedSlot.selected_count + 1;
  //     }
  //     cartt.push(selectedSlot);
  //   }
  //   setCart(cartt);
  //   forceUpdate();
  // }

  const onAddToCartClick = (slot: Slot) => {
    if (!didMount.current) return;
    let index = -1;
    const _slots = slots;
    if (_slots.length > 0) {
      index = _slots.findIndex((s) => {
        return s.slot_number === slot.slot_number;
      });
    }
    if (index > -1) {
      _slots[index].selected_count = slot.selected_count;
      setSlots(_slots);
    }
    setSelectedSlot(null);
    forceUpdate();
    hideDialog();
  };

  const onRemoveFromCartClick = (slot: Slot) => {
    if (!didMount.current) return;
    let index = -1;
    const _slots = slots;
    if (_slots.length > 0) {
      index = _slots.findIndex((s) => {
        return s.slot_number === slot.slot_number;
      });
    }
    if (index > -1) {
      _slots[index].selected_count = 0;
      setSlots(_slots);
    }
    setSelectedSlot(null);
    forceUpdate();
    hideDialog();
  };

  const onEmptyCartClick = () => {
    if (!didMount.current) return;
    show_confirm_empty_cart_dialog.current = true;
    history.push({
      pathname: history.location.pathname,
      hash: "dialog",
    });
  };

  const continueEmptyCart = () => {
    const _slots = slots;
    for (let i = 0; i < _slots.length; i++) {
      _slots[i].selected_count = 0;
    }
    setSlots(_slots);
    forceUpdate();
  };

  const onBuyNowClick = (slot: Slot) => {
    onAddToCartClick(slot);
    if (!didMount.current) return;
    setTimeout(() => {
      if (!didMount.current) return;
      history.push({
        pathname: history.location.pathname,
        hash: "confirm",
      });
    }, 500);
  };

  const getTotalSelected = () => {
    let a = 0;
    slots.forEach((s) => {
      if (s.selected_count !== undefined && s.selected_count > 0) {
        a = a + s.selected_count;
      }
    });
    return a;
  };

  const getTotalSelectedPrice = () => {
    let a = 0;
    slots.forEach((s) => {
      if (s.selected_count !== undefined && s.selected_count > 0) {
        a = a + s.selected_count * s.price;
      }
    });
    return a;
  };

  const onContinueClick = () => {
    if (!didMount.current) return;
    setTimeout(() => {
      if (!didMount.current) return;
      history.push({
        pathname: history.location.pathname,
        hash: "payment",
      });
    }, 500);
  };

  const paymentDialogView = () => {
    if (!device) {
      return <></>;
    }
    return (
      <div
        className="shop-payment-dialog"
        id={"shop-payment-dialog-" + props.u}
        onClick={(e) => {
          if (
            e.target ===
            document.getElementById(`shop-payment-dialog-${props.u}`)
          ) {
            if (payment_dialog_loading) return;
            if (history.length > 1) {
              history.goBack();
            } else {
              history.push(`${Routes.Shop}/${props.u}`);
            }
          }
        }}
      >
        <div className="shop-payment-dialog-contents">
          {payment_dialog_loading && (
            <div className="loading-lay">
              <div className="def-loading-lay">
                <div className="def-loading-spinner" />
                <span className="def-loading-text">Loading...</span>
              </div>
            </div>
          )}
          <div className="shop-payment-dialog-header">
            <FaTimes
              className="shop-payment-dialog-close-btn"
              onClick={(e) => {
                if (history.length > 1) {
                  history.goBack();
                } else {
                  history.push(`${Routes.Shop}/${props.u}`);
                }
              }}
            />
            <span className="shop-payment-dialog-header-text">Payment</span>
          </div>
          <div className="shop-payment-dialog-body">
            {error_cart_fault.length === 0 && (
              <>
                <span className="shop-payment-dialog-body-title">
                  {"Provide Your Mobile Number" +
                    (device.country.toLowerCase().includes("tanzania")
                      ? " (+255)"
                      : "")}
                </span>
                <input
                  className="shop-payment-dialog-input"
                  type="text"
                  value={phone_number_input}
                  placeholder={
                    device.country.toLowerCase().includes("tanzania")
                      ? "+255xxxxxxxxx"
                      : "+xxxxxxxxxx"
                  }
                  onChange={(e) => {
                    setPhoneNumberInput(e.currentTarget.value);
                    setErrorPaymentInput("");
                  }}
                />
              </>
            )}
            {errorPaymentInput.length > 0 && (
              <span className="shop-payment-dialog-input-error">
                {errorPaymentInput}
              </span>
            )}
            {error_cart_fault && (
              <span className="shop-payment-dialog-input-error-cart">
                {error_cart_fault}
              </span>
            )}
          </div>
          <div className="shop-payment-dialog-footer">
            {!payment_dialog_loading && error_cart_fault.length === 0 && (
              <>
                <div
                  className="shop-payment-dialog-footer-btn action-btn"
                  onClick={() => {
                    if (history.length > 1) {
                      history.goBack();
                    } else {
                      history.push(`${Routes.Shop}/${props.u}`);
                    }
                  }}
                >
                  <FaTimes className="shop-payment-dialog-footer-icon action-icon" />
                  <span className="shop-payment-dialog-footer-text action-text">
                    Cancel
                  </span>
                </div>
                <div
                  className="shop-payment-dialog-footer-btn action-btn"
                  onClick={onPaymentContinueClick}
                >
                  <FaCheckCircle className="shop-payment-dialog-footer-icon action-icon" />
                  <span className="shop-payment-dialog-footer-text action-text">
                    Proceed
                  </span>
                </div>
              </>
            )}
            {error_cart_fault.length > 0 && (
              <div
                className="shop-payment-dialog-footer-btn action-btn"
                onClick={onRefreshClick}
              >
                <MdRefresh className="shop-payment-dialog-footer-icon action-icon" />
                <span className="shop-payment-dialog-footer-text action-text">
                  Retry
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const onPaymentContinueClick = () => {
    if (!didMount.current) return;
    if (!device) return;
    let phone = phone_number_input;
    if (phone.length === 0) {
      setErrorPaymentInput("Please enter your mobile number to proceed");
      phone_number.current = "";
      setErrorCartFault("");
      return;
    }
    let p = phone.startsWith("+") ? phone.replaceAll("+", "") : phone;
    let isNum = /^\d+$/.test(p);
    if (!isNum) {
      setErrorPaymentInput("Please enter a valid mobile number to proceed");
      phone_number.current = "";
      setErrorCartFault("");
      return;
    }
    setErrorPaymentInput("");
    setErrorCartFault("");
    let ph = p;
    if (device.country.toLowerCase().includes("tanzania")) {
      if (p.startsWith("255")) {
        ph = ph.substring(3);
      }
      if (ph.startsWith("0")) {
        ph = ph.substring(1);
      }
    }
    phone_number.current = ph;
    // setErrorCartFault("Sorry. It looks like there's changes in machine products. Please try again now.\n\nYou will require to repeat all processes again.");
    // return;

    setPaymentDialogLoading(true);
    paymentDialogLoadingRef.current = true;

    // check if vending slots are still the same as was loaded before
    DatabaseManager.getInstance()
      .getShopDeviceData(props.u)
      .then((r) => {
        if (!didMount.current) return;
        setPaymentDialogLoading(false);
        paymentDialogLoadingRef.current = false;
        if (r.success && r.success.data && r.success.data.length > 0) {
          const new_solts: Slot[] = r.success.data[0].slots
            ? r.success.data[0].slots
            : [];
          const old_slots = slots;
          if (old_slots.length !== new_solts.length) {
            setErrorCartFault(
              "Sorry. It looks like there's changes in machine products. Please try again now.\n\nYou will require to repeat all processes again."
            );
          } else {
            let was_error = false;
            for (let i = 0; i < new_solts.length; i++) {
              const ns = new_solts[i];
              let ns_match = false;
              for (var j = 0; j < old_slots.length; j++) {
                const os = old_slots[j];
                if (os.slot_number == ns.slot_number) {
                  ns_match = true;
                  if (
                    os.brand_name !== ns.brand_name ||
                    os.price !== ns.price ||
                    os.quantity !== ns.quantity
                  ) {
                    was_error = true;
                    break;
                  }
                }
              }
              if (!ns_match) {
                was_error = true;
                break;
              }
            }
            if (was_error) {
              setErrorCartFault(
                "Sorry. It looks like there's changes in machine products. Please try again now.\n\nYou will require to repeat all processes again."
              );
            } else {
              // start process
              startProcess();
            }
          }
        } else if (r.error) {
          setErrorCartFault(
            r.error.message
              ? r.error.message
              : "Sorry, we couldn't load the machine data. Please try again now.\n\nYou will require to repeat all processes again."
          );
        } else {
          setErrorCartFault(
            "Sorry, something went wrong while loading machine data. Please try again now.\n\nYou will require to repeat all processes again."
          );
        }
      })
      .catch(() => {
        if (!didMount.current) return;
        setPaymentDialogLoading(false);
        paymentDialogLoadingRef.current = false;
        setErrorCartFault(
          "Sorry, something went wrong while loading machine data. Please try again now.\n\nYou will require to repeat all processes again."
        );
      });
  };

  const randomIntFromInterval = (min: number, max: number) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const startProcess = () => {
    if (!didMount.current) return;
    if (!device) return;

    internal_request_code.current = randomIntFromInterval(1000, 9999);
    // send request code to machine
    props.onRequestShopDevice(device, internal_request_code.current);

    if (request_code_timeout.current !== null) {
      clearTimeout(request_code_timeout.current);
    }

    // add new request code timeout
    request_code_timeout.current = setTimeout(() => {
      if (!didMount.current) return;
      setRequestCodeError(
        "Sorry. The waiting time for request code is up. Please try again."
      );
    }, 15000);

    setProcessStage("request-code");

    // show process dialog
    setShowProcessDialog(true);
  };

  useEffect(() => {
    if (!didMount.current) return;
    if (!show_process_dialog) return;
    if (!props.socket_dev_reply) return;
    switch (props.socket_dev_reply.reply) {
      case "ok":
        if (process_stage === "request-code") {
          if (request_code_timeout.current !== null) {
            clearTimeout(request_code_timeout.current);
          }
          // add new request code timeout
          request_code_timeout.current = setTimeout(() => {
            if (!didMount.current) return;
            setRequestCodeError(
              "Sorry. The waiting time for request code is up. Please try again."
            );
          }, 15000);
          setRequestCodeSent(true);
        } else if (process_stage === "processing") {
        }
        break;
      case "busy":
        if (process_stage === "request-code") {
          if (request_code_timeout.current !== null) {
            clearTimeout(request_code_timeout.current);
          }
          setRequestCodeError(
            "Sorry. The machine is busy at the moment. Please try again after few minutes."
          );
          setRequestCodeSent(true);
        } else if (process_stage === "processing") {
          setProcessLoading(false);
          setProcessError([
            "Machine Is Busy!",
            "Sorry, The machine is busy at the moment.",
            "Please try again after few minutes.",
          ]);
          setProcessMessage([]);
          setProcessCompleted(true);
          forceUpdate();
        }
        break;
      case "error":
        if (process_stage === "request-code") {
          if (request_code_timeout.current !== null) {
            clearTimeout(request_code_timeout.current);
          }
          setRequestCodeError(
            "Sorry. There was an error while connecting to machine. Please try again."
          );
          setRequestCodeSent(true);
        } else if (process_stage === "processing") {
          setProcessLoading(false);
          setProcessError([
            "Ow! Somethig Went Wrong!",
            "Sorry, There was an error while connecting to machine.",
            "Please try again.",
          ]);
          setProcessMessage([]);
          setProcessCompleted(true);
          forceUpdate();
        }
        break;
      default:
        break;
    }
  }, [
    props.socket_dev_reply,
    props.socket_dev_reply?.request_code,
    props.socket_dev_reply?.reply,
  ]);

  const onSubmitRequestCodeClick = () => {
    if (!didMount.current) return;
    if (!device) return;
    if (request_code_input.length === 0) {
      if (request_code_timeout.current !== null) {
        clearTimeout(request_code_timeout.current);
      }
      setRequestCodeError(
        "Sorry. Your request code is invalid. Please try again."
      );
      return;
    }
    if (
      isNaN(Number(request_code_input)) ||
      request_code_input !== internal_request_code.current.toString()
    ) {
      if (request_code_timeout.current !== null) {
        clearTimeout(request_code_timeout.current);
      }
      setRequestCodeError(
        "Sorry. Your request code is invalid. Please try again."
      );
      return;
    }
    const cart: Slot[] = [];
    slots.forEach((s) => {
      if (s.selected_count !== undefined && s.selected_count > 0) {
        cart.push(s);
      }
    });

    // send cart to machine
    props.onSendShopCart(device, cart, "mobile", phone_number.current);
    // props.onSendShopCart(device, cart, 'cash', phone_number.current); // TODO: temporary for testing

    // set processing stage
    setProcessStage("processing");
    // register a process timeout clock
    registerProcessTimeout();
  };

  const registerProcessTimeout = () => {
    unregisterProcessTimeout();
    // assign new process_timeout for two minutes
    process_timeout.current = setTimeout(() => {
      if (!didMount.current) return;
      setProcessLoading(false);
      setProcessError([
        "Process Timeout!",
        "Sorry, The process failed due to long waiting time for machine to respond.",
        "Please contact the customer service for further actions, or try again later.",
      ]);
      setProcessMessage([]);
      setProcessCompleted(true);
      forceUpdate();
      unregisterProcessTimeout();
    }, 1000 * 60 * 2);
  };

  const unregisterProcessTimeout = () => {
    if (process_timeout.current !== null) {
      clearTimeout(process_timeout.current);
      process_timeout.current = null;
    }
  };

  useEffect(() => {
    if (!didMount.current) return;
    if (!show_process_dialog) return;
    if (!props.socket_dev_process) return;
    switch (props.socket_dev_process.state) {
      case "initial":
        setProcessLoading(true);
        setProcessError([]);
        setProcessMessage(["Initiatialize payment process..."]);
        setProcessCompleted(false);
        registerProcessTimeout();
        forceUpdate();
        break;
      case "payment_pending":
        setProcessLoading(true);
        setProcessError([]);
        setProcessMessage([
          "Payment Pending",
          "Waiting for payment to complete...",
          "Please follow other instructions on the machine's screen.",
        ]);
        setProcessCompleted(false);
        registerProcessTimeout();
        forceUpdate();
        break;
      case "payment_failed":
        setProcessLoading(false);
        setProcessError([
          "Ow! Payment Failed!",
          "Sorry, You can repeat this process after sometimes.",
          "Please contact the customer service for further actions.",
        ]);
        setProcessMessage([]);
        setProcessCompleted(true);
        unregisterProcessTimeout();
        forceUpdate();
        break;
      case "payment_canceled":
        setProcessLoading(false);
        setProcessError([
          "Ow! Payment Canceled!",
          "It looks like the payment was canceled.",
          "Please try again.",
          "If there's an error, please contact the customer service for further actions.",
          "Thanks.",
        ]);
        setProcessMessage([]);
        setProcessCompleted(true);
        unregisterProcessTimeout();
        forceUpdate();
        break;
      case "payment_success":
        setProcessLoading(true);
        setProcessError([]);
        setProcessMessage([
          "Payment Success.",
          "Waiting for product(s) shipping...",
        ]);
        setProcessCompleted(false);
        registerProcessTimeout();
        forceUpdate();
        break;
      case "shipping_started":
        setProcessLoading(true);
        setProcessError([]);
        setProcessMessage([
          "Shipping Started",
          `${
            props.socket_dev_process.slot_number > 0
              ? "Shipping item on slot no: " +
                props.socket_dev_process.slot_number
              : ""
          }`,
          `${
            props.socket_dev_process.completed_count > 0
              ? props.socket_dev_process.completed_count + " Completed"
              : ""
          }`,
          `${
            props.socket_dev_process.remained_count > 0
              ? props.socket_dev_process.remained_count + " Remained."
              : ""
          }`,
        ]);
        setProcessCompleted(false);
        registerProcessTimeout();
        forceUpdate();
        break;
      case "shipping_failed":
        setProcessLoading(false);
        setProcessError([
          "Sorry, Shipping Failed! 😔",
          "Please take your receipt and please contact the customer service for further actions.",
        ]);
        setProcessMessage([]);
        setProcessCompleted(true);
        unregisterProcessTimeout();
        forceUpdate();
        break;
      case "shipping_finished":
        setProcessLoading(true);
        setProcessError([]);
        setProcessMessage([
          "Shipping Finished",
          `${
            props.socket_dev_process.completed_count > 0
              ? props.socket_dev_process.completed_count + " Completed"
              : ""
          }`,
          `${
            props.socket_dev_process.remained_count > 0
              ? props.socket_dev_process.remained_count + " Remained."
              : ""
          }`,
        ]);
        setProcessCompleted(false);
        registerProcessTimeout();
        forceUpdate();
        break;
      case "shipping_completed":
        setProcessLoading(false);
        setProcessError([]);
        setProcessMessage([
          "Shipping Completed 😊",
          "Please take your receipt.",
          "Thanks for your purchase with us. You are welcome again.",
        ]);
        setProcessCompleted(true);
        unregisterProcessTimeout();
        forceUpdate();
        break;
      default:
        break;
    }
  }, [
    props.socket_dev_process,
    props.socket_dev_process?.state,
    props.socket_dev_process?.slot_number,
    props.socket_dev_process?.remained_count,
  ]);

  const processDialogView = () => {
    return (
      <div className="shop-process-dialog">
        <div className="shop-process-dialog-contents">
          <div className="shop-process-dialog-header">
            <span className="shop-process-dialog-header-text">Process</span>
          </div>
          <div className="shop-process-dialog-body">
            {process_stage === "request-code" && (
              <div className="shop-process-code-lay">
                {!request_code_sent && (
                  <div className="initial-lay">
                    <span className="initial-text">
                      Connecting to machine. Please wait...
                    </span>
                    <div className="def-loading-lay">
                      <div className="def-loading-spinner" />
                      <span className="def-loading-text">Loading...</span>
                    </div>
                  </div>
                )}
                {request_code_error.length === 0 && (
                  <>
                    <span className="process-code-title">
                      Please enter below the code number shown on the machine's
                      screen right now.
                    </span>
                    <input
                      className="process-code-input"
                      type="text"
                      value={request_code_input}
                      placeholder="enter code number"
                      onChange={(e) => {
                        setRequestCodeInput(e.currentTarget.value);
                      }}
                    />
                    {request_code_input.length > 0 && (
                      <div
                        className="request-code-btn action-btn"
                        onClick={onSubmitRequestCodeClick}
                      >
                        <FaCheckCircle className="request-code-btn-icon action-icon" />
                        <span className="request-code-btn-text action-text">
                          Proceed
                        </span>
                      </div>
                    )}
                  </>
                )}
                {request_code_error.length > 0 && (
                  <div className="request-code-error-lay">
                    <span className="request-code-error-text">
                      {request_code_error}
                    </span>
                    <div
                      className="request-code-error-btn action-btn"
                      onClick={() => {
                        setShowProcessDialog(false);
                        setProcessStage("request-code");
                        setRequestCodeInput("");
                        setRequestCodeError("");
                        setRequestCodeSent(false);
                        if (request_code_timeout.current !== null) {
                          clearTimeout(request_code_timeout.current);
                          request_code_timeout.current = null;
                        }
                        internal_request_code.current = 0;
                        // onRefreshClick();
                        history.push({
                          pathname: history.location.pathname,
                          hash: "payment",
                        });
                      }}
                    >
                      <MdRefresh className="request-code-error-btn-icon action-icon" />
                      <span className="request-code-error-btn-text action-text">
                        Retry
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {process_stage === "processing" && (
              <div className="shop-process-work-lay">
                <span className="shop-process-work-announce-text">
                  Please be near the machine to take your order.
                </span>
                {process_loading && process_error.length === 0 && (
                  <div className="def-loading-lay">
                    <div className="def-loading-spinner" />
                    {/* <span className='def-loading-text'>Working...</span> */}
                  </div>
                )}
                {process_message.length > 0 && (
                  <div className="shop-process-work-text-lay">
                    {process_message.map((m, i) => {
                      if (m.length === 0) return null;
                      return (
                        <span key={i} className="shop-process-work-text">
                          {m}
                        </span>
                      );
                    })}
                  </div>
                )}
                {process_error.length > 0 && (
                  <div className="shop-process-work-error-lay">
                    {process_error.map((m, i) => (
                      <span key={i} className="shop-process-work-error-text">
                        {m}
                      </span>
                    ))}
                  </div>
                )}
                {process_completed && (
                  <div className="shop-process-work-success-lay">
                    <div
                      className="shop-process-work-success-btn action-btn"
                      onClick={onProcessOkClick}
                    >
                      <FaCheckCircle className="shop-process-work-success-btn-icon action-icon" />
                      <span className="shop-process-work-success-btn-text action-text">
                        Ok
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const onProcessOkClick = () => {
    setShowProcessDialog(false);
    setProcessStage("request-code");
    setRequestCodeInput("");
    setRequestCodeError("");
    setRequestCodeSent(false);
    if (request_code_timeout.current !== null) {
      clearTimeout(request_code_timeout.current);
      request_code_timeout.current = null;
    }
    internal_request_code.current = 0;

    setProcessLoading(false);
    setProcessMessage([]);
    setProcessError([]);
    setProcessCompleted(false);

    forceUpdate();

    history.push({
      pathname: Routes.Shop + "/" + props.u,
      hash: "",
    });
    onRefreshClick();
  };

  return (
    <>
      {history.location.pathname.startsWith(Routes.Shop + "/" + props.u) &&
        show_process_dialog &&
        processDialogView()}
      {!show_process_dialog && (
        <>
          {history.location.pathname.startsWith(Routes.Shop + "/" + props.u) &&
            device &&
            !isLoading &&
            slots.length > 0 &&
            slots.filter((slot) => {
              return (
                slot.selected_count !== undefined && slot.selected_count > 0
              );
            }).length > 0 &&
            getTotalSelectedPrice() > 0 &&
            history.location.hash.includes("payment") &&
            paymentDialogView()}
          {history.location.pathname.startsWith(Routes.Shop + "/" + props.u) &&
            device &&
            !isLoading &&
            slots.length > 0 &&
            slots.filter((slot) => {
              return (
                slot.selected_count !== undefined && slot.selected_count > 0
              );
            }).length > 0 &&
            getTotalSelectedPrice() > 0 &&
            history.location.hash.includes("confirm") && (
              <ConfirmCartDialog
                cart={slots.filter((slot) => {
                  return (
                    slot.selected_count !== undefined && slot.selected_count > 0
                  );
                })}
                device_id={device.id}
                device_username={device.username}
                currency={device.currency}
                // onCancelClick={() => { }}
                onContinueClick={onContinueClick}
              />
            )}
          {history.location.pathname.startsWith(Routes.Shop + "/" + props.u) &&
            show_confirm_empty_cart_dialog.current &&
            history.location.hash.includes("dialog") && (
              <div
                className="confirm-empty-cart-lay"
                id="confirm-empty-cart-lay"
                onClick={(e) => {
                  if (
                    e.target ===
                    document.getElementById("confirm-empty-cart-lay")
                  ) {
                    // show_confirm_empty_cart_dialog.current = false;
                    hideDialog();
                  }
                }}
              >
                <div className="confirm-contents">
                  <div className="confirm-text-lay">
                    <span className="confirm-text">{`Remove all items in this cart ?`}</span>
                  </div>
                  <div className="confirm-btns-lay">
                    <div
                      className="cancel-btn action-red-btn"
                      onClick={() => {
                        // show_confirm_empty_cart_dialog.current = false;
                        hideDialog();
                      }}
                    >
                      <MdCancel className="confirm-icon action-red-icon" />
                      <span className="confirm-text action-red-text">
                        Cancel
                      </span>
                    </div>
                    <div
                      className="confirm-btn action-btn"
                      onClick={() => {
                        // show_confirm_empty_cart_dialog.current = false;
                        hideDialog();
                        continueEmptyCart();
                      }}
                    >
                      <FaCheckCircle className="confirm-icon action-icon" />
                      <span className="confirm-text action-text">
                        Remove All
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {device && selectedSlot && (
            <SlotItemDialog
              device_id={device.id}
              device_username={device.username}
              currency={device.currency}
              slot={selectedSlot}
              onAddToCartClick={onAddToCartClick}
              onRemoveFromCartClick={onRemoveFromCartClick}
              onBuyNowClick={onBuyNowClick}
            />
          )}
        </>
      )}
      <div
        className="device-shop-main"
        style={{
          display: history.location.pathname.startsWith(
            Routes.Shop + "/" + props.u
          )
            ? "flex"
            : "none",
        }}
      >
        <div className="device-shop-main-header-lay">
          <BiArrowBack
            className="back-btn"
            onClick={(e) => {
              e.preventDefault();
              if (history.length > 1) {
                history.goBack();
              } else {
                history.push(`${Routes.Dashboard}`);
              }
            }}
          />
          {device && <span className="header-title-text">{device.name}</span>}
          <div className="header-right">
            {props.active && (
              <HiStatusOnline
                className="header-right-icon online"
                title="online"
              />
            )}
            {!props.active && (
              <HiOutlineStatusOffline
                className="header-right-icon offline"
                title="offline"
              />
            )}
            <button className="refresh-btn action-btn" onClick={onRefreshClick}>
              <MdRefresh className="refresh-icon action-icon" />
              <span className="refresh-text action-text">Refresh</span>
            </button>
          </div>
        </div>
        <div className="device-shop-main-body-lay">
          {isLoading && (
            <div className="loading-lay">
              <div className="def-loading-lay">
                <div className="def-loading-spinner" />
                <span className="def-loading-text">Loading...</span>
              </div>
            </div>
          )}
          {!isLoading && (!device || error.length > 0) && (
            <div className="empty-lay">
              {error.length > 0 && <span className="error-text">{error}</span>}
              {error.length === 0 && (
                <span className="empty-text">
                  Couldn't find Vending Machine. Please Again Later.
                </span>
              )}
            </div>
          )}
          {device && !isLoading && (
            <>
              <div
                className="tab-lay"
                style={{
                  display:
                    history.location.pathname ===
                      `${Routes.Shop}/${device.username}` ||
                    history.location.pathname ===
                      `${Routes.Shop}/${device.username}/` ||
                    history.location.pathname.startsWith(
                      `${Routes.Shop}/${device.username}/slots`
                    )
                      ? "flex"
                      : "none",
                }}
              >
                <div className="tab-root-lay">
                  <div className="tab-first-lay">
                    <span className="id-text">{device.username}</span>
                    <div
                      className="image-lay"
                      style={{
                        backgroundImage: `url(${require("../../../assets/images/moran-vending-machine.png")})`,
                      }}
                    >
                      <div className="image-lay-top" />
                    </div>
                    <div className="tab-info-lay">
                      <div className="tab-info-first">
                        <span className="business-text">
                          {device.business_name}
                        </span>
                        <div className="location-lay">
                          <span className="location-title">
                            {device.location_name}
                          </span>
                          <div className="location-info">
                            <MdLocationOn className="location-icon" />
                            <span className="location-text">
                              {device.region + ", " + device.country}
                            </span>
                          </div>
                        </div>
                      </div>
                      {device.phone_number && (
                        <div className="tab-info-sec">
                          <a
                            href={`tel:${device.phone_number}`}
                            target="_blank"
                            rel="noreferrer"
                            className="info-sec-item-lay also-link"
                          >
                            <FaPhoneAlt className="info-sec-item-icon" />
                            <span className="info-sec-item-text">
                              {device.phone_number}
                            </span>
                          </a>
                        </div>
                      )}
                      <div className="tab-info-sec">
                        <div className="info-sec-item-lay">
                          <RiLayoutBottom2Fill className="info-sec-item-icon" />
                          <span className="info-sec-item-text">
                            {slots.length +
                              " " +
                              (slots.length === 1 ? "Slot" : "Slots")}
                          </span>
                        </div>
                        <div className="info-sec-item-lay">
                          <BsListCheck className="info-sec-item-icon" />
                          <span className="info-sec-item-text">
                            {getTotalBrands() + " Total Brands"}
                          </span>
                        </div>
                        <div className="info-sec-item-lay">
                          <TbBottle className="info-sec-item-icon" />
                          <span className="info-sec-item-text">
                            {getTotalAvailable() + " Items Available"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-slots-lay also-shopping-lay">
                    {!isLoading &&
                      slots.length > 0 &&
                      slots.map((s, i) => {
                        return (
                          <ShopSlotItem
                            key={i}
                            device_id={device.id}
                            device_username={device.username}
                            currency={device.currency}
                            s={s}
                          />
                        );
                      })}
                    {!isLoading && slots.length === 0 && (
                      <div className="slots-empty-lay">
                        <span className="slots-empty-text">
                          Not Available Items. Please Check Back Later.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="tab-lay"
                style={{
                  display: history.location.pathname.startsWith(
                    Routes.Shop + "/" + device.username + "/cart"
                  )
                    ? "flex"
                    : "none",
                }}
              >
                <div className="tab-root-lay">
                  <div className="tab-first-lay">
                    <div className="tab-info-lay">
                      <div className="tab-info-first">
                        <span className="business-text">Cart</span>
                      </div>
                      <div className="tab-info-sec">
                        <div className="info-sec-item-lay">
                          <BsListCheck className="info-sec-item-icon" />
                          <span className="info-sec-item-text">
                            {getTotalSelected() + " Total Selected"}
                          </span>
                        </div>
                        <div className="info-sec-item-lay">
                          <IoPricetag className="info-sec-item-icon" />
                          <span className="info-sec-item-text">
                            {getTotalSelectedPrice()
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                              " " +
                              device.currency +
                              " Total Price"}
                          </span>
                        </div>
                      </div>
                      <div className="tab-info-first">
                        {!isLoading &&
                          slots.length > 0 &&
                          slots.filter((slot) => {
                            return (
                              slot.selected_count !== undefined &&
                              slot.selected_count > 0
                            );
                          }).length > 0 && (
                            <div
                              className="tab-btn action-btn"
                              onClick={onEmptyCartClick}
                            >
                              <MdDelete className="tab-btn-icon action-icon" />
                              <span className="tab-btn-text action-text">
                                Empty Cart
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="tab-slots-lay also-cart-lay">
                    {!isLoading &&
                      slots.length > 0 &&
                      slots.filter((slot) => {
                        return (
                          slot.selected_count !== undefined &&
                          slot.selected_count > 0
                        );
                      }).length > 0 &&
                      slots
                        .filter((slot) => {
                          return (
                            slot.selected_count !== undefined &&
                            slot.selected_count > 0
                          );
                        })
                        .map((s, i) => {
                          return (
                            <ShopSlotItem
                              key={i}
                              device_id={device.id}
                              device_username={device.username}
                              currency={device.currency}
                              s={s}
                            />
                          );
                        })}
                    {!isLoading &&
                      slots.filter((slot) => {
                        return (
                          slot.selected_count !== undefined &&
                          slot.selected_count > 0
                        );
                      }).length === 0 && (
                        <Link
                          to={Routes.Shop + "/" + device.username}
                          className="slots-empty-lay"
                        >
                          <BsCartCheckFill className="slots-empty-text-icon" />
                          <span className="slots-empty-text">
                            Yout Cart Is Empty. Try Shop Something.
                          </span>
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="device-shop-main-footer-lay">
          <div className="device-shop-main-footer-seg-lay">
            <Link
              to={`${Routes.Shop}/${props.u}`}
              className="device-shop-main-footer-btn action-btn"
            >
              <HiShoppingCart className="device-shop-main-footer-btn-icon action-icon" />
              <span className="device-shop-main-footer-btn-text action-text">
                Shopping
              </span>
            </Link>
          </div>
          <div className="device-shop-main-footer-seg-lay">
            {device && getTotalSelectedPrice() > 0 && (
              <Link
                to={{
                  pathname: history.location.pathname,
                  hash: "confirm",
                }}
                className="device-shop-main-footer-btn action-btn"
              >
                <span className="device-shop-main-footer-btn-cart-price">
                  {getTotalSelectedPrice()
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " " +
                    device.currency}
                </span>
                <FaCheckCircle className="device-shop-main-footer-btn-icon action-icon" />
                <span className="device-shop-main-footer-btn-text action-text">
                  Buy Now
                </span>
              </Link>
            )}
          </div>
          <div className="device-shop-main-footer-seg-lay">
            <Link
              to={`${Routes.Shop}/${props.u}/cart`}
              className="device-shop-main-footer-btn action-btn"
            >
              {device && getTotalSelected() > 0 && (
                <span className="device-shop-main-footer-btn-cart-count">
                  {getTotalSelected()}
                </span>
              )}
              <BsCartCheckFill className="device-shop-main-footer-btn-icon action-icon" />
              <span className="device-shop-main-footer-btn-text action-text">
                Cart
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopDevice;

type ShopSlotItemProp = {
  device_id: Device["id"];
  device_username: Device["username"];
  currency: Device["currency"];
  s: Slot;
};

const ShopSlotItem = (props: ShopSlotItemProp) => {
  const history = useHistory();

  const image_url =
    props.s.brand_image_title !== null && props.s.brand_image_title !== ""
      ? `${media_url}${props.device_id}/images/${props.s.brand_image_title}`
      : require("../../../assets/images/bottle-stub.png");
  const total_price_string = props.s.price
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Link
      to={{
        pathname: history.location.pathname,
        search: `?slot=${props.s.slot_number}`,
      }}
      className="shop-slot-item"
    >
      {props.s.selected_count !== undefined && props.s.selected_count > 0 && (
        <span className="shop-slot-item-number-text-selected">
          {"+" + props.s.selected_count}
        </span>
      )}
      <span className="shop-slot-item-number-text">{props.s.slot_number}</span>
      <div className="shop-slot-item-image-lay">
        <div className="shop-slot-item-image-top" />
        <img
          className="shop-slot-item-image"
          src={image_url}
          alt="slot brand"
        />
        <span className="shop-slot-item-price-text" title={"Price"}>
          {total_price_string + " " + props.currency}
        </span>
      </div>
      <div className="shop-slot-item-info-lay">
        <span className="shop-slot-item-info-name">{props.s.brand_name}</span>
        <span className="shop-slot-item-info-available">
          {props.s.quantity + " in stock"}
        </span>
      </div>
    </Link>
  );
};

type SlotItemDialogProps = {
  slot: Slot;
  device_id: Device["id"];
  device_username: Device["username"];
  currency: Device["currency"];
  onAddToCartClick: (slot: Slot) => void;
  onRemoveFromCartClick: (slot: Slot) => void;
  onBuyNowClick: (slot: Slot) => void;
};

const SlotItemDialog = (props: SlotItemDialogProps) => {
  const history = useHistory();
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [slot, setSlot] = useState<Slot>(props.slot);

  useEffect(() => {
    didMount.current = true;
    return () => {
      didMount.current = false;
    };
  }, []);

  useEffect(() => {
    if (!didMount.current) return;
    setSlot(props.slot);
  }, [props.slot]);

  const image_url =
    slot.brand_image_title !== null && slot.brand_image_title !== ""
      ? `${media_url}${props.device_id}/images/${slot.brand_image_title}`
      : require("../../../assets/images/bottle-stub.png");
  const total_price_string = slot.price
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const onPlusBtnClick = () => {
    if (!didMount.current) return;
    const _sc = slot;
    if (_sc.selected_count === undefined) {
      _sc.selected_count = 1;
    } else if (_sc.selected_count + 1 <= _sc.quantity) {
      _sc.selected_count = _sc.selected_count + 1;
    }
    setSlot(_sc);
    forceUpdate();
    // console.log('onPlusBtnClick', slot.selected_count);
  };

  const onMinusBtnClick = () => {
    if (!didMount.current) return;
    const _sc = slot;
    if (_sc.selected_count === undefined) {
      _sc.selected_count = 0;
    } else if (_sc.selected_count - 1 >= 0) {
      _sc.selected_count = _sc.selected_count - 1;
    }
    setSlot(_sc);
    forceUpdate();
    // console.log('onMinusBtnClick', slot.selected_count);
  };

  return (
    <div
      className="shop-slot-item-dialog"
      id={`shop-slot-item-dialog-${props.device_id}-${slot.slot_number}`}
      onClick={(e) => {
        if (
          e.target ===
          document.getElementById(
            `shop-slot-item-dialog-${props.device_id}-${slot.slot_number}`
          )
        ) {
          if (history.length > 1) {
            history.goBack();
          } else {
            history.push(`${Routes.Shop}/${props.device_username}`);
          }
        }
      }}
    >
      <div className="shop-slot-item-dialog-contents">
        <FaTimes
          className="shop-slot-item-dialog-close-btn"
          onClick={(e) => {
            if (history.length > 1) {
              history.goBack();
            } else {
              history.push(`${Routes.Shop}/${props.device_username}`);
            }
          }}
        />
        <span className="shop-slot-item-dialog-number-text">
          {slot.slot_number}
        </span>
        <div
          className={`shop-slot-item-dialog-body${
            slot.selected_count !== undefined && slot.selected_count > 0
              ? " with-footer"
              : ""
          }`}
        >
          <div className="shop-slot-item-dialog-image-lay">
            <div className="shop-slot-item-dialog-image-top" />
            <img
              className="shop-slot-item-dialog-image"
              src={image_url}
              alt="slot brand"
            />
            <span className="shop-slot-item-dialog-price-text" title={"Price"}>
              {total_price_string + " " + props.currency}
            </span>
          </div>
          <div className="shop-slot-item-dialog-info-lay">
            <span className="shop-slot-item-dialog-info-name">
              {slot.brand_name}
            </span>
            <span className="shop-slot-item-dialog-info-available">
              {slot.quantity + " in stock"}
            </span>
          </div>
          <div className="shop-slot-item-dialog-add-lay">
            <FaPlus
              className="shop-slot-item-dialog-add-btn plus-btn"
              style={{
                visibility:
                  (slot.selected_count === undefined ||
                    slot.selected_count < slot.quantity) &&
                  slot.quantity > 0
                    ? "visible"
                    : "hidden",
              }}
              onClick={onPlusBtnClick}
            />
            <span className="shop-slot-item-dialog-add-info-name">
              {(slot.selected_count !== undefined ? slot.selected_count : 0) +
                " Selected"}
            </span>
            <FaMinus
              className="shop-slot-item-dialog-add-btn minus-btn"
              style={{
                visibility:
                  slot.selected_count !== undefined &&
                  slot.selected_count > 0 &&
                  slot.quantity > 0
                    ? "visible"
                    : "hidden",
              }}
              onClick={onMinusBtnClick}
            />
          </div>
          <div className="shop-slot-item-dialog-add-price-lay">
            <span className="shop-slot-item-dialog-add-price-title">
              Total Price:{" "}
            </span>
            <span className="shop-slot-item-dialog-add-price-text">
              {(slot.selected_count !== undefined
                ? slot.selected_count * slot.price
                : 0) +
                " " +
                props.currency}
            </span>
          </div>
        </div>
        {slot.selected_count !== undefined && slot.selected_count > 0 && (
          <div className="shop-slot-item-dialog-footer">
            <div
              className="shop-slot-item-dialog-footer-btn action-btn"
              onClick={() => props.onRemoveFromCartClick(props.slot)}
            >
              <MdDelete className="shop-slot-item-dialog-footer-icon action-icon" />
              <span className="shop-slot-item-dialog-footer-text action-text">
                Remove
              </span>
            </div>
            <div
              className="shop-slot-item-dialog-footer-btn action-btn"
              onClick={() => props.onBuyNowClick(props.slot)}
            >
              <FaCheckCircle className="shop-slot-item-dialog-footer-icon action-icon" />
              <span className="shop-slot-item-dialog-footer-text action-text">
                Buy Now
              </span>
            </div>
            <div
              className="shop-slot-item-dialog-footer-btn action-btn"
              onClick={() => props.onAddToCartClick(props.slot)}
            >
              <FaCartPlus className="shop-slot-item-dialog-footer-icon action-icon" />
              <span className="shop-slot-item-dialog-footer-text action-text">
                Add To Cart
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type ConfirmCartDialogProps = {
  cart: Slot[];
  device_id: Device["id"];
  device_username: Device["username"];
  currency: Device["currency"];
  // onCancelClick: () => void;
  onContinueClick: () => void;
};

const ConfirmCartDialog = (props: ConfirmCartDialogProps) => {
  const history = useHistory();

  const confirmSlotItem = (s: Slot, key: string) => {
    const image_url =
      s.brand_image_title !== null && s.brand_image_title !== ""
        ? `${media_url}${props.device_id}/images/${s.brand_image_title}`
        : require("../../../assets/images/bottle-stub.png");
    const total_price_string = s.price
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <Link
        to={{
          pathname: history.location.pathname,
          search: `?slot=${s.slot_number}`,
        }}
        className="shop-slot-confirm"
        key={key}
      >
        <div className="shop-slot-confirm-image-lay">
          <div className="shop-slot-confirm-image-top" />
          <img
            className="shop-slot-confirm-image"
            src={image_url}
            alt="slot brand"
          />
        </div>
        <div className="shop-slot-confirm-info-lay">
          <span className="shop-slot-confirm-brand-name">{s.brand_name}</span>
          <div className="shop-slot-confirm-bottom-lay">
            <span className="shop-slot-confirm-slot-text">{s.slot_number}</span>
            <div className="shop-slot-confirm-bottom-number-lay">
              <span
                className="shop-slot-confirm-bottom-number-price"
                title={"Price"}
              >
                {total_price_string + " " + props.currency}
              </span>
              <span className="shop-slot-confirm-bottom-number-selected">
                {"X" + s.selected_count}
              </span>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  const getTotalSelected = () => {
    let a = 0;
    props.cart.forEach((s) => {
      if (s.selected_count !== undefined && s.selected_count > 0) {
        a = a + s.selected_count;
      }
    });
    return a;
  };

  const getTotalSelectedPrice = () => {
    let a = 0;
    props.cart.forEach((s) => {
      if (s.selected_count !== undefined && s.selected_count > 0) {
        a = a + s.selected_count * s.price;
      }
    });
    return a;
  };

  return (
    <div
      className="shop-confirm-dialog"
      id={`shop-confirm-dialog-${props.device_id}`}
      onClick={(e) => {
        if (
          e.target ===
          document.getElementById(`shop-confirm-dialog-${props.device_id}`)
        ) {
          if (history.length > 1) {
            history.goBack();
          } else {
            history.push(`${Routes.Shop}/${props.device_username}`);
          }
        }
      }}
    >
      <div className="shop-confirm-dialog-contents">
        <div className="shop-confirm-dialog-header">
          <FaTimes
            className="shop-confirm-dialog-close-btn"
            onClick={(e) => {
              if (history.length > 1) {
                history.goBack();
              } else {
                history.push(`${Routes.Shop}/${props.device_username}`);
              }
            }}
          />
          <span className="shop-confirm-dialog-header-text">
            Confirm Your Order
          </span>
        </div>
        <div className="shop-confirm-dialog-body">
          <div className="shop-confirm-dialog-body-slots-lay">
            {props.cart.map((s, i) => confirmSlotItem(s, i + ""))}
          </div>
          <span className="shop-cofirm-dialog-total-text">
            {getTotalSelected() + " Total Selected"}
          </span>
          <div className="shop-cofirm-dialog-price-lay">
            <span className="shop-cofirm-dialog-price-title">
              Total Price:{" "}
            </span>
            <span className="shop-cofirm-dialog-price-text">
              {getTotalSelectedPrice()
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " " +
                props.currency}
            </span>
          </div>
        </div>
        <div className="shop-cofirm-dialog-footer">
          <div
            className="shop-cofirm-dialog-footer-btn action-btn"
            onClick={() => {
              if (history.length > 1) {
                history.goBack();
              } else {
                history.push(`${Routes.Shop}/${props.device_username}`);
              }
            }}
          >
            <FaTimes className="shop-cofirm-dialog-footer-icon action-icon" />
            <span className="shop-cofirm-dialog-footer-text action-text">
              Cancel
            </span>
          </div>
          <div
            className="shop-cofirm-dialog-footer-btn action-btn"
            onClick={props.onContinueClick}
          >
            <FaCheckCircle className="shop-cofirm-dialog-footer-icon action-icon" />
            <span className="shop-cofirm-dialog-footer-text action-text">
              Confirm
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
