import "./index.scss";

import { BsLightbulbFill, BsLightbulbOffFill } from "react-icons/bs";
import { DeviceCommand, WsDeviceActivity } from "../../../../../models";
import {
  FaCaretDown,
  FaCaretUp,
  FaCheckCircle,
  FaDoorClosed,
  FaDoorOpen,
  FaTemperatureLow,
  FaTimes,
} from "react-icons/fa";
import { IoRadio, IoWarning } from "react-icons/io5";
import {
  MdLightbulb,
  MdLightbulbOutline,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdRefresh,
} from "react-icons/md";
import { useReducer, useState } from "react";
import { BiFridge } from "react-icons/bi";
import React from "react";
type Props = {
  user_allowed: boolean;
  is_initial_fetching: boolean;
  is_sending_switch_cmd: boolean;
  sending_cmd_message: string | null;
  is_sending_switch_cmd_error: boolean;
  socket_dev_activity: WsDeviceActivity | undefined;
  setSendingCmdMessage: (message: string | null) => void;
  setIsSendingSwitchCmdError: (is_error: boolean) => void;
  setIsSendingSwitchCmd: (is_sending: boolean) => void;
  sendSwitchCommand: (command: DeviceCommand, value: number) => void;
  onRebootMachineClick: () => void;
};

type temperature = {
  value: number;
  text: string;
  selected: boolean;
};

const ActionsView = (props: Props) => {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [show_temperature_list, setShowTemperatureList] = useState(false);

  const getTemp = () => {
    const _temperatures: temperature[] = [];
    // loop from 0 to 30
    for (let i = 0; i <= 30; i++) {
      const temperature: temperature = {
        value: i,
        text: i + " °C",
        selected: false,
      };
      _temperatures.push(temperature);
    }
    return _temperatures;
  };

  const [temperatures, setTemperatures] = useState(getTemp());
  const [show_action_buttons, setShowActionButtons] = useState(false);

  return (
    <>
      {props.socket_dev_activity && (
        <div className="live-lay blue-grad">
          {props.is_initial_fetching && (
            <div className="device-detail-item-loader loading-shimmer" />
          )}
          <div className="live-lay-img temp-grad">
            <div
              className="live-lay-img-bg"
              style={{
                backgroundImage: `url(${require("../../../../../../assets/images/moran-vending-machine.png")})`,
              }}
            />
            <IoRadio className="live-lay-img-icon" />
          </div>
          <div className="live-lay-contents-main">
            <div className="live-lay-contents">
              {props.socket_dev_activity.fridge !== undefined && (
                <div className="live-seg-item">
                  <BiFridge className="live-seg-item-icon" />
                  <div className="live-seg-item-text-lay">
                    <span className="live-seg-item-title">Fridge</span>
                    <span className="live-seg-item-text">
                      {props.socket_dev_activity.fridge === 0 ? "OFF" : "ON"}
                    </span>
                  </div>
                </div>
              )}
              {props.socket_dev_activity.temp !== undefined && (
                <div className="live-seg-item">
                  <FaTemperatureLow className="live-seg-item-icon" />
                  <div className="live-seg-item-text-lay">
                    <span className="live-seg-item-title">Temperature</span>
                    <span className="live-seg-item-text">
                      {props.socket_dev_activity.temp.toFixed(2) + " °C"}
                    </span>
                  </div>
                </div>
              )}
              {props.socket_dev_activity.door !== undefined && (
                <div className="live-seg-item">
                  {props.socket_dev_activity.door === 0 && (
                    <FaDoorClosed className="live-seg-item-icon" />
                  )}
                  {props.socket_dev_activity.door !== 0 && (
                    <FaDoorOpen className="live-seg-item-icon" />
                  )}
                  <div className="live-seg-item-text-lay">
                    <span className="live-seg-item-title">Door</span>
                    <span className="live-seg-item-text">
                      {props.socket_dev_activity.door === 0 ? "CLOSED" : "OPEN"}
                    </span>
                  </div>
                </div>
              )}
              {props.socket_dev_activity.light !== undefined && (
                <div className="live-seg-item">
                  {props.socket_dev_activity.light === 0 && (
                    <BsLightbulbOffFill className="live-seg-item-icon" />
                  )}
                  {props.socket_dev_activity.light !== 0 && (
                    <BsLightbulbFill className="live-seg-item-icon" />
                  )}
                  <div className="live-seg-item-text-lay">
                    <span className="live-seg-item-title">Light</span>
                    <span className="live-seg-item-text">
                      {props.socket_dev_activity.light === 0 ? "OFF" : "ON"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {props.user_allowed && (
        <div className="device-actions-lay blue-grad">
          {props.is_initial_fetching && (
            <div className="device-detail-item-loader loading-shimmer" />
          )}
          <div className="device-actions-lay-img">
            <div
              className="device-actions-lay-img-bg"
              style={{
                backgroundImage: `url(${require("../../../../../../assets/images/moran-vending-machine.png")})`,
              }}
            />
            <IoRadio className="device-actions-lay-img-icon" />
          </div>
          <div className="device-actions-lay-contents">
            {(props.is_sending_switch_cmd || props.sending_cmd_message) && (
              <div className="device-actions-lay-modal">
                {props.is_sending_switch_cmd && (
                  <div className="def-loading-lay">
                    <div className="def-loading-spinner" />
                    <span className="def-loading-text">Sending command...</span>
                  </div>
                )}
                {props.sending_cmd_message && (
                  <div
                    className="def-dialog-lay"
                    onClick={(e) => {
                      e.preventDefault();
                      props.setSendingCmdMessage(null);
                      props.setIsSendingSwitchCmdError(false);
                      props.setIsSendingSwitchCmd(false);
                    }}
                  >
                    {props.is_sending_switch_cmd_error && (
                      <IoWarning className="def-dialog-icon error-icon" />
                    )}
                    {!props.is_sending_switch_cmd_error && (
                      <FaCheckCircle className="def-dialog-icon" />
                    )}
                    <span
                      className={
                        props.is_sending_switch_cmd_error
                          ? "def-dialog-text error-text"
                          : "def-dialog-text"
                      }
                    >
                      {props.sending_cmd_message}
                    </span>
                  </div>
                )}
              </div>
            )}
            {show_temperature_list && (
              <div
                className="tmp-list-lay"
                id="tmp-list-ly"
                onClick={(e) => {
                  try {
                    if ((e.target as any).id === "tmp-list-ly") {
                      setShowTemperatureList(false);
                    }
                  } catch (err) {
                    // console.log('root: err: ', err);
                  }
                }}
              >
                <div className="tmp-list-contents" id="tmp-list-contents">
                  <div className="temp-list-header">
                    <span className="temp-list-header-text">
                      Select Temperature
                    </span>
                    <FaTimes
                      className="temp-list-close-btn"
                      onClick={() => setShowTemperatureList(false)}
                    />
                  </div>
                  <div className="temp-list-body">
                    {temperatures.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            item.selected ? "tmp-item selected" : "tmp-item"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            // update selected temperature
                            // loop over all temperatures and unselect all
                            for (let i = 0; i < temperatures.length; i++) {
                              temperatures[i].selected =
                                temperatures[i].value === item.value;
                            }
                            setTemperatures(temperatures);
                            setShowTemperatureList(false);
                            forceUpdate();
                            props.sendSwitchCommand(
                              "turn-on-fridge",
                              item.value
                            );
                          }}
                        >
                          {item.text}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <div className="actions-lay">
              <div
                className="actions-btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowActionButtons(!show_action_buttons);
                }}
              >
                {!show_action_buttons && (
                  <>
                    <FaCaretDown className="actions-icon" />
                    <span className="actions-text">Show Actions</span>
                  </>
                )}
                {show_action_buttons && (
                  <>
                    <FaCaretUp className="actions-icon" />
                    <span className="actions-text">Hide Actions</span>
                  </>
                )}
              </div>
            </div>
            {show_action_buttons && (
              <div className="actions-btns-lay">
                <div
                  className="actions-btn-item actions-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowTemperatureList(true);
                  }}
                >
                  <MdRadioButtonChecked className="actions-btn-item-icon actions-icon" />
                  <span className="actions-btn-item-text actions-text">
                    Turn ON Refrigeration
                  </span>
                </div>
                <div
                  className="actions-btn-item actions-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.sendSwitchCommand("turn-off-fridge", 0);
                  }}
                >
                  <MdRadioButtonUnchecked className="actions-btn-item-icon actions-icon" />
                  <span className="actions-btn-item-text actions-text">
                    Turn OFF Refrigeration
                  </span>
                </div>
                <div
                  className="actions-btn-item actions-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.sendSwitchCommand("turn-on-light", 0);
                  }}
                >
                  <MdLightbulb className="actions-btn-item-icon actions-icon" />
                  <span className="actions-btn-item-text actions-text">
                    Turn ON LED Lights
                  </span>
                </div>
                <div
                  className="actions-btn-item actions-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.sendSwitchCommand("turn-off-light", 0);
                  }}
                >
                  <MdLightbulbOutline className="actions-btn-item-icon actions-icon" />
                  <span className="actions-btn-item-text actions-text">
                    Turn OFF LED Lights
                  </span>
                </div>
                <div
                  className="actions-btn-item actions-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.sendSwitchCommand("turn-on-glass-heat", 0);
                  }}
                >
                  <MdRadioButtonChecked className="actions-btn-item-icon actions-icon" />
                  <span className="actions-btn-item-text actions-text">
                    Turn ON Glass Heating
                  </span>
                </div>
                <div
                  className="actions-btn-item actions-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.sendSwitchCommand("turn-off-glass-heat", 0);
                  }}
                >
                  <MdRadioButtonUnchecked className="actions-btn-item-icon actions-icon" />
                  <span className="actions-btn-item-text actions-text">
                    Turn OFF Glass Heating
                  </span>
                </div>
                <div
                  className="actions-btn-item actions-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onRebootMachineClick();
                  }}
                >
                  <MdRefresh className="actions-btn-item-icon actions-icon" />
                  <span className="actions-btn-item-text actions-text">
                    Reboot Machine
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ActionsView;
