function filterAndSortReports(reports, startDate, endDate) {
  // Convert input dates to Date objects for comparison
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Check if start date is greater than end date for sorting order
  const isDescending = start > end;

  // Filter the reports within the date range
  const filteredReports = reports.filter((report) => {
    const reportDate = new Date(report.report_date);
    if (!isDescending) {
      return reportDate >= start && reportDate <= end;
    } else {
      return reportDate <= start && reportDate >= end;
    }
  });

  // Sort the filtered reports by date
  const sortedReports = filteredReports.sort((a, b) => {
    const dateA = new Date(a.report_date);
    const dateB = new Date(b.report_date);
    return isDescending ? dateB - dateA : dateA - dateB;
  });

  // Remove the XmlPayLoad field
  const cleanedReports = sortedReports.map((report) => {
    const { XmlPayLoad, ...rest } = report;
    return rest;
  });

  return cleanedReports;
}

function filterAndSortReceipts(receipts, startDate, endDate) {
  // Convert input dates to Date objects for comparison
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Check if start date is greater than end date for sorting order
  const isDescending = start > end;

  console.log(isDescending);
  // Filter the receipts within the date range
  const filteredReceipts = receipts.filter((receipt) => {
    const receiptDate = new Date(receipt.receipt_date);
    if (isDescending) {
      return receiptDate <= start && receiptDate >= end;
    } else {
      return receiptDate >= start && receiptDate <= end;
    }
    // return receiptDate >= start && receiptDate <= end;
  });

  // Sort the filtered receipts by date
  const sortedReceipts = filteredReceipts.sort((a, b) => {
    const dateA = new Date(a.receipt_date);
    const dateB = new Date(b.receipt_date);
    return isDescending ? dateB - dateA : dateA - dateB;
  });

  // Rename the receipt_number field to R_num and remove specified fields
  const cleanedReceipts = sortedReceipts.map((receipt) => {
    const {
      receipt_number,
      verify_link,
      ACKCODE,
      ACKMSG,
      RCTVNUM,
      customerId,
      ...rest
    } = receipt;
    return { ...rest, R_num: receipt_number };
  });

  return cleanedReceipts;
}

exports.filterAndSortReports = filterAndSortReports;
exports.filterAndSortReceipts = filterAndSortReceipts;
