import "./index.scss";
import React from "react";
import {
  FaHome,
  FaInfo,
  FaReceipt,
  FaSignOutAlt,
  FaTachometerAlt,
  FaUserAlt,
} from "react-icons/fa";

import { HiShoppingCart } from "react-icons/hi";
import { IoSettings } from "react-icons/io5";
import { Link } from "react-router-dom";
import { MdNotifications } from "react-icons/md";
import { RiDeviceFill } from "react-icons/ri";
import Routes from "../../../utils/routes";

type Props = {
  show: boolean;
  current_page: string;
  onLogOutBtnClick(): void;
};
const SideNav = (props: Props) => {
  return (
    <div
      className={props.show ? "side-nav-lay side-nav-lay-top" : "side-nav-lay"}
      id="side-nav"
      // style={{ display: props.displayStyle }}
    >
      <Link to={Routes.Landing} className="nav-home">
        <FaHome className="item-icon" />
        <span className="item-text">Home</span>
      </Link>
      <div className="side-nav-list-lay">
        <Link
          to={Routes.Dashboard}
          className={
            props.current_page.startsWith(Routes.Dashboard)
              ? "dash-btn dash-btn-selected"
              : "dash-btn dash-btn-normal"
          }
        >
          <FaTachometerAlt className="item-icon" />
          <span className="item-text">Dashboard</span>
        </Link>
        <Link
          to={Routes.Devices}
          className={
            props.current_page.startsWith(Routes.Devices)
              ? "dash-btn dash-btn-selected"
              : "dash-btn dash-btn-normal"
          }
        >
          <RiDeviceFill className="item-icon" />
          <span className="item-text">Devices</span>
        </Link>
        <Link
          to={Routes.Receipts}
          className={
            props.current_page.startsWith(Routes.Receipts)
              ? "dash-btn dash-btn-selected"
              : "dash-btn dash-btn-normal"
          }
        >
          <FaReceipt className="item-icon" />
          <span className="item-text">Accounting</span>
        </Link>
        {/* <Link to={Routes.Activities} className={
          props.current_page.startsWith(Routes.Activities) ?
            "dash-btn dash-btn-selected" :
            "dash-btn dash-btn-normal"
        }>
          <MdNotifications className='item-icon' />
          <span className='item-text'>Activities</span>
        </Link> */}
        <Link
          to={Routes.Account}
          className={
            props.current_page.startsWith(Routes.Account)
              ? "dash-btn dash-btn-selected"
              : "dash-btn dash-btn-normal"
          }
        >
          <FaUserAlt className="item-icon" />
          <span className="item-text">Account</span>
        </Link>
        <Link
          to={Routes.Shop}
          className={
            props.current_page.startsWith(Routes.Shop)
              ? "dash-btn dash-btn-selected"
              : "dash-btn dash-btn-normal"
          }
        >
          <HiShoppingCart className="item-icon" />
          <span className="item-text">Shop</span>
        </Link>
        <Link
          to={Routes.Settings}
          className={
            props.current_page.startsWith(Routes.Settings)
              ? "dash-btn dash-btn-selected"
              : "dash-btn dash-btn-normal"
          }
        >
          <IoSettings className="item-icon" />
          <span className="item-text">Settings</span>
        </Link>
      </div>
      <div className="footer-cont">
        <div className="logout-btn-cont">
          <div
            className="logout-btn"
            onClick={(e) => {
              e.preventDefault();
              props.onLogOutBtnClick();
            }}
          >
            <FaSignOutAlt className="logout-icon" />
            <span className="logout-text">Log Out</span>
          </div>
        </div>
        <div className="footer-seg">
          <Link
            className="footer-link"
            to={Routes.Landing}
            target="_blank"
            rel="noreferrer"
          >
            Home
          </Link>
          <Link
            className="footer-link"
            to={Routes.Privacy}
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </Link>
          <Link
            className="footer-link"
            to={Routes.Terms}
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </Link>
        </div>
        <div className="footer-seg">
          <span className="footer-text">Copyright &copy;</span>
        </div>
        <div className="footer-seg">
          <a
            className="footer-link"
            href="https://imperialinnovations.co.tz"
            target="_blank"
            rel="noreferrer"
          >
            Imperial Innovations
          </a>
        </div>
        <div className="footer-seg">
          <span className="footer-text">2023</span>
        </div>
        <div className="footer-seg">
          <span className="footer-text">All Rights Reserved</span>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
