import "./index.scss";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  MdSignalWifiStatusbar4Bar,
  MdSignalWifiStatusbarConnectedNoInternet4,
} from "react-icons/md";
import { useEffect, useReducer, useRef, useState } from "react";

import { BiArrowBack } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import Routes from "../../../utils/routes";

type Props = {
  onNavMenuClick(): void;
  user_name: string | null;
  socket_connected: boolean;
  current_page_title: string;
};

const Header = (props: Props) => {
  const history = useHistory();
  const [loading_count, setLoadingCount] = useState(0);
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    didMount.current = true;
    window.addEventListener("data-loading", () => {
      if (!didMount.current) return;
      setLoadingCount(loading_count + 1);
      forceUpdate();
    });
    window.addEventListener("data-loaded", () => {
      if (!didMount) {
        return;
      }
      let _loading_count = loading_count - 1;
      if (_loading_count < 0) {
        _loading_count = 0;
      }
      setLoadingCount(_loading_count);
      forceUpdate();
    });
    return () => {
      didMount.current = false;
    };
  }, []);

  return (
    <div className="header">
      <div className="header-left-lay">
        <BiArrowBack
          className="back-btn"
          onClick={(e) => {
            e.preventDefault();
            if (history.length > 1) {
              history.goBack();
            } else {
              history.push(`${Routes.Dashboard}`);
            }
          }}
        />
        <FaBars
          className="menu-btn"
          onClick={(e) => {
            e.preventDefault();
            props.onNavMenuClick();
          }}
        />
      </div>
      <div className="title-lay">
        <span className="page-title">{props.current_page_title + " - "}</span>
        <span className="app-title">Moran Vending Machine</span>
      </div>

      <div className="header-right-lay">
        {loading_count > 0 && (
          <div className="loading-lay">
            <div className="loading-lay-content def-loading-spinner-sm" />
          </div>
        )}
        <div className="status-lay">
          {props.socket_connected && (
            <MdSignalWifiStatusbar4Bar
              className="status-icon"
              title="Realtime connection is on"
            />
          )}
          {!props.socket_connected && (
            <MdSignalWifiStatusbarConnectedNoInternet4
              className="status-icon status-red-icon"
              title="Realtime connection is off"
            />
          )}
        </div>
        <Link
          to={Routes.Account}
          className="user-lay"
          title={props.user_name ? props.user_name : "User"}
        >
          <p className="user-name">
            {props.user_name ? props.user_name : "User"}
          </p>
          <i className="fas fa-user user-icon"></i>
        </Link>
        <div className="pallete-container">
          <label className="switch" title="Change theme">
            <input type="checkbox" className="checkbox" id="theme-btn" />
            <span className="toggle-thumb">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 20 20"
                style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}
              >
                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 20 20"
                style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}
              >
                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
              </svg>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Header;

export const Footer = () => {
  return (
    <div className="bottom-layer">
      {/* <span className="selector-none">
          &copy; 2021 &nbsp;|&nbsp; 
          <a href="http://imperialinnovations.co.tz" target="_blank" title="http://imperialinnovations.co.tz">Imperial Innovations</a>
          &nbsp; (IMC) Tz &nbsp;|&nbsp; All Rights Reserved
        </span> */}
    </div>
  );
};
