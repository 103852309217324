import "./index.scss";

import DatabaseManager, { media_url } from "../../../../service";
import {
  Device,
  DeviceCommand,
  RequestCode,
  Slot,
  UserData,
  UserDevice,
  UserRole,
  WsDeviceActivity,
} from "../../../models";
import {
  FaCheckCircle,
  FaInfoCircle,
  FaListUl,
  FaPhoneAlt,
  FaReceipt,
  FaTimes,
  FaUpload,
} from "react-icons/fa";
import {
  HiOutlineStatusOffline,
  HiShoppingCart,
  HiStatusOnline,
} from "react-icons/hi";
import { IoApps, IoRadio, IoWarning } from "react-icons/io5";
import { Link, useHistory } from "react-router-dom";
import {
  MdAccessTimeFilled,
  MdCancel,
  MdLocationOn,
  MdRefresh,
  MdSettings,
  MdUpdate,
} from "react-icons/md";
import { RiCurrencyFill, RiDeviceFill } from "react-icons/ri";
import { useEffect, useReducer, useRef, useState } from "react";

import ActionsView from "./tabs/actions";
import ActivitiesTab from "./tabs/activities";
import { BiExport } from "react-icons/bi";
import { BsCartCheckFill } from "react-icons/bs";
import { FiActivity } from "react-icons/fi";
import FormatterUtil from "../../../../utils/formatter-util";
import LogsTab from "./tabs/logs";
import OrdersTab from "./tabs/orders";
import Routes from "../../../../utils/routes";
import SlotEdit from "./tabs/slots/slot-edit";
import SlotsView from "./tabs/slots";
import moment from "moment";
import React from "react";
const countryList = require("country-list");

type DeviceItemProps = {
  current_page: string;
  user_id: string;
  device: UserDevice;
  user_data: UserData;
  is_device_active?: boolean;
  socket_dev_activity?: WsDeviceActivity;
  onDeviceUpdated: (device: UserDevice) => void;
  onRequestDeviceCommand: (
    device_id: string,
    command: DeviceCommand,
    value: number,
    request_code: number
  ) => void;
};

const DeviceItem = (props: DeviceItemProps) => {
  const history = useHistory();
  const databaseManager: DatabaseManager = new DatabaseManager();
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const fetch_runnable = useRef<any | null>(null);
  const cmd_request_codes = useRef<RequestCode[]>([]);
  const device_reply_refresh_code = useRef<number>(0);

  const device_modal_ref = useRef<HTMLDivElement>(null);
  const scroller = useRef<HTMLDivElement>(null);

  const [is_fetching, setIsFetching] = useState(false);
  const [is_initial_fetching, setIsInitialFetching] = useState(true);
  const [show_modal, setShowModal] = useState(false);
  const [user_allowed, setUserAllowed] = useState(false);
  const [user_owner, setUserOwner] = useState(false);
  const [user_role, setUserRole] = useState<UserRole>("user");
  //
  const [name, setName] = useState("");
  const [location_name, setLocationName] = useState("");
  const [currency, setCurrency] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [slots, setSlots] = useState<Slot[]>([]);
  const [date_created, setDateCreated] = useState("");
  const [date_updated, setDateUpdated] = useState("");
  const [is_there_data_to_show, setIsThereDataToShow] = useState(false);
  const [total_stock, setTotalStock] = useState(0);
  const [total_price, setTotalPrice] = useState(0);
  //
  const [input_name, setInputName] = useState("");
  const [input_location_name, setInputLocationName] = useState("");
  const [input_currency, setInputCurrency] = useState("");
  const [input_phone_number, setInputPhoneNumber] = useState("");
  const [input_business_name, setInputBusinessName] = useState("");
  const [input_country, setInputCountry] = useState("");
  const [input_region, setInputRegion] = useState("");
  const [input_address, setInputAddress] = useState("");
  const [input_tin_number, setInputTinNumber] = useState("");
  const [input_vrn_number, setInputVrnNumber] = useState("");
  //
  const [video_title_1, setVideoTitle1] = useState("");
  const [previous_video_title_1, setPreviousVideoTitle1] = useState("");
  const [video_src_1, setVideoSrc1] = useState<string | null>(null);
  const [video_file_1, setVideoFile1] = useState<File | null>(null);
  //
  const [video_title_2, setVideoTitle2] = useState("");
  const [previous_video_title_2, setPreviousVideoTitle2] = useState("");
  const [video_src_2, setVideoSrc2] = useState<string | null>(null);
  const [video_file_2, setVideoFile2] = useState<File | null>(null);
  //
  const [video_title_3, setVideoTitle3] = useState("");
  const [previous_video_title_3, setPreviousVideoTitle3] = useState("");
  const [video_src_3, setVideoSrc3] = useState<string | null>(null);
  const [video_file_3, setVideoFile3] = useState<File | null>(null);
  //
  const [video_title_4, setVideoTitle4] = useState("");
  const [previous_video_title_4, setPreviousVideoTitle4] = useState("");
  const [video_src_4, setVideoSrc4] = useState<string | null>(null);
  const [video_file_4, setVideoFile4] = useState<File | null>(null);
  //
  const [video_title_5, setVideoTitle5] = useState("");
  const [previous_video_title_5, setPreviousVideoTitle5] = useState("");
  const [video_src_5, setVideoSrc5] = useState<string | null>(null);
  const [video_file_5, setVideoFile5] = useState<File | null>(null);
  //
  const [show_confirm_update_all_dialog, setShowConfirmUpdateAllDialog] =
    useState(false);
  const show_confirm_update_all_dialog_ref = useRef(false);
  //
  const [
    show_confirm_reboot_machine_dialog,
    setShowConfirmRebootMachineDialog,
  ] = useState(false);
  const show_confirm_reboot_machine_dialog_ref = useRef(false);
  //

  const [is_updating, setIsUpdating] = useState(false);
  const [updating_message, setUpdatingMessage] = useState<string | null>(null);
  const [is_updating_error, setIsUpdatingError] = useState(false);
  const [is_sending_switch_cmd, setIsSendingSwitchCmd] = useState(false);
  const [sending_cmd_message, setSendingCmdMessage] = useState<string | null>(
    null
  );
  const [is_sending_switch_cmd_error, setIsSendingSwitchCmdError] =
    useState(false);
  //
  //
  const [selected_slot, setSelectedSlot] = useState<Slot | null>(null);
  const [selected_slot_refresh_id, setSelectedSlotRefreshId] = useState(0);
  const [selected_slot_proceed_update, setSelectedSlotProceedUpdate] =
    useState(false);

  const [actsRefreshId, setActsRefreshId] = useState(0);
  const [logsRefreshId, setLogsRefreshId] = useState(0);
  const [ordersRefreshId, setOrdersRefreshId] = useState(0);

  // const [wasActsTableMounted, setWasActsTableMounted] = useState(false);
  // const [wasLogsTableMounted, setWasLogsTableMounted] = useState(false);
  // const [wasOrdersTableMounted, setWasOrdersTableMounted] = useState(false);

  const [actsListSize, setActsListSize] = useState(0);
  const [actsExportId, setActsExportId] = useState(0);

  const [logsListSize, setLogsListSize] = useState(0);

  const [ordersListSize, setOrdersListSize] = useState(0);
  const [ordersQuantitySize, setOrdersQuantitySize] = useState(0);
  const [orderPriceSize, setOrderPriceSize] = useState(0);
  const [ordersExportId, setOrdersExportId] = useState(0);

  const handleKeyPress = (e: any) => {
    // go back if user press escape
    if (e.keyCode === 27 || e.key === "Escape") {
      if (!didMount.current) return;
      if (is_updating) return;
      if (show_confirm_update_all_dialog_ref.current) {
        setShowConfirmUpdateAllDialog(false);
        show_confirm_update_all_dialog_ref.current = false;
        history.replace({
          pathname: history.location.pathname,
          hash: "",
        });
        forceUpdate();
        return;
      }
      if (show_confirm_reboot_machine_dialog_ref.current) {
        setShowConfirmRebootMachineDialog(false);
        show_confirm_reboot_machine_dialog_ref.current = false;
        history.replace({
          pathname: history.location.pathname,
          hash: "",
        });
        forceUpdate();
        return;
      }
      if (
        history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/settings`
        )
      ) {
        if (history.length > 1) {
          history.goBack();
        } else {
          history.push(`${Routes.Devices}/${props.device.username}`);
        }
      }
    }
  };

  useEffect(() => {
    didMount.current = true;
    document.addEventListener("keydown", handleKeyPress, false);
    updateDevice(props.device);
    getData();

    // if (history.location.pathname.startsWith(`${Routes.Devices}/${props.device.username}/activities`)) {
    //   setWasActsTableMounted(true);
    //   forceUpdate();
    // } else if (history.location.pathname.startsWith(`${Routes.Devices}/${props.device.username}/logs`)) {
    //   setWasLogsTableMounted(true);
    //   forceUpdate();
    // } else if (history.location.pathname.startsWith(`${Routes.Devices}/${props.device.username}/orders`)) {
    //   setWasOrdersTableMounted(true);
    //   forceUpdate();
    // }

    return () => {
      didMount.current = false;
      document.removeEventListener("keydown", handleKeyPress, false);
      if (fetch_runnable.current) {
        clearInterval(fetch_runnable.current);
      }
    };
  }, []);

  // useEffect(() => {
  //   if (!didMount.current) return;
  //   if (history.location.pathname.startsWith(`${Routes.Devices}/${props.device.username}/activities`)) {
  //     setWasActsTableMounted(true);
  //     forceUpdate();
  //   } else if (history.location.pathname.startsWith(`${Routes.Devices}/${props.device.username}/logs`)) {
  //     setWasLogsTableMounted(true);
  //     forceUpdate();
  //   } else if (history.location.pathname.startsWith(`${Routes.Devices}/${props.device.username}/orders`)) {
  //     setWasOrdersTableMounted(true);
  //     forceUpdate();
  //   }
  // }, [history.location.pathname]);

  useEffect(() => {
    if (!didMount.current) return;
    updateDevice(props.device);
  }, [
    props.device,
    props.device.name,
    props.device.location_name,
    props.device.last_updated,
    props.device.currency,
    props.device.phone_number,
    props.device.business_name,
    props.device.country,
    props.device.region,
    props.device.address,
    props.device.tin_number,
    props.device.vrn_number,
  ]);

  useEffect(() => {
    if (!didMount.current) return;
    if (props.device.device_reply_refresh_id) {
      if (
        props.device.device_reply_refresh_id !==
        device_reply_refresh_code.current
      ) {
        device_reply_refresh_code.current =
          props.device.device_reply_refresh_id;
        const reply = props.device.device_reply;
        if (reply) {
          switch (reply.requested_command) {
            case "update-device":
              if (reply.success === true) {
                if (cmd_request_codes.current.length > 0) {
                  // get last request code
                  const last_request_code =
                    cmd_request_codes.current[
                      cmd_request_codes.current.length - 1
                    ];
                  if (
                    last_request_code.command === "update-device" &&
                    last_request_code.completed === false
                  ) {
                    last_request_code.completed = true;
                    cmd_request_codes.current[
                      cmd_request_codes.current.length - 1
                    ] = last_request_code;
                    // proceed to update
                    proceedUpdateDevice();
                  }
                }
              } else {
                // the device is busy at the moment
                setIsUpdating(false);
                setIsUpdatingError(true);
                setUpdatingMessage(
                  "Device is busy at the moment. Please try again later."
                );
              }
              break;
            case "reload-device":
              break;
            case "update-all-slots":
              if (reply.success === true) {
                if (cmd_request_codes.current.length > 0) {
                  // get last request code
                  const last_request_code =
                    cmd_request_codes.current[
                      cmd_request_codes.current.length - 1
                    ];
                  if (
                    last_request_code.command === "update-all-slots" &&
                    last_request_code.completed === false
                  ) {
                    last_request_code.completed = true;
                    cmd_request_codes.current[
                      cmd_request_codes.current.length - 1
                    ] = last_request_code;
                    // proceed to update all slots
                    proceedToUpdateAllSlots();
                  }
                }
              } else {
                // the device is busy at the moment
                setIsUpdating(false);
                setIsUpdatingError(true);
                setUpdatingMessage(
                  "Device is busy at the moment. Please try again later."
                );
              }
              break;
            case "update-single-slot":
              if (reply.success === true) {
                if (cmd_request_codes.current.length > 0) {
                  // get last request code
                  const last_request_code =
                    cmd_request_codes.current[
                      cmd_request_codes.current.length - 1
                    ];
                  if (
                    last_request_code.command === "update-single-slot" &&
                    last_request_code.completed === false
                  ) {
                    last_request_code.completed = true;
                    cmd_request_codes.current[
                      cmd_request_codes.current.length - 1
                    ] = last_request_code;
                    // proceed to update single slot
                    setSelectedSlotRefreshId(selected_slot_refresh_id + 1);
                    setSelectedSlotProceedUpdate(true);
                  }
                }
              } else {
                // the device is busy at the moment
                setSelectedSlotRefreshId(selected_slot_refresh_id + 1);
                setSelectedSlotProceedUpdate(false);
              }
              break;
            case "turn-on-fridge":
            case "turn-off-fridge":
            case "turn-on-light":
            case "turn-off-light":
            case "turn-on-glass-heat":
            case "turn-off-glass-heat":
            case "reboot-device":
              if (reply.success === true) {
                if (cmd_request_codes.current.length > 0) {
                  // get last request code
                  const last_request_code =
                    cmd_request_codes.current[
                      cmd_request_codes.current.length - 1
                    ];
                  if (
                    (last_request_code.command === "turn-on-fridge" ||
                      last_request_code.command === "turn-off-fridge" ||
                      last_request_code.command === "turn-on-light" ||
                      last_request_code.command === "turn-off-light" ||
                      last_request_code.command === "turn-on-glass-heat" ||
                      last_request_code.command === "turn-off-glass-heat" ||
                      last_request_code.command === "reboot-device") &&
                    last_request_code.completed === false
                  ) {
                    last_request_code.completed = true;
                    cmd_request_codes.current[
                      cmd_request_codes.current.length - 1
                    ] = last_request_code;
                    // the device is busy at the moment
                    setIsSendingSwitchCmd(false);
                    setSendingCmdMessage("Action successful completed.");
                    setIsSendingSwitchCmdError(false);
                    setTimeout(() => {
                      if (!didMount.current) {
                        return;
                      }
                      setIsSendingSwitchCmd(false);
                      setSendingCmdMessage(null);
                      setIsSendingSwitchCmdError(false);
                    }, 3000);
                  }
                }
              } else {
                // the device is busy at the moment
                setIsSendingSwitchCmd(false);
                setSendingCmdMessage(
                  "Device is busy at the moment. Please try again later."
                );
                setIsSendingSwitchCmdError(true);
              }
              break;
            default:
              break;
          }
        }
      }
    }
  }, [props.device.device_reply_refresh_id]);

  const updateDevice = (device: Device) => {
    if (!didMount.current || !device) {
      return;
    }
    if (device.name) {
      setInputName(device.name);
    }
    if (device.location_name) {
      setInputLocationName(device.location_name);
    }
    if (device.currency) {
      setInputCurrency(device.currency);
    }
    if (device.phone_number) {
      setInputPhoneNumber(device.phone_number);
    }
    if (device.business_name) {
      setInputBusinessName(device.business_name);
    }
    if (device.country) {
      setInputCountry(device.country);
    }
    if (device.region) {
      setInputRegion(device.region);
    }
    if (device.address) {
      setInputAddress(device.address);
    }
    if (device.tin_number) {
      setInputTinNumber(device.tin_number);
    }
    if (device.vrn_number) {
      setInputVrnNumber(device.vrn_number);
    }
  };

  const onRefreshClick = () => {
    if (!didMount.current || is_fetching) {
      return;
    }
    if (
      history.location.pathname ===
        `${Routes.Devices}/${props.device.username}` ||
      history.location.pathname ===
        `${Routes.Devices}/${props.device.username}/`
    ) {
      getData();
    } else if (
      history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/activities`
      )
    ) {
      setActsRefreshId(actsRefreshId + 1);
      forceUpdate();
    } else if (
      history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/logs`
      )
    ) {
      setLogsRefreshId(logsRefreshId + 1);
      forceUpdate();
    } else if (
      history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/orders`
      )
    ) {
      setOrdersRefreshId(ordersRefreshId + 1);
      forceUpdate();
    }
  };

  const getData = () => {
    if (!didMount.current) return;
    if (
      !history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}`
      )
    ) {
      return;
    }
    setIsFetching(true);
    setIsThereDataToShow(true);
    forceUpdate();
    // databaseManager.cancelRequests();
    databaseManager
      .getUserDevice(props.user_id, props.device.id)
      .then((result: any) => {
        if (!didMount.current) return;
        // console.log('getUserDevice result', result);
        setIsFetching(false);
        setIsInitialFetching(false);
        if (
          result &&
          result.success &&
          result.success.data &&
          result.success.data.length > 0 &&
          result.success.data[0]
        ) {
          const data = result.success.data[0];
          if (data) {
            if (data.device) {
              const device: UserDevice = {
                id: data.device.id ? data.device.id : "",
                user_allowed: data.device.user_allowed
                  ? data.device.user_allowed
                  : false,
                user_owner: data.device.user_owner
                  ? data.device.user_owner
                  : false,
                user_role: data.device.user_role
                  ? data.device.user_role
                  : "user",
                name: data.device.name ? data.device.name : "",
                location_name: data.device.location_name
                  ? data.device.location_name
                  : "",
                currency: data.device.currency ? data.device.currency : "",
                phone_number: data.device.phone_number
                  ? data.device.phone_number
                  : "",
                username: data.device.username ? data.device.username : "",
                last_updated: data.device.last_updated
                  ? data.device.last_updated
                  : 0,
                date_created: data.device.date_created
                  ? data.device.date_created
                  : 0,
                video_title_1: data.device.video_title_1
                  ? data.device.video_title_1
                  : "",
                video_title_2: data.device.video_title_2
                  ? data.device.video_title_2
                  : "",
                video_title_3: data.device.video_title_3
                  ? data.device.video_title_3
                  : "",
                video_title_4: data.device.video_title_4
                  ? data.device.video_title_4
                  : "",
                video_title_5: data.device.video_title_5
                  ? data.device.video_title_5
                  : "",
                business_name: data.device.business_name
                  ? data.device.business_name
                  : "",
                country: data.device.country ? data.device.country : "",
                region: data.device.region ? data.device.region : "",
                address: data.device.address ? data.device.address : "",
                tin_number: data.device.tin_number
                  ? data.device.tin_number
                  : "",
                vrn_number: data.device.vrn_number
                  ? data.device.vrn_number
                  : "",
                slots: [],
              };
              let total_stock = 0;
              let total_price = 0;
              for (let i = 0; i < data.slots.length; i++) {
                const slot: Slot = data.slots[i];
                if (slot && slot.quantity > 0) {
                  total_stock += slot.quantity;
                  total_price += slot.price * slot.quantity;
                }
              }
              setUserAllowed(device.user_allowed);
              setUserOwner(device.user_owner);
              setUserRole(device.user_role);
              setName(device.name);
              setLocationName(device.location_name);
              setCurrency(device.currency);
              setPhoneNumber(device.phone_number);
              //
              setDateCreated(
                moment(device.date_created).format("DD-MM-YYYY HH:mm:ss")
              );
              setDateUpdated(
                moment(device.last_updated).format("DD-MM-YYYY HH:mm:ss")
              );
              //
              setInputName(device.name);
              setInputLocationName(device.location_name);
              setInputCurrency(device.currency);
              setInputPhoneNumber(device.phone_number);
              setInputBusinessName(device.business_name);
              setInputCountry(device.country);
              setInputRegion(device.region);
              setInputAddress(device.address);
              setInputTinNumber(device.tin_number);
              setInputVrnNumber(device.vrn_number);
              setTotalStock(total_stock);
              setTotalPrice(total_price);
              //
              setVideoTitle1(device.video_title_1);
              setPreviousVideoTitle1("");
              setVideoSrc1(
                device.video_title_1 !== ""
                  ? `${media_url}${props.device.id}/videos/${device.video_title_1}`
                  : ""
              );
              setVideoFile1(null);
              //
              setVideoTitle2(device.video_title_2);
              setPreviousVideoTitle2("");
              setVideoSrc2(
                device.video_title_2 !== ""
                  ? `${media_url}${props.device.id}/videos/${device.video_title_2}`
                  : ""
              );
              setVideoFile2(null);
              //
              setVideoTitle3(device.video_title_3);
              setPreviousVideoTitle3("");
              setVideoSrc3(
                device.video_title_3 !== ""
                  ? `${media_url}${props.device.id}/videos/${device.video_title_3}`
                  : ""
              );
              setVideoFile3(null);
              //
              setVideoTitle4(device.video_title_4);
              setPreviousVideoTitle4("");
              setVideoSrc4(
                device.video_title_4 !== ""
                  ? `${media_url}${props.device.id}/videos/${device.video_title_4}`
                  : ""
              );
              setVideoFile4(null);
              //
              setVideoTitle5(device.video_title_5);
              setPreviousVideoTitle5("");
              setVideoSrc5(
                device.video_title_5 !== ""
                  ? `${media_url}${props.device.id}/videos/${device.video_title_5}`
                  : ""
              );
              setVideoFile5(null);
            }
            if (data.slots && data.slots.length > 0) {
              const slots: Slot[] = [];
              for (let i = 0; i < data.slots.length; i++) {
                const slot_number = data.slots[i].slot_number
                  ? data.slots[i].slot_number
                  : 0;
                slots.push({
                  slot_number: slot_number,
                  quantity: data.slots[i].quantity ? data.slots[i].quantity : 0,
                  price: data.slots[i].price ? data.slots[i].price : 0,
                  brand_name: data.slots[i].brand_name
                    ? data.slots[i].brand_name
                    : `Product ${slot_number}`,
                  brand_image_title: data.slots[i].brand_image_title
                    ? data.slots[i].brand_image_title
                    : "",
                  brand_video_title: data.slots[i].brand_video_title
                    ? data.slots[i].brand_video_title
                    : "",
                  selected_count: 0,
                });
              }
              setSlots(slots);
            } else {
              setSlots([]);
            }
          }
        }
        let there_is_no_any_data =
          name === "" &&
          location_name === "" &&
          currency === "" &&
          phone_number === "" &&
          slots.length === 0;

        setIsThereDataToShow(!there_is_no_any_data);
        forceUpdate();
      })
      .catch((_error: any) => {
        if (!didMount.current) return;
        setIsFetching(false);
        setIsInitialFetching(false);
        forceUpdate();
      });
  };

  const onValueChange = (
    type:
      | "name"
      | "location"
      | "currency"
      | "phone"
      | "business"
      | "country"
      | "region"
      | "address"
      | "tin"
      | "vrn",
    e: any
  ) => {
    e.preventDefault();
    if (!didMount.current) return;
    switch (type) {
      case "name":
        setInputName(e.currentTarget.value);
        break;
      case "location":
        setInputLocationName(e.currentTarget.value);
        break;
      case "currency":
        setInputCurrency(e.currentTarget.value);
        break;
      case "phone":
        setInputPhoneNumber(e.currentTarget.value);
        break;
      case "business":
        setInputBusinessName(e.currentTarget.value);
        break;
      case "country":
        setInputCountry(e.currentTarget.value);
        break;
      case "region":
        setInputRegion(e.currentTarget.value);
        break;
      case "address":
        setInputAddress(e.currentTarget.value);
        break;
      case "tin":
        setInputTinNumber(e.currentTarget.value);
        break;
      case "vrn":
        setInputVrnNumber(e.currentTarget.value);
        break;
      default:
        break;
    }
    forceUpdate();
  };

  const onSettingSubmit = (event: any) => {
    event.preventDefault();
    if (!didMount.current) return;
    if (!user_allowed) {
      return;
    }
    if (input_name === "") {
      setUpdatingMessage("Please enter device name");
      setIsUpdatingError(true);
    }
    if (input_location_name === "") {
      setUpdatingMessage("Please enter device location name");
      setIsUpdatingError(true);
      return;
    }
    if (input_currency === "") {
      setUpdatingMessage("Please enter device currency");
      setIsUpdatingError(true);
      return;
    }
    if (input_phone_number === "") {
      setUpdatingMessage("Please enter device phone number");
      setIsUpdatingError(true);
      return;
    }
    if (input_business_name === "") {
      setUpdatingMessage("Please enter business name");
      setIsUpdatingError(true);
      return;
    }
    if (input_country === "") {
      setUpdatingMessage("Please enter business country");
      setIsUpdatingError(true);
      return;
    }
    if (input_region === "") {
      setUpdatingMessage("Please enter business region");
      setIsUpdatingError(true);
      return;
    }
    if (input_address === "") {
      setUpdatingMessage("Please enter business address");
      setIsUpdatingError(true);
      return;
    }
    if (input_tin_number === "") {
      setUpdatingMessage("Please enter business tin number");
      setIsUpdatingError(true);
      return;
    }
    if (input_vrn_number === "") {
      setUpdatingMessage("Please enter business vrn number");
      setIsUpdatingError(true);
      return;
    }
    //
    setIsUpdating(true);
    cmd_request_codes.current.push({
      command: "update-device",
      code: new Date().getTime(),
      completed: false,
    });
    // TODO: send request to device via socket
    props.onRequestDeviceCommand(
      props.device.id,
      cmd_request_codes.current[cmd_request_codes.current.length - 1].command,
      0,
      cmd_request_codes.current[cmd_request_codes.current.length - 1].code
    );

    setTimeout(() => {
      // if no reply from device, then proceed to update
      if (cmd_request_codes.current.length > 0) {
        // get last request code
        const last_request_code =
          cmd_request_codes.current[cmd_request_codes.current.length - 1];
        if (
          last_request_code.command === "update-device" &&
          last_request_code.completed === false
        ) {
          last_request_code.completed = true;
          cmd_request_codes.current[cmd_request_codes.current.length - 1] =
            last_request_code;
          // proceed to update
          proceedUpdateDevice();
        }
      }
    }, 10000);
  };

  const proceedUpdateDevice = () => {
    if (!didMount.current) return;
    if (!user_allowed) {
      return;
    }
    if (input_name === "") {
      setUpdatingMessage("Please enter device name");
      setIsUpdatingError(true);
    }
    if (input_location_name === "") {
      setUpdatingMessage("Please enter device location name");
      setIsUpdatingError(true);
      return;
    }
    if (input_currency === "") {
      setUpdatingMessage("Please enter device currency");
      setIsUpdatingError(true);
      return;
    }
    if (input_phone_number === "") {
      setUpdatingMessage("Please enter device phone number");
      setIsUpdatingError(true);
      return;
    }
    // const video_file_1 = video_file_1;
    // const video_file_2 = video_file_2;
    // const video_file_3 = video_file_3;
    // const video_file_4 = video_file_4;
    // const video_file_5 = video_file_5;
    // //
    // const previous_video_title_1 = previous_video_title_1;
    // const previous_video_title_2 = previous_video_title_2;
    // const previous_video_title_3 = previous_video_title_3;
    // const previous_video_title_4 = previous_video_title_4;
    // const previous_video_title_5 = previous_video_title_5;
    // //
    setIsUpdating(true);
    //
    databaseManager
      .updateDevice(
        props.user_id,
        props.device.id,
        input_name,
        input_location_name,
        input_currency,
        input_phone_number,
        video_file_1,
        previous_video_title_1,
        video_file_2,
        previous_video_title_2,
        video_file_3,
        previous_video_title_3,
        video_file_4,
        previous_video_title_4,
        video_file_5,
        previous_video_title_5,
        input_business_name,
        input_country,
        input_region,
        input_address,
        input_tin_number,
        input_vrn_number
      )
      .then((result) => {
        if (!didMount.current) return;
        setIsUpdating(false);
        if (result && result.success) {
          setUpdatingMessage("Device updated.");
          setIsUpdatingError(false);
          // TODO: tell the device to refresh data
          props.onRequestDeviceCommand(props.device.id, "reload-device", 0, 0);
          setTimeout(() => {
            if (!didMount.current) return;
            setUpdatingMessage(null);
            setIsUpdatingError(false);
            //
            const updated_device = props.device;
            updated_device.name = input_name;
            updated_device.location_name = input_location_name;
            updated_device.currency = input_currency;
            updated_device.phone_number = input_phone_number;
            if (result.success.data && result.success.data.length > 0) {
              const data = result.success.data[0];
              updated_device.video_title_1 = data.video_title_1
                ? data.video_title_1
                : video_title_1;
              updated_device.video_title_2 = data.video_title_2
                ? data.video_title_2
                : video_title_2;
              updated_device.video_title_3 = data.video_title_3
                ? data.video_title_3
                : video_title_3;
              updated_device.video_title_4 = data.video_title_4
                ? data.video_title_4
                : video_title_4;
              updated_device.video_title_5 = data.video_title_5
                ? data.video_title_5
                : video_title_5;
            }
            updated_device.business_name = input_business_name;
            updated_device.country = input_country;
            updated_device.region = input_region;
            updated_device.address = input_address;
            updated_device.tin_number = input_tin_number;
            updated_device.vrn_number = input_vrn_number;
            //
            props.onDeviceUpdated(updated_device);
          }, 1000);
        } else if (result && result.error && result.error.message) {
          setUpdatingMessage(result.error.message);
          setIsUpdatingError(true);
        } else {
          setUpdatingMessage(
            "An error occured while updating device. Please try again."
          );
          setIsUpdatingError(true);
        }
      })
      .catch(() => {
        if (!didMount.current) return;
        setIsUpdating(false);
        setUpdatingMessage(
          "An error occured while updating device. Please try again."
        );
        setIsUpdatingError(true);
      });
  };

  const updateAllSlots = (e: any) => {
    e.preventDefault();
    if (!user_allowed) {
      return;
    }
    //
    setIsUpdating(true);
    setShowConfirmUpdateAllDialog(true);
    show_confirm_update_all_dialog_ref.current = true;
    history.push({
      pathname: history.location.pathname,
      hash: "dialog",
    });

    cmd_request_codes.current.push({
      command: "update-all-slots",
      code: new Date().getTime(),
      completed: false,
    });
    // TODO: send request to device via socket
    props.onRequestDeviceCommand(
      props.device.id,
      cmd_request_codes.current[cmd_request_codes.current.length - 1].command,
      0,
      cmd_request_codes.current[cmd_request_codes.current.length - 1].code
    );

    setTimeout(() => {
      // if no reply from device, then proceed to update
      if (cmd_request_codes.current.length > 0) {
        // get last request code
        const last_request_code =
          cmd_request_codes.current[cmd_request_codes.current.length - 1];
        if (
          last_request_code.command === "update-all-slots" &&
          last_request_code.completed === false
        ) {
          last_request_code.completed = true;
          cmd_request_codes.current[cmd_request_codes.current.length - 1] =
            last_request_code;
          // proceed to update all slots
          proceedToUpdateAllSlots();
        }
      }
    }, 10000);
  };

  const proceedToUpdateAllSlots = () => {
    if (!didMount.current) return;
    if (!user_allowed) {
      return;
    }
    setIsUpdating(true);
    setShowConfirmUpdateAllDialog(true);
    show_confirm_update_all_dialog_ref.current = true;
    history.push({
      pathname: history.location.pathname,
      hash: "dialog",
    });
    databaseManager
      .updateDeviceAllSlots(props.user_id, props.device.id)
      .then((result) => {
        if (!didMount.current) {
          return;
        }
        setIsUpdating(false);
        if (result && result.success) {
          // TODO: tell the device to refresh data
          props.onRequestDeviceCommand(props.device.id, "reload-device", 0, 0);
          setUpdatingMessage("All slots are updated.");
          setIsUpdatingError(false);
          setTimeout(() => {
            if (!didMount.current) {
              return;
            }
            setUpdatingMessage(null);
            setIsUpdatingError(false);
            setShowConfirmUpdateAllDialog(false);
            show_confirm_update_all_dialog_ref.current = false;
            history.replace({
              pathname: history.location.pathname,
              hash: "",
            });
            //
            props.onDeviceUpdated(props.device); // update the slot in the parent component
          }, 1000);
        } else if (result && result.error && result.error.message) {
          setUpdatingMessage(result.error.message);
          setIsUpdatingError(true);
          setShowConfirmUpdateAllDialog(false);
          show_confirm_update_all_dialog_ref.current = false;
          history.replace({
            pathname: history.location.pathname,
            hash: "",
          });
        } else {
          setUpdatingMessage(
            "An error occured while updating all slots. Please try again."
          );
          setIsUpdatingError(true);
          setShowConfirmUpdateAllDialog(false);
          show_confirm_update_all_dialog_ref.current = false;
          history.replace({
            pathname: history.location.pathname,
            hash: "",
          });
        }
      })
      .catch(() => {
        if (!didMount.current) {
          return;
        }
        setShowConfirmUpdateAllDialog(false);
        show_confirm_update_all_dialog_ref.current = false;
        setIsUpdating(false);
        setUpdatingMessage(
          "An error occured while updating all slots. Please try again."
        );
        setIsUpdatingError(true);
      });
  };

  const sendSwitchCommand = (command: DeviceCommand, value: number) => {
    if (!didMount.current) return;
    if (!user_allowed) {
      return;
    }
    setIsSendingSwitchCmd(true);
    setSendingCmdMessage(null);
    setIsSendingSwitchCmdError(false);
    forceUpdate();
    cmd_request_codes.current.push({
      command: command,
      code: new Date().getTime(),
      completed: false,
    });
    // TODO: send request to device via socket
    props.onRequestDeviceCommand(
      props.device.id,
      cmd_request_codes.current[cmd_request_codes.current.length - 1].command,
      value,
      cmd_request_codes.current[cmd_request_codes.current.length - 1].code
    );

    setTimeout(() => {
      // if no reply from device, then proceed
      if (cmd_request_codes.current.length > 0) {
        // get last request code
        const last_request_code =
          cmd_request_codes.current[cmd_request_codes.current.length - 1];
        if (
          (last_request_code.command === "turn-on-fridge" ||
            last_request_code.command === "turn-off-fridge" ||
            last_request_code.command === "turn-on-light" ||
            last_request_code.command === "turn-off-light" ||
            last_request_code.command === "turn-on-glass-heat" ||
            last_request_code.command === "turn-off-glass-heat" ||
            last_request_code.command === "reboot-device") &&
          last_request_code.completed === false
        ) {
          last_request_code.completed = true;
          cmd_request_codes.current[cmd_request_codes.current.length - 1] =
            last_request_code;
          // the device is busy at the moment
          setIsSendingSwitchCmd(false);
          setSendingCmdMessage(
            "Action could not complete. Please try again later."
          );
          setIsSendingSwitchCmdError(true);
        }
      }
    }, 10000);
  };

  const onRequestDeviceSlotCommand = () => {
    if (!didMount.current) {
      return;
    }
    if (!user_allowed) {
      return;
    }
    cmd_request_codes.current.push({
      command: "update-single-slot",
      code: new Date().getTime(),
      completed: false,
    });
    // TODO: send request to device via socket
    props.onRequestDeviceCommand(
      props.device.id,
      cmd_request_codes.current[cmd_request_codes.current.length - 1].command,
      0,
      cmd_request_codes.current[cmd_request_codes.current.length - 1].code
    );

    setTimeout(() => {
      // if no reply from device, then proceed to update
      if (cmd_request_codes.current.length > 0) {
        // get last request code
        const last_request_code =
          cmd_request_codes.current[cmd_request_codes.current.length - 1];
        if (
          last_request_code.command === "update-single-slot" &&
          last_request_code.completed === false
        ) {
          last_request_code.completed = true;
          cmd_request_codes.current[cmd_request_codes.current.length - 1] =
            last_request_code;
          // proceed to update single slot
          setSelectedSlotRefreshId(selected_slot_refresh_id + 1);
          setSelectedSlotProceedUpdate(true);
        }
      }
    }, 10000);
  };

  // const setSelectedSlot = (slot: Slot) => {
  //   if (!didMount.current) return;
  //   setSelectedSlot(slot);
  // }

  const onDeviceSlotUpdated = (slot: Slot) => {
    if (!didMount.current) return;
    if (!user_allowed) {
      return;
    }
    // update slots in state
    // const slots = slots;
    let index = -1;
    for (let i = 0; i < slots.length; i++) {
      if (slots[i].slot_number === slot.slot_number) {
        index = i;
        break;
      }
    }
    if (index >= 0) {
      slots[index] = slot;
    }
    setSlots(slots);
    forceUpdate();
    // TODO: tell the device to refresh data
    props.onRequestDeviceCommand(props.device.id, "reload-device", 0, 0);
  };

  const confirmAllView = () => {
    return (
      <div
        className="confirm-all-lay"
        id="confirm-all-lay"
        onClick={(e) => {
          if (e.target === document.getElementById("confirm-all-lay")) {
            if (is_updating) return;
            setShowConfirmUpdateAllDialog(false);
            show_confirm_update_all_dialog_ref.current = false;
            history.replace({
              pathname: history.location.pathname,
              hash: "",
            });
          }
        }}
      >
        <div className="confirm-contents">
          {(is_updating || updating_message) && (
            <div className="confirm-modal">
              {is_updating && (
                <div className="def-loading-lay">
                  <div className="def-loading-spinner" />
                  <span className="def-loading-text">Loading...</span>
                </div>
              )}
              {updating_message && (
                <div
                  className="def-dialog-lay"
                  onClick={(e) => {
                    e.preventDefault();
                    setUpdatingMessage(null);
                    setIsUpdatingError(false);
                    setIsUpdating(false);
                  }}
                >
                  {is_updating_error && (
                    <IoWarning className="def-dialog-icon error-icon" />
                  )}
                  {!is_updating_error && (
                    <FaCheckCircle className="def-dialog-icon" />
                  )}
                  <span
                    className={
                      is_updating_error
                        ? "def-dialog-text error-text"
                        : "def-dialog-text"
                    }
                  >
                    {updating_message}
                  </span>
                </div>
              )}
            </div>
          )}
          <div className="confirm-text-lay">
            <span className="confirm-text">{`Confirm To Full Load Machine For All ${slots.length} Slots?`}</span>
          </div>
          <div className="confirm-btns-lay">
            <div
              className="cancel-btn action-red-btn"
              onClick={() => {
                setShowConfirmUpdateAllDialog(false);
                show_confirm_update_all_dialog_ref.current = false;
                history.replace({
                  pathname: history.location.pathname,
                  hash: "",
                });
              }}
            >
              <MdCancel className="confirm-icon action-red-icon" />
              <span className="confirm-text action-red-text">Cancel</span>
            </div>
            <div className="confirm-btn action-btn" onClick={updateAllSlots}>
              <FaCheckCircle className="confirm-icon action-icon" />
              <span className="confirm-text action-text">Confirm</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const confirmRebootMachineView = () => {
    return (
      <div
        className="confirm-all-lay"
        id="confirm-all-lay"
        onClick={(e) => {
          if (e.target === document.getElementById("confirm-all-lay")) {
            setShowConfirmRebootMachineDialog(false);
            show_confirm_reboot_machine_dialog_ref.current = false;
            history.replace({
              pathname: history.location.pathname,
              hash: "",
            });
          }
        }}
      >
        <div className="confirm-contents">
          <div className="confirm-text-lay">
            <span className="confirm-text">{`Are you sure you want to reboot this machine?`}</span>
          </div>
          <div className="confirm-btns-lay">
            <div
              className="cancel-btn action-red-btn"
              onClick={() => {
                setShowConfirmRebootMachineDialog(false);
                show_confirm_reboot_machine_dialog_ref.current = false;
                history.replace({
                  pathname: history.location.pathname,
                  hash: "",
                });
              }}
            >
              <MdCancel className="confirm-icon action-red-icon" />
              <span className="confirm-text action-red-text">Cancel</span>
            </div>
            <div
              className="confirm-btn action-btn"
              onClick={() => {
                if (user_allowed) {
                  sendSwitchCommand("reboot-device", 0);
                }
                setShowConfirmRebootMachineDialog(false);
                show_confirm_reboot_machine_dialog_ref.current = false;
                history.replace({
                  pathname: history.location.pathname,
                  hash: "",
                });
              }}
            >
              <FaCheckCircle className="confirm-icon action-icon" />
              <span className="confirm-text action-text">Yes Reboot</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const slotEditView = () => {
    return (
      <SlotEdit
        slot={selected_slot as Slot}
        user_id={props.user_id}
        device_id={props.device.id}
        device_username={props.device.username}
        curreny={props.device.currency}
        user_allowed={user_allowed}
        slot_refresh_id={selected_slot_refresh_id}
        proceed_update={selected_slot_proceed_update}
        onSlotUpdated={(slot: Slot) => onDeviceSlotUpdated(slot)}
        onRequestDeviceSlotCommand={() => onRequestDeviceSlotCommand()}
      />
    );
  };

  const settingsView = () => {
    return (
      <div
        className="setting-lay"
        id="setting-lay"
        onClick={(e) => {
          if (e.target === document.getElementById("setting-lay")) {
            if (is_updating) return;
            if (history.length > 1) {
              history.goBack();
            } else {
              history.push(`${Routes.Devices}/${props.device.username}`);
            }
          }
        }}
      >
        <div className="setting-contents">
          <div className="setting-title-lay">
            {user_allowed && (
              <button
                className="update-btn action-btn"
                onClick={onSettingSubmit}
              >
                <FaCheckCircle className="update-icon action-icon" />
                <span className="update-text action-text">Update</span>
              </button>
            )}
            <span className="setting-text">Device Settings</span>
            <FaTimes
              className="setting-close-btn"
              title="close"
              onClick={(e) => {
                e.preventDefault();
                if (history.length > 1) {
                  history.goBack();
                } else {
                  history.push(`${Routes.Devices}/${props.device.username}`);
                }
              }}
            />
          </div>
          {(is_updating || updating_message) && (
            <div className="setting-modal">
              {is_updating && (
                <div className="def-loading-lay">
                  <div className="def-loading-spinner" />
                  <span className="def-loading-text">Loading...</span>
                </div>
              )}
              {updating_message && (
                <div
                  className="def-dialog-lay"
                  onClick={(e) => {
                    e.preventDefault();
                    setUpdatingMessage(null);
                    setIsUpdatingError(false);
                    setIsUpdating(false);
                  }}
                >
                  {is_updating_error && (
                    <IoWarning className="def-dialog-icon error-icon" />
                  )}
                  {!is_updating_error && (
                    <FaCheckCircle className="def-dialog-icon" />
                  )}
                  <span
                    className={
                      is_updating_error
                        ? "def-dialog-text error-text"
                        : "def-dialog-text"
                    }
                  >
                    {updating_message}
                  </span>
                </div>
              )}
            </div>
          )}
          <div className="form-contents">
            <div className="form-lay first-lay">
              <div className="form-title-lay">
                <span className="form-title">Device Details</span>
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Device Name</span>
                <input
                  className="name-input input"
                  name="device_name"
                  value={input_name}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("name", e);
                  }}
                  type="text"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter device name"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Location Name</span>
                <input
                  className="location-input input"
                  name="device_location"
                  value={input_location_name}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("location", e);
                  }}
                  type="text"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter device location name"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Phone Number</span>
                <input
                  className="phone-input input"
                  name="device_phone_number"
                  value={input_phone_number}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("phone", e);
                  }}
                  type="phone"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter device phone number"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Currency</span>
                <input
                  className="currency-input input"
                  name="device_currency"
                  value={input_currency}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("currency", e);
                  }}
                  type="text"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter device currency"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Business Name</span>
                <input
                  className="business-input input"
                  name="company-name"
                  value={input_business_name}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("business", e);
                  }}
                  type="text"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter business name"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Business Country</span>
                <select
                  className="country-input select"
                  name="country"
                  value={input_country}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("country", e);
                  }}
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter business country"
                >
                  <option value="" className="search-option">
                    Select Country
                  </option>
                  {countryList.getNames().map((n: string, i: number) => {
                    return (
                      <option key={i} value={n} className="search-option">
                        {n}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Business Region</span>
                <input
                  className="region-input input"
                  name="region"
                  value={input_region}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("region", e);
                  }}
                  type="text"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter business region"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Business Address</span>
                <input
                  className="address-input input"
                  name="address"
                  value={input_address}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("address", e);
                  }}
                  type="text"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter business address"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Business TIN Number</span>
                <input
                  className="tin-input input"
                  name="tin-number"
                  value={input_tin_number}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("tin", e);
                  }}
                  type="text"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter business TIN number"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Business VRN Number</span>
                <input
                  className="vrn-input input"
                  name="vrn-number"
                  value={input_vrn_number}
                  onChange={(e) => {
                    if (!user_allowed) return;
                    onValueChange("vrn", e);
                  }}
                  type="text"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter business VRN number"
                />
              </div>
            </div>
            <div className="form-lay second-lay">
              <div className="form-title-lay">
                <span className="form-title">Video Ads</span>
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Video Ad no. 1</span>
                {user_allowed && (
                  <button
                    className="video-btn media-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const inputFileElement =
                        document.getElementById("video-input-file-1");
                      if (inputFileElement) {
                        inputFileElement.click();
                      }
                    }}
                  >
                    Select Video Advertisement No. 1 (optional)
                  </button>
                )}
                {user_allowed && (
                  <input
                    className="video-input file-input input"
                    hidden={true}
                    multiple={false}
                    type="file"
                    id="video-input-file-1"
                    accept="video/mp4, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g, video/mp4, video/mpeg, video/ogg, video/webm, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/x-flv, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g"
                    onChange={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const files: FileList | null = e.target.files;
                      if (files && files.length > 0) {
                        const file: File = files[0];
                        if (file) {
                          // make sure file is not null
                          const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
                          const actual_filesize = parseFloat(filesize);
                          if (actual_filesize <= 100) {
                            // make sure file size is less than 100 MB
                            const filePath = URL.createObjectURL(file);
                            setVideoSrc1(filePath);
                            setVideoFile1(
                              file
                            ); /* this will be updated on server storage*/
                            setPreviousVideoTitle1(
                              video_title_1
                            ); /* set previous_video_title to remove on server storage */
                          } else {
                            setUpdatingMessage(
                              "Video size should be less than 100 MB"
                            );
                          }
                        }
                      }
                    }}
                  />
                )}
                {video_src_1 && (
                  <div className="video-cont media-cont">
                    {user_allowed && (
                      <FaTimes
                        className="media-close-icon"
                        title={"remove video"}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!didMount.current) return;
                          setVideoSrc1(null);
                          setVideoFile1(null);
                          setPreviousVideoTitle1(
                            video_title_1
                          ); /* set previous_video_title to remove on server storage */
                        }}
                      />
                    )}
                    {/* <div className="media-top" /> */}
                    <video
                      className="device-media"
                      src={video_src_1}
                      controls={true}
                      autoPlay={false}
                    />
                  </div>
                )}
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Video Ad no. 2</span>
                {user_allowed && (
                  <button
                    className="video-btn media-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const inputFileElement =
                        document.getElementById("video-input-file-2");
                      if (inputFileElement) {
                        inputFileElement.click();
                      }
                    }}
                  >
                    Select Video Advertisement No. 2 (optional)
                  </button>
                )}
                {user_allowed && (
                  <input
                    className="video-input file-input input"
                    hidden={true}
                    multiple={false}
                    type="file"
                    id="video-input-file-2"
                    accept="video/mp4, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g, video/mp4, video/mpeg, video/ogg, video/webm, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/x-flv, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g"
                    onChange={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const files: FileList | null = e.target.files;
                      if (files && files.length > 0) {
                        const file: File = files[0];
                        if (file) {
                          // make sure file is not null
                          const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
                          const actual_filesize = parseFloat(filesize);
                          if (actual_filesize <= 100) {
                            // make sure file size is less than 100 MB
                            const filePath = URL.createObjectURL(file);
                            setVideoSrc2(filePath);
                            setVideoFile2(
                              file
                            ); /* this will be updated on server storage*/
                            setPreviousVideoTitle2(
                              video_title_2
                            ); /* set previous_video_title to remove on server storage */
                          } else {
                            setUpdatingMessage(
                              "Video size should be less than 100 MB"
                            );
                          }
                        }
                      }
                    }}
                  />
                )}
                {video_src_2 && (
                  <div className="video-cont media-cont">
                    {user_allowed && (
                      <FaTimes
                        className="media-close-icon"
                        title={"remove video"}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!didMount.current) return;
                          setVideoSrc2(null);
                          setVideoFile2(null);
                          setPreviousVideoTitle2(
                            video_title_2
                          ); /* set previous_video_title to remove on server storage */
                        }}
                      />
                    )}
                    {/* <div className="media-top" /> */}
                    <video
                      className="device-media"
                      src={video_src_2}
                      controls={true}
                      autoPlay={false}
                    />
                  </div>
                )}
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Video Ad no. 3</span>
                {user_allowed && (
                  <button
                    className="video-btn media-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const inputFileElement =
                        document.getElementById("video-input-file-3");
                      if (inputFileElement) {
                        inputFileElement.click();
                      }
                    }}
                  >
                    Select Video Advertisement No. 3 (optional)
                  </button>
                )}
                {user_allowed && (
                  <input
                    className="video-input file-input input"
                    hidden={true}
                    multiple={false}
                    type="file"
                    id="video-input-file-3"
                    accept="video/mp4, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g, video/mp4, video/mpeg, video/ogg, video/webm, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/x-flv, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g"
                    onChange={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const files: FileList | null = e.target.files;
                      if (files && files.length > 0) {
                        const file: File = files[0];
                        if (file) {
                          // make sure file is not null
                          const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
                          const actual_filesize = parseFloat(filesize);
                          if (actual_filesize <= 100) {
                            // make sure file size is less than 100 MB
                            const filePath = URL.createObjectURL(file);
                            setVideoSrc3(filePath);
                            setVideoFile3(
                              file
                            ); /* this will be updated on server storage*/
                            setPreviousVideoTitle3(
                              video_title_3
                            ); /* set previous_video_title to remove on server storage */
                          } else {
                            setUpdatingMessage(
                              "Video size should be less than 100 MB"
                            );
                          }
                        }
                      }
                    }}
                  />
                )}
                {video_src_3 && (
                  <div className="video-cont media-cont">
                    {user_allowed && (
                      <FaTimes
                        className="media-close-icon"
                        title={"remove video"}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!didMount.current) return;
                          setVideoSrc3(null);
                          setVideoFile3(
                            null
                          ); /* this will be updated on server storage*/
                          setPreviousVideoTitle3(
                            video_title_3
                          ); /* set previous_video_title to remove on server storage */
                        }}
                      />
                    )}
                    {/* <div className="media-top" /> */}
                    <video
                      className="device-media"
                      src={video_src_3}
                      controls={true}
                      autoPlay={false}
                    />
                  </div>
                )}
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Video Ad no. 4</span>
                {user_allowed && (
                  <button
                    className="video-btn media-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const inputFileElement =
                        document.getElementById("video-input-file-4");
                      if (inputFileElement) {
                        inputFileElement.click();
                      }
                    }}
                  >
                    Select Video Advertisement No. 4 (optional)
                  </button>
                )}
                {user_allowed && (
                  <input
                    className="video-input file-input input"
                    hidden={true}
                    multiple={false}
                    type="file"
                    id="video-input-file-4"
                    accept="video/mp4, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g, video/mp4, video/mpeg, video/ogg, video/webm, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/x-flv, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g"
                    onChange={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const files: FileList | null = e.target.files;
                      if (files && files.length > 0) {
                        const file: File = files[0];
                        if (file) {
                          // make sure file is not null
                          const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
                          const actual_filesize = parseFloat(filesize);
                          if (actual_filesize <= 100) {
                            // make sure file size is less than 100 MB
                            const filePath = URL.createObjectURL(file);
                            setVideoSrc4(filePath);
                            setVideoFile4(
                              file
                            ); /* this will be updated on server storage*/
                            setPreviousVideoTitle4(
                              video_title_4
                            ); /* set previous_video_title to remove on server storage */
                          } else {
                            setUpdatingMessage(
                              "Video size should be less than 100 MB"
                            );
                          }
                        }
                      }
                    }}
                  />
                )}
                {video_src_4 && (
                  <div className="video-cont media-cont">
                    {user_allowed && (
                      <FaTimes
                        className="media-close-icon"
                        title={"remove video"}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!didMount.current) return;
                          setVideoSrc4(null);
                          setVideoFile4(
                            null
                          ); /* this will be updated on server storage*/
                          setPreviousVideoTitle4(
                            video_title_4
                          ); /* set previous_video_title to remove on server storage */
                        }}
                      />
                    )}
                    {/* <div className="media-top" /> */}
                    <video
                      className="device-media"
                      src={video_src_4}
                      controls={true}
                      autoPlay={false}
                    />
                  </div>
                )}
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Video Ad no. 5</span>
                {user_allowed && (
                  <button
                    className="video-btn media-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const inputFileElement =
                        document.getElementById("video-input-file-5");
                      if (inputFileElement) {
                        inputFileElement.click();
                      }
                    }}
                  >
                    Select Video Advertisement No. 5 (optional)
                  </button>
                )}
                {user_allowed && (
                  <input
                    className="video-input file-input input"
                    hidden={true}
                    multiple={false}
                    type="file"
                    id="video-input-file-5"
                    accept="video/mp4, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g, video/mp4, video/mpeg, video/ogg, video/webm, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/x-flv, video/3gpp, video/3gpp2, video/3gp2, video/3g2, video/3gp, video/3g"
                    onChange={(e) => {
                      e.preventDefault();
                      if (!didMount.current) return;
                      const files: FileList | null = e.target.files;
                      if (files && files.length > 0) {
                        const file: File = files[0];
                        if (file) {
                          // make sure file is not null
                          const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
                          const actual_filesize = parseFloat(filesize);
                          if (actual_filesize <= 100) {
                            // make sure file size is less than 100 MB
                            const filePath = URL.createObjectURL(file);
                            setVideoSrc5(filePath);
                            setVideoFile5(
                              file
                            ); /* this will be updated on server storage*/
                            setPreviousVideoTitle5(
                              video_title_5
                            ); /* set previous_video_title to remove on server storage */
                          } else {
                            setUpdatingMessage(
                              "Video size should be less than 100 MB"
                            );
                          }
                        }
                      }
                    }}
                  />
                )}
                {video_src_5 && (
                  <div className="video-cont media-cont">
                    {user_allowed && (
                      <FaTimes
                        className="media-close-icon"
                        title={"remove video"}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!didMount.current) return;
                          setVideoSrc5(null);
                          setVideoFile5(
                            null
                          ); /* this will be updated on server storage*/
                          setPreviousVideoTitle5(
                            video_title_5
                          ); /* set previous_video_title to remove on server storage */
                        }}
                      />
                    )}
                    {/* <div className="media-top" /> */}
                    <video
                      className="device-media"
                      src={video_src_5}
                      controls={true}
                      autoPlay={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {show_confirm_update_all_dialog &&
        history.location.hash.includes("dialog") &&
        confirmAllView()}
      {show_confirm_reboot_machine_dialog &&
        history.location.hash.includes("dialog") &&
        confirmRebootMachineView()}
      {selected_slot &&
        history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/slots/${selected_slot.slot_number}`
        ) &&
        slotEditView()}
      {history.location.pathname.startsWith(
        `${Routes.Devices}/${props.device.username}/settings`
      ) && settingsView()}
      {show_modal && (
        <div
          className="device-modal"
          // id={'device-modal-' + props.device.id}
          ref={device_modal_ref}
          onClick={() => {
            if (!didMount.current) {
              return;
            }
            setShowModal(false);
          }}
        />
      )}
      <div className="tab-item-header">
        <span className="tab-item-header-text">{props.device.name}</span>
        <div className="tab-item-header-right">
          {props.is_device_active && (
            <HiStatusOnline
              className="tab-item-header-right-icon online"
              title="online"
            />
          )}
          {!props.is_device_active && (
            <HiOutlineStatusOffline
              className="tab-item-header-right-icon offline"
              title="offline"
            />
          )}
          <button
            className="tab-item-header-right-btn action-btn"
            onClick={onRefreshClick}
          >
            <MdRefresh className="tab-item-header-right-btn-icon action-icon" />
            <span className="tab-item-header-right-btn-text action-text">
              Refresh
            </span>
          </button>
        </div>
      </div>
      <div
        className={`tab-item-body${
          history.location.pathname.startsWith(
            `${Routes.Devices}/${props.device.username}/slots`
          ) ||
          history.location.pathname.startsWith(
            `${Routes.Devices}/${props.device.username}/activities`
          ) ||
          history.location.pathname.startsWith(
            `${Routes.Devices}/${props.device.username}/logs`
          ) ||
          history.location.pathname.startsWith(
            `${Routes.Devices}/${props.device.username}/orders`
          ) ||
          history.location.pathname.startsWith(
            `${Routes.Devices}/${props.device.username}/receipts`
          )
            ? " with-footer"
            : ""
        }`}
        ref={scroller}
      >
        <div className="info-lay">
          <div className="info-contents blue-grad">
            {is_initial_fetching && (
              <div className="device-detail-item-loader loading-shimmer" />
            )}
            <div className="info-left-lay temp-grad">
              <div
                className="left-bg"
                style={{
                  backgroundImage: `url(${require("../../../../assets/images/moran-vending-machine.png")})`,
                }}
              />
              <div className="left-main-lay">
                <RiDeviceFill className="device-icon" />
                <span className="device-username-text">
                  {props.device.username}
                </span>
                <Link
                  to={`${Routes.Devices}/${props.device.username}/settings`}
                  className="edit-btn action-btn"
                >
                  <MdSettings className="edit-icon action-icon" />
                  <span className="edit-text action-text">Settings</span>
                </Link>
              </div>
            </div>
            <div className="info-right-lay">
              <div className="main-info-lay">
                <div className="info-right-item">
                  <FaInfoCircle className="info-icon" />
                  <span className="name-text" title="Device Identification">
                    {props.device.id}
                  </span>
                </div>
                <div className="info-right-item">
                  <MdLocationOn className="info-icon" />
                  <span className="name-text" title="Device Location">
                    {props.device.location_name}
                  </span>
                </div>
                <div className="info-right-item">
                  <RiCurrencyFill className="info-icon" />
                  <span className="name-text" title="Currency">
                    {props.device.currency}
                  </span>
                </div>
                <div className="info-right-item">
                  <FaPhoneAlt className="info-icon" />
                  <span
                    className="name-text"
                    title="Device Customer Service Phone Number"
                  >
                    {props.device.phone_number}
                  </span>
                </div>
                <div className="info-right-item">
                  <MdAccessTimeFilled className="info-icon" />
                  <span className="name-text" title="Date Created">
                    {date_created}
                  </span>
                </div>
                <div className="info-right-item">
                  <MdUpdate className="info-icon" />
                  <span className="name-text" title="Last Updated">
                    {date_updated}
                  </span>
                </div>
              </div>
              <div className="main-info-lay with-full-lay">
                <div className="info-right-item">
                  <Link
                    to={Routes.Shop + "/" + props.device.username}
                    className="info-btn"
                  >
                    <HiShoppingCart className="info-icon" />
                    <span className="name-text">Shop</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="device-tabs-header">
          <Link
            className="device-tabs-header-item"
            to={Routes.Devices + "/" + props.device.username}
          >
            <IoRadio className="device-tabs-header-item-icon" />
            <span className="device-tabs-header-item-text">Actions</span>
            {(history.location.pathname ===
              `${Routes.Devices}/${props.device.username}` ||
              history.location.pathname ===
                `${Routes.Devices}/${props.device.username}/` ||
              history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/settings`
              )) && <div className="device-tabs-header-item-active" />}
          </Link>
          <Link
            className="device-tabs-header-item"
            to={Routes.Devices + "/" + props.device.username + "/slots"}
          >
            <IoApps className="device-tabs-header-item-icon" />
            <span className="device-tabs-header-item-text">Slots</span>
            {history.location.pathname.startsWith(
              `${Routes.Devices}/${props.device.username}/slots`
            ) && <div className="device-tabs-header-item-active" />}
          </Link>
          <Link
            className="device-tabs-header-item"
            to={Routes.Devices + "/" + props.device.username + "/activities"}
          >
            <FiActivity className="device-tabs-header-item-icon" />
            <span className="device-tabs-header-item-text">Activities</span>
            {history.location.pathname.startsWith(
              `${Routes.Devices}/${props.device.username}/activities`
            ) && <div className="device-tabs-header-item-active" />}
          </Link>
          <Link
            className="device-tabs-header-item"
            to={Routes.Devices + "/" + props.device.username + "/logs"}
          >
            <FaListUl className="device-tabs-header-item-icon" />
            <span className="device-tabs-header-item-text">Logs</span>
            {history.location.pathname.startsWith(
              `${Routes.Devices}/${props.device.username}/logs`
            ) && <div className="device-tabs-header-item-active" />}
          </Link>
          <Link
            className="device-tabs-header-item"
            to={Routes.Devices + "/" + props.device.username + "/orders"}
          >
            <BsCartCheckFill className="device-tabs-header-item-icon" />
            <span className="device-tabs-header-item-text">Orders</span>
            {history.location.pathname.startsWith(
              `${Routes.Devices}/${props.device.username}/orders`
            ) && <div className="device-tabs-header-item-active" />}
          </Link>
          <Link
            className="device-tabs-header-item"
            to={Routes.Devices + "/" + props.device.username + "/receipts"}
          >
            <FaReceipt className="device-tabs-header-item-icon" />
            <span className="device-tabs-header-item-text">Receipts</span>
            {history.location.pathname.startsWith(
              `${Routes.Devices}/${props.device.username}/receipts`
            ) && <div className="device-tabs-header-item-active" />}
          </Link>
        </div>
        <div className="device-tabs-lay">
          <div
            className="device-tab-item"
            style={{
              display:
                history.location.pathname ===
                  `${Routes.Devices}/${props.device.username}` ||
                history.location.pathname ===
                  `${Routes.Devices}/${props.device.username}/` ||
                history.location.pathname.startsWith(
                  `${Routes.Devices}/${props.device.username}/settings`
                )
                  ? "flex"
                  : "none",
            }}
          >
            <ActionsView
              user_allowed={user_allowed}
              is_initial_fetching={is_initial_fetching}
              is_sending_switch_cmd={is_sending_switch_cmd}
              sending_cmd_message={sending_cmd_message}
              is_sending_switch_cmd_error={is_sending_switch_cmd_error}
              socket_dev_activity={props.socket_dev_activity}
              setSendingCmdMessage={(message: string | null) => {
                setSendingCmdMessage(message);
              }}
              setIsSendingSwitchCmdError={(is_error: boolean) => {
                setIsSendingSwitchCmdError(is_error);
              }}
              setIsSendingSwitchCmd={(is_sending: boolean) => {
                setIsSendingSwitchCmd(is_sending);
              }}
              sendSwitchCommand={sendSwitchCommand}
              onRebootMachineClick={() => {
                if (!user_allowed) return;
                setShowConfirmRebootMachineDialog(true);
                show_confirm_reboot_machine_dialog_ref.current = true;
                history.push({
                  pathname: history.location.pathname,
                  hash: "dialog",
                });
              }}
            />
          </div>
          <div
            className="device-tab-item"
            style={{
              display: history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/slots`
              )
                ? "flex"
                : "none",
            }}
          >
            <SlotsView
              device={props.device}
              is_initial_fetching={is_initial_fetching}
              slots={slots}
              user_allowed={user_allowed}
              // total_stock={total_stock}
              // total_price={total_price}
              // setShowConfirmUpdateAllDialog={(show: boolean) => {
              //   setShowConfirmUpdateAllDialog(show);
              //   show_confirm_update_all_dialog_ref.current = show;
              //   history.push({
              //     pathname: history.location.pathname,
              //     hash: 'dialog'
              //   });
              // }}
              setSelectedSlot={(slot) => {
                setSelectedSlot(slot);
              }}
            />
          </div>
          <div
            className="device-tab-item"
            style={{
              display: history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/activities`
              )
                ? "flex"
                : "none",
            }}
          >
            <ActivitiesTab
              scroller={scroller.current as HTMLElement}
              device={props.device}
              user_data={props.user_data}
              refreshId={actsRefreshId}
              show={history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/activities`
              )}
              export_id={actsExportId}
              onListChanged={(size) => setActsListSize(size)}
            />
          </div>
          <div
            className="device-tab-item"
            style={{
              display: history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/logs`
              )
                ? "flex"
                : "none",
            }}
          >
            <LogsTab
              scroller={scroller.current as HTMLElement}
              device={props.device}
              user_data={props.user_data}
              refreshId={logsRefreshId}
              show={history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/logs`
              )}
              onListChanged={(size) => setLogsListSize(size)}
            />
          </div>
          <div
            className="device-tab-item"
            style={{
              display: history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/orders`
              )
                ? "flex"
                : "none",
            }}
          >
            <OrdersTab
              scroller={scroller.current as HTMLElement}
              device={props.device}
              user_data={props.user_data}
              refreshId={ordersRefreshId}
              show={history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/orders`
              )}
              export_id={ordersExportId}
              onListChanged={(size) => setOrdersListSize(size)}
              onQuantityChanged={(size) => setOrdersQuantitySize(size)}
              onPriceChanged={(size) => setOrderPriceSize(size)}
            />
          </div>
        </div>
        {/* <div className='empty-body-footer' /> */}
      </div>
      <div
        className={`tab-item-footer${
          history.location.pathname.startsWith(
            `${Routes.Devices}/${props.device.username}/slots`
          )
            ? " slots-footer"
            : history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/activities`
              )
            ? " activities-footer"
            : history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/logs`
              )
            ? " logs-footer"
            : history.location.pathname.startsWith(
                `${Routes.Devices}/${props.device.username}/orders`
              )
            ? " orders-footer"
            : ""
        }`}
        style={{
          display:
            history.location.pathname.startsWith(
              `${Routes.Devices}/${props.device.username}/slots`
            ) ||
            history.location.pathname.startsWith(
              `${Routes.Devices}/${props.device.username}/activities`
            ) ||
            history.location.pathname.startsWith(
              `${Routes.Devices}/${props.device.username}/logs`
            ) ||
            history.location.pathname.startsWith(
              `${Routes.Devices}/${props.device.username}/orders`
            )
              ? "flex"
              : "none",
        }}
      >
        {history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/slots`
        ) && (
          <>
            <div className="tab-item-footer-left">
              <div className="count-lay">
                <span className="count-lay-text">
                  {FormatterUtil.formatNumber(slots.length)}
                </span>
                <span className="count-lay-title">
                  {slots.length === 1 ? "Slot" : "Slots"}
                </span>
              </div>
              <div className="count-lay">
                <span className="count-lay-text">
                  {FormatterUtil.formatNumber(total_stock)}
                </span>
                <span className="count-lay-title">{"In Stock"}</span>
              </div>
              <div className="count-lay">
                <span className="count-lay-text">
                  {FormatterUtil.formatNumber(
                    total_price /*.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')*/
                  ) +
                    " " +
                    props.device.currency}
                </span>
                <span className="count-lay-title">{"Total Price"}</span>
              </div>
            </div>
            <div className="tab-item-footer-right">
              <button
                className="export-btn action-btn"
                onClick={() => {
                  setShowConfirmUpdateAllDialog(true);
                  show_confirm_update_all_dialog_ref.current = true;
                  history.push({
                    pathname: history.location.pathname,
                    hash: "dialog",
                  });
                }}
              >
                <FaUpload className="action-icon" />
                <span className="action-text">Load</span>
              </button>
            </div>
          </>
        )}
        {history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/activities`
        ) && (
          <>
            <div className="tab-item-footer-left">
              <div className="count-lay">
                <span className="count-lay-text">
                  {FormatterUtil.formatNumber(actsListSize)}
                </span>
                <span className="count-lay-title">
                  {actsListSize === 1 ? "Activity" : "Activities"}
                </span>
              </div>
            </div>
            <div className="tab-item-footer-right">
              {actsListSize > 0 && (
                <button
                  className="export-btn action-btn"
                  onClick={() => {
                    setActsExportId(actsExportId + 1);
                  }}
                >
                  <BiExport className="action-icon" />
                  <span className="action-text">Export</span>
                </button>
              )}
            </div>
          </>
        )}
        {history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/logs`
        ) && (
          <>
            <div className="tab-item-footer-left">
              <div className="count-lay">
                <span className="count-lay-text">
                  {FormatterUtil.formatNumber(logsListSize)}
                </span>
                <span className="count-lay-title">
                  {logsListSize === 1 ? "Log" : "Logs"}
                </span>
              </div>
            </div>
          </>
        )}
        {history.location.pathname.startsWith(
          `${Routes.Devices}/${props.device.username}/orders`
        ) && (
          <>
            <div className="tab-item-footer-left">
              <div className="count-lay">
                <span className="count-lay-text">
                  {FormatterUtil.formatNumber(ordersListSize)}
                </span>
                <span className="count-lay-title">
                  {ordersListSize === 1 ? "Order" : "Orders"}
                </span>
              </div>
              <div className="count-lay">
                <span className="count-lay-text">
                  {FormatterUtil.formatNumber(ordersQuantitySize)}
                </span>
                <span className="count-lay-title">
                  {ordersQuantitySize === 1 ? "Quantity" : "Quantities"}
                </span>
              </div>
              <div className="count-lay">
                <span className="count-lay-text">
                  {FormatterUtil.formatNumber(orderPriceSize) +
                    " " +
                    props.device.currency}
                </span>
                <span className="count-lay-title">{"Price"}</span>
                {/* <span className='count-lay-title'>{total_prices === 1 ? 'Price' : 'Prices'}</span> */}
              </div>
            </div>
            <div className="tab-item-footer-right">
              {ordersListSize > 0 && (
                <button
                  className="export-btn action-btn"
                  onClick={() => {
                    setOrdersExportId(ordersExportId + 1);
                  }}
                >
                  <BiExport className="action-icon" />
                  <span className="action-text">Export</span>
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DeviceItem;
