import "./index.scss";
import React from "react";
import {
  Device,
  DeviceCommand,
  DeviceGroup,
  SocketUserDevice,
  UserData,
  UserDevice,
  WsDeviceActivity,
} from "../../models";
import { HiOutlineStatusOffline, HiStatusOnline } from "react-icons/hi";

import DeviceItem from "./device";
import { Link } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import { RiDeviceFill } from "react-icons/ri";
import Routes from "../../../utils/routes";
import moment from "moment";

type Props = {
  current_page: string;
  devices_loading: boolean;
  device_groups: DeviceGroup[];
  error_loading: string | null;
  socket_devices: SocketUserDevice[];
  devs_activities: WsDeviceActivity[];
  user_data: UserData;
  user_id: string;
  onDeviceUpdated: (device: UserDevice) => void;
  sendDeviceCommand: (
    device_id: string,
    command: DeviceCommand,
    value: number,
    request_code: number
  ) => void;
  onReloadClick: () => void;
};

const DevicesTab = (props: Props) => {
  return (
    <div
      className="tab-lay"
      style={{
        display: props.current_page.startsWith(Routes.Devices)
          ? "flex"
          : "none",
      }}
    >
      <div className="tab-list">
        <div className="tab-list-header">
          <span className="tab-list-header-text">All Devices</span>
        </div>
        <div className="tab-list-body">
          {props.devices_loading && (
            <>
              {/* <i className="fa-li fa fa-spinner fa-spin loading-icon"></i> */}
              <div className="device-item-loader loading-shimmer" />
              <div className="device-item-loader loading-shimmer" />
              <div className="device-item-loader loading-shimmer" />
              <div className="device-item-loader loading-shimmer" />
              <div className="device-item-loader loading-shimmer" />
              <div className="device-item-loader loading-shimmer" />
              <div className="device-item-loader loading-shimmer" />
              <div className="device-item-loader loading-shimmer" />
            </>
          )}
          {props.error_loading && (
            <p
              className="message-box"
              onClick={(e) => {
                e.preventDefault();
                props.onReloadClick();
              }}
            >
              {props.error_loading}
            </p>
          )}
          {props.device_groups.map((g: DeviceGroup, index: number) => {
            return (
              <div className="devices-location-cont" key={index}>
                <div className="devices-location-title-lay">
                  <div className="lines-lay">
                    <div className="vert-2 lines" />
                    <div className="horz lines" />
                  </div>
                  <p className="devices-location-title">{g.location_name}</p>
                </div>
                {g.devices.map((d: Device, i: number, a: Device[]) => {
                  return (
                    <div className="device-item" key={i}>
                      <div className="lines-lay">
                        <div className="vert-1 lines" />
                        <div
                          className="vert-2 lines"
                          style={{
                            display: a.length - 1 === i ? "none" : "block",
                          }}
                        />
                        <div className="horz lines" />
                      </div>
                      <Link
                        to={Routes.Devices + "/" + d.username}
                        className={
                          props.current_page.startsWith(
                            Routes.Devices + "/" + d.username
                          )
                            ? "device-item-btn device-item-selected device-" +
                              d.username +
                              ""
                            : "device-item-btn device-item-normal device-" +
                              d.username +
                              ""
                        }
                      >
                        <div className="item-top-lay">
                          <RiDeviceFill className="item-icon" />
                          <span className="item-text">{d.name}</span>
                        </div>
                        <div className="item-sec-lay">
                          <MdRefresh className="item-icon" />
                          <span className="item-text">
                            {moment(d.last_updated).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </span>
                        </div>
                        {props.socket_devices.find(
                          (sk, _index) => sk.device_id === d.id
                        )?.online && (
                          <HiStatusOnline
                            className="item-btm-icon online"
                            title="online"
                          />
                        )}
                        {!props.socket_devices.find(
                          (sk, _index) => sk.device_id === d.id
                        )?.online && (
                          <HiOutlineStatusOffline
                            className="item-btm-icon offline"
                            title="offline"
                          />
                        )}
                      </Link>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`tab-details${
          props.current_page.startsWith(Routes.Devices + "/") &&
          props.current_page.length > 9
            ? " tab-details-with-item"
            : ""
        }`}
      >
        {(props.current_page === Routes.Devices ||
          props.current_page === Routes.Devices + "/") && (
          <div className="tab-item-empty-lay">
            <span className="tab-item-empty-text">Select device to view</span>
          </div>
        )}
        {props.user_data &&
          props.user_data.devices.map((d, i) => {
            // /devices/:device_id
            return (
              <div
                className="tab-item-main"
                key={"tab-item-main-" + i}
                id={"tab-item-main-" + d.username}
                style={{
                  display: props.current_page.startsWith(
                    `${Routes.Devices}/${d.username}`
                  )
                    ? "flex"
                    : "none",
                }}
              >
                {props.user_data &&
                  props.user_id &&
                  (d.shown ||
                    props.current_page.startsWith(
                      `${Routes.Devices}/${d.username}`
                    )) && (
                    <DeviceItem
                      current_page={props.current_page}
                      user_id={props.user_id}
                      device={d}
                      user_data={props.user_data}
                      is_device_active={
                        props.socket_devices.find(
                          (sk, _index) => sk.device_id === d.id
                        )?.online
                      }
                      socket_dev_activity={props.devs_activities.find(
                        (act) => act.device_id === d.id
                      )}
                      onDeviceUpdated={(device) =>
                        props.onDeviceUpdated(device)
                      }
                      onRequestDeviceCommand={(
                        device_id: string,
                        command: DeviceCommand,
                        value: number,
                        request_code: number
                      ) =>
                        props.sendDeviceCommand(
                          device_id,
                          command,
                          value,
                          request_code
                        )
                      }
                    />
                  )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DevicesTab;
