import "./index.scss";
import React from "react";
import { Device, UserData, UserDevice } from "../../../models";
import { FaCheckCircle, FaEdit, FaTimes } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useReducer, useRef, useState } from "react";

import DatabaseManager from "../../../../service";
import { IoWarning } from "react-icons/io5";
import { MdSettings } from "react-icons/md";
import { RiDeviceFill } from "react-icons/ri";
import Routes from "../../../../utils/routes";
import moment from "moment";

type Props = {
  current_page: string;
  user_data: UserData;
  // mutual_devices: MutualDevice[];
  // mutual_devices_loading: boolean;
  // error_loading_mutual: string | null;
  // show: boolean;
  // onReloadMutualDevices: () => void;
  onAccountNameUpdated: (name: string) => void;
  onLogOutBtnClick: () => void;
};

const ProfileTab = (props: Props) => {
  const history = useHistory();
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const avatar: string = require("../../../../assets/images/avatar.png");

  const databaseManager: DatabaseManager = new DatabaseManager();

  const [updating, setUpdating] = useState(false);

  const [input_name, setInputName] = useState(props.user_data.name);
  const [input_old_password, setInputOldPassword] = useState("");
  const [input_password, setInputPassword] = useState("");
  const [input_password_2, setInputPassword2] = useState("");

  const [is_updating, setIsUpdating] = useState(false);
  const [updating_message, setUpdatingMessage] = useState<string | null>(null);
  const [is_updating_error, setIsUpdatingError] = useState(false);

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 27 || e.key === "Escape") {
      if (!didMount.current) return;
      if (is_updating) return;
      if (
        history.location.pathname.startsWith(`${Routes.Account}/profile/edit`)
      ) {
        if (history.length > 1) {
          history.goBack();
        } else {
          history.push(`${Routes.Account}/profile`);
        }
      }
    }
  };

  useEffect(() => {
    didMount.current = true;

    // sort devices by last_update
    props.user_data.devices.sort((a: Device, b: Device) => {
      return moment(b.last_updated).diff(moment(a.last_updated));
    });
    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      didMount.current = false;
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);

  useEffect(() => {
    if (!didMount.current) return;
    props.user_data.devices.sort((a: Device, b: Device) => {
      return moment(b.last_updated).diff(moment(a.last_updated));
    });
  }, [props]);

  useEffect(() => {
    if (!didMount.current) return;
    if (
      history.location.pathname === Routes.Account ||
      history.location.pathname === Routes.Account + "/"
    ) {
      if (is_updating) return;
      setInputName(props.user_data.name);
      setInputOldPassword("");
      setInputPassword("");
      setInputPassword2("");
    }
  }, [history.location.pathname]);

  const onValueChange = (
    type: "name" | "old_psk" | "new_psk" | "new_psk2",
    e: any
  ) => {
    e.preventDefault();
    if (!didMount.current) return;
    if (type === "name") {
      setInputName(e.target.value);
    } else if (type === "old_psk") {
      setInputOldPassword(e.target.value);
    } else if (type === "new_psk") {
      setInputPassword(e.target.value);
    } else if (type === "new_psk2") {
      setInputPassword2(e.target.value);
    }
    forceUpdate();
  };

  const onSubmitName = () => {
    if (input_name === "") {
      setUpdatingMessage("Account name is required.");
      setIsUpdatingError(true);
      return;
    }

    setIsUpdating(true);
    setIsUpdatingError(false);

    databaseManager.changeName(props.user_data.id, input_name, {
      onResult: (result) => {
        if (!didMount.current) {
          return;
        }
        setIsUpdating(false);
        if (result && result.success) {
          setIsUpdating(false);
          setUpdatingMessage("Account name updated.");
          setIsUpdatingError(false);
          setTimeout(() => {
            if (!didMount.current) {
              return;
            }
            setIsUpdating(false);
            setUpdatingMessage(null);
            setIsUpdatingError(false);
            //
            props.onAccountNameUpdated(input_name);
          }, 1000);
        } else if (result && result.error && result.error.message) {
          setIsUpdating(false);
          setUpdatingMessage(result.error.message);
          setIsUpdatingError(true);
        } else {
          setIsUpdating(false);
          setUpdatingMessage(
            "An error occured while updating account. Please try again."
          );
          setIsUpdatingError(true);
        }
      },
      onError: () => {
        if (!didMount.current) {
          return;
        }
        setIsUpdating(false);
        setUpdatingMessage(
          "An error occured while updating account. Please try again."
        );
        setIsUpdatingError(true);
      },
    });
  };

  const onSubmitPassword = () => {
    if (
      input_old_password === "" ||
      input_old_password.length < 5 ||
      input_password === "" ||
      input_password.length < 5 ||
      input_password_2 === "" ||
      input_password_2.length < 5
    ) {
      setUpdatingMessage("Enter valid password");
      setIsUpdatingError(true);
      return;
    }

    if (input_password === input_old_password) {
      setUpdatingMessage("New password must be different from old one");
      setIsUpdatingError(true);
      return;
    }

    if (input_password != input_password_2) {
      setUpdatingMessage("Passwords does not match");
      setIsUpdatingError(true);
      return;
    }

    setIsUpdating(true);
    setIsUpdatingError(false);

    databaseManager.changePassword(
      props.user_data.id,
      input_old_password,
      input_password,
      {
        onResult: (result) => {
          if (!didMount.current) {
            return;
          }
          setIsUpdating(false);
          if (result && result.success) {
            setIsUpdating(false);
            setUpdatingMessage(
              "Account's password updated. You need to re-login now."
            );
            setIsUpdatingError(false);
            setTimeout(() => {
              if (!didMount.current) {
                return;
              }
              setIsUpdating(false);
              setUpdatingMessage(null);
              setIsUpdatingError(false);
              //
              // props.onAccountNameUpdated(input_name);
              props.onLogOutBtnClick();
            }, 1000);
          } else if (result && result.error && result.error.message) {
            setIsUpdating(false);
            setUpdatingMessage(result.error.message);
            setIsUpdatingError(true);
          } else {
            setIsUpdating(false);
            setUpdatingMessage(
              "An error occured while updating account. Please try again."
            );
            setIsUpdatingError(true);
          }
        },
        onError: () => {
          if (!didMount.current) {
            return;
          }
          setIsUpdating(false);
          setUpdatingMessage(
            "An error occured while updating account. Please try again."
          );
          setIsUpdatingError(true);
        },
      }
    );
  };

  const editAccountView = () => {
    return (
      <div
        className="edit-profile-lay"
        id="edit-profile-lay"
        onClick={(e) => {
          if (e.target === document.getElementById("edit-profile-lay")) {
            if (is_updating) return;
            setInputName(props.user_data.name);
            setInputOldPassword("");
            setInputPassword("");
            setInputPassword2("");
            // initialDialogState();
            if (history.length > 1) {
              history.goBack();
            } else {
              history.push(Routes.Account + "/profile");
            }
          }
        }}
      >
        <div className="edit-profile-contents">
          <div className="edit-profile-title-lay">
            <span className="edit-profile-title-text">{`Edit Account`}</span>
            <FaTimes
              className="edit-profile-close-btn"
              title="close"
              onClick={(e) => {
                e.preventDefault();
                if (is_updating) return;
                setInputName(props.user_data.name);
                setInputOldPassword("");
                setInputPassword("");
                setInputPassword2("");
                if (history.length > 1) {
                  history.goBack();
                } else {
                  history.push(Routes.Account);
                }
              }}
            />
          </div>
          {(is_updating || updating_message) && (
            <div className="edit-profile-modal">
              {is_updating && (
                <div className="def-loading-lay">
                  <div className="def-loading-spinner" />
                  <span className="def-loading-text">Loading...</span>
                </div>
              )}
              {updating_message && (
                <div
                  className="def-dialog-lay"
                  onClick={(e) => {
                    e.preventDefault();
                    setUpdatingMessage(null);
                    setIsUpdatingError(false);
                    setIsUpdating(false);
                  }}
                >
                  {is_updating_error && (
                    <IoWarning className="def-dialog-icon error-icon" />
                  )}
                  {!is_updating_error && (
                    <FaCheckCircle className="def-dialog-icon" />
                  )}
                  <span
                    className={
                      is_updating_error
                        ? "def-dialog-text error-text"
                        : "def-dialog-text"
                    }
                  >
                    {updating_message}
                  </span>
                </div>
              )}
            </div>
          )}
          <div className="form-contents">
            <div className="form-lay">
              <div className="input-field-lay">
                <span className="input-field-text">Account Name</span>
                <input
                  className="input"
                  value={input_name}
                  onChange={(e) => {
                    onValueChange("name", e);
                  }}
                  type="text"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter your name"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Email</span>
                <input
                  className="input"
                  value={props.user_data.email}
                  type="text"
                  disabled={true}
                  autoComplete={"off"}
                  placeholder="Your email address"
                />
              </div>
              <button className="update-btn action-btn" onClick={onSubmitName}>
                <FaCheckCircle className="update-icon action-icon" />
                <span className="update-text action-text">Update</span>
              </button>
            </div>
            <div className="form-lay">
              <div className="input-field-lay">
                <span className="input-field-text">Old Password</span>
                <input
                  className="input"
                  value={input_old_password}
                  onChange={(e) => {
                    onValueChange("old_psk", e);
                  }}
                  type="password"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter your old password"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">New Password</span>
                <input
                  className="input"
                  value={input_password}
                  onChange={(e) => {
                    onValueChange("new_psk", e);
                  }}
                  type="password"
                  required={true}
                  autoComplete={"off"}
                  placeholder="Enter your new password"
                />
              </div>
              <div className="input-field-lay">
                <span className="input-field-text">Confirm New Password</span>
                <input
                  className="input"
                  value={input_password_2}
                  onChange={(e) => {
                    onValueChange("new_psk2", e);
                  }}
                  type="password"
                  required={true}
                  autoComplete={"off"}
                  placeholder="confirm new password"
                />
              </div>
              <button
                className="update-btn action-btn"
                onClick={onSubmitPassword}
              >
                <FaCheckCircle className="update-icon action-icon" />
                <span className="update-text action-text">Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {history.location.pathname.startsWith(`${Routes.Account}/profile/edit`) &&
        editAccountView()}
      {updating && (
        <div className="updating-lay">
          <div className="def-loading-lay">
            <div className="def-loading-spinner" />
            <span className="def-loading-text">Loading...</span>
          </div>
        </div>
      )}
      <div className="tab-item-header">
        <div className="tab-item-header-left">
          <Link
            to={`${Routes.Account}/profile/edit`}
            className="tab-item-header-left-btn action-btn"
          >
            <FaEdit className="tab-item-header-left-btn-icon action-icon" />
            <span className="tab-item-header-left-btn-text action-text">
              Edit
            </span>
          </Link>
        </div>
        <span className="tab-item-header-text-center">Profile</span>
      </div>
      <div className="tab-item-body">
        <div className="profile-first-lay">
          <div className="profile-avatar-cont">
            <div className="profile-avatar-top" />
            <img src={avatar} alt="avatar" className="profile-avatar-img" />
          </div>
          <div className="profile-text-cont">
            <div className="profile-text-lay">
              <span className="profile-text-name">{props.user_data.name}</span>
            </div>
            {props.user_data.email && (
              <div className="profile-text-lay">
                <span className="profile-text-1">Email: </span>
                <span className="profile-text-2">{props.user_data.email}</span>
              </div>
            )}
            {/* {props.user_data.id &&
                  <div className='profile-text-lay'>
                    <span className='profile-text-1'>Role: </span>
                    <span className='profile-text-2'>{role}</span>
                  </div>
                } */}
            <div className="profile-text-lay">
              <span className="profile-text-1">Devices: </span>
              <span className="profile-text-2">
                {props.user_data.devices.length === 1
                  ? `1 Device`
                  : `${props.user_data.devices.length} Devices`}
              </span>
            </div>
          </div>
        </div>
        <div className="profile-second-lay">
          <span className="profile-second-list-title">All Devices</span>
          <div className="profile-second-list-lay">
            {props.user_data.devices.map(
              (device: UserDevice, index: number) => {
                return <DeviceListItem key={index} device={device} />;
              }
            )}
          </div>
        </div>
        <div className="empty-body-footer" />
      </div>
    </>
  );
};

export default ProfileTab;

const DeviceListItem = (props: { device: UserDevice }) => {
  const last_updated = moment(props.device.last_updated).format(
    "DD-MM-YYYY hh:mm:ss a"
  );
  return (
    <div className="device-item-lay">
      <div className="device-item-left">
        <RiDeviceFill className="device-item-icon" />
      </div>
      <div className="device-item-links-lay">
        <Link
          to={`${Routes.Devices}/${props.device.username}`}
          className="device-item-center"
        >
          <div className="item-name-lay">
            <span className="item-name-text-1">Name: </span>
            <span className="item-name-text-2">{props.device.name}</span>
          </div>
          <div className="item-name-lay">
            <span className="item-name-text-1">Location: </span>
            <span className="item-name-text-2">
              {props.device.location_name}
            </span>
          </div>
          <div className="item-name-lay">
            <span className="item-name-text-1">Last Updated: </span>
            <span className="item-name-text-2">{last_updated}</span>
          </div>
          <div className="item-name-lay">
            <span className="item-name-text-1">User Role: </span>
            <span className="item-name-text-2">
              {props.device.user_role.replace("_", " ")}
            </span>
          </div>
        </Link>
        <Link
          to={`${Routes.Devices}/${props.device.username}/settings`}
          className="device-item-right action-btn"
        >
          <MdSettings className="action-icon" />
          <span className="action-text">Settings</span>
        </Link>
      </div>
    </div>
  );
};
