import "./index.scss";
import React from "react";
import {
  DeviceCommand,
  DeviceGroup,
  MutualDevice,
  SocketUserDevice,
  UserData,
  UserDevice,
  WsDevice,
  WsDeviceActivity,
  WsDeviceReply,
  WsUserCallback,
} from "../models";
import { useHistory, withRouter } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Receipt from "./receipt/receipt";
import AccountTab from "./account";
import Dashboard from "./dashboard";
import DatabaseManager from "../../service";
import DevicesTab from "./devices";
import Header from "./header";
import Routes from "../../utils/routes";
import SideNav from "./side-nav";
import Themer from "../../utils/themer";
import VendingWebSocket from "./web-socket";
import WindowLocationHandler from "../../utils/location-handler";

const Main = () => {
  const history = useHistory();
  const didMount = useRef(false);

  const vendingSocket = useRef<VendingWebSocket | null>(null);
  const socket_url = useRef<string | null>(null);

  const [user_id, setUserId] = useState<string | null>(null);
  const [user_name, setUserName] = useState<string | null>(null);

  const user_data_ref = useRef<UserData | null>(null);
  const [user_data, set_user_data] = useState<UserData | null>(null);

  const [current_page, setCurrentPage] = useState<string>(
    window.location.pathname
  );
  const [current_page_title, setCurrentPageTitle] = useState<string>("");
  const [dashboard_showed, setDashboardShowed] = useState<boolean>(false);
  const [devices_showed, setDevicesShowed] = useState<boolean>(false);
  const [receipt_showed, setReceptShowed] = useState<boolean>(false);
  const [account_showed, setAccountShowed] = useState<boolean>(false);

  const device_groups_ref = useRef<DeviceGroup[]>([]);
  const [device_groups, set_device_groups] = useState<DeviceGroup[]>([]);

  const socket_devices_ref = useRef<SocketUserDevice[]>([]);
  const [socket_devices, set_socket_devices] = useState<SocketUserDevice[]>([]);

  const device_ids_ref = useRef<string[]>([]);
  const [device_ids, set_device_ids] = useState<string[]>([]);

  const mutual_devices_ref = useRef<MutualDevice[]>([]);
  const [mutual_devices, set_mutual_devices] = useState<MutualDevice[]>([]);

  const [devices_loading, setDevicesLoading] = useState<boolean>(false);
  const [mutual_devices_loading, setMutualDevicesLoading] =
    useState<boolean>(false);
  const [error_loading, setErrorLoading] = useState<string | null>(null);
  const [error_loading_mutual, setErrorLoadingMutual] = useState<string | null>(
    null
  );

  const [show_modal, setShowModal] = useState<boolean>(false);
  const [socket_connected, setSocketConnected] = useState<boolean>(false);

  const devs_activities_ref = useRef<WsDeviceActivity[]>([]);
  const [devs_activities, set_devs_activities] = useState<WsDeviceActivity[]>(
    []
  );

  useEffect(() => {
    didMount.current = true;
    const user_id = window.localStorage.getItem("vending-uid");
    const user_name = window.localStorage.getItem("vending-un");
    if (!user_id) {
      let { from }: any = history.location.state || {
        from: { pathname: Routes.Login },
      };
      history.replace(from);
      return;
    }

    // console.log('user_id: ' + user_id);
    setUserId(user_id);
    setUserName(user_name);

    onReloadClick();
    WindowLocationHandler.subscribe((path) => {
      if (!didMount.current) return;
      switchPage(path);
    });
    switchPage(current_page);
    Themer.subscribe();
    return () => {
      didMount.current = false;
      WindowLocationHandler.unsubscribe();
      Themer.unsubscribe();
      if (vendingSocket.current) {
        vendingSocket.current.stopSocket();
        vendingSocket.current.endSocket();
        vendingSocket.current = null;
      }
    };
  }, []);

  const updateUserData = () => {
    if (!user_data_ref.current) {
      return user_data_ref.current;
    }
    user_data_ref.current.devices.forEach((_d) => {
      if (device_ids_ref.current.indexOf(_d.id) === -1) {
        device_ids_ref.current.push(_d.id);
      }
      _d.shown = current_page.startsWith(`${Routes.Devices}/${_d.username}`);
      if (_d.location_name) {
        const exist_position = device_groups_ref.current.findIndex(
          (g) => g.location_name === _d.location_name
        );
        if (exist_position >= 0) {
          if (!device_groups_ref.current[exist_position].devices) {
            device_groups_ref.current[exist_position].devices = [_d];
          } else {
            const pos = device_groups_ref.current[
              exist_position
            ].devices.findIndex((d) => d.id === _d.id);
            if (pos >= 0) {
              device_groups_ref.current[exist_position].devices[pos] = _d;
            } else {
              device_groups_ref.current[exist_position].devices.push(_d);
            }
          }
        } else {
          device_groups_ref.current.push({
            location_name: _d.location_name,
            devices: [_d],
          });
        }
      }
    });
    set_device_ids(device_ids_ref.current);
    set_device_groups(device_groups_ref.current);
    set_user_data(user_data_ref.current);
  };

  const initVendingWebSocket = () => {
    if (!socket_url.current) return;
    if (vendingSocket.current) {
      vendingSocket.current.stopSocket();
      vendingSocket.current.endSocket();
      setSocketConnected(false);
    }
    vendingSocket.current = new VendingWebSocket(socket_url.current, {
      onConnect: () => {
        if (!didMount.current) return;
        // console.log('socket: onConnect');
        setSocketConnected(true);
        if (user_data_ref.current && vendingSocket.current) {
          vendingSocket.current.initUserSocket(
            user_data_ref.current,
            user_data_ref.current.devices
          );
        }
      },
      onDisconnect: () => {
        if (!didMount.current) return;
        // console.log('socket: onDisconnect');
        setSocketConnected(false);
      },
      onUserCallback: (_userCallback: WsUserCallback) => {},
      onDeviceJoin: (device: WsDevice) => {
        if (!didMount.current) return;
        console.log("socket: onDeviceJoin", device.id);
        if (device.id) {
          setDeviceActive(device.id, true);
        }
      },
      onDeviceActivity: (activity: WsDeviceActivity) => {
        console.log("socket: onDeviceActivity", activity.device_id);
        if (!activity || !activity.device_id) return;
        const arr = devs_activities_ref.current;
        let index = arr.findIndex(
          (act) => act.device_id === activity.device_id
        );
        if (index >= 0) {
          arr[index] = activity;
        } else {
          arr.push(activity);
        }
        devs_activities_ref.current = [...arr];
        set_devs_activities(devs_activities_ref.current);
      },
      onDeviceReply: (deviceReply: WsDeviceReply) => {
        if (!didMount.current || !deviceReply.device_id) return;
        // console.log('socket: onDeviceReply', deviceReply.device_id);
        setDeviceReply(deviceReply);
      },
      onDeviceLeave: (device: WsDevice) => {
        if (!didMount.current) return;
        // console.log('socket: onDeviceLeave', device.id);
        if (device.id) {
          setDeviceActive(device.id, false);
        }
      },
    });
    if (user_data_ref.current) {
      vendingSocket.current.initUserSocket(
        user_data_ref.current,
        user_data_ref.current.devices
      );
    }
    // console.log('socket initialized');
    updateSocketDevices();
  };

  const sendDeviceCommand = (
    device_id: string,
    command: DeviceCommand,
    value: number,
    request_code: number
  ) => {
    if (!vendingSocket.current) return;
    vendingSocket.current.sendUserCommand(
      device_id,
      command,
      value,
      request_code
    );
  };

  const setDeviceActive = (device_id: string, active: boolean) => {
    if (!didMount.current) return;
    if (!user_data_ref.current) return;
    const pos = user_data_ref.current.devices.findIndex(
      (d) => d.id === device_id
    );
    if (pos >= 0) {
      user_data_ref.current.devices[pos].device_active = active;
      updateUserData();
    }

    updateSocketDevices();
  };

  const updateSocketDevices = () => {
    console.log("socket: updateSocketDevices");
    if (!didMount.current) return;
    if (!user_data_ref.current) return;
    if (!vendingSocket.current) return;
    socket_devices_ref.current = vendingSocket.current.getSocketDevices();
    if (socket_devices_ref.current.length > 0) {
      user_data_ref.current.devices.forEach((d) => {
        if (!d.slots) {
          d.slots = [];
        }
        socket_devices_ref.current.forEach((s, i) => {
          if (s.device_id === d.id) {
            let _total_slots = 0;
            let _total_slots_filled = 0;
            let _total_price = 0;
            for (let z = 0; z < d.slots.length; z++) {
              _total_slots++;
              if (d.slots[z].quantity > 0) {
                _total_slots_filled++;
                _total_price =
                  _total_price + d.slots[z].price * d.slots[z].quantity;
              }
            }
            socket_devices_ref.current[i].info = {
              name: d.name,
              username: d.username,
              location_name: d.location_name,
              last_updated: d.last_updated,
              date_created: d.date_created,
              total_slots: _total_slots,
              total_slots_filled: _total_slots_filled,
              total_price: _total_price,
            };
          }
        });
      });
    }
    set_socket_devices(socket_devices_ref.current);
  };

  const setDeviceReply = (reply: WsDeviceReply) => {
    if (!didMount.current) return;
    if (!user_data_ref.current) return;
    // console.log('setDeviceReply');
    const pos = user_data_ref.current.devices.findIndex(
      (d) => d.id === reply.device_id
    );
    if (pos >= 0) {
      const _device = user_data_ref.current.devices[pos];
      _device.device_reply = reply;
      const device_reply_refresh_id = _device.device_reply_refresh_id
        ? _device.device_reply_refresh_id + 1
        : 1;
      _device.device_reply_refresh_id = device_reply_refresh_id;
      user_data_ref.current.devices[pos] = _device;
      updateUserData();
    }

    updateSocketDevices();
  };

  const switchPage = (path: string) => {
    // console.log('switchPage: ' + path);
    setShowModal(false);
    setCurrentPage(path);
    if (path === Routes.Devices || path === Routes.Devices + "/") {
      document.title = "Devices - Moran Vending Machine";
      setCurrentPageTitle("Devices");
    }
    if (path.startsWith(`${Routes.Devices}/`)) {
      document.title = "Device - Moran Vending Machine";
      setCurrentPageTitle("Device");
      setDevicesShowed(true);
      let deviceId = path.replace(Routes.Devices + "/", "");
      if (deviceId.length > 0) {
        if (user_data_ref.current) {
          const pos = user_data_ref.current.devices.findIndex(
            (d) => d.username === deviceId
          );
          if (pos >= 0) {
            user_data_ref.current.devices[pos].shown = true;
            updateUserData();
          }
        }
      }
    } else if (path.startsWith(Routes.Dashboard)) {
      document.title = "Dashboard - Moran Vending Machine";
      setCurrentPageTitle("Dashboard");
      setDashboardShowed(true);
    } else if (path.startsWith(Routes.Account)) {
      document.title = "Account - Moran Vending Machine";
      setCurrentPageTitle("Account");
      setAccountShowed(true);
    } else if (path === Routes.Help) {
      document.title = "Help - Moran Vending Machine";
      setCurrentPageTitle("Help");
    }
    // forceUpdate();
  };

  const onReloadClick = () => {
    if (!didMount.current) return;
    // console.log('onReloadClick');
    const user_id = localStorage.getItem("vending-uid");
    if (!user_id) return;
    device_groups_ref.current = [];
    setErrorLoading(null);

    new DatabaseManager().getUserDevices(user_id, {
      onResult: (result: any) => {
        if (!didMount.current) return;
        setDevicesLoading(false);
        if (result && result.success && result.success.data) {
          user_data_ref.current =
            result.success.data.length > 0 ? result.success.data[0] : null;
          updateUserData();
          setErrorLoading(
            result.success.data.length > 0 ? null : "No devices found"
          );
          if (
            result.success.data.length > 0 &&
            result.success.data[0] &&
            result.success.data[0].socket_url
          ) {
            const _socket_url = result.success.data[0].socket_url;
            if (socket_url.current !== _socket_url) {
              socket_url.current = result.success.data[0].socket_url;
              initVendingWebSocket();
            }
          }
          updateSocketDevices();
          getMutualDevices(); // get mutual devices
        } else {
          setDevicesLoading(false);
          setErrorLoading("Could not load devices. Click Here To Retry");
        }
      },
      onError: () => {
        // console.log(e);
        if (!didMount.current) return;
        setDevicesLoading(false);
        setErrorLoading("Could not load devices. Click Here To Retry");
      },
    });
  };

  const getMutualDevices = () => {
    if (!didMount.current) return;
    // console.log('getMutualDevices');
    const user_id = localStorage.getItem("vending-uid");
    if (!user_id) return;

    setMutualDevicesLoading(true);
    setErrorLoadingMutual(null);

    new DatabaseManager()
      .getMutualDevices(user_id, device_ids_ref.current)
      .then((result: any) => {
        if (!didMount.current) return;
        // console.log('getMutualDevices: ', result);
        setMutualDevicesLoading(false);
        if (result && result.success && result.success.data) {
          mutual_devices_ref.current =
            result.success.data && result.success.data.length > 0
              ? result.success.data
              : [];
          set_mutual_devices(mutual_devices_ref.current);
          setErrorLoadingMutual(
            result.success.data.length > 0
              ? null
              : "No other members found with common devices. Click Here To Retry"
          );
        } else {
          setMutualDevicesLoading(false);
          setErrorLoadingMutual("Could not load devices. Click Here To Retry");
        }
      });
  };

  // const arrangeDevices = (devices: UserDevice[], new_list: boolean): DeviceGroup[] => {
  //   const _groups: DeviceGroup[] = new_list ? device_groups_ref.current : [];
  //   const _ids: string[] = [];
  //   for (var i = 0; i < devices.length; i++) {
  //     const _d: UserDevice = devices[i];
  //     if (_ids.indexOf(_d.id) === -1) {
  //       _ids.push(_d.id);
  //     }
  //     _d.shown = current_page.startsWith(`${Routes.Devices}/${_d.username}`);
  //     if (_d.location_name) {
  //       var exist: boolean = false;
  //       var exist_position: number = -1;
  //       for (var e = 0; e < _groups.length; e++) {
  //         const _user_device = _groups[e];
  //         if (_user_device.location_name === _d.location_name) {
  //           exist = true;
  //           exist_position = e;
  //           break;
  //         }
  //       }
  //       if (exist) {
  //         _groups[exist_position].devices.push(_d);
  //       }
  //       else {
  //         _groups.push({
  //           location_name: _d.location_name,
  //           devices: [_d],
  //         });
  //       }
  //     }
  //   }
  //   device_ids_ref.current = _ids;
  //   forceUpdate();
  //   return _groups;
  // }

  const onDeviceUpdated = (device: UserDevice) => {
    // console.log('onDeviceUpdated: ', device);
    if (!didMount.current) return;
    if (!user_data_ref.current) return;
    const pos = user_data_ref.current.devices.findIndex(
      (d) => d.id === device.id
    );
    if (pos >= 0) {
      user_data_ref.current.devices[pos] = device;
      updateUserData();
    }

    updateSocketDevices();
  };

  const onAccountNameUpdated = (name: string) => {
    onReloadClick();
  };

  const onLogOutBtnClick = () => {
    if (!didMount.current) return;
    localStorage.removeItem("vending-un");
    localStorage.removeItem("vending-uid");
    localStorage.removeItem("vending-ur");
    setTimeout(() => history.push("/"), 100);
  };

  // const isDeviceActive = (device_id: string) => {
  //   let is_active = false;
  //   let index = -1;
  //   const _socket_devices = socket_devices;
  //   for (let i = 0; i < _socket_devices.length; i++) {
  //     const sd = _socket_devices[i];
  //     if (sd.device_id === device_id) {
  //       index = i;
  //       break;
  //     }
  //   }
  //   if (index !== -1) {
  //     is_active = _socket_devices[index].online;
  //   }
  //   return is_active;
  // }

  return (
    <div className="main-home-lay">
      <SideNav
        show={show_modal}
        current_page={current_page}
        onLogOutBtnClick={onLogOutBtnClick}
      />
      <div
        className={
          show_modal ? "side-nav-modal active-modal" : "side-nav-modal"
        }
        onClick={(e) => {
          e.preventDefault();
          setShowModal(false);
        }}
      />
      <Header
        onNavMenuClick={() => {
          setShowModal(true);
        }}
        user_name={user_name}
        socket_connected={socket_connected}
        current_page_title={current_page_title}
      />
      <div className="iot-main-contents" id="main-contents">
        {(dashboard_showed || current_page.startsWith(Routes.Dashboard)) &&
          user_id &&
          user_data && (
            <Dashboard
              current_page={current_page}
              user_data={user_data}
              device_ids={device_ids}
              devices={socket_devices}
              show={current_page.startsWith(Routes.Dashboard)}
              onReloadClick={onReloadClick}
            />
          )}
        {(account_showed || current_page.startsWith(Routes.Account)) &&
          user_data &&
          user_id && (
            <AccountTab
              current_page={current_page}
              user_data={user_data}
              mutual_devices={mutual_devices}
              mutual_devices_loading={mutual_devices_loading}
              error_loading_mutual={error_loading_mutual}
              show={current_page.startsWith(Routes.Account)}
              onReloadMutualDevices={getMutualDevices}
              onAccountNameUpdated={onAccountNameUpdated}
              onLogOutBtnClick={onLogOutBtnClick}
            />
          )}
        {(devices_showed || current_page.startsWith(Routes.Devices)) &&
          user_data &&
          user_id && (
            <DevicesTab
              current_page={current_page}
              devices_loading={devices_loading}
              device_groups={device_groups}
              error_loading={error_loading}
              socket_devices={socket_devices}
              devs_activities={devs_activities}
              user_data={user_data}
              user_id={user_id}
              onDeviceUpdated={onDeviceUpdated}
              sendDeviceCommand={sendDeviceCommand}
              onReloadClick={onReloadClick}
            />
          )}
        {(receipt_showed || current_page.startsWith(Routes.Receipts)) &&
          user_data &&
          user_id && (
            <div>
              <Receipt />
            </div>
          )}
      </div>
    </div>
  );
};
export default withRouter(Main);
