import "./index.scss";
import React from "react";
import {
  DashboardData,
  OrderEntry,
  RecentOrder,
  SocketUserDevice,
  UserData,
} from "../../models";
import { HiOutlineStatusOffline, HiStatusOnline } from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useReducer, useRef, useState } from "react";

import DatabaseManager from "../../../service";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { DateRangePicker } from "rsuite";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { MdRefresh } from "react-icons/md";
import Period from "../../../utils/period";
import { RiDeviceFill } from "react-icons/ri";
import Routes from "../../../utils/routes";
import addDays from "date-fns/addDays";
import moment from "moment";
import subDays from "date-fns/subDays";

// import pureknob from '../../../libs/knob/pureknob';

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

const { combine, before, afterToday } = DateRangePicker;

class ChartOps {
  private options: Highcharts.Options = {
    global: {
      // useUTC: false
    },
    chart: {
      // id: id,
      // height: 400,
      // width: '100%',
      type: "column",
      // stacked: true,
      // foreColor: "#ccc",
      // backgroundColor: 'inherit',
      zoomType: "x",
      events: {},
    },
    // colors: ['#3fb0e4', '#811796'],
    title: {
      text: "",
      align: "left",
      style: {
        fontSize: "16px",
        color: "var(--color-edit-txt)",
        fontWeight: "normal",
        fontFamily: "inherit",
        // fontFamily: 'Trebuchet MS, Verdana, sans-serif',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        //align: 'right',
        //verticalAlign: 'top',
        y: 0,
      },
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
          symbol: "circle",
        },
        lineWidth: 2,
      },
    },
    credits: { enabled: false },
    // tooltip: {
    //   // formatter: () => {
    //   //   return 'x: ' + Highcharts.dateFormat('%e %b %y %H:%M:%S', x) +
    //   //     ' y: ' + y.toFixed(2);
    //   // }
    // },
    time: {
      useUTC: false,
    },
    xAxis: {
      type: "category",
      allowDecimals: true,
      startOnTick: true,
      // minRange: 1000 * 60 * 60,
      // dateTimeLabelFormats:
      // gridLineWidth: 1,
      // gridLineColor: 'red',
      // lineColor: 'var(--color-edit-txt)',
      // tickColor: 'var(--color-edit-txt)',
      labels: {
        style: {
          fontSize: "12px",
          color: "var(--color-edit-txt)",
          fontWeight: "normal",
          fontFamily: "Trebuchet MS, Verdana, sans-serif",
        },
      },
      title: {
        style: {
          fontSize: "12px",
          color: "var(--color-edit-txt)",
          fontWeight: "normal",
          fontFamily: "Trebuchet MS, Verdana, sans-serif",
        },
      },
      categories: [],
      crosshair: true,
    },
    yAxis: {
      minorTickInterval: "auto",
      // lineColor: 'var(--color-edit-txt)',
      lineWidth: 1,
      tickWidth: 1,
      // tickColor: 'var(--color-edit-txt)',
      // gridLineWidth: 1,
      // gridLineColor: 'red',
      labels: {
        style: {
          fontSize: "12px",
          color: "var(--color-edit-txt)",
          fontWeight: "normal",
          fontFamily: "Trebuchet MS, Verdana, sans-serif",
        },
      },
      title: {
        style: {
          fontSize: "12px",
          color: "var(--color-edit-txt)",
          fontWeight: "normal",
          fontFamily: "Trebuchet MS, Verdana, sans-serif",
        },
        text: "Total Orders Per Machine",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    series: [],
  };

  public get getOptions(): Highcharts.Options {
    return this.options;
  }
}

class PieChartOps {
  private options: Highcharts.Options = {
    chart: {
      type: "pie",
      events: {},
    },
    title: {
      text: "",
      align: "left",
      style: {
        fontSize: "16px",
        color: "var(--color-edit-txt)",
        fontWeight: "normal",
        fontFamily: "inherit",
        // fontFamily: 'Trebuchet MS, Verdana, sans-serif',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        //align: 'right',
        //verticalAlign: 'top',
        y: 0,
      },
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
          symbol: "circle",
        },
        lineWidth: 2,
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.y:.1f}%",
        },
      },
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          style: {
            color: "var(--color-edit-txt)",
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Trebuchet MS, Verdana, sans-serif",
          },
        },
      },
    },
    credits: { enabled: false },
    // subtitle: {
    //   text: 'Click the slices to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
    // },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: "%",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      // headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      // '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      // footerFormat: '</table>',
      // shared: true,
      // useHTML: true
    },
    series: [],
  };

  public get getOptions(): Highcharts.Options {
    return this.options;
  }
}

type DashbordProps = {
  current_page: string;
  user_data: UserData;
  device_ids: string[];
  show: boolean;
  devices: SocketUserDevice[];
  onReloadClick: () => void;
};

const Dashboard = (props: DashbordProps) => {
  const history = useHistory();
  const didMount = useRef(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const fetch_runnable: any = useRef(null);
  const databaseManager: DatabaseManager = new DatabaseManager();
  const dashboard_ref: React.RefObject<HTMLDivElement> = useRef(null);
  const _chart_options = new ChartOps().getOptions;
  const _price_chart_options = new ChartOps().getOptions;
  const _pie_chart_options = new PieChartOps().getOptions;

  const [dashboard_loading, setDashboardLoading] = useState(false);
  const [initial_loading, setInitialLoading] = useState(true);

  const [dashboard_data, setDashboardData] = useState<DashboardData | null>(
    null
  );

  (_chart_options.chart as any).type = "column";
  if (_chart_options.title) _chart_options.title.text = "Daily Orders";
  (_chart_options.yAxis as any).title.text = "Total Orders Per Machine";
  // _chart_options.colors = ['#3f3e82', '#1bd111', '#ff0000'];
  if (_chart_options.chart && _chart_options.chart.events) {
    _chart_options.chart.events.load = () => onChartLoad();
    _chart_options.chart.events.render = () => onChartRendered();
    _chart_options.chart.events.redraw = () => onChartRedraw();
  }
  // _chart_options.series = [
  //   // {
  //   //   name: "Inside",
  //   //   type: "column",
  //   //   turboThreshold: 10000,
  //   //   data: []
  //   // },
  //   // {
  //   //   name: "Outside",
  //   //   type: "column",
  //   //   turboThreshold: 10000,
  //   //   data: []
  //   // }
  // ];
  const [chart_options, setChartOptions] =
    useState<Highcharts.Options>(_chart_options);

  (_price_chart_options.chart as any).type = "line";
  if (_price_chart_options.title)
    _price_chart_options.title.text = "Daily Orders Sold";
  (_price_chart_options.yAxis as any).title.text =
    "Total Orders Prices Per Machine";
  // _price_chart_options.colors = ['#3f3e82', '#1bd111', '#ff0000'];
  if (_price_chart_options.chart && _price_chart_options.chart.events) {
    _price_chart_options.chart.events.load = () => onChartLoad();
    _price_chart_options.chart.events.render = () => onChartRendered();
    _price_chart_options.chart.events.redraw = () => onChartRedraw();
  }
  const [prices_chart_options, setPriceChartOptions] =
    useState<Highcharts.Options>(_price_chart_options);

  (_pie_chart_options.chart as any).type = "pie";
  if (_pie_chart_options.title)
    _pie_chart_options.title.text = "Sales Distributions Per Machine";
  // (_pie_chart_options.yAxis as any).title.text = 'Total Orders Per Machine';
  // _pie_chart_options.colors = ['#3f3e82', '#1bd111', '#ff0000'];
  if (_pie_chart_options.chart && _pie_chart_options.chart.events) {
    _pie_chart_options.chart.events.load = () => onChartLoad();
    _pie_chart_options.chart.events.render = () => onChartRendered();
    _pie_chart_options.chart.events.redraw = () => onChartRedraw();
  }
  const [pie_chart_options, setPieChartOptions] =
    useState<Highcharts.Options>(_pie_chart_options);

  const [range_display_date, setRangeDisplayDate] = useState("");
  const [_r_d_d, setRdd] = useState(false);
  const [current_display_date, setCurrentDisplayDate] = useState("");
  const [_c_d_d, setCdd] = useState(false);
  const [selected_date, setSelectedDate] = useState<DateRange>([
    Period.getPreviousSevenDaysItsFirstDay(),
    Period.getTodayEndTime(),
  ]);
  const [show_modal, setShowModal] = useState(false);
  const [last_data_date, setLastDataDate] = useState(0);

  const dashboard_modal_ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    didMount.current = true;
    resetData([Period.getTodayStartTime(), Period.getTodayEndTime()], false);
    listenForInputFocus();
    // const temp_in_knob_view = document.getElementById('temp-in-knob');
    // if (temp_in_knob_view) {
    //   temp_in_knob_view.appendChild(temp_in_knob.node());
    // }
    // const temp_out_knob_view = document.getElementById('temp-out-knob');
    // if (temp_out_knob_view) {
    //   temp_out_knob_view.appendChild(temp_out_knob.node());
    // }
    // const light_knob_view = document.getElementById('light-knob');
    // if (light_knob_view) {
    //   light_knob_view.appendChild(light_knob.node());
    // }
    // const humi_knob_view = document.getElementById('humi-knob');
    // if (humi_knob_view) {
    //   humi_knob_view.appendChild(humidity_knob.node());
    // }
    updateGauge();
    setDefaultChartData(props.user_data.devices.length);
    return () => {
      didMount.current = false;
      if (fetch_runnable.current) {
        clearInterval(fetch_runnable.current);
      }
    };
  }, []);

  const updateGauge = () => {
    //   temp_in_knob.setValue(
    //     (state.dashboard_data &&
    //       state.dashboard_data.averages &&
    //       state.dashboard_data.averages.temperature &&
    //       state.dashboard_data.averages.temperature.inside) ?
    //       state.dashboard_data.averages.temperature.inside : 0.0);
    //   temp_out_knob.setValue(
    //     (state.dashboard_data &&
    //       state.dashboard_data.averages &&
    //       state.dashboard_data.averages.temperature &&
    //       state.dashboard_data.averages.temperature.outside) ?
    //       state.dashboard_data.averages.temperature.outside : 0.0);
    //   temp_in_knob.setProperty('colorFG',
    //     (state.dashboard_data &&
    //       state.dashboard_data.averages &&
    //       state.dashboard_data.averages.temperature &&
    //       state.dashboard_data.averages.temperature.inside > 15) ?
    //       '#f003ad' : '#0391f0');
    //   temp_out_knob.setProperty('colorFG',
    //     ((state.dashboard_data &&
    //       state.dashboard_data.averages &&
    //       state.dashboard_data.averages.temperature &&
    //       state.dashboard_data.averages.temperature.outside < 15) ||
    //       (state.dashboard_data &&
    //         state.dashboard_data.averages &&
    //         state.dashboard_data.averages.temperature &&
    //         state.dashboard_data.averages.temperature.outside > 35)) ?
    //       '#f003ad' : '#0391f0');
    //   light_knob.setValue(
    //     (state.dashboard_data &&
    //       state.dashboard_data.averages &&
    //       state.dashboard_data.averages.light) ?
    //       state.dashboard_data.averages.light : 0.0);
    //   humidity_knob.setValue(
    //     (state.dashboard_data &&
    //       state.dashboard_data.averages &&
    //       state.dashboard_data.averages.humidity) ?
    //       state.dashboard_data.averages.humidity : 0.0);
    //   light_knob.setProperty('colorFG',
    //     (state.dashboard_data &&
    //       state.dashboard_data.averages &&
    //       state.dashboard_data.averages.light >= 75) ?
    //       '#0391f0' : '#f003ad');
    //   humidity_knob.setProperty('colorFG',
    //     (state.dashboard_data &&
    //       state.dashboard_data.averages &&
    //       state.dashboard_data.averages.humidity > 50) ?
    //       '#f003ad' : '#0391f0');
  };

  const setDefaultChartData = (all_devices_count: number) => {
    if (!didMount.current) return;

    const categories = []; // days_in_selected_date_array
    const series: { name: string; data: number[] }[] = []; // series_array

    // const _selected_date = selected_date;
    // find how many days are in the selected date using moment.js
    const days_in_selected_date = moment(selected_date[1]).diff(
      moment(selected_date[0]),
      "days"
    );
    // console.log('selected_date: ', selected_date);
    // console.log('days_in_selected_date', days_in_selected_date);
    if (days_in_selected_date > 0) {
      // get each day its strt datetime of the selected date
      for (let i = 0; i < days_in_selected_date + 1; i++) {
        const found_day = moment(selected_date[0]).add(i, "days").format("ll");
        categories.push(found_day);
        // console.log('found_day', found_day);
      }
    } else {
      const found_day = moment(selected_date[0]).format("ll");
      categories.push(found_day);
    }
    if (all_devices_count > 0) {
      for (let i = 0; i < all_devices_count; i++) {
        series.push({
          name: `Device ${i + 1}`,
          data: [],
        });
        for (let d = 0; d < categories.length; d++) {
          series[i].data.push(0);
        }
      }
    } else {
      series.push({
        name: "No Devices",
        data: [],
      });
    }

    (_chart_options.xAxis as any).categories = categories;
    _chart_options.series = series as any;
    _chart_options.series &&
      _chart_options.series.map((series_item) => {
        if (
          series_item.type === "area" ||
          series_item.type === "line" ||
          series_item.type === "bar" ||
          series_item.type === "column"
        ) {
          return (series_item.turboThreshold = series_item.data
            ? series_item.data.length
            : 0);
        } else return series_item;
      });
    setChartOptions(_chart_options);

    (_price_chart_options.xAxis as any).categories = categories;
    _price_chart_options.series = series as any;
    _price_chart_options.series &&
      _price_chart_options.series.map((series_item) => {
        if (
          series_item.type === "area" ||
          series_item.type === "line" ||
          series_item.type === "bar" ||
          series_item.type === "column"
        ) {
          return (series_item.turboThreshold = series_item.data
            ? series_item.data.length
            : 0);
        } else return series_item;
      });
    setPriceChartOptions(_price_chart_options);

    // (_pie_chart_options.xAxis as any).categories = categories;
    _pie_chart_options.series = series as any;
    setPieChartOptions(_pie_chart_options);

    window.setTimeout(
      () => {
        // Highcharts && Highcharts.charts && Highcharts.charts.forEach(chart => {
        //   if (chart) chart.redraw();
        // });
        forceUpdate();
      },
      chart_options.series ? chart_options.series.length : 1000
    );

    // console.log ('categories: ', categories);
    // console.log ('series: ', series);
  };

  const listenForInputFocus = () => {
    setTimeout(() => {
      if (!didMount.current) {
        return;
      }
      if (dashboard_ref.current) {
        // find first input element
        const input_element = dashboard_ref.current.querySelector(
          "input"
        ) as HTMLInputElement; // deviceItem.querySelector('input[class="rs-picker-toggle-textbox"]') as HTMLInputElement;
        if (input_element) {
          // detect on focus
          input_element.addEventListener("focus", () => {
            if (!didMount.current) {
              return;
            }
            // console.log('input_element.focus()');
            setShowModal(true);
            forceUpdate();

            setTimeout(() => {
              if (!didMount.current) {
                return;
              }
              // find element by class name
              const buttons_container = document.querySelector(
                ".rs-picker-toolbar"
              ) as HTMLDivElement;
              if (buttons_container) {
                // get all buttons and add event listener for click
                const buttons = buttons_container.querySelectorAll("button");
                if (buttons && buttons.length > 0) {
                  for (let i = 0; i < buttons.length; i++) {
                    if (!didMount.current) {
                      return;
                    }
                    const button = buttons[i] as HTMLButtonElement;
                    if (button) {
                      button.addEventListener("click", () => {
                        if (!didMount.current) {
                          return;
                        }
                        // console.log('button.click()');
                        setShowModal(false);
                        forceUpdate();
                      });
                    }
                  }
                }
              }
            }, 1000);
          });
        }

        // detect ecscape key for this div
        document.addEventListener("keydown", (e) => {
          if (!didMount.current) {
            return;
          }
          if (!history.location.pathname.includes(Routes.Dashboard)) return;
          if (e.keyCode === 27) {
            // console.log('dashboard_ref.current.keydown()');
            if (dashboard_ref.current) {
              if (!didMount.current) {
                return;
              }
              if (dashboard_modal_ref.current) {
                // perform click
                dashboard_modal_ref.current.click();
              } else {
                // console.log('modal not found');
                setShowModal(false);
                forceUpdate();
              }
            }
          }
        });
      }
    }, 100);
  };

  const resetData = (dateRange: DateRange, fromDatePicker: boolean) => {
    if (!didMount.current) return;
    const weeklyDateRange: DateRange = [
      Period.getPreviousSevenDaysItsFirstDay(),
      Period.getTodayEndTime(),
    ];

    setLastDataDate(0);
    setDashboardLoading(false);
    //setIsThereDataToShow(true);
    setRangeDisplayDate("");
    setRdd(false);
    setCurrentDisplayDate("");
    setCdd(false);
    // setAllDevicesCount(0);
    // setActiveDevicesCount(0);
    // setAvgTempIn(0.00);
    // setAvgTempOut(0.00);
    // setAvgLight(0.00);
    // setAvgHumi(0.00);
    //
    setSelectedDate(fromDatePicker ? dateRange : weeklyDateRange);

    forceUpdate();

    if (fetch_runnable.current) clearInterval(fetch_runnable.current); // clear previous intervals
    databaseManager.cancelRequests();

    setTimeout(() => {
      if (!didMount.current) return;
      if (!history.location.pathname.startsWith(`${Routes.Dashboard}`)) {
        return;
      }
      watchData(selected_date);
      fetch_runnable.current = setInterval(() => {
        if (Period.isToday(selected_date[1].valueOf())) {
          watchData(selected_date);
        }
      }, 60000 * 5);
    }, 500);
  };

  const watchData = (dateRange: DateRange) => {
    if (!didMount.current) return;
    if (dashboard_loading) return;
    if (!history.location.pathname.startsWith(`${Routes.Dashboard}`)) {
      return;
    }
    if (!Period.isToday(dateRange[1].valueOf())) {
      if (fetch_runnable.current) {
        clearInterval(fetch_runnable.current);
      }
    }
    // console.log('watchData: called: ', dateRange);
    const selected_date: DateRange = dateRange;
    const start_date: Date = selected_date[0]
      ? selected_date[0]
      : Period.getTodayStartTime();
    const end_date: Date = selected_date[1]
      ? selected_date[1]
      : Period.getTodayEndTime();

    if (last_data_date === 0) {
      const the_start_date: number = start_date.valueOf(); // + 1;
      const the_end_date: number = end_date.valueOf(); // + 1;
      getData(the_start_date, the_end_date);
    } else {
      if (last_data_date > 0) {
        const _last_data_date: number = last_data_date; // + 1;
        const _next_date: number = end_date.valueOf(); // + 1;
        getData(_last_data_date, _next_date);
      }
    }
  };

  const onRefreshClick = () => {
    if (!didMount.current || dashboard_loading) {
      return;
    }
    if (!history.location.pathname.startsWith(`${Routes.Dashboard}`)) {
      return;
    }
    watchData(selected_date);
  };

  const getData = (start_time: number, end_time: number) => {
    if (!didMount.current) return;
    if (!history.location.pathname.startsWith(`${Routes.Dashboard}`)) {
      return;
    }
    if (start_time > 0) {
      setRangeDisplayDate(Period.displayRangeDate(start_time, end_time));
      setRdd(true);
      setDashboardLoading(true);
      setInitialLoading(false);
      forceUpdate();
      databaseManager.cancelRequests();
      databaseManager
        .getDashboardData(
          props.user_data.id,
          props.device_ids,
          start_time,
          end_time
        )
        .then((result: any) => {
          if (!didMount.current) return;
          // console.log("getDashboardData: result: ", result);
          setDashboardLoading(false);
          if (
            result &&
            result.success &&
            result.success.data &&
            result.success.data.length > 0
          ) {
            const dashboard_data: DashboardData = result.success.data[0];
            if (dashboard_data) {
              let total_devices = 0;
              const recent_orders = dashboard_data.recent_orders;
              const all_order_entries: OrderEntry[] = [];
              if (recent_orders && recent_orders.length > 0) {
                recent_orders.forEach((recent_order) => {
                  total_devices++;
                  if (recent_order) {
                    // if (device.last_updated) {
                    //   const now_time = moment().valueOf();
                    //   const last_updated_time = moment(device.last_updated).valueOf();
                    //   if (now_time - last_updated_time < 60000) {
                    //     active_devices++;
                    //   }
                    // }
                    if (recent_order.orders && recent_order.orders.length > 0) {
                      recent_order.orders.forEach((order) => {
                        if (order) {
                          all_order_entries.push({
                            device_id: recent_order.device_id,
                            device_name: recent_order.device_name,
                            device_username: recent_order.device_username,
                            location_name: recent_order.location_name,
                            order: order,
                          });
                        }
                      });
                    }
                  }
                });
              }
              if (all_order_entries.length > 0) {
                // sort by date created asc
                all_order_entries.sort((a, b) => {
                  if (a.order.date_created < b.order.date_created) {
                    return -1;
                  }
                  if (a.order.date_created > b.order.date_created) {
                    return 1;
                  }
                  return 0;
                });
                type GroupByOrderDate = {
                  date: string;
                };
                type GroupByDeviceId = {
                  device_id: string;
                  entries: OrderEntry[];
                };
                const grouped_by_date_entries: GroupByOrderDate[] = [];
                const grouped_by_id_entries: GroupByDeviceId[] = [];
                // group by date from time
                all_order_entries.forEach((entry) => {
                  // use index to find the date
                  let found_date_index = -1;
                  if (grouped_by_date_entries.length > 0) {
                    for (let i = 0; i < grouped_by_date_entries.length; i++) {
                      if (
                        grouped_by_date_entries[i].date ===
                        moment(entry.order.date_created).format("ll")
                      ) {
                        found_date_index = i;
                        break;
                      }
                    }
                  }
                  if (found_date_index === -1) {
                    grouped_by_date_entries.push({
                      date: moment(entry.order.date_created).format("ll"),
                      // entries: [entry],
                    });
                  }

                  // use index to find the id
                  let found_id_index = -1;
                  if (grouped_by_id_entries.length > 0) {
                    for (let i = 0; i < grouped_by_id_entries.length; i++) {
                      if (
                        grouped_by_id_entries[i].device_id === entry.device_id
                      ) {
                        found_id_index = i;
                        break;
                      }
                    }
                  }
                  if (found_id_index === -1) {
                    grouped_by_id_entries.push({
                      device_id: entry.device_id,
                      entries: [entry],
                    });
                  } else {
                    grouped_by_id_entries[found_id_index].entries.push(entry);
                  }
                });

                const categories: string[] = []; // x-axis categories
                // const categories_by_datetime: string[] = []; // x-axis categories
                const series: { name: string; data: number[] }[] = []; // y-axis series
                const price_series: { name: string; data: number[] }[] = []; // y-axis series
                const pie_series: {
                  name: string;
                  colorByPoint: boolean;
                  data: { name: string; y: number }[];
                }[] = []; // y-axis series
                grouped_by_date_entries.forEach((gd) => {
                  categories.push(gd.date);
                  // categories_by_datetime.push(moment(gd.date).format("ll"));
                });

                pie_series.push({
                  name: "Devices",
                  colorByPoint: true,
                  data: [],
                });

                const pie_series_data: { name: string; y: number }[] = [];

                const total_sold_by_all_devices =
                  getTotalPriceForAllDevices(all_order_entries);

                grouped_by_id_entries.forEach((gi) => {
                  // gi: grouped_by_id_devices_data_entries
                  const total_sold_by_device = getTotalPriceForDevice(
                    all_order_entries,
                    gi.device_id
                  );
                  const percentage_by_device =
                    (total_sold_by_device / total_sold_by_all_devices) * 100;
                  pie_series_data.push({
                    name: gi.entries[0].device_name,
                    y: percentage_by_device,
                  });
                  series.push({
                    name: gi.entries[0].device_name,
                    data: [],
                  });
                  price_series.push({
                    name: gi.entries[0].device_name,
                    data: [],
                  });
                  const series_data: number[] = [];
                  const price_series_data: number[] = [];
                  categories.forEach((date) => {
                    const total_orders_count = getOrdersCountByDate(
                      all_order_entries,
                      gi.device_id,
                      date
                    );
                    series_data.push(total_orders_count);
                    const total_orders_price = getTotalPriceSoldByDate(
                      all_order_entries,
                      gi.device_id,
                      date
                    );
                    price_series_data.push(total_orders_price);

                    // const total_sold_by_date = getTotalPriceSoldByDateForAllDevices(all_order_entries, date);
                    // get percentage of total sold by date
                    // const percentage_by_device = (total_orders_price / total_sold_by_date) * 100;
                    // pie_series_data.push({
                    //   name: gi.entries[0].device_name,
                    //   y: percentage_by_device,
                    // });

                    // let found_same_date_index = -1;
                    // for (let x = 0; x < gi.entries.length; x++) { // data entries
                    //   if (date === moment(gi.entries[x].order.date_created).format("ll")) { // find the same date
                    //     found_same_date_index = x;
                    //     break;
                    //   }
                    // }
                    // if (found_same_date_index > -1) {
                    //   series_data.push(gi.entries[found_same_date_index].order.items_count); // push the total entries
                    // } else {
                    //   series_data.push(0); // push 0 if not the same date
                    // }
                  });
                  series[series.length - 1].data = series_data;
                  price_series[price_series.length - 1].data =
                    price_series_data;
                });

                (_chart_options.xAxis as any).categories = categories;
                _chart_options.series = series as any;
                _chart_options.series &&
                  _chart_options.series.map((series_item) => {
                    if (
                      series_item.type === "area" ||
                      series_item.type === "line" ||
                      series_item.type === "bar" ||
                      series_item.type === "column"
                    ) {
                      return (series_item.turboThreshold = series_item.data
                        ? series_item.data.length
                        : 0);
                    } else return series_item;
                  });
                setChartOptions(_chart_options);

                (_price_chart_options.xAxis as any).categories = categories;
                _price_chart_options.series = price_series as any;
                _price_chart_options.series &&
                  _price_chart_options.series.map((series_item) => {
                    if (
                      series_item.type === "area" ||
                      series_item.type === "line" ||
                      series_item.type === "bar" ||
                      series_item.type === "column"
                    ) {
                      return (series_item.turboThreshold = series_item.data
                        ? series_item.data.length
                        : 0);
                    } else return series_item;
                  });
                setPriceChartOptions(_price_chart_options);

                pie_series[0].data = pie_series_data;

                _pie_chart_options.series = pie_series as any;
                setPieChartOptions(_pie_chart_options);

                window.setTimeout(
                  () => {
                    // Highcharts && Highcharts.charts && Highcharts.charts.forEach(chart => {
                    //   if (chart) chart.redraw();
                    // });
                    forceUpdate();
                  },
                  chart_options.series ? chart_options.series.length : 1000
                );

                // console.log('categories: ', categories);
                // console.log('series: ', series);
              } else {
                setDefaultChartData(props.device_ids.length);
              }
              // setActiveDevicesCount(active_devices);
              // setAllDevicesCount(dashboard_data.total_devices ? dashboard_data.total_devices : 0);
              setDashboardData(dashboard_data);
            }
          }
          setCurrentDisplayDate(moment().format("HH:mm:ss"));
          setCdd(true);
          forceUpdate();
          updateGauge();
        })
        .catch((_error: any) => {
          if (!didMount.current) return;
          setDashboardLoading(false);
          setInitialLoading(false);
          setCurrentDisplayDate(moment().format("HH:mm:ss"));
          setCdd(true);
          forceUpdate();
        });
    }
  };

  const getOrdersCountByDate = (
    data: OrderEntry[],
    device_id: string,
    date: string
  ) => {
    let orders_count = 0;
    // convert date to time millis using moment
    const time = moment(date).valueOf();
    const _date_tonight = moment(date).endOf("day").valueOf();

    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      // get the same device id
      if (entry.device_id === device_id) {
        if (
          entry.order.date_created >= time &&
          entry.order.date_created <= _date_tonight
        ) {
          orders_count++;
        }
      }
    }

    return orders_count;
  };

  const getTotalPriceSoldByDate = (
    data: OrderEntry[],
    device_id: string,
    date: string
  ) => {
    let total_price = 0;
    // convert date to time millis using moment
    const time = moment(date).valueOf();
    const _date_tonight = moment(date).endOf("day").valueOf();

    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      // get the same device id
      if (entry.device_id === device_id) {
        if (
          entry.order.date_created >= time &&
          entry.order.date_created <= _date_tonight
        ) {
          total_price = total_price + entry.order.price;
        }
      }
    }
    return total_price;
  };

  // const getTotalPriceSoldByDateForAllDevices=(data: OrderEntry[], date: string)=> {
  //   let total_price = 0;
  //   // convert date to time millis using moment
  //   const time = moment(date).valueOf();
  //   const _date_tonight = moment(date).endOf('day').valueOf();

  //   for (let i = 0; i < data.length; i++) {
  //     const entry = data[i];
  //     if (entry.order.date_created >= time && entry.order.date_created <= _date_tonight) {
  //       total_price = total_price + entry.order.price;
  //     }
  //   }
  //   return total_price;
  // }

  const getTotalPriceForAllDevices = (data: OrderEntry[]) => {
    let total_price = 0;

    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      total_price = total_price + entry.order.price;
    }
    return total_price;
  };

  const getTotalPriceForDevice = (data: OrderEntry[], device_id: string) => {
    let total_price = 0;
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      if (entry.device_id === device_id) {
        total_price = total_price + entry.order.price;
      }
    }
    return total_price;
  };

  const onChartRendered = () => {
    // console.log('onChartRendered: called:');
  };

  const onChartLoad = () => {
    // console.log('onChartLoad:     called:');
  };

  const onChartRedraw = () => {
    // console.log('onChartRedraw:   called:');
  };

  // const clearCharts=()=> {
  //   _chart_options.series && _chart_options.series.map((series_item) => {
  //     if (series_item.type === 'area' ||
  //       series_item.type === 'line' ||
  //       series_item.type === 'bar' ||
  //       series_item.type === 'column') {
  //       return series_item.data = [];
  //     }
  //     else return series_item;
  //   });
  //   setChartOptions(_chart_options);
  //   forceUpdate();
  // }

  const datePicker = () => {
    const lower_date = new Date();
    lower_date.setFullYear(2022, 5, 1);
    lower_date.setHours(0, 0, 0, 0);

    return (
      <DateRangePicker
        format={"dd-MM-yyyy HH:mm:ss"}
        appearance="subtle"
        placeholder="Select date range"
        character="  to  "
        // size="sm"
        defaultValue={selected_date}
        cleanable={false}
        oneTap={false}
        open={show_modal}
        disabledDate={
          combine &&
          combine(before && before(lower_date), afterToday && afterToday())
        }
        style={{
          backgroundColor: "var(--color-secondary)",
        }}
        ranges={[
          {
            label: "Yesterday",
            value: [
              addDays(Period.getDayStartTime(), -1),
              addDays(Period.getDayEndTime(), -1),
            ],
          },
          {
            label: "Today",
            value: [Period.getTodayStartTime(), Period.getTodayEndTime()],
          },
          // {
          //   label: 'Tomorrow',
          //   value: [dateFns.addDays(new Date(), 1), dateFns.addDays(new Date(), 1)]
          // },
          {
            label: "Last 7 days",
            value: [
              subDays(Period.getDayStartTime(), 6),
              Period.getDayEndTime(),
            ],
          },
          {
            label: "Last 30 days",
            value: [
              subDays(Period.getDayStartTime(), 29),
              Period.getDayEndTime(),
            ],
          },
        ]}
        onOpen={() => {
          // console.log('date-range-picker: opened');
          setShowModal(true);
        }}
        onChange={(date: DateRange | null) => {
          if (date === null) {
            return;
          }
          // console.log('date-range-picker: changed: ', date);
          resetData(date, true);
        }}
        onOk={(
          date: DateRange,
          event: React.SyntheticEvent<Element, Event>
        ) => {
          if (date === null) {
            return;
          }
          event.preventDefault();
          // console.log('date-range-picker: ok: ', date);
          resetData(date, true);
        }}
        onClose={() => {
          setShowModal(false);
        }}
      />
    );
  };

  let active_devices_count = 0;
  for (const device of props.devices) {
    if (device.online) {
      active_devices_count++;
    }
  }
  return (
    <div
      className="dashboard-main"
      ref={dashboard_ref}
      style={{ display: props.show ? "flex" : "none" }}
    >
      {show_modal && (
        <div
          className="dashboard-modal"
          ref={dashboard_modal_ref}
          onClick={() => {
            if (!didMount.current) return;
            setShowModal(false);
          }}
        />
      )}
      <div className="dashboard-contents-lay">
        <div className="top-lay">
          <Link to={Routes.Devices} className="counts-cont">
            {initial_loading && (
              <div className="dashboard-detail-item-loader loading-shimmer" />
            )}
            <div className="all-count-cont">
              {/* {state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />} */}
              <div className="text-cont">
                <p className="all-count-title item-title">All Devices</p>
                <p className="all-count-info item-info">
                  {props.devices.length > 1 || props.devices.length === 0
                    ? props.devices.length + " Devices"
                    : props.devices.length + " Device"}
                </p>
              </div>
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="all-count-icon"
              >
                <path
                  fill="var(--color-icon)"
                  d="M0,160V48A48,48,0,0,1,48,0H336a48,48,0,0,1,48,48V160H320V72a8,8,0,0,0-8-8H296a8,8,0,0,0-8,8v88Zm320,32V376a8,8,0,0,1-8,8H296a8,8,0,0,1-8-8V192H0V464a48,48,0,0,0,48,48H336a48,48,0,0,0,48-48V192Z"
                  className=""
                ></path>
              </svg>
            </div>
            <div className="active-count-cont">
              {/* {state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />} */}
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className={
                  active_devices_count > 0
                    ? "active-count-icon actual-active"
                    : "active-count-icon"
                }
              >
                <path
                  fill="var(--color-icon)"
                  d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"
                  className=""
                ></path>
              </svg>
              {/* <div className="active-count-icon"/> */}
              <div className="text-cont">
                <p className="active-count-title item-title">Active Devices</p>
                <p className="active-count-info item-info">
                  {active_devices_count > 1 || active_devices_count === 0
                    ? active_devices_count + " Devices"
                    : active_devices_count + " Device"}
                </p>
              </div>
            </div>
          </Link>
          <div className="time-count-cont">
            {initial_loading && (
              <div className="dashboard-detail-item-loader loading-shimmer" />
            )}
            <div className="time-count-left">
              <div className="range-display-lay">
                <div className="range-display-cont">{datePicker()}</div>
              </div>
              <div className="current-display-lay">
                <p className="info">
                  Last Loading: {_c_d_d ? current_display_date : "Loading..."}
                </p>
              </div>
            </div>
            <div className="time-count-right">
              <button
                className="refresh-btn action-btn"
                onClick={() => {
                  props.onReloadClick();
                  onRefreshClick();
                }}
              >
                <MdRefresh className="refresh-icon action-icon" />
                <span className="refresh-text action-text">Refresh</span>
              </button>
            </div>
          </div>
        </div>
        <div className="body-lay">
          <div className="body-item">
            <div className="left-lay body-item-contents-lay">
              <div className="dashboard-chart-lay">
                {initial_loading && (
                  <div className="dashboard-detail-item-loader loading-shimmer" />
                )}
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={"chart"}
                  allowChartUpdate={true}
                  immutable={false}
                  updateArgs={[true, true, false]}
                  containerProps={{
                    className: "chart-lay",
                    id: "dashboard-chart-1",
                  }}
                  options={pie_chart_options}
                />
              </div>
            </div>
            <div className="right-lay body-item-contents-lay">
              {/* <div className="average-lay">
                <div className="avg-left-cont avg-temp-cont">
                  {state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />}
                  <div className='text-title'>Averages ( °C )</div>
                  <div className="temp-in-lay">
                    <div className="temp-in">
                      <div className='text-info'>Temperature Inside</div>
                    </div>
                    <div className="temp-in-knob avg-knob" id="temp-in-knob"></div>
                  </div>
                  <div className="temp-out-lay">
                    <div className="temp-out">
                      <div className='text-info'>Temperature Outside</div>
                    </div>
                    <div className="temp-out-knob avg-knob" id="temp-out-knob"></div>
                  </div>
                </div>
                <div className='avg-right-cont'>
                  {state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />}
                  <div className='text-title'>Averages ( % )</div>
                  <div className="avg-light-lay">
                    <div className="avg-light">
                      <div className='text-info'>Light</div>
                    </div>
                    <div className="light-knob avg-knob" id="light-knob"></div>
                  </div>
                  <div className="avg-humi-lay">
                    <div className="avg-humi">
                      <div className='text-info'>Humidity</div>
                    </div>
                    <div className="humi-knob avg-knob" id="humi-knob"></div>
                  </div>
                </div>
              </div> */}
              <div className="dashboard-chart-lay">
                {initial_loading && (
                  <div className="dashboard-detail-item-loader loading-shimmer" />
                )}
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={"chart"}
                  allowChartUpdate={true}
                  immutable={false}
                  updateArgs={[true, true, false]}
                  containerProps={{
                    className: "chart-lay",
                    id: "dashboard-chart-2",
                  }}
                  options={chart_options}
                />
              </div>
            </div>
          </div>
          <div className="body-item">
            <div className="left-lay body-item-contents-lay">
              {initial_loading && (
                <div className="dashboard-detail-item-loader loading-shimmer" />
              )}
              <div className="body-item-contents-title-lay">
                <span className="body-item-contents-title">Recent Devices</span>
              </div>
              {props.devices.map((device, index) => {
                return (
                  <div className="device-item" key={index}>
                    <div className="item-left-lay">
                      <RiDeviceFill className="item-icon" />
                    </div>
                    {device.info && (
                      <Link
                        to={Routes.Devices + "/" + device.info.username}
                        className="item-center-lay"
                      >
                        <span className="item-text-title">
                          {device.info.name}
                        </span>
                        <div className="item-botton-lay">
                          <MdRefresh className="item-icon" />
                          <span className="item-text-info">
                            {moment(device.info.last_updated).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </span>
                        </div>
                      </Link>
                    )}
                    <div className="item-right-lay">
                      {device.online && (
                        <HiStatusOnline
                          className="item-icon online"
                          title="online"
                        />
                      )}
                      {!device.online && (
                        <HiOutlineStatusOffline
                          className="item-icon offline"
                          title="offline"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              {/* {state.initial_loading && <div className='device-detail-item-loader loading-shimmer' />}
              <CoolerMap
                is_dashboard={true}
                is_setting={false}
                locations={[]}
                is_location_data_loaded={!state.initial_loading}
                location={undefined}
                last_location_updated={0}
              /> */}
            </div>
            <div className="right-lay body-item-contents-lay">
              <div className="dashboard-chart-lay">
                {initial_loading && (
                  <div className="dashboard-detail-item-loader loading-shimmer" />
                )}
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={"chart"}
                  allowChartUpdate={true}
                  immutable={false}
                  updateArgs={[true, true, false]}
                  containerProps={{
                    className: "chart-lay",
                    id: "dashboard-chart-3",
                  }}
                  options={prices_chart_options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
