import "./index.scss";
import React from "react";
import { MutualDevice, UserData } from "../../models";
import { Link } from "react-router-dom";

import { RiDeviceFill } from "react-icons/ri";
import Routes from "../../../utils/routes";
import { FaUser, FaUserFriends } from "react-icons/fa";
import ProfileTab from "./profile";
import MembersTab from "./members";

type AccountProps = {
  current_page: string;
  user_data: UserData;
  mutual_devices: MutualDevice[];
  mutual_devices_loading: boolean;
  error_loading_mutual: string | null;
  show: boolean;
  onReloadMutualDevices: () => void;
  onAccountNameUpdated: (name: string) => void;
  onLogOutBtnClick: () => void;
};

const AccountTab = (props: AccountProps) => {
  return (
    <div
      className="tab-lay"
      style={{
        display: props.current_page.startsWith(Routes.Account)
          ? "flex"
          : "none",
      }}
    >
      <div className="tab-list">
        <div className="tab-list-header">
          <span className="tab-list-header-text">Account</span>
        </div>
        <div className="tab-list-body">
          <div className="devices-location-cont">
            <div className="devices-location-title-lay">
              <div className="lines-lay">
                <div className="vert-2 lines" />
                <div className="horz lines" />
              </div>
            </div>
            <div className="device-item">
              <div className="lines-lay">
                <div className="vert-1 lines" />
                <div className="vert-2 lines" />
                <div className="horz lines" />
              </div>
              <Link
                to={Routes.Account + "/profile"}
                className={
                  props.current_page.startsWith(Routes.Account + "/profile")
                    ? "device-item-btn device-item-selected"
                    : "device-item-btn device-item-normal"
                }
              >
                <div className="item-top-lay">
                  <FaUser className="item-icon" />
                  <span className="item-text">Profile</span>
                </div>
              </Link>
            </div>
            <div className="device-item">
              <div className="lines-lay">
                <div className="vert-1 lines" />
                <div className="horz lines" />
              </div>
              <Link
                to={Routes.Account + "/members"}
                className={
                  props.current_page.startsWith(Routes.Account + "/members")
                    ? "device-item-btn device-item-selected"
                    : "device-item-btn device-item-normal"
                }
              >
                <div className="item-top-lay">
                  <FaUserFriends className="item-icon" />
                  <span className="item-text">Members</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`tab-details${
          props.current_page.startsWith(Routes.Account + "/profile") ||
          props.current_page.startsWith(Routes.Account + "/members")
            ? " tab-details-with-item"
            : ""
        }`}
      >
        {(props.current_page === Routes.Account ||
          props.current_page === Routes.Account + "/") && (
          <div className="tab-item-empty-lay">
            <span className="tab-item-empty-text">Select link to view</span>
          </div>
        )}
        <div
          className="tab-item-main"
          style={{
            display: props.current_page.startsWith(`${Routes.Account}/profile`)
              ? "flex"
              : "none",
          }}
        >
          <ProfileTab
            current_page={props.current_page}
            user_data={props.user_data}
            onAccountNameUpdated={props.onAccountNameUpdated}
            onLogOutBtnClick={props.onLogOutBtnClick}
          />
        </div>
        <div
          className="tab-item-main"
          style={{
            display: props.current_page.startsWith(`${Routes.Account}/members`)
              ? "flex"
              : "none",
          }}
        >
          <MembersTab
            current_user={props.user_data}
            mutual_devices={props.mutual_devices}
            mutual_devices_loading={props.mutual_devices_loading}
            error_loading_mutual={props.error_loading_mutual}
            onReloadMutualDevices={props.onReloadMutualDevices}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountTab;
