
const FormatterUtil = {
  formatNumber: (num: number) => {
    // if (num >= 1000000000000000) {
    //   return (num / 1000000000000000).toFixed(1).replace(/\.0$/, '') + 'P';
    // }
    // if (num >= 1000000000000) {
    //   return (num / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
    // }
    // if (num >= 1000000000) {
    //   return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    // }
    // if (num >= 1000000) {
    //   return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    // }
    // if (num >= 1000) {
    //   return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    // }
    // return num.toString();
    if (num < 10000) return "" + num;

    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(num)) / 3 | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return num.toString();

    const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = num / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  },
  // format price with separated thousands
  formatPrice: (price: number) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export default FormatterUtil;