import "./index.scss";
import React from "react";
import {
  Device,
  Member,
  MutualDevice,
  SearchUser,
  SearchUserDevice,
  UserData,
  UserDevice,
  UserRole,
} from "../../../models";
import { FaCheckCircle, FaPlus, FaTimes } from "react-icons/fa";
import { MdCancel, MdDelete, MdLocationOn, MdRefresh } from "react-icons/md";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";

import DatabaseManager from "../../../../service";
import { GroupedVirtuoso, Virtuoso } from "react-virtuoso";
import { IoWarning } from "react-icons/io5";
import { RiDeviceFill } from "react-icons/ri";
import Routes from "../../../../utils/routes";
import { Link, useHistory } from "react-router-dom";

type Props = {
  current_user: UserData;
  mutual_devices: MutualDevice[];
  mutual_devices_loading: boolean;
  error_loading_mutual: string | null;
  onReloadMutualDevices: () => void;
};

const MembersTab = (props: Props) => {
  const history = useHistory();
  const avatar: string = require("../../../../assets/images/avatar.png");

  const didMount = useRef(false);

  const [updating, setUpdating] = useState(false);
  const [members, setMembers] = useState<Member[]>(() => {
    let ms: Member[] = [];
    props.mutual_devices.forEach((obj) => {
      for (let i = 0; i < obj.members.length; i++) {
        ms.push(obj.members[i]);
      }
    });
    return ms;
  });

  const groupCounts = useMemo(() => {
    let counts: number[] = [];
    let ms: Member[] = [];
    props.mutual_devices.forEach((obj) => {
      counts.push(obj.members.length);
      for (let i = 0; i < obj.members.length; i++) {
        ms.push(obj.members[i]);
      }
    });
    setMembers(ms);
    return counts;
  }, [props.mutual_devices, props.mutual_devices.length]);

  const isCurrentUserAlsoOwnerOfThisDevice = (device: MutualDevice) => {
    let is_owner = false;
    for (let i = 0; i < props.current_user.devices.length; i++) {
      if (
        props.current_user.devices[i].id === device.device_id &&
        props.current_user.devices[i].user_owner === true
      ) {
        is_owner = true;
        break;
      }
    }
    return is_owner;
  };

  const getCurrentUserRoleForThisDevice = (device: MutualDevice) => {
    let role: UserRole = "user";
    for (let i = 0; i < props.current_user.devices.length; i++) {
      if (props.current_user.devices[i].id === device.device_id) {
        role = props.current_user.devices[i].user_role;
        break;
      }
    }
    return role;
  };

  const canCurrentMemberChangeThisUsersAccess = (
    device: MutualDevice,
    member: Member
  ) => {
    let canDoIt = false;
    if (member.owner === true) {
      canDoIt = false;
    } else if (props.current_user.id === member.user_id) {
      canDoIt = false;
    } else if (isCurrentUserAlsoOwnerOfThisDevice(device)) {
      canDoIt = true;
    } else {
      if (
        getCurrentUserRoleForThisDevice(device) === "admin" ||
        getCurrentUserRoleForThisDevice(device) === "super_admin"
      ) {
        canDoIt = true;
      }
    }
    return canDoIt;
  };

  const getUsernameFromEmail = (email: string) => {
    // trim email to get username
    let username = email;
    if (email.indexOf("@") >= 0) {
      username = email.substring(0, email.indexOf("@"));
    }
    return username;
  };

  const updateMemberAccess = (
    device_id: string,
    member_id: string,
    role: UserRole,
    allowed: boolean
  ) => {
    // if (!didMount.current) {
    //   return;
    // }
    console.log("called");
    // console.log('updating member access: role: ' + role + ', allowed: ' + allowed);

    setUpdating(true);

    new DatabaseManager()
      .updateMemberAccess(
        props.current_user.id,
        member_id,
        device_id,
        role,
        allowed
      )
      .then((res: any) => {
        // console.log(res);
        console.log("changes applied");
        if (!didMount) {
          return;
        }
        setUpdating(false);
        props.onReloadMutualDevices();
      })
      .catch((_err: any) => {
        if (!didMount) {
          return;
        }
        setUpdating(false);
      });
  };

  const scroller = useRef<HTMLDivElement>(null);
  return (
    <>
      {history.location.pathname.startsWith(
        `${Routes.Account}/members/add`
      ) && (
        <AddMembersTab
          user_data={props.current_user}
          onClose={() => {
            // forceUpdate();
          }}
        />
      )}
      {updating && (
        <div className="updating-lay">
          <div className="def-loading-lay">
            <div className="def-loading-spinner" />
            <span className="def-loading-text">Loading...</span>
          </div>
        </div>
      )}
      <div className="tab-item-header">
        <div className="tab-item-header-left">
          <Link
            to={`${Routes.Account}/members/add`}
            className="tab-item-header-left-btn action-btn"
          >
            <FaPlus className="tab-item-header-left-btn-icon action-icon" />
            <span className="tab-item-header-left-btn-text action-text">
              Add Members
            </span>
          </Link>
        </div>
        <span className="tab-item-header-text-center">Members</span>
        <div className="tab-item-header-right">
          <button
            className="tab-item-header-right-btn action-btn"
            onClick={props.onReloadMutualDevices}
          >
            <MdRefresh className="tab-item-header-right-btn-icon action-icon" />
            <span className="tab-item-header-right-btn-text action-text">
              Refresh
            </span>
          </button>
        </div>
      </div>
      <div className="tab-item-body" ref={scroller}>
        {props.mutual_devices_loading && (
          <div className="loading-lay">
            <div className="def-loading-lay">
              <div className="def-loading-spinner" />
              <span className="def-loading-text">Loading...</span>
            </div>
          </div>
        )}
        {((!props.mutual_devices_loading &&
          props.mutual_devices.length === 0) ||
          props.error_loading_mutual) && (
          <div className="no-devices-lay">
            {!props.error_loading_mutual && (
              <span className="no-devices-text">
                No other members found with common devices
              </span>
            )}
            {props.error_loading_mutual && (
              <span
                className="no-devices-text error-text"
                onClick={() => props.onReloadMutualDevices()}
              >
                {props.error_loading_mutual}
              </span>
            )}
          </div>
        )}
        {!props.mutual_devices_loading &&
          props.mutual_devices.length > 0 &&
          props.mutual_devices.map((device, index) => {
            if (!device) return null;
            return (
              <>
                <div className="mutual-device-header" key={index}>
                  <div
                    className={`mutual-device-header-inner${
                      (device.members || ([] as Member[])).filter(
                        (d) => d.role !== "super_admin"
                      ).length === 0
                        ? " empty-list"
                        : ""
                    }`}
                  >
                    <div className="device-left">
                      <RiDeviceFill className="device-icon" />
                    </div>
                    <div className="device-right">
                      <span className="device-name">{device.device_name}</span>
                      <div className="location-lay">
                        <MdLocationOn className="location-icon" />
                        <span className="location-text">
                          {device.location_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {(device.members || ([] as Member[])).filter(
                  (d) => d.role !== "super_admin"
                ).length > 0 && (
                  <Virtuoso
                    className="mutual-device-body"
                    customScrollParent={scroller.current || undefined}
                    data={(device.members || ([] as Member[])).filter(
                      (d) => d.role !== "super_admin"
                    )}
                    itemContent={(index: number, member: Member) => {
                      if (!member) return null;
                      return (
                        <div
                          className={`member-lay${
                            (device.members || ([] as Member[])).filter(
                              (d) => d.role !== "super_admin"
                            ).length -
                              1 ===
                            index
                              ? " last-item"
                              : ""
                          }`}
                          key={index}
                        >
                          {member.role !== "super_admin" && (
                            <div className="member-main-lay">
                              <div className="member-content-lay">
                                <div className="member-first-lay">
                                  <div className="member-left">
                                    {/* <RiUserFill className="member-icon" /> */}
                                    <div className="member-avatar-cont">
                                      <div className="member-avatar-top" />
                                      <img
                                        src={avatar}
                                        alt="avatar"
                                        className="member-avatar-img"
                                      />
                                    </div>
                                  </div>
                                  <div className="member-right">
                                    <span className="member-name">
                                      {member.user_name}
                                    </span>
                                    <span className="member-email">
                                      {getUsernameFromEmail(member.email)}
                                    </span>
                                    {/* <div className='role-lay'>
                            <MdPerson className='role-icon' />
                            <span className='role-text'>{getUsernameFromEmail(member.email)}</span>
                          </div> */}
                                    {/* <div className='role-lay'>
                            <MdRefresh className='role-icon' />
                            <span className='role-text'>{moment(member.date_updated).format('DD-MM-YYYY hh:mm:ss a')}</span>
                          </div> */}
                                  </div>
                                </div>
                                <div className="member-second-lay">
                                  <div className="member-select-lay">
                                    <span className="member-select-lay-text">
                                      Role
                                    </span>
                                    <select
                                      className="member-select refresh-btn"
                                      value={member.role}
                                      title={member.role}
                                      disabled={
                                        !canCurrentMemberChangeThisUsersAccess(
                                          device,
                                          member
                                        )
                                      }
                                      onChange={(e) => {
                                        e.preventDefault();
                                        if (
                                          canCurrentMemberChangeThisUsersAccess(
                                            device,
                                            member
                                          )
                                        ) {
                                          let val: UserRole = "user";
                                          switch (e.target.value) {
                                            case "usper_admin":
                                              val = "super_admin";
                                              break;
                                            case "admin":
                                              val = "admin";
                                              break;
                                            case "cashier":
                                              val = "cashier";
                                              break;
                                            case "user":
                                              val = "user";
                                              break;
                                            default:
                                              val = "user";
                                              break;
                                          }
                                          updateMemberAccess(
                                            device.device_id,
                                            member.user_id,
                                            val,
                                            member.allowed
                                          );
                                        }
                                      }}
                                    >
                                      <option value={"user"}>User</option>
                                      <option value={"cashier"}>Cashier</option>
                                      <option value={"admin"}>Admin</option>
                                      {/* <option value={'super_admin'}>Super Admin</option> */}
                                    </select>
                                  </div>
                                  <div className="member-select-lay">
                                    <span className="member-select-lay-text">
                                      Allowed
                                    </span>
                                    <select
                                      className="member-select refresh-btn"
                                      value={member.allowed ? 1 : 0}
                                      disabled={
                                        !canCurrentMemberChangeThisUsersAccess(
                                          device,
                                          member
                                        )
                                      }
                                      onChange={(e) => {
                                        e.preventDefault();
                                        if (
                                          canCurrentMemberChangeThisUsersAccess(
                                            device,
                                            member
                                          )
                                        ) {
                                          const allowed =
                                            e.target.value === "1"
                                              ? true
                                              : false;

                                          updateMemberAccess(
                                            device.device_id,
                                            member.user_id,
                                            member.role,
                                            allowed
                                          );
                                        }
                                      }}
                                    >
                                      <option value={1}>Yes</option>
                                      <option value={0}>No</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }}
                  />
                )}
              </>
            );
          })}
        <div className="empty-body-footer" />
      </div>
    </>
  );
};

export default MembersTab;

// type MutualDeviceProps = {
//   mutual_device: MutualDevice,
//   current_user: UserData,
//   mutual_devices_loading: boolean;
//   error_loading_mutual: string | null;
//   onReloadMutualDevices: () => void;
//   onAccessChange: (device_id: string, member_id: string, role: UserRole, allowed: boolean) => void;
// }

// const MutualDeviceView = (props: MutualDeviceProps) => {
//   const history = useHistory();
//   const avatar: string = require('../../../../assets/images/avatar.png');

//   const isCurrentUserAlsoOwnerOfThisDevice = () => {
//     let is_owner = false;
//     for (let i = 0; i < props.current_user.devices.length; i++) {
//       if (props.current_user.devices[i].id === props.mutual_device.device_id &&
//         props.current_user.devices[i].user_owner === true) {
//         is_owner = true;
//         break;
//       }
//     }
//     return is_owner;
//   }

//   const getCurrentUserRoleForThisDevice = () => {
//     let role: UserRole = 'user';
//     for (let i = 0; i < props.current_user.devices.length; i++) {
//       if (props.current_user.devices[i].id === props.mutual_device.device_id) {
//         role = props.current_user.devices[i].user_role;
//         break;
//       }
//     }
//     return role;
//   }

//   const canCurrentMemberChangeThisUsersAccess = (member: Member) => {
//     let canDoIt = false;
//     if (member.owner === true) {
//       canDoIt = false;
//     } else if (props.current_user.id === member.user_id) {
//       canDoIt = false;
//     } else if (isCurrentUserAlsoOwnerOfThisDevice()) {
//       canDoIt = true;
//     } else {
//       if (getCurrentUserRoleForThisDevice() === 'admin' || getCurrentUserRoleForThisDevice() === 'super_admin') {
//         canDoIt = true;
//       }
//     }
//     return canDoIt;
//   }

//   const getUsernameFromEmail = (email: string) => {
//     // trim email to get username
//     let username = email;
//     if (email.indexOf('@') >= 0) {
//       username = email.substring(0, email.indexOf('@'));
//     }
//     return username;
//   }

//   const updateMemberAccess = (device_id: string, member_id: string, role: UserRole, allowed: boolean) => {
//     if (!didMount.current) {
//       return;
//     }

//     // console.log('updating member access: role: ' + role + ', allowed: ' + allowed);

//     setUpdating(true);

//     new DatabaseManager().updateMemberAccess(
//       props.current_user.id, member_id, device_id, role, allowed)
//       .then((res: any) => {
//         // console.log(res);
//         if (!didMount) {
//           return;
//         }
//         setUpdating(false);
//         props.onReloadMutualDevices();
//       })
//       .catch((_err: any) => {
//         if (!didMount) {
//           return;
//         }
//         setUpdating(false);
//       });
//   }

//   return (
//     <>
//       {history.location.pathname.startsWith(`${Routes.Account}/members/add`) &&
//         <AddMembersTab
//           user_data={props.current_user}
//           onClose={() => {
//             // setSelectedLog(null);
//             // forceUpdate();
//           }}
//         />
//       }
//       <div className="mutual-device-lay blue-grad-partial">
//         <div className='main-device'>
//           <div className="device-left">
//             <RiDeviceFill className="device-icon" />
//           </div>
//           <div className="device-right">
//             <span className="device-name">{props.mutual_device.device_name}</span>
//             <div className='location-lay'>
//               <MdLocationOn className='location-icon' />
//               <span className='location-text'>{props.mutual_device.location_name}</span>
//             </div>
//           </div>
//         </div>
//         <div className='members-lay'>
//           <span className='members-lay-title'>Members</span>
//           {props.mutual_device.members.map((member: Member, index: number) => {
//             return (
//               <div className='member-lay' key={index}>
//                 {member.role !== 'super_admin' &&
//                   <div className='member-main-lay'>
//                     <div className='member-content-lay'>
//                       <div className='member-first-lay'>
//                         <div className="member-left">
//                           {/* <RiUserFill className="member-icon" /> */}
//                           <div className="member-avatar-cont">
//                             <div className="member-avatar-top" />
//                             <img src={avatar} alt="avatar" className="member-avatar-img" />
//                           </div>
//                         </div>
//                         <div className="member-right">
//                           <span className="member-name">{member.user_name}</span>
//                           <span className='member-email'>{getUsernameFromEmail(member.email)}</span>
//                           {/* <div className='role-lay'>
//                             <MdPerson className='role-icon' />
//                             <span className='role-text'>{getUsernameFromEmail(member.email)}</span>
//                           </div> */}
//                           {/* <div className='role-lay'>
//                             <MdRefresh className='role-icon' />
//                             <span className='role-text'>{moment(member.date_updated).format('DD-MM-YYYY hh:mm:ss a')}</span>
//                           </div> */}
//                         </div>
//                       </div>
//                       <div className='member-second-lay'>
//                         <div className='member-select-lay'>
//                           <span className='member-select-lay-text'>Role</span>
//                           <select className='member-select refresh-btn'
//                             value={member.role}
//                             title={member.role}
//                             disabled={!canCurrentMemberChangeThisUsersAccess(member)}
//                             onChange={(e) => {
//                               e.preventDefault();
//                               if (canCurrentMemberChangeThisUsersAccess(member)) {
//                                 let val: UserRole = 'user';
//                                 switch (e.target.value) {
//                                   case 'usper_admin':
//                                     val = 'super_admin';
//                                     break;
//                                   case 'admin':
//                                     val = 'admin';
//                                     break;
//                                   case 'cashier':
//                                     val = 'cashier';
//                                     break;
//                                   case 'user':
//                                     val = 'user';
//                                     break;
//                                   default:
//                                     val = 'user';
//                                     break;
//                                 }
//                                 props.onAccessChange(props.mutual_device.device_id, member.user_id, val, member.allowed);
//                               }
//                             }}>
//                             <option value={'user'}>User</option>
//                             <option value={'cashier'}>Cashier</option>
//                             <option value={'admin'}>Admin</option>
//                             {/* <option value={'super_admin'}>Super Admin</option> */}
//                           </select>
//                         </div>
//                         <div className='member-select-lay'>
//                           <span className='member-select-lay-text'>Allowed</span>
//                           <select className='member-select refresh-btn'
//                             value={member.allowed ? 1 : 0}
//                             disabled={!canCurrentMemberChangeThisUsersAccess(member)}
//                             onChange={(e) => {
//                               e.preventDefault();
//                               if (canCurrentMemberChangeThisUsersAccess(member)) {
//                                 // const allowed = member.allowed ? "1" : "0";
//                                 const allowed = e.target.value === "1" ? true : false;
//                                 // props.onAccessChange(props.mutual_device.device_id, member.user_id, e.target.value === '1');
//                                 props.onAccessChange(props.mutual_device.device_id, member.user_id, member.role, allowed);
//                               }
//                             }}>
//                             <option value={1}>Yes</option>
//                             <option value={0}>No</option>
//                           </select>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 }
//               </div>
//             )
//           })}
//         </div>
//       </div>
//     </>
//   );
// }

type AddMembersTabProps = {
  user_data: UserData;
  onClose: () => void;
};

const AddMembersTab = (props: AddMembersTabProps) => {
  const history = useHistory();
  const databaseManager: DatabaseManager = new DatabaseManager();
  const didMount = useRef(false);

  const avatar: string = require("../../../../assets/images/avatar.png");

  const [search_term, setSearchTerm] = useState("");

  const [is_updating, setIsUpdating] = useState(false);
  const [updating_message, setUpdatingMessage] = useState<string | null>(null);
  const [updating_error, setUpdatingError] = useState<boolean>(false);
  const [is_loading, setIsLoading] = useState(false);
  const [error_loading, setErrorLoading] = useState<string | null>(null);
  const [was_searched_at_least_once, setWasSearchedAtLeastOnce] =
    useState(false);

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [list, setList] = useState<SearchUser[]>([]);
  const [listD, setListD] = useState<SearchUserDevice[]>([]);

  const [selected_member_id, setSelectedMemberId] = useState<string | null>(
    null
  );
  const [selected_member_name, setSelectedMemberName] = useState<string | null>(
    null
  );
  const [selected_member_email, setSelectedMemberEmail] = useState<
    string | null
  >(null);
  const [selected_member_role, setSelectedMemberRole] =
    useState<UserRole>("user");
  const [selected_member_allowed, setSelectedMemberAllowed] =
    useState<boolean>(true);
  const [selected_device_id, setSelectedDeviceId] = useState<string | null>(
    null
  );
  const [selected_device_name, setSelectedDeviceName] = useState<string | null>(
    null
  );
  const [selected_device_location_name, setSelectedDeviceLocationName] =
    useState<string | null>(null);

  const [selection_type, setSelectionType] = useState<"add" | "remove" | null>(
    null
  );
  const [show_confirm_dialog, setShowConfirmDialog] = useState(false);
  const show_confirm_dialog_ref = useRef(false);

  const handleKeyPress = (e: any) => {
    // go back if user press escape
    if (e.keyCode === 27 || e.key === "Escape") {
      if (!didMount.current) return;
      if (is_updating) return;
      if (
        !history.location.pathname.startsWith(`${Routes.Account}/members/add`)
      )
        return;
      if (
        show_confirm_dialog_ref.current &&
        history.location.hash.includes("dialog")
      ) {
        history.replace({
          pathname: history.location.pathname,
          hash: "",
        });
        initialDialogState();
        return;
      }
      if (
        history.location.pathname.startsWith(`${Routes.Account}/members/add`)
      ) {
        if (history.length > 1) {
          history.goBack();
        } else {
          history.push(`${Routes.Account}`);
        }
      }
    }
  };

  useEffect(() => {
    didMount.current = true;
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      didMount.current = false;
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);

  const getData = (search_term: string) => {
    if (!didMount.current) return;

    if (search_term.length === 0) {
      setList([]);
      setListD([]);
      forceUpdate();
      return;
    }
    setList([]);
    setListD([]);
    setIsLoading(true);
    forceUpdate();
    databaseManager.cancelRequests();
    setTimeout(() => {
      databaseManager
        .getUsersList(props.user_data.id, search_term)
        .then((result: any) => {
          if (!didMount.current) return;
          setIsLoading(false);
          setWasSearchedAtLeastOnce(true);
          if (result && result.success && result.success.data) {
            setErrorLoading(
              result.success.data.length > 0
                ? null
                : "No users found. Try searching with a different term."
            );
            if (result.success.data.length > 0) {
              const list: SearchUser[] = [];
              for (let i = 0; i < result.success.data.length; i++) {
                const data = result.success.data[i];
                const _search_user_devices: SearchUserDevice[] = data.devices
                  ? getSearcUserhDevices(data.devices)
                  : [];
                const search_user: SearchUser = {
                  user_id: data.user_id,
                  user_name: data.user_name,
                  email: data.email,
                  date_updated: data.date_updated,
                  devices: _search_user_devices,
                };
                // console.log('i', i, search_user.devices.length);
                list.push(search_user);
              }
              setList(list);
              forceUpdate();
            } else {
              setList([]);
              setListD([]);
              forceUpdate();
            }
          } else {
            setList([]);
            setListD([]);
            setErrorLoading("Could not load users. Click Here To Retry");
            forceUpdate();
          }
        });
    }, 100);
  };

  const groupCounts = useMemo(() => {
    let counts: number[] = [];
    let ds: SearchUserDevice[] = [];
    list.forEach((obj) => {
      counts.push(obj.devices.length);
      for (let i = 0; i < obj.devices.length; i++) {
        ds.push(obj.devices[i]);
      }
    });
    setListD(ds);
    // console.log('list', list, 'groupCounts', counts);
    return counts;
  }, [list]);

  const getSearcUserhDevices = (devices: SearchUserDevice[]) => {
    let search_user_devices: SearchUserDevice[] = [];

    if (devices.length > 0) {
      for (let i = 0; i < devices.length; i++) {
        const d = devices[i];
        let found_index = props.user_data.devices.findIndex(
          (device: Device) => device.id === d.device_id
        );
        if (found_index >= 0) {
          search_user_devices.push(d);
        }
      }
    }
    return search_user_devices;
  };

  // const getActualIndex = (index: number, groupIndex: number) => {
  //   // index is the index of the item in the list
  //   // groupIndex is the index of specific group
  //   let actualIndex = index;

  //   let count: number = -1;
  //   // const ls: { g: number, x: number }[] = [];
  //   list.forEach((obj, ig) => {
  //     if (ig !== groupIndex) {
  //       for (let i = 0; i < obj.devices.length; i++) {
  //         count++;
  //       }
  //     } else {
  //       // let last_count = count;
  //       for (let i = 0; i < obj.devices.length; i++) {
  //         if (count !== index) {
  //           count++;
  //         } else {
  //           actualIndex = i;
  //           break;
  //         }
  //       }
  //     }
  //   });

  //   return actualIndex;
  // }

  // useEffect(() => {
  //   listD.forEach((_item, index) => {
  //     list.forEach((group, groupIndex) => {
  //       let actualIndex = getActualIndex(index, groupIndex);
  //       let device = list[groupIndex].devices[actualIndex];
  //       if (device) console.log('index:', index, 'group:', groupIndex, 'id:', device.device_username, 'actualIndex:', actualIndex, 'name:', group.user_name);
  //     });
  //   });

  // }, [listD]);

  const getUsernameFromEmail = (email: string) => {
    // trim email to get username
    let username = email;
    if (email.indexOf("@") >= 0) {
      username = email.substring(0, email.indexOf("@"));
    }
    return username;
  };

  const isCurrentUserAlsoOwnerOfThisDevice = (device_id: string) => {
    let is_owner = false;
    if (props.user_data.devices) {
      for (let i = 0; i < props.user_data.devices.length; i++) {
        const device = props.user_data.devices[i];
        if (device.id === device_id && device.user_owner === true) {
          is_owner = true;
          break;
        }
      }
    }
    return is_owner;
  };

  const getListOfDevicesThatCurrentUserIsOwnerOf = () => {
    let devices: UserDevice[] = [];
    if (props.user_data.devices) {
      for (let i = 0; i < props.user_data.devices.length; i++) {
        const device = props.user_data.devices[i];
        if (device.user_owner === true) {
          devices.push(device);
        }
      }
    }
    return devices;
  };

  const getListOfDeviceToAddToAnotherUserIfCurrentUserIsOwnerAndUserDoesNotHaveThisDevice =
    (userDevice: SearchUserDevice[]) => {
      let devices: UserDevice[] = getListOfDevicesThatCurrentUserIsOwnerOf();
      let devices_to_add: UserDevice[] = [];
      if (devices.length > 0) {
        for (let i = 0; i < devices.length; i++) {
          const device = devices[i];
          let found_index = userDevice.findIndex(
            (d: SearchUserDevice) => d.device_id === device.id
          );
          if (found_index < 0) {
            devices_to_add.push(device);
          }
        }
      }
      return devices_to_add;
    };

  const initialDialogState = () => {
    setShowConfirmDialog(false);
    show_confirm_dialog_ref.current = false;
    setSelectedMemberId(null);
    setSelectedMemberName(null);
    setSelectedMemberEmail(null);
    setSelectedMemberAllowed(false);
    setSelectedMemberRole("user");
    setSelectedDeviceId(null);
    setSelectedDeviceName(null);
    setSelectedDeviceLocationName(null);
    setSelectionType(null);
  };

  const getDeviceNameAndLocationNameFromDeviceId = (device_id: string) => {
    let _device: { name: string; location_name: string } = {
      name: "",
      location_name: "",
    };
    let found_index = props.user_data.devices.findIndex(
      (device: Device) => device.id === device_id
    );
    if (found_index >= 0) {
      _device.name = props.user_data.devices[found_index].name;
      _device.location_name =
        props.user_data.devices[found_index].location_name;
    }
    return _device;
  };

  const addOrRemoveMember = () => {
    if (!didMount.current) return;
    if (!selected_device_id) return;
    if (!selected_device_name) return;
    if (!selected_device_location_name) return;
    if (!selected_member_id) return;
    if (!selected_member_name) return;
    if (!selected_member_email) return;
    if (!selected_member_role) return;
    if (!selection_type) return;
    //
    setIsUpdating(true);
    setShowConfirmDialog(true);
    show_confirm_dialog_ref.current = true;
    history.push({
      pathname: history.location.pathname,
      hash: "dialog",
    });

    if (selection_type === "add") {
      databaseManager
        .addMemberToDevice(
          props.user_data.id,
          selected_member_id,
          selected_device_id,
          selected_member_role,
          selected_member_allowed
        )
        .then((result: any) => {
          if (!didMount.current) return;
          setIsUpdating(false);
          if (result.success) {
            setUpdatingMessage("Member added successfully");
            setUpdatingError(false);
            setTimeout(() => {
              if (!didMount.current) {
                return;
              }
              setUpdatingMessage(null);
              setUpdatingError(false);
              initialDialogState();
              getData(search_term); // refresh data
              //
            }, 1000);
          } else if (result && result.error && result.error.message) {
            setUpdatingMessage(result.error.message);
            setUpdatingError(true);
            // initialDialogState();
          } else {
            setUpdatingMessage(
              "An error occured while adding member. Please try again."
            );
            setUpdatingError(true);
            // initialDialogState();
          }
        })
        .catch(() => {
          if (!didMount.current) return;
          setIsUpdating(false);
          setUpdatingMessage(
            "An error occured while adding member. Please try again."
          );
          setUpdatingError(true);
          // initialDialogState();
        });
    } else if (selection_type === "remove") {
      databaseManager
        .removeMemberFromDevice(
          props.user_data.id,
          selected_member_id,
          selected_device_id
        )
        .then((result: any) => {
          if (!didMount.current) return;
          setIsUpdating(false);
          if (result.success) {
            setUpdatingMessage("Member removed successfully");
            setUpdatingError(false);
            setTimeout(() => {
              if (!didMount.current) {
                return;
              }
              setUpdatingMessage(null);
              setUpdatingError(false);
              initialDialogState();
              getData(search_term); // refresh data
              //
            }, 1000);
          } else if (result && result.error && result.error.message) {
            setUpdatingMessage(result.error.message);
            setUpdatingError(true);
            // initialDialogState();
          } else {
            setUpdatingMessage(
              "An error occured while removing member. Please try again."
            );
            setUpdatingError(true);
            // initialDialogState();
          }
        })
        .catch(() => {
          if (!didMount.current) return;
          setIsUpdating(false);
          setUpdatingMessage(
            "An error occured while removing member. Please try again."
          );
          setUpdatingError(true);
          // initialDialogState();
        });
    }
  };

  return (
    <>
      {show_confirm_dialog && history.location.hash.includes("dialog") && (
        <div
          className="add-members-confirm-lay"
          id="add-members-confirm-lay"
          onClick={(e) => {
            if (
              e.target === document.getElementById("add-members-confirm-lay")
            ) {
              if (is_updating) return;
              initialDialogState();
              history.replace({
                pathname: history.location.pathname,
                hash: "",
              });
            }
          }}
        >
          <div className="confirm-contents">
            {(is_updating || updating_message) && (
              <div className="confirm-modal">
                {is_updating && (
                  <div className="def-loading-lay">
                    <div className="def-loading-spinner" />
                    <span className="def-loading-text">Loading...</span>
                  </div>
                )}
                {updating_message && (
                  <div
                    className="def-dialog-lay"
                    onClick={(e) => {
                      e.preventDefault();
                      setUpdatingMessage(null);
                      setUpdatingError(false);
                      setIsUpdating(false);
                    }}
                  >
                    {updating_error && (
                      <IoWarning className="def-dialog-icon error-icon" />
                    )}
                    {!updating_error && (
                      <FaCheckCircle className="def-dialog-icon" />
                    )}
                    <span
                      className={
                        updating_error
                          ? "def-dialog-text error-text"
                          : "def-dialog-text"
                      }
                    >
                      {updating_message}
                    </span>
                  </div>
                )}
              </div>
            )}
            <div className="confirm-setting-lay">
              {selected_member_name &&
                selected_device_id &&
                selected_device_name &&
                selected_device_location_name &&
                selected_member_id &&
                selected_member_name &&
                selected_member_email && (
                  <>
                    <div className="confirm-text-lay">
                      {selection_type === "add" && (
                        <span className="confirm-text">{`Add "${selected_member_name}" to "${selected_device_name}" device access ?`}</span>
                      )}
                      {selection_type === "remove" && (
                        <span className="confirm-text">{`Are you sure you want to remove "${selected_member_name}" from accessing "${selected_device_name}" device ?`}</span>
                      )}
                    </div>
                    <div className="adjust-lay">
                      <div className="adjust-item-lay">
                        <span className="adjust-item-label">Name</span>
                        <span className="adjust-item-value">
                          {selected_member_name}
                        </span>
                      </div>
                      <div className="adjust-item-lay">
                        <span className="adjust-item-label">Email</span>
                        <span className="adjust-item-value">
                          {selected_member_email}
                        </span>
                      </div>
                      <div className="adjust-item-lay">
                        <span className="adjust-item-label">Role</span>
                        <select
                          className="adjust-item-value"
                          value={selected_member_role}
                          disabled={selection_type === "remove" ? true : false}
                          onChange={(e) => {
                            let val: UserRole = "user";
                            switch (e.target.value) {
                              case "usper_admin":
                                val = "super_admin";
                                break;
                              case "admin":
                                val = "admin";
                                break;
                              case "cashier":
                                val = "cashier";
                                break;
                              case "user":
                                val = "user";
                                break;
                              default:
                                val = "user";
                                break;
                            }
                            setSelectedMemberRole(val);
                          }}
                        >
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                          <option value="cashier">Cashier</option>
                        </select>
                      </div>
                      <div className="adjust-item-lay">
                        <span className="adjust-item-label">Allowed</span>
                        <select
                          className="adjust-item-value"
                          value={selected_member_allowed ? "true" : "false"}
                          disabled={selection_type === "remove" ? true : false}
                          onChange={(e) => {
                            let val = false;
                            switch (e.target.value) {
                              case "true":
                                val = true;
                                break;
                              case "false":
                                val = false;
                                break;
                              default:
                                val = false;
                                break;
                            }
                            setSelectedMemberAllowed(val);
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div>
                        {selection_type === "add" && (
                          <span className="confirm-title-text">
                            Add to device
                          </span>
                        )}
                        {selection_type === "remove" && (
                          <span className="confirm-title-text">
                            Remove from device
                          </span>
                        )}
                      </div>
                      <div className="adjust-item-lay">
                        <span className="adjust-item-label">Device Id</span>
                        <span className="adjust-item-value">
                          {selected_device_id}
                        </span>
                      </div>
                      <div className="adjust-item-lay">
                        <span className="adjust-item-label">Device Name</span>
                        <span className="adjust-item-value">
                          {selected_device_name}
                        </span>
                      </div>
                      <div className="adjust-item-lay">
                        <span className="adjust-item-label">
                          Device Location
                        </span>
                        <span className="adjust-item-value">
                          {selected_device_location_name}
                        </span>
                      </div>
                    </div>
                  </>
                )}
            </div>
            <div className="confirm-btns-lay">
              <div
                className="cancel-btn action-red-btn"
                onClick={() => {
                  initialDialogState();
                }}
              >
                <MdCancel className="confirm-icon action-red-icon" />
                <span className="confirm-text action-red-text">Cancel</span>
              </div>
              <div
                className="confirm-btn action-btn"
                onClick={addOrRemoveMember}
              >
                <FaCheckCircle className="confirm-icon action-icon" />
                <span className="confirm-text action-text">Confirm</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="add-members-lay"
        id="add-members-lay"
        onClick={(e) => {
          if (e.target === document.getElementById("add-members-lay")) {
            if (is_updating) return;
            if (history.length > 1) {
              history.goBack();
            } else {
              history.push(`${Routes.Account}`);
            }
          }
        }}
      >
        <div className="add-members-contents">
          <div className="add-members-contents-title-lay">
            <span className="add-members-title-text">Add Members</span>
            <FaTimes
              className="add-members-close-btn"
              title="close"
              onClick={(e) => {
                e.preventDefault();
                if (history.length > 1) {
                  history.goBack();
                } else {
                  history.push(`${Routes.Account}`);
                }
              }}
            />
          </div>
          <div className="form-contents">
            <div className="add-members-search-lay">
              <input
                type="text"
                className="add-members-search-input"
                placeholder="Search for members"
                value={search_term}
                onInput={(e) => {
                  setSearchTerm(e.currentTarget.value);
                  getData(e.currentTarget.value);
                }}
              />
              <button
                className="add-members-search-btn action-btn"
                onClick={() => getData(search_term)}
              >
                {/* <MdSearch className='add-members-search-icon action-icon' /> */}
                <span className="add-members-search-text action-text">
                  Search
                </span>
              </button>
            </div>
            <div className="form-contents-body">
              {is_loading && (
                <div className="loading-lay">
                  <div className="def-loading-lay">
                    <div className="def-loading-spinner" />
                    <span className="def-loading-text">Loading...</span>
                  </div>
                </div>
              )}
              {((!is_loading && list.length === 0) || error_loading) && (
                <div className="no-users-lay">
                  {!error_loading && (
                    <span className="no-users-text">
                      {was_searched_at_least_once
                        ? "No users found, try searching with different term."
                        : "Type name or email to search for a user"}
                    </span>
                  )}
                  {error_loading && (
                    <span
                      className="no-users-text error-text"
                      onClick={() => getData(search_term)}
                    >
                      {error_loading}
                    </span>
                  )}
                </div>
              )}
              {!is_loading && (
                <GroupedVirtuoso
                  className="add-members-list"
                  groupCounts={groupCounts}
                  groupContent={(index) => {
                    let user = list[index];
                    if (!user) return null;
                    return (
                      <div
                        className={`user-lay${
                          user.devices.length === 0 ? " last" : ""
                        }`}
                        key={index}
                      >
                        <div className="user-first-lay">
                          <div className="user-left">
                            <div className="user-avatar-cont">
                              <div className="user-avatar-top" />
                              <img
                                src={avatar}
                                alt="avatar"
                                className="user-avatar-img"
                              />
                            </div>
                          </div>
                          <div className="user-right">
                            <span className="user-name">{user.user_name}</span>
                            <span className="user-email">
                              {getUsernameFromEmail(user.email)}
                            </span>
                          </div>
                        </div>
                        <div className="user-second-lay">
                          <span className="user-second-lay-text">{`${
                            user.devices.length
                          } ${
                            user.devices.length === 1 ? "device" : "devices"
                          }`}</span>
                          {getListOfDeviceToAddToAnotherUserIfCurrentUserIsOwnerAndUserDoesNotHaveThisDevice(
                            user.devices
                          ).length > 0 && (
                            <select
                              className="user-second-lay-select "
                              onChange={(e) => {
                                if (!didMount.current) return;
                                let device_id = e.currentTarget.value;
                                if (device_id === "") return;
                                let _device =
                                  getDeviceNameAndLocationNameFromDeviceId(
                                    device_id
                                  );

                                setSelectedDeviceId(device_id);
                                setSelectedDeviceName(_device.name);
                                setSelectedDeviceLocationName(
                                  _device.location_name
                                );
                                //
                                setSelectedMemberId(user.user_id);
                                setSelectedMemberName(user.user_name);
                                setSelectedMemberEmail(user.email);
                                setSelectedMemberRole("user");
                                setSelectedMemberAllowed(false);
                                //
                                setSelectionType("add");
                                show_confirm_dialog_ref.current = true;
                                setShowConfirmDialog(true);
                                history.push({
                                  pathname: history.location.pathname,
                                  hash: "dialog",
                                });
                              }}
                            >
                              <option value="">Select device to add</option>
                              {getListOfDeviceToAddToAnotherUserIfCurrentUserIsOwnerAndUserDoesNotHaveThisDevice(
                                user.devices
                              ).map((device: UserDevice, index: number) => {
                                return (
                                  <option key={index} value={device.id}>
                                    {device.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </div>
                    );
                  }}
                  itemContent={(index: number, groupIndex: number) => {
                    let user = list[groupIndex];
                    let device = listD[index];
                    // console.log('group:', groupIndex, 'index:', index, 'obj:', device.device_name);
                    // let actualIndex = groupIndex === 0 ? index : getActualIndex(index, groupIndex);
                    // let device = list[groupIndex].devices[actualIndex];
                    // console.log('group:', groupIndex, 'index:', index, 'actualIndex:', actualIndex, 'obj:', device.device_name);
                    if (!device) return null;
                    return (
                      <div className={`device-lay`} key={index}>
                        <div className="device-info-lay">
                          <div className="device-left">
                            <RiDeviceFill className="device-icon" />
                          </div>
                          <div className="device-right">
                            <span className="device-name">
                              {device.device_name}
                            </span>
                            <div className="location-lay">
                              <MdLocationOn className="location-icon" />
                              <span className="location-text">
                                {device.location_name}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="device-action-lay">
                          {device.user_owner && (
                            <>
                              <div className="device-action-info">
                                <span className="device-action-info-text">
                                  Owner
                                </span>
                              </div>
                              <span className="info-bullet">&bull;</span>
                            </>
                          )}
                          <div className="device-action-info">
                            <span className="device-action-info-text">
                              {device.user_role.replace(/_/g, " ")}
                            </span>
                          </div>
                          <span className="info-bullet">&bull;</span>
                          <div className="device-action-info">
                            <span className="device-action-info-text">
                              {device.user_allowed ? "Allowed" : "Not Allowed"}
                            </span>
                          </div>
                          {isCurrentUserAlsoOwnerOfThisDevice(
                            device.device_id
                          ) &&
                            !device.user_owner && (
                              <button
                                className="device-action-btn action-red-btn"
                                onClick={() => {
                                  if (!didMount.current) return;
                                  setSelectedDeviceId(device.device_id);
                                  setSelectedDeviceName(device.device_name);
                                  setSelectedDeviceLocationName(
                                    device.location_name
                                  );
                                  //
                                  setSelectedMemberId(user.user_id);
                                  setSelectedMemberName(user.user_name);
                                  setSelectedMemberEmail(user.email);
                                  setSelectedMemberRole(device.user_role);
                                  setSelectedMemberAllowed(device.user_allowed);
                                  //
                                  setSelectionType("remove");
                                  show_confirm_dialog_ref.current = true;
                                  setShowConfirmDialog(true);
                                  history.push({
                                    pathname: history.location.pathname,
                                    hash: "dialog",
                                  });
                                }}
                              >
                                <MdDelete className="device-action-icon action-red-icon" />
                                <span className="device-action-text action-red-text">
                                  Remove
                                </span>
                              </button>
                            )}
                        </div>
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
