import "./index.scss";
import React from "react";
import { Link, useHistory } from "react-router-dom";

import AuthContext from "../../../service/context";
import DatabaseManager from "../../../service";
import Routes from "../../../utils/routes";
import Themer from "../../../utils/themer";
import { useContext, useEffect, useRef, useState } from "react";
import queryString from "query-string";
import * as jose from "jose";

const AuthPage = () => {
  const history = useHistory();
  const didMount = useRef(false);
  const databaseManager: DatabaseManager = new DatabaseManager();
  const contextType = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [type, setType] = useState<"passwordRecover" | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);

  useEffect(() => {
    didMount.current = true;
    // const user_id = localStorage.getItem("vending-uid");
    // if (user_id) {
    //   contextType.setIsAuthenticated(true);
    //   let { from }: any = history.location.state || {
    //     from: { pathname: Routes.Dashboard },
    //   };
    //   history.replace(from);
    // }
    if (!history.location.search.includes("type")) {
      setTitle("Unknown action!");
      history.replace(Routes.Landing);
      return;
    }

    const parsed = queryString.parse(history.location.search);

    if (parsed.type === "passwordRecover") {
      if (!parsed.token || parsed.token.length === 0) {
        setTitle("Unknown token!");
        return;
      }
      // setToken(parsed.token as string);
      init(parsed.token as string);
    } else {
      setTitle("Unknown action!");
      return;
    }

    Themer.subscribe();
    document.title = "Auth - Moran Vending Machine";

    return () => {
      didMount.current = false;
      Themer.unsubscribe();
    };
  }, []);

  const init = (token: string) => {
    const key = process.env.REACT_APP_DB_SECRET_KEY || "";
    const secret = new TextEncoder().encode(key);
    jose
      .jwtVerify(token, secret, { algorithms: ["HS256"] })
      .then((data) => {
        if (!didMount.current) return;
        // console.log('header', protectedHeader);
        // console.log('payload', data.payload);

        // if (data.payload.exp) {
        //   const expired = Date.now() >= data.payload.exp * 1000;
        //   if (expired) {
        //     setTitle('Sorry, this link is expired. Please request a new link to continue');
        //     setType(null);
        //   } else {
        //   }
        // }
        if (data.payload.id && data.payload.email) {
          setSessionId(data.payload.id as string);
          setEmail(data.payload.email as string);
        } else {
          setTitle(
            "Sorry, this link is expired. Please request a new link to continue."
          );
          setType(null);
          return;
        }

        setTitle("Password Recover");
        setType("passwordRecover");
      })
      .catch((error: jose.errors.JOSEError) => {
        if (!didMount.current) return;
        // console.log('JWT error code', error.code);
        // console.log('JWT error message', error.message);
        if (error.code === "ERR_JWT_EXPIRED") {
          setTitle(
            "Sorry, this link is expired. Please request a new link to continue."
          );
        } else {
          setTitle(
            "Authentication error. Please request a new link to continue."
          );
        }
        setType(null);
      });
  };

  const onSubmitForPasswordRecover = (event: any) => {
    event.preventDefault();
    if (!didMount.current) return;

    // check if password is valid
    if (!validatePassword(password)) {
      setError("Password must be at least 6 characters");
      return;
    }

    // check if password and password_confirm match
    if (password !== password_confirm) {
      setError("Passwords do not match");
      return;
    }

    if (!sessionId) {
      setTitle(
        "Sorry, this link is expired. Please request a new link to continue."
      );
      setType(null);
      return;
    }

    setIsLoading(true);
    setError(null);

    databaseManager.recoverPassword(email, password, sessionId, {
      onResult: (result: any) => {
        if (!didMount.current) return;
        setIsLoading(false);
        if (result) {
          // console.log('result: ', result);
          if (result.success) {
            contextType.setIsAuthenticated(false);
            databaseManager.logoutUser({
              onResult: () => {},
              onError: () => {},
            });
            // const name = result.success.data[0].name;
            // const user_id = result.success.data[0].id;
            // const user_role = result.success.data[0].role;
            // localStorage.setItem('vending-un', name);
            // localStorage.setItem('vending-uid', user_id);
            // localStorage.setItem('vending-ur', user_role);

            // contextType.setIsAuthenticated(true);
            // let { from }: any = history.location.state || {
            //   from: { pathname: Routes.Dashboard },
            // };
            // history.replace(from);
            if (result.success.message && result.success.message.length > 0) {
              setError(result.success.message);
            }
            setTimeout(() => {
              history.replace(Routes.Login);
            }, 3000);
          } else {
            setError(result.error.message);
          }
        } else {
          setError("Sorry, something went wrong. Please try again later.");
        }
      },
      onError: (_err: any) => {
        if (!didMount.current) return;
        // console.log('fetch: error: ', err);
        setIsLoading(false);
        setError("Could not auth. Reason, User Login Error!");
      },
    });
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
    setIsInvalid(password.length < 3 || password_confirm.length < 3);
  };

  const onPasswordConfirmChange = (event: any) => {
    setPasswordConfirm(event.target.value);
    setIsInvalid(password.length < 3 || password_confirm.length < 3);
  };

  const validatePassword = (password: string) => {
    return password.length >= 6;
  };

  return (
    <div className="auth-main">
      <div className="auth-header">
        <span className="app-title">Moran Vending Machine</span>
        <div className="pallete-container">
          <label className="switch" title="Change theme">
            <input type="checkbox" className="checkbox" id="theme-btn" />
            <span className="toggle-thumb">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 20 20"
                style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}
              >
                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 20 20"
                style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}
              >
                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
              </svg>
            </span>
          </label>
        </div>
      </div>
      <div className="auth-form-container">
        <form
          className="form-container"
          onSubmit={
            type && type === "passwordRecover"
              ? onSubmitForPasswordRecover
              : () => {}
          }
        >
          <span className="auth-title">{title}</span>
          {type && type === "passwordRecover" && (
            <>
              <input
                required={true}
                className="password-input input"
                name="password"
                value={password}
                onChange={onPasswordChange}
                type="password"
                autoComplete="current-password"
                placeholder="Enter new password"
              />
              <input
                required={true}
                className="password-confirm-input input"
                name="password_confirm"
                value={password_confirm}
                onChange={onPasswordConfirmChange}
                type="password"
                autoComplete="current-password"
                placeholder="Confirm new password"
              />
              <button className="signup-btn" disabled={isInvalid} type="submit">
                Reset Password
              </button>
            </>
          )}
          {isLoading && (
            <i className="fa-li fa fa-spinner fa-spin loading-icon"></i>
          )}
          {error && <p className="message-box">{error}</p>}
          <Link to={Routes.Login} className="signup-btn">
            Login
          </Link>
          <Link to={Routes.Signup} className="signup-btn">
            Create Account
          </Link>
          <div className="footer-lay">
            <div className="footer-seg">
              <Link
                className="footer-link"
                to={Routes.Landing}
                target="_blank"
                rel="noreferrer"
              >
                Home
              </Link>
              <Link
                className="footer-link"
                to={Routes.Shop}
                target="_blank"
                rel="noreferrer"
              >
                Shop
              </Link>
              <Link
                className="footer-link"
                to={Routes.Privacy}
                target="_blank"
                rel="noreferrer"
              >
                Privacy
              </Link>
              <Link
                className="footer-link"
                to={Routes.Terms}
                target="_blank"
                rel="noreferrer"
              >
                Terms
              </Link>
            </div>
            <div className="footer-seg">
              <span className="footer-text">Copyright &copy;</span>
              <a
                className="footer-link"
                href="https://imperialinnovations.co.tz"
                target="_blank"
                rel="noreferrer"
              >
                Imperial Innovations
              </a>
              <span className="footer-text">2023</span>
            </div>
            <div className="footer-seg">
              <span className="footer-text">All Rights Reserved</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthPage;
